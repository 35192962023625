/* eslint-disable no-plusplus */
import React, { useState, useEffect } from "react";
import { css, StyleSheet } from "aphrodite";
import {
  greenBlue,
  white,
  black,
  transparent,
  buttonBlue,
} from "shared/styles/colors";
import { LanguagePreference, ScaleType } from "shared/types";
import Text, { Font } from "shared/components/Text";
import EffectivenessRatingImage from "static/images/EffectivenessRatingImage.png";
import { TranslationService } from "services/Translation/Translation.Service";
import { Identifiers, Page } from "services/Translation/enums";

interface Props {
  questionText: string;
  index: number;
  values: any;
  id: string;
  scaleType: ScaleType;
  image?: boolean;
  userLanguage: LanguagePreference;
}

const ScaleQuestion = ({ values, id, scaleType, userLanguage }: Props) => {
  const generateScale = (type) => {
    const scaleArray: number[] = [];
    if (type === ScaleType.ScaleFive) {
      for (let i = 1; i <= 5; i++) {
        scaleArray.push(i);
      }
    } else if (type === ScaleType.ScaleTen) {
      for (let i = 1; i <= 10; i++) {
        scaleArray.push(i);
      }
    } else if (type === ScaleType.EffectivenessRating) {
      for (let i = 0; i <= 5; i++) {
        scaleArray.push(i);
      }
    }
    return scaleArray;
  };

  const getButtonClass = (number) => {
    let buttonClassName = styles.ScaleButton;
    if (
      scaleType === ScaleType.ScaleFive ||
      scaleType === ScaleType.EffectivenessRating
    ) {
      if (selectedNumber === number) {
        buttonClassName = styles.ScaleButtonSelected;
      } else {
        buttonClassName = styles.ScaleButton;
      }
    } else if (selectedNumber === number) {
      buttonClassName = styles.smallScaleButtonSelected;
    } else {
      buttonClassName = styles.smallScaleButton;
    }
    return buttonClassName;
  };

  const mapableArray = generateScale(scaleType);
  const [selectedNumber, setSelectedNumber] = useState<number | null>(
    values[id]
  );
  const setScaleValue = (number) => {
    setSelectedNumber(number);
    values[id] = number;
  };

  useEffect(() => {
    if (values[id]) {
      setSelectedNumber(values[id]);
    }
  }, [values[id]]);

  const page = Page.QuestionPage;
  let scaleMessage;
  if (selectedNumber === 0 || selectedNumber === 1) {
    scaleMessage = TranslationService.getStaticText({
      page,
      userLanguage,
      textIdentifier: Identifiers.NotGettingIt,
    });
  } else if (selectedNumber === 2 || selectedNumber === 3) {
    scaleMessage = TranslationService.getStaticText({
      page,
      userLanguage,
      textIdentifier: Identifiers.GettingIt,
    });
  } else if (selectedNumber === 4 || selectedNumber === 5) {
    scaleMessage = TranslationService.getStaticText({
      page,
      userLanguage,
      textIdentifier: Identifiers.GotIt,
    });
  }
  return (
    <div className={css(styles.ScaleQuestionContainer)}>
      {scaleType === ScaleType.EffectivenessRating && (
        <img
          src={EffectivenessRatingImage}
          alt="Watering Flowers"
          title="Watering Flowers"
          className={css(styles.image)}
        />
      )}
      <div className={css(styles.scale)}>
        <div className={css(styles.innerScale)}>
          {mapableArray?.map((number, index) => {
            const className = getButtonClass(number); // TODO: Refactor using styled components, this solution is a bit messy
            return (
              <button
                type="button"
                className={css(styles.button)}
                key={index}
                onClick={() => setScaleValue(number)}
                aria-label=""
              >
                <Text style={className}>{number}</Text>
              </button>
            );
          })}
        </div>
      </div>
      {scaleType === ScaleType.EffectivenessRating && scaleMessage && (
        <div className={css(styles.scaleMessageContainer)}>
          <Text font={Font.ProximaNovaSemibold}>{scaleMessage}</Text>
        </div>
      )}
    </div>
  );
};

export default ScaleQuestion;

ScaleQuestion.defaultProps = {
  scaleType: ScaleType.EffectivenessRating,
};

const styles = StyleSheet.create({
  ScaleQuestionContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  scale: {
    width: "100%",
    background: buttonBlue,
    height: 47,
    borderRadius: 46,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  innerScale: {
    width: "97%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  ScaleButton: {
    height: 33,
    width: 33,
    color: white,
    fontSize: 16,
    lineHeight: 0.88,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "transparent",
    ":hover": {
      background: white,
      color: buttonBlue,
      cursor: "pointer",
    },
    transition: "300ms",
    borderRadius: 46,
  },
  ScaleButtonSelected: {
    height: 33,
    width: 33,
    borderRadius: 46,
    background: white,
    color: buttonBlue,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 16,
    ":hover": {
      cursor: "pointer",
    },
    transition: "300ms",
  },
  smallScaleButton: {
    height: 30,
    width: 30,
    color: white,
    fontSize: 16,
    lineHeight: 0.88,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "transparent",
    ":hover": {
      cursor: "pointer",
    },
    transition: "300ms",
    borderRadius: 46,
  },
  smallScaleButtonSelected: {
    height: 30,
    width: 30,
    borderRadius: 46,
    background: white,
    color: greenBlue,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 16,
    ":hover": {
      cursor: "pointer",
    },
    transition: "300ms",
  },
  progressBar: {
    width: 315,
    height: 47,
    background: "red",
    borderRadius: 46,
    position: "relative",
    transition: "500ms",
    left: 0,
  },
  scaleMessageContainer: {
    height: 36,
    width: 107,
    background: "rgba(14, 166, 180, .3)",
    borderRadius: 46,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
    marginTop: 20,
    marginBottom: 22,
  },
  scaleMessage: {
    fontSize: 16,
    lineHeight: 1.2,
    color: black,
  },
  image: {
    height: 202,
    width: 214,
  },
  button: {
    borderRadius: 46,
    background: transparent,
    padding: 0,
  },
});
