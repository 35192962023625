import { Variants } from "framer-motion";

export const gridTopicTransition: Variants = {
  spring: {
    transition: {
      type: "spring",
      stiffness: 300,
      damping: 30,
      duration: 1.5,
    },
  },
};

export const topicGridTransition: Variants = {
  fadeOut: {
    opacity: 0,
    transition: {
      duration: 1.6,
      easeInOut: [0.43, 0.13, 0.23, 0.96],
    },
  },
};

export const topicCarouselTransition: Variants = {
  fadeIn: {
    opacity: 1,
    position: "relative",
    transition: {
      duration: 1,
      easeInOut: [0.43, 0.13, 0.23, 0.96],
    },
  },
};

export const focusListTransition: Variants = {
  hidden: {
    y: 24,
    opacity: 0,
    width: "100%",
  },
  fadeIn: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.4,
      easeInOut: [0.43, 0.13, 0.23, 0.96],
    },
  },
};
