import gql from "graphql-tag";

export const TOPIC_USER_DATA = gql`
  query {
    topics {
      id
      color
      order
      translationData {
        name
      }
    }
    userLanguage
  }
`;

export const GRAPH_QUESTIONS_QUERY = gql`
  query PieChartQuestions($topicId: String!) {
    multiGraphedPieQuestions: graphQuestions(
      topicId: $topicId
      graphType: "MULTI_GRAPHED_PIE"
      ignoreUser: false
    ) {
      id
      translationData {
        text
      }
      graphTranslations {
        graphTitle
        bottomOfAxis
        topOfAxis
      }
    }
    singleGraphedPieQuestions: graphQuestions(
      topicId: $topicId
      graphType: "SINGLE_GRAPHED_PIE"
      ignoreUser: false
    ) {
      id
      translationData {
        text
      }
      graphTranslations {
        graphTitle
        bottomOfAxis
        topOfAxis
      }
    }
    singleGraphedLineQuestions: graphQuestions(
      topicId: $topicId
      graphType: "SINGLE_GRAPHED_LINE"
      ignoreUser: false
    ) {
      id
      translationData {
        text
      }
      graphTranslations {
        graphTitle
        bottomOfAxis
        topOfAxis
      }
    }
    comparisonSingleGraphedLineOne: graphQuestions(
      topicId: $topicId
      graphType: "COMPARISON_SINGLE_GRAPHED_LINE_ONE"
      ignoreUser: false
    ) {
      id
      translationData {
        text
      }
      graphTranslations {
        graphTitle
        bottomOfAxis
        topOfAxis
      }
    }
    comparisonSingleGraphedLineTwo: graphQuestions(
      topicId: $topicId
      graphType: "COMPARISON_SINGLE_GRAPHED_LINE_TWO"
      ignoreUser: false
    ) {
      id
      translationData {
        text
      }
      graphTranslations {
        graphTitle
        bottomOfAxis
        topOfAxis
      }
    }
    effectivenessRatingQuestions: graphQuestions(
      topicId: $topicId
      graphType: "EFFECTIVENESS_RATING"
      ignoreUser: false
    ) {
      id
      translationData {
        text
      }
      graphTranslations {
        graphTitle
        bottomOfAxis
        topOfAxis
      }
    }
    statsFocusData(topicId: $topicId) {
      topicCount
      selectedFocuses {
        translationData {
          name
          id
        }
      }
      unselectedFocuses {
        translationData {
          name
          id
        }
      }
    }
    topicsIncluded {
      translationData {
        name
      }
    }
  }
`;

export const COHORT_ADMIN_GRAPH_QUESTIONS_QUERY = gql`
  query PieChartQuestions($topicId: String!, $cohortId: String!) {
    multiGraphedPieQuestions: graphQuestions(
      topicId: $topicId
      graphType: "MULTI_GRAPHED_PIE"
      ignoreUser: true
      cohortId: $cohortId
    ) {
      id
      translationData {
        text
      }
      graphTranslations {
        graphTitle
        bottomOfAxis
        topOfAxis
      }
    }
    singleGraphedLineQuestions: graphQuestions(
      topicId: $topicId
      graphType: "SINGLE_GRAPHED_LINE"
      ignoreUser: true
      cohortId: $cohortId
    ) {
      id
      translationData {
        text
      }
      graphTranslations {
        graphTitle
        bottomOfAxis
        topOfAxis
      }
    }
    comparisonSingleGraphedLineOne: graphQuestions(
      topicId: $topicId
      graphType: "COMPARISON_SINGLE_GRAPHED_LINE_ONE"
      ignoreUser: true
      cohortId: $cohortId
    ) {
      id
      translationData {
        text
      }
      graphTranslations {
        graphTitle
        bottomOfAxis
        topOfAxis
      }
    }
    comparisonSingleGraphedLineTwo: graphQuestions(
      topicId: $topicId
      graphType: "COMPARISON_SINGLE_GRAPHED_LINE_TWO"
      ignoreUser: true
      cohortId: $cohortId
    ) {
      id
      translationData {
        text
      }
      graphTranslations {
        graphTitle
        bottomOfAxis
        topOfAxis
      }
    }
  }
`;
