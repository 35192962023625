import React from "react";
import styled from "@emotion/styled";
import { StyleSheet } from "aphrodite";
import Text, { Font } from "shared/components/Text";
import { buttonBlue, white } from "shared/styles/colors";
import { LanguagePreference } from "shared/types";
import { getTopicProgressFlower } from "./utils";

interface Props {
  topic: string;
  timesCompleted: number;
  topicOrder: number;
  userLanguage: LanguagePreference;
}

const TopicProgress = ({
  topic,
  timesCompleted,
  topicOrder,
  userLanguage,
}: Props) => {
  const topicData = getTopicProgressFlower({
    topicOrder,
    timesCompleted,
    userLanguage,
  });

  return (
    <TopicProgressContainer>
      <TextContainer>
        <Text font={Font.ProximaNovaRegular} style={styles.description}>
          {topic}
        </Text>
        <Text font={Font.ProximaNovaRegular} style={styles.subDescription}>
          {topicData.copy}
        </Text>
      </TextContainer>
      <FlowerImageContainer
        src={topicData.imgSrc}
        alt="flower"
        title="flower image"
      />
    </TopicProgressContainer>
  );
};

export default TopicProgress;

const TopicProgressContainer = styled.div({
  background: buttonBlue,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  paddingTop: 25,
  paddingLeft: 15,
  paddingRight: 20,
  paddingBottom: 20,
});

const TextContainer = styled.div({
  maxWidth: 200,
});

const FlowerImageContainer = styled.img({
  width: 105,
  height: 104,
  borderRadius: 555,
});

const styles = StyleSheet.create({
  title: {
    fontSize: 10,
    color: white,
    letterSpacing: 1.5,
    lineHeight: 1.5,
    textTransform: "uppercase",
  },
  description: {
    color: white,
    fontSize: 16,
    lineHeight: 1.2,
    margin: "6px 27px 3px 0",
  },
  subDescription: {
    color: white,
    fontSize: 12,
    lineHeight: 1.2,
  },
});
