import { QuestionType } from "@quick-check/common";

export const getInitialValues = (questionsArray, pageAnswers) => {
  const initialValues: any = {};
  if (pageAnswers?.pageHasResponses) {
    questionsArray.forEach((question) => {
      if (!initialValues[question.id]) {
        let questionResponse;
        // eslint-disable-next-line no-unused-expressions
        pageAnswers?.questionResponses?.forEach((response) => {
          if (response?.question?.id === question.id) {
            questionResponse = response;
          }
        });
        if ([QuestionType.SingleSelectWithOther].includes(question.type)) {
          let answer;
          let submittingWithOther;
          if (questionResponse?.answerText) {
            answer = questionResponse?.answerText;
            submittingWithOther = true;
          } else {
            answer = questionResponse?.choices[0].id;
            submittingWithOther = false;
          }
          initialValues[question.id] = {
            answer,
            submittingWithOther,
          };
        } else if (
          [
            QuestionType.SingleSelect,
            QuestionType.SingleGraphedLine,
            QuestionType.Dropdown,
            QuestionType.ComparisonSingleGraphedLineOne,
            QuestionType.ComparisonSingleGraphedLineTwo,
          ].includes(question.type)
        ) {
          initialValues[question.id] = questionResponse?.choices[0].id;
        } else if (
          [QuestionType.MultiSelectWithOther].includes(question.type)
        ) {
          const choiceIds = questionResponse?.choices?.map(
            (choice) => choice.id
          );
          initialValues[question.id] = {
            answer: choiceIds,
            answerText: questionResponse?.answerText,
          };
        } else if (
          [QuestionType.MultiSelect, QuestionType.MultiGraphedPie].includes(
            question.type
          )
        ) {
          const choiceIds = questionResponse?.choices?.map(
            (choice) => choice.id
          );
          initialValues[question.id] = choiceIds;
        } else if ([QuestionType.FreeText].includes(question.type)) {
          initialValues[question.id] = questionResponse?.answerText;
        } else if (
          [
            QuestionType.EffectivenessRating,
            QuestionType.ScaleFive,
            QuestionType.ScaleTen,
          ].includes(question.type)
        ) {
          initialValues[question.id] = questionResponse.answerInteger;
        }
      }
    });
  } else {
    questionsArray.forEach((question) => {
      if (!initialValues[question.id]) {
        if ([QuestionType.SingleSelectWithOther].includes(question.type)) {
          initialValues[question.id] = {
            answer: "",
            submittingWithOther: false,
          };
        } else if (question.type === QuestionType.MultiSelectWithOther) {
          initialValues[question.id] = {
            answer: [],
            answerText: "",
          };
        } else {
          initialValues[question.id] = "";
        }
      }
    });
  }
  return initialValues;
};
