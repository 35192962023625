import { useMutation } from "@apollo/react-hooks";
import styled from "@emotion/styled";
import gql from "graphql-tag";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useToasts } from "react-toast-notifications";
import {
  Page,
  Identifiers as TextIdentifiers,
  Identifiers,
} from "services/Translation/enums";
import { TranslationService } from "services/Translation/Translation.Service";
import Button from "shared/components/Button";
import Text, { Font } from "shared/components/Text";
import { settingsFormRed, transparent, turquoise } from "shared/styles/colors";
import { LanguagePreference } from "shared/types";
import SettingsCard from "../SettingsCard";
import { UPDATE_USER_INFO } from "../shared/mutations";
import * as EmailValidator from "email-validator";
import { Form, Input, sharedStyles } from "../shared/styledComponents";
import passwordValidator from "password-validator";

const UPDATE_PASSWORD = gql`
  mutation CreatePassword($inviteId: String!, $password: String!) {
    createPassword(inviteId: $inviteId, password: $password) {
      token
    }
  }
`;

interface Props {
  email: string;
  userLanguage: LanguagePreference;
  inviteId: string;
}

const LoginInfoForm = ({ email, userLanguage, inviteId }: Props) => {
  const { register, handleSubmit } = useForm({
    defaultValues: {
      email,
    },
  });
  const [editEmailLoginInfo, setEditEmailLoginInfo] = useState<boolean>(false);
  const [passwordIsEditable, setPasswordIsEditable] = useState<boolean>(false);
  const [emailValidationError, setEmailValidationError] = useState<boolean>(
    false
  );
  const [passwordValidationError, setPasswordValidationError] = useState<
    boolean
  >(false);
  const {
    register: passwordRegister,
    handleSubmit: passwordHandleSubmit,
  } = useForm();
  const { addToast } = useToasts();
  const [
    updateUserInfo,
    {
      data: updateUserData,
      error: updateUserError,
      loading: updateUserLoading,
    },
  ] = useMutation(UPDATE_USER_INFO);

  const [
    updatePassword,
    {
      data: updatePasswordData,
      error: updatePasswordError,
      loading: updatePasswordLoading,
    },
  ] = useMutation(UPDATE_PASSWORD);

  useEffect(() => {
    let message;
    if (updatePasswordData) {
      addToast("Success", {
        appearance: "success",
        autoDismiss: true,
      });
      setPasswordIsEditable(false);
    }
    if (updatePasswordError) {
      message = "There was an error updating your settings";
      if (
        updatePasswordError.graphQLErrors &&
        updatePasswordError.graphQLErrors.length > 0
      ) {
        message = updatePasswordError.graphQLErrors[0].message;
      }
      addToast(message, {
        appearance: "error",
        autoDismiss: true,
      });
    }
  }, [updatePasswordData, updatePasswordError]);

  useEffect(() => {
    let message;
    if (updateUserData) {
      setEditEmailLoginInfo(false);
      addToast("Success", {
        appearance: "success",
        autoDismiss: true,
      });
    }
    if (updateUserError) {
      message = "There was an error updating your settings";
      if (
        updateUserError.graphQLErrors &&
        updateUserError.graphQLErrors.length > 0
      ) {
        message = updateUserError.graphQLErrors[0].message;
      }
      addToast(message, {
        appearance: "error",
        autoDismiss: true,
      });
    }
    if (updateUserData && updateUserData.updateUserInfo?.updatedLanguage) {
      window.location.reload();
    }
  }, [updateUserData, updateUserError]);

  const onSubmit = (data) => {
    const emailIsValid = EmailValidator.validate(data.email);
    if (emailIsValid) {
      updateUserInfo({
        variables: {
          email: data.email,
        },
      });
      setEmailValidationError(false);
    } else {
      setEmailValidationError(true);
    }
  };

  // eslint-disable-next-line new-cap
  const fullPasswordValidator = new passwordValidator();
  fullPasswordValidator.has().letters().has().digits().has().not().spaces();
  const onPasswordSubmit = (data) => {
    if (
      data.password === data.confirmPassword &&
      data.password.length >= 8 &&
      fullPasswordValidator.validate(data.password)
    ) {
      updatePassword({
        variables: {
          inviteId,
          password: data.password,
        },
      });
      setPasswordValidationError(false);
    } else {
      setPasswordValidationError(true);
    }
  };

  const page = Page.Settings;
  const isFakeEmail =
    email.includes("@testing.com") || email.includes("@test.com");

  return (
    <SettingsCard
      hideEditButton={isFakeEmail}
      userLanguage={userLanguage}
      title={TranslationService.getStaticText({
        page,
        userLanguage,
        textIdentifier: Identifiers.Login,
      })}
      editState={editEmailLoginInfo}
      setEditState={setEditEmailLoginInfo}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        {!isFakeEmail && (
          <>
            <Text
              color={emailValidationError ? settingsFormRed : turquoise}
              style={sharedStyles.inputLabel}
              font={Font.ProximaNovaBold}
            >
              {TranslationService.getStaticText({
                page,
                userLanguage,
                textIdentifier: Identifiers.Email,
              })}
            </Text>
            <Input
              isError={emailValidationError}
              isActive={editEmailLoginInfo}
              {...register("email")}
              defaultValue={email}
              disabled={!editEmailLoginInfo}
            />
            {emailValidationError && (
              <Text
                style={sharedStyles.settingsError}
                font={Font.ProximaNovaSemibold}
              >
                {TranslationService.getStaticText({
                  page,
                  userLanguage,
                  textIdentifier: Identifiers.EmailError,
                })}
              </Text>
            )}
            {editEmailLoginInfo && (
              <Button
                ariaLabel={TranslationService.getStaticText({
                  page,
                  userLanguage,
                  textIdentifier: Identifiers.Save,
                })}
                type="submit"
                text={TranslationService.getStaticText({
                  page,
                  userLanguage,
                  textIdentifier: Identifiers.Save,
                })}
                buttonStyle={sharedStyles.buttonStyle}
                loading={updateUserLoading}
              />
            )}
          </>
        )}
      </Form>
      <Text
        style={sharedStyles.inputLabel}
        color={passwordValidationError ? settingsFormRed : turquoise}
        font={Font.ProximaNovaBold}
      >
        {TranslationService.getStaticText({
          page,
          userLanguage,
          textIdentifier: Identifiers.Password,
        })}
      </Text>
      <PasswordForm onSubmit={passwordHandleSubmit(onPasswordSubmit)}>
        <InputContainer>
          <ChangeButton
            onClick={() => setPasswordIsEditable(!passwordIsEditable)}
          >
            <Text
              font={Font.ProximaNovaBold}
              color={turquoise}
              style={sharedStyles.changeText}
            >
              {passwordIsEditable
                ? TranslationService.getStaticText({
                    page,
                    userLanguage,
                    textIdentifier: Identifiers.Nevermind,
                  })
                : TranslationService.getStaticText({
                    page,
                    userLanguage,
                    textIdentifier: Identifiers.Change,
                  })}
            </Text>
          </ChangeButton>
          <Input
            isError={passwordValidationError}
            isActive={passwordIsEditable}
            {...passwordRegister("password")}
            placeholder={
              passwordIsEditable
                ? TranslationService.getStaticText({
                    userLanguage,
                    page: Page.CreatePassword,
                    textIdentifier: TextIdentifiers.Password,
                  })
                : "******"
            }
            type="password"
            disabled={!passwordIsEditable}
          />
        </InputContainer>
        {passwordIsEditable && (
          <>
            <Input
              isError={passwordValidationError}
              isActive={passwordIsEditable}
              {...passwordRegister("confirmPassword")}
              placeholder={TranslationService.getStaticText({
                userLanguage,
                page: Page.CreatePassword,
                textIdentifier: TextIdentifiers.ConfirmPassword,
              })}
              type="password"
              disabled={!passwordIsEditable}
            />
            {passwordValidationError && (
              <Text style={sharedStyles.settingsError}>
                {TranslationService.getStaticText({
                  page,
                  userLanguage,
                  textIdentifier: Identifiers.PasswordError,
                })}
              </Text>
            )}
            {passwordValidationError && (
              <Text
                font={Font.ProximaNovaRegular}
                style={sharedStyles.explicitError}
              >
                {TranslationService.getStaticText({
                  page,
                  userLanguage,
                  textIdentifier: Identifiers.VerbosePasswordError,
                })}
              </Text>
            )}
            <Button
              ariaLabel={TranslationService.getStaticText({
                page,
                userLanguage,
                textIdentifier: Identifiers.Save,
              })}
              type="submit"
              text={TranslationService.getStaticText({
                page,
                userLanguage,
                textIdentifier: Identifiers.Save,
              })}
              buttonStyle={sharedStyles.buttonStyle}
              loading={updatePasswordLoading}
            />
          </>
        )}
      </PasswordForm>
    </SettingsCard>
  );
};

export default LoginInfoForm;

const InputContainer = styled.div({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-end",
});

const ChangeButton = styled.button({
  position: "relative",
  top: 30,
  right: 10,
  background: transparent,
});

const PasswordForm = styled.form({
  position: "relative",
  bottom: 14,
  display: "flex",
  flexDirection: "column",
});
