import { css, StyleSheet } from "aphrodite";
import React from "react";
import { useQuery } from "@apollo/react-hooks";
import Text, { Font } from "shared/components/Text";
import Spacer from "shared/components/Spacer";
import Button from "shared/components/Button";
import {
  turquoise,
  mainGrey,
  greyThree,
  greyAccent,
} from "shared/styles/colors";
import ReflectionStartImage from "static/images/ReflectionStartImage.png"; // Filler image
import gql from "graphql-tag";
import { useHistory } from "react-router";
import { ROUTES } from "../../constants";
import GraphqlErrorScreen from "shared/components/ErrorBoundary/GraphqlErrorScreen";
import { Identifiers, Page } from "services/Translation/enums";
import { TranslationService } from "services/Translation/Translation.Service";
import { standardDropShadow } from "shared/styles/constants";
import { useDocumentTitle } from "shared/hooks";
import NavigationHeader from "shared/components/Navigation/NavigationHeader";

const REFLECTION_START_QUERY = gql`
  query {
    currentUserFocus {
      translationData {
        name
      }
      topic {
        id
        translationData {
          name
        }
      }
    }
    firstSurveyPageId
    userLanguage
    userCompletedReflections {
      totalComplete
      totalReflections
    }
  }
`;

const ReflectionStart = () => {
  useDocumentTitle("Reflection Start | Reflectable");
  const { loading, error, data } = useQuery(REFLECTION_START_QUERY, {
    fetchPolicy: "no-cache",
  });
  const history = useHistory();

  if (loading) {
    return null;
  }

  if (error) {
    return <GraphqlErrorScreen error={error} />;
  }

  const onStartButtonClick = () => {
    history.push(`${ROUTES.QUESTIONS}/${data.firstSurveyPageId}`);
  };

  if (data) {
    const topicText = data.currentUserFocus?.topic?.translationData?.name;
    const focusText = data.currentUserFocus?.translationData?.name;
    const page = Page.ReflectionStart;
    const userLanguage = data?.userLanguage ?? "ENGLISH";
    const totalComplete = data.userCompletedReflections?.totalComplete;
    const totalReflections = data.userCompletedReflections?.totalReflections;
    return (
      <section className={css(styles.ReflectionStart)}>
        <div className={css(styles.ReflectionStartInnerContainer)}>
          <div className={css(styles.topAccent)} />
          <NavigationHeader />
          <Spacer height={10} flexGrow={0} />
          <Spacer />
          <img
            className={css(styles.image)}
            src={ReflectionStartImage}
            alt="Teacher Reflecting"
            title="Teacher Reflecting"
          />
          <Text style={styles.title} font={Font.ProximaNovaBold}>
            {TranslationService.getStaticText({
              page,
              userLanguage,
              textIdentifier: Identifiers.Title,
            })}
          </Text>
          <Text style={styles.header} font={Font.ProximaNovaBold}>
            {TranslationService.getStaticText({
              page,
              userLanguage,
              textIdentifier: Identifiers.TopicSelect,
            })}
          </Text>
          <Text style={styles.text} font={Font.ProximaNovaRegular}>
            {topicText}
          </Text>
          <div className={css(styles.border)} />
          <Text style={styles.header} font={Font.ProximaNovaBold}>
            {TranslationService.getStaticText({
              page,
              userLanguage,
              textIdentifier: Identifiers.FocusSelect,
            })}
          </Text>
          <Text style={styles.text} font={Font.ProximaNovaRegular}>
            {focusText}
          </Text>
          <div className={css(styles.border)} />
          <Text style={styles.header} font={Font.ProximaNovaBold}>
            {TranslationService.getStaticText({
              page,
              userLanguage,
              textIdentifier: Identifiers.CurrentWeek,
            })}
          </Text>
          <Text style={styles.text} font={Font.ProximaNovaRegular}>
            {totalComplete + 1} / {totalReflections}
          </Text>
          <Spacer />
        </div>
        <Spacer removeOnDesktop />
        <Button
          ariaLabel={TranslationService.getStaticText({
            page: Page.Shared,
            userLanguage,
            textIdentifier: Identifiers.ContinueButton,
          })}
          buttonStyle={styles.button}
          text={TranslationService.getStaticText({
            page: Page.Shared,
            userLanguage,
            textIdentifier: Identifiers.ContinueButton,
          })}
          onClick={() => onStartButtonClick()}
        />
        <Spacer flexGrow={0} height={5} />
      </section>
    );
  }
  return null;
};

export default ReflectionStart;

const styles = StyleSheet.create({
  ReflectionStart: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    minHeight: "100vh",
    paddingBottom: 40,
    "@media (min-width: 630px)": {
      justifyContent: "center",
    },
  },
  ReflectionStartInnerContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    "@media (min-width: 630px)": {
      width: 630,
      minHeight: 672,
      boxShadow: standardDropShadow,
      borderRadius: 10,
      overflow: "hidden",
      paddingBottom: 60,
      marginBottom: 40,
    },
  },
  topAccent: {
    display: "none",
    height: 10,
    width: "100%",
    backgroundColor: greyAccent,
    "@media (min-width: 630px)": {
      display: "block",
    },
  },
  image: {
    height: 205,
    width: "auto",
    marginBottom: 30,
    "@media (min-width: 630px)": {
      marginBottom: 10,
      height: 255,
    },
  },
  title: {
    color: turquoise,
    fontSize: 30,
    marginBottom: 30,
    maxWidth: 260,
    "@media (min-width: 630px)": {
      marginBottom: 68,
    },
  },
  header: {
    color: turquoise,
    fontSize: 12,
    lineHeight: 1.2,
    letterSpacing: 2,
    // paddingTop: 20,
    marginBottom: 5,
    textTransform: "uppercase",
    display: "flex",
    "@media (max-width: 360px)": {
      paddingTop: 10,
    },
  },
  text: {
    color: mainGrey,
    fontSize: 16,
    lineHeight: 1.2,
    marginBottom: 19,
    maxWidth: 315,
    "@media (min-width: 630px)": {
      maxWidth: 414,
      marginBottom: 19,
    },
  },
  border: {
    height: 1,
    width: "100%",
    maxWidth: 315,
    borderBottom: `1px solid ${greyThree}`,
    marginBottom: 20,
    "@media (min-width: 630px)": {
      maxWidth: 414,
    },
  },
  button: {},
});
