import { useLazyQuery } from "@apollo/react-hooks";
import { css, StyleSheet } from "aphrodite";
import gql from "graphql-tag";
import { toLower } from "lodash";
import React, { useEffect } from "react";
import Spinner from "react-spinkit";
import Card from "shared/components/Card";
import Text, { Font } from "shared/components/Text";
import {
  adminOrange,
  buttonBlue,
  mainGrey,
  pieOrangeOne,
  pieOrangeThree,
  pieOrangeTwo,
  turquoise,
  white,
} from "shared/styles/colors";
import { VictoryPie } from "victory";

const QUESTION_RESPONSE = gql`
  query($cohortId: String!, $topicId: String!, $questionId: String!) {
    cohortAdminBehaviorResponses(
      cohortId: $cohortId
      topicId: $topicId
      questionId: $questionId
    ) {
      responsesData
      percentageData {
        responseName
        maxPercentage
      }
    }
  }
`;

interface Props {
  topicId: string;
  questionId: string;
  cohortId: string;
  questionTitle: string;
}

const CohortAdminQuestionCard = ({
  topicId,
  questionId,
  cohortId,
  questionTitle,
}: Props) => {
  const [
    questionResponseQuery,
    { data, loading, error },
  ] = useLazyQuery(QUESTION_RESPONSE, { fetchPolicy: "no-cache" });
  let content;
  if (loading || error) {
    content = (
      <div className={css(styles.loadingContainer)}>
        <Spinner color={buttonBlue} fadeIn="none" name="line-scale" />
      </div>
    );
  }

  useEffect(() => {
    questionResponseQuery({ variables: { topicId, questionId, cohortId } });
  }, [topicId]);
  const colors = [pieOrangeThree, pieOrangeTwo, pieOrangeOne];

  function generatePieGraphValues(array) {
    const arrayToBeReturned: any = [];
    for (const [key, value] of Object.entries(array)) {
      arrayToBeReturned.push({
        x: key,
        y: value,
      });
    }
    return arrayToBeReturned;
  }

  if (data) {
    const {
      cohortAdminBehaviorResponses: {
        percentageData: { responseName, maxPercentage },
        responsesData,
      },
    } = data;
    const graphData = generatePieGraphValues(responsesData);
    const labels = {
      stroke: mainGrey,
      fontSize: "12px",
      lineHeight: 1.2,
      fontFamily: "ProximaNova",
      fontWeight: 500,
      letterSpacing: 0.3,
      maxWidth: 75,
    };
    content = (
      <>
        <Text font={Font.ProximaNovaBold} style={styles.title}>
          {questionTitle}
        </Text>
        <VictoryPie
          height={200}
          padding={35}
          colorScale={colors}
          data={graphData}
          labels={({ datum }) => `"${datum.x}"`}
          style={{
            labels,
            data: {
              padding: 500,
              fillOpacity: 1,
              stroke: white,
              strokeWidth: 2,
            },
          }}
        />
        <Text font={Font.ProximaNovaBold} style={styles.percentText}>
          {maxPercentage} of responses indicate {toLower(questionTitle)}{" "}
          {toLower(responseName) === "no change" && "showed "}
          {toLower(responseName)}
        </Text>
      </>
    );
  }
  return (
    <Card cardStyle={styles.cardStyle} contentStyle={styles.cardContent}>
      {content}
    </Card>
  );
};

export default CohortAdminQuestionCard;

const styles = StyleSheet.create({
  CohortAdminQuestionCard: {},
  cardStyle: {
    minHeight: 370,
    width: 390,
    marginBottom: 25,
  },
  cardContent: {
    paddingTop: 31,
    paddingLeft: 24,
    paddingRight: 24,
  },
  title: {
    textTransform: "uppercase",
    fontSize: 12,
    lineHeight: 1.2,
    letterSpacing: 2,
    color: turquoise,
    marginBottom: 38,
  },
  percentText: {
    color: adminOrange,
    textAlign: "center",
    lineHeight: 1.2,
    fontSize: 20,
    marginTop: 26,
    marginBottom: 10,
  },
  loadingContainer: {
    width: "100%",
    height: "100%",
    paddingBottom: 62,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  //
});
