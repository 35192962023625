import { Identifiers } from "../enums";
import { Translation } from "../Translation.Service";
import { LanguagePreference } from "../../../shared/types";

const ReflectionsSummaryTranslations: Translation = {
  [Identifiers.Title]: {
    [LanguagePreference.English]: "Reflections",
    [LanguagePreference.Spanish]: "Reflexiones",
    [LanguagePreference.Vietnamese]: "Suy ngẫm",
  },
  [Identifiers.SubTitle]: {
    [LanguagePreference.English]: "Select a Reflection to view:",
    [LanguagePreference.Spanish]: "Selecciona una Reflexión para ver:",
    [LanguagePreference.Vietnamese]: "Chọn một Suy ngẫm để xem:",
  },
  [Identifiers.Reflection]: {
    [LanguagePreference.English]: "Reflection",
    [LanguagePreference.Spanish]: "Reflexión",
    [LanguagePreference.Vietnamese]: "Suy ngẫm",
  },
  [Identifiers.ReflectionsSummary]: {
    [LanguagePreference.English]: "reflection summary",
    [LanguagePreference.Spanish]: "resumen de reflexión",
    [LanguagePreference.Vietnamese]: "TÓM TẮT SUY NGẫM",
  },
  [Identifiers.ReflectionIncomplete]: {
    [LanguagePreference.English]: "Reflection Incomplete",
    [LanguagePreference.Spanish]: "Reflexión incompleta",
    [LanguagePreference.Vietnamese]: "Phản ánh chưa đầy đủ",
  },
  [Identifiers.ReflectionIncompleteText]: {
    [LanguagePreference.English]: "One Or More Items Were Not Complete",
    [LanguagePreference.Spanish]: "Uno o más elementos no estaban completos",
    [LanguagePreference.Vietnamese]: "Một hoặc nhiều mục chưa hoàn thành",
  },
};

export default ReflectionsSummaryTranslations;
