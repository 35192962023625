import styled from "@emotion/styled";
import { StyleSheet } from "aphrodite";
import React, { Dispatch, SetStateAction } from "react";
import { Identifiers, Page } from "services/Translation/enums";
import { TranslationService } from "services/Translation/Translation.Service";
import Card from "shared/components/Card";
import Text, { Font } from "shared/components/Text";
import { transparent, turquoise } from "shared/styles/colors";
import { LanguagePreference } from "shared/types";

interface Props {
  children: any;
  title: string;
  editState: boolean;
  setEditState: Dispatch<SetStateAction<any>>;
  userLanguage: LanguagePreference;
  hideEditButton?: boolean;
}

const SettingsCard = ({
  children,
  title,
  editState,
  setEditState,
  userLanguage,
  hideEditButton = false,
}: Props) => {
  return (
    <Card cardStyle={styles.cardStyle} contentStyle={styles.contentStyle}>
      <TopContainer hideEditButton={hideEditButton}>
        <Text
          font={Font.ProximaNovaBold}
          color={turquoise}
          style={styles.title}
        >
          {title}
        </Text>
        {!hideEditButton && (
          <EditButton onClick={() => setEditState(!editState)}>
            <Text
              font={Font.ProximaNovaBold}
              color={turquoise}
              style={styles.changeText}
            >
              {editState
                ? TranslationService.getStaticText({
                    page: Page.Settings,
                    userLanguage,
                    textIdentifier: Identifiers.Nevermind,
                  })
                : TranslationService.getStaticText({
                    page: Page.Settings,
                    userLanguage,
                    textIdentifier: Identifiers.Edit,
                  })}
            </Text>
          </EditButton>
        )}
      </TopContainer>
      {children}
    </Card>
  );
};

export default SettingsCard;

const styles = StyleSheet.create({
  cardStyle: {
    minHeight: 162,
    marginBottom: 19,
  },
  contentStyle: {
    paddingTop: 20,
    paddingBottom: 21,
    padding: "20px 19px 21px",
    display: "flex",
    flexDirection: "column",
  },
  title: {
    fontSize: 16,
    lineHeight: 1.2,
    textTransform: "uppercase",
  },
  changeText: {
    textDecoration: "underline",
    cursor: "pointer",
  },
  dropDown: {},
});

const TopContainer = styled.div(
  ({ hideEditButton }: { hideEditButton: boolean }) => ({
    display: "flex",
    justifyContent: hideEditButton ? "flex-start" : "space-between",
    marginBottom: 12,
  })
);

const EditButton = styled.button({
  background: transparent,
});
