import styled from "@emotion/styled";
import React from "react";
import InvitationText from "../InvitationText";
import { Dropdown, DropdownProps } from "semantic-ui-react";
import { StyleSheet, css } from "aphrodite";
import { arrowGreen, greenBlue, white } from "shared/styles/colors";
import Text, { Font } from "shared/components/Text";
import { languageOptions, userTypeOptions } from "./constants";
import "./styles/aboutYouDropdown.scss";
import { TranslationService } from "services/Translation/Translation.Service";
import { Identifiers, Page } from "services/Translation/enums";
import { LanguagePreference } from "shared/types";
import Spacer from "shared/components/Spacer";
import { useHistory, useLocation } from "react-router";
import { getUserTypeTranslations } from "./utils";

interface Props {
  onDropDownChange: ({
    newLanguagePreference,
    newUserType,
  }: {
    newLanguagePreference?: string | undefined;
    newUserType?: string | undefined;
  }) => void;
  userLanguage: LanguagePreference;
}

const TellUsAboutYourself = ({ onDropDownChange, userLanguage }: Props) => {
  const history = useHistory();
  const location = useLocation();
  const defaultOptions: DropdownProps = {
    fluid: true,
    className: `${css(styles.inputSelect)} react-dropdown`,
    selection: true,
    compact: true,
  };
  const lanuageDropdownOptions: DropdownProps = {
    ...defaultOptions,
    options: languageOptions,
    onChange: (e, { value }) => {
      onDropDownChange({ newLanguagePreference: value?.toString() });
      history.push(`${location.pathname}?language=${value?.toString()}`);
    },
    defaultValue: userLanguage,
  };
  const userOptionsWithTranslations = getUserTypeTranslations({
    userTypes: userTypeOptions,
    userLanguage,
  });
  const userTypeDropdownOptions: DropdownProps = {
    ...defaultOptions,
    onChange: (e, { value }) =>
      onDropDownChange({ newUserType: value?.toString() }),
    options: userOptionsWithTranslations,
    defaultValue: userTypeOptions[0].value,
  };

  const page = Page.AboutYourself;

  return (
    <TellUsAboutYourselfContainer>
      <InvitationText
        titleText={TranslationService.getStaticText({
          page,
          userLanguage,
          textIdentifier: Identifiers.Title,
        })}
        paragraphText={TranslationService.getStaticText({
          page,
          userLanguage,
          textIdentifier: Identifiers.Body,
        })}
      />
      <OuterWrapper>
        <LabelRow justifyContent="flex-start">
          <Text font={Font.ProximaNovaSemibold} style={styles.text}>
            {TranslationService.getStaticText({
              page,
              userLanguage,
              textIdentifier: Identifiers.LanguagePreference,
            })}
            *
          </Text>
        </LabelRow>
        <Dropdown {...lanuageDropdownOptions} />
        <LabelRow>
          <Text font={Font.ProximaNovaSemibold} style={styles.text}>
            {TranslationService.getStaticText({
              page,
              userLanguage,
              textIdentifier: Identifiers.UserType,
            })}
            *
          </Text>
          <Text
            font={Font.ProximaNovaRegular}
            style={[styles.text, styles.smallText]}
          >
            {TranslationService.getStaticText({
              page,
              userLanguage,
              textIdentifier: Identifiers.Required,
            })}
          </Text>
        </LabelRow>
        <Dropdown {...userTypeDropdownOptions} />
      </OuterWrapper>
      <Spacer height={206} />
    </TellUsAboutYourselfContainer>
  );
};

export default TellUsAboutYourself;

const styles = StyleSheet.create({
  inputSelect: {
    background: white,
    border: `1px solid ${greenBlue}`,
    borderRadius: 8,
    minHeight: 60,
    width: "100%",
    maxWidth: 335,
    padding: "11px 40px 12px 20px",
    color: arrowGreen,
    fontSize: 16,
    textTransform: "capitalize",
    marginBottom: 24,
    display: "flex",
    alignItems: "center",
    ":hover": {
      outline: "2px solid dodgerblue",
    },
  },
  text: {
    marginBottom: 8,
    fontSize: 16,
  },
  smallText: {
    fontSize: 14,
  },
});

const TellUsAboutYourselfContainer = styled.div({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginTop: 22,
});

const OuterWrapper = styled.div({
  marginTop: 30,
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
});

const LabelRow = styled.div(
  ({ justifyContent = "space-between" }: { justifyContent?: string }) => ({
    display: "flex",
    justifyContent,
    width: "100%",
    maxWidth: 335,
  })
);
