import React, { useState } from "react";
import { css, StyleSheet } from "aphrodite";
import { Dropdown, DropdownProps, Icon } from "semantic-ui-react";
import {
  transparent,
  greenBlue,
  darkGreenBlue,
  activeBlue,
} from "shared/styles/colors";
import { LanguagePreference } from "shared/types";
import styled from "@emotion/styled";

interface Props {
  userLanguage: LanguagePreference;
  handleLanguagePreferenceChange: (currentLanguage) => void;
}

const InvitationDropDown = ({
  userLanguage,
  handleLanguagePreferenceChange,
}: Props) => {
  const [currentLanguage, setCurrentLanguage] = useState(userLanguage);

  const languages = Object.values(LanguagePreference).map((language) => {
    return language;
  });

  const selectableArray = languages.map((language, index) => ({
    key: index,
    text: language,
    value: language,
  }));

  const dropdownOptions: DropdownProps = {
    fluid: true,
    className: `${css(styles.invitationDropDown)} react-dropdown`,
    selection: true,
    compact: true,
    options: selectableArray,
    value: currentLanguage,
    onChange: (e, { value }) => {
      setCurrentLanguage(value as LanguagePreference);
      handleLanguagePreferenceChange(value as LanguagePreference);
    },
  };
  return (
    <Dropdown
      className={css(styles.invitationDropDown)}
      selection
      icon={<DropdownArrow name="angle down" />}
      {...dropdownOptions}
    />
  );
};

export default InvitationDropDown;

const styles = StyleSheet.create({
  invitationDropDown: {
    backgroundColor: transparent,
    border: `1px solid ${greenBlue}`,
    borderColor: greenBlue,
    borderRadius: 8,
    height: 60,
    padding: "22px 12px 19px 10px",
    marginBottom: 21,
    width: "100%",
    color: darkGreenBlue,
    "::placeholder": {
      color: darkGreenBlue,
    },
    ":focus": {
      border: `2px solid ${activeBlue}`,
      outline: "none",
    },
  },
});

const DropdownArrow = styled(Icon)({
  position: "absolute",
  right: 0,
  cursor: "pointer",
});
