import { css, StyleSheet } from "aphrodite";
import React, { useEffect } from "react";
import { mainGrey, redOrange, white } from "shared/styles/colors";
import Text, { Font } from "shared/components/Text";
import Button from "./Button";
import { useHistory } from "react-router";
import Modal from "react-modal";
import "../styles/modal.css";

interface Props {
  modalIsOpen: boolean;
  closeModal: () => void;
  setModalIsOpen: (modalIsOpen: boolean) => void;
}

const ExitModal = ({ modalIsOpen, closeModal, setModalIsOpen }: Props) => {
  const onModalOpen = () => {
    // included because "react-modal" does not prevent app scroll
    document.body.classList.add("hidden-body");
  };
  const onModalClose = () => {
    closeModal();
    document.body.classList.remove("hidden-body");
  };
  const modalStyle = {
    content: {
      width: "90%",
      maxWidth: "858px",
      height: "248px",
      borderRadius: "10px",
      backgroundColor: white,
      textAlign: "center",
      paddingTop: "50px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      zIndex: 3,
      position: "static",
    },
    overlay: {
      background: "rgba(0, 0, 0, .85)",
      zIndex: 500,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
  };

  const history = useHistory();
  const onExitButtonPress = () => {
    onModalClose();
    history.goBack();
  };

  useEffect(() => {
    window.onpopstate = () => {
      setModalIsOpen(!modalIsOpen);
    };
  }, []);

  Modal.setAppElement("#root");

  // TODO: Add translations: we may be rethinking the way this feature works
  return (
    <Modal
      isOpen={modalIsOpen}
      onAfterOpen={onModalOpen}
      onRequestClose={onModalClose}
      contentLabel="Example Modal"
      style={modalStyle}
    >
      <div className={css(styles.modal)}>
        <Text font={Font.ProximaNovaSemibold} style={styles.modalText}>
          Your answers won’t be saved if you go back. Do you still want to go
          back?
        </Text>
        <Button
          textStyle={styles.buttonText}
          buttonStyle={styles.exitButton}
          onClick={() => onExitButtonPress()}
          text="go back"
          ariaLabel="go back"
        />
        <Button
          textStyle={styles.buttonText}
          buttonStyle={styles.cancelButton}
          onClick={() => onModalClose()}
          text="cancel"
          ariaLabel="cancel"
        />
      </div>
    </Modal>
  );
};

export default ExitModal;

const styles = StyleSheet.create({
  modal: {
    width: "100%",
    height: 248,
    borderRadius: 10,
    backgroundColor: white,
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  modalText: {
    color: mainGrey,
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 30,
    "@media (min-width: 630px)": {
      fontSize: 20,
    },
  },
  exitButton: {
    width: 155,
    height: 40,
    borderRadius: 8,
    backgroundColor: redOrange,
    marginBottom: 10,
  },
  cancelButton: {
    width: 155,
    height: 40,
    borderRadius: 8,
    // backgroundColor: redOrange,
    marginBottom: 10,
  },
  buttonText: {
    fontSize: 16,
    color: white,
    textTransform: "uppercase",
  },
});
