import React, { useEffect, useState } from "react";
import { LanguagePreference, QuestionItem, ScaleType } from "shared/types";
import { css, StyleSheet } from "aphrodite";
import MultiSelect from "../MultiSelect/MultiSelect";
import DropDown from "../DropDown/DropDown";
import { greyAccent, redOrange } from "shared/styles/colors";
import ScaleQuestion from "../ScaleQuestion/ScaleQuestion";
import SingleSelect from "../SingleSelect/SingleSelect";
import FreeText from "../FreeText/FreeText";
import ActionText from "../ActionText";
import QuestionText from "../../../shared/components/QuestionText/QuestionText";
import { QuestionType } from "@quick-check/common";
import { TranslationService } from "services/Translation/Translation.Service";
import { Identifiers, Page } from "services/Translation/enums";

interface Props {
  question: QuestionItem;
  index: number;
  values: any | null;
  handleChange?: (any) => void | null;
  handleSubmit?: (any) => void | null;
  scaleType?: ScaleType;
  isSubmitting: boolean;
  hasResponses: boolean;
  userLanguage: LanguagePreference;
}

const Question = ({
  question,
  index,
  values,
  handleChange,
  handleSubmit,
  scaleType,
  isSubmitting,
  hasResponses,
  userLanguage,
}: Props) => {
  const [isError, setIsError] = useState(false);
  const [hasFirstRendered, setHasFirstRendered] = useState(true);
  useEffect(() => {
    // prevent effect from running on first render
    if (hasFirstRendered) {
      setHasFirstRendered(false);
      return;
    }
    if (
      isSubmitting &&
      [QuestionType.MultiSelectWithOther].includes(question.type) &&
      !values[question.id]?.answer.length &&
      !values[question.id]?.answerText
    ) {
      setIsError(true);
    } else if (values[question.id] || !isSubmitting) {
      setIsError(false);
    } else {
      setIsError(true);
    }
  }, [handleSubmit, isSubmitting]);

  const translationText = question.translationData.text;

  let content;
  switch (question.type) {
    case QuestionType.Dropdown: {
      content = (
        <DropDown
          hasResponse={hasResponses}
          questionText={translationText}
          index={index}
          answersArray={question.choices}
          values={values}
          id={question.id}
        />
      );
      break;
    }
    case QuestionType.FreeText: {
      content = (
        <FreeText
          handleChange={handleChange}
          id={question.id}
          inputValue={values[question.id]}
          userLanguage={userLanguage}
        />
      );
      break;
    }
    case QuestionType.MultiGraphedPie:
    case QuestionType.MultiSelect: {
      content = (
        <MultiSelect
          userLanguage={userLanguage}
          values={values}
          questionText={translationText}
          answersArray={question.choices}
          index={index}
          id={question.id}
        />
      );
      break;
    }
    case QuestionType.MultiSelectWithOther: {
      content = (
        <MultiSelect
          values={values}
          questionText={translationText}
          answersArray={question.choices}
          index={index}
          id={question.id}
          withOther
          userLanguage={userLanguage}
        />
      );
      break;
    }
    case QuestionType.ScaleFive: {
      content = (
        <ScaleQuestion
          scaleType={scaleType}
          values={values}
          questionText={translationText}
          index={index}
          id={question.id}
          userLanguage={userLanguage}
        />
      );
      break;
    }
    case QuestionType.EffectivenessRating: {
      content = (
        <ScaleQuestion
          scaleType={scaleType}
          values={values}
          questionText={translationText}
          index={index}
          id={question.id}
          userLanguage={userLanguage}
        />
      );
      break;
    }
    case QuestionType.ScaleTen: {
      content = (
        <ScaleQuestion
          scaleType={scaleType}
          values={values}
          questionText={translationText}
          index={index}
          id={question.id}
          image={false}
          userLanguage={userLanguage}
        />
      );
      break;
    }
    case QuestionType.SingleGraphedLine:
    case QuestionType.ComparisonSingleGraphedLineOne:
    case QuestionType.ComparisonSingleGraphedLineTwo:
    case QuestionType.SingleGraphedPie:
    case QuestionType.SingleSelect: {
      content = (
        <SingleSelect
          values={values}
          questionText={translationText}
          answersArray={question.choices}
          index={index}
          id={question.id}
          userLanguage={userLanguage}
        />
      );
      break;
    }
    case QuestionType.SingleSelectWithOther: {
      content = (
        <SingleSelect
          values={values}
          questionText={translationText}
          answersArray={question.choices}
          index={index}
          id={question.id}
          withOther
          userLanguage={userLanguage}
        />
      );
      break;
    }
    default:
      return null;
  }
  return (
    <div key={index} className={css(styles.QuestionWrapper)}>
      <div
        className={isError ? css(styles.errorAccent) : css(styles.topAccent)}
      />
      {scaleType === ScaleType.EffectivenessRating && (
        // TODO make text editable
        <ActionText
          text={TranslationService.getStaticText({
            page: Page.QuestionPage,
            userLanguage,
            textIdentifier: Identifiers.EffectivenessRatingText,
          })}
        />
      )}
      <div className={css(styles.innerContainer)}>
        <QuestionText
          questionNumber={index}
          questionText={question?.translationData.text}
        />
        <>{content}</>
      </div>
    </div>
  );
};

export default Question;

const styles = StyleSheet.create({
  QuestionWrapper: {
    width: 355,
    minHeight: 169,
    boxShadow: "0 2px 2px 1px rgba(0, 0, 0, .1)",
    borderRadius: 10,
    paddingBottom: 20,
    marginBottom: 40,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "@media (min-width: 630px)": {
      maxWidth: 630,
      width: "100%",
    },
  },
  topAccent: {
    backgroundColor: greyAccent,
    width: "100%",
    height: 10,
    borderRadius: "10px 10px 0 0",
    marginBottom: 20,
    transition: "all 300ms",
  },
  errorAccent: {
    backgroundColor: redOrange,
    width: "100%",
    height: 10,
    borderRadius: "10px 10px 0 0",
    marginBottom: 20,
    transition: "all 300ms",
  },
  innerContainer: {
    width: 315,
    "@media (min-width: 630px)": {
      maxWidth: 510,
      width: "100%",
      marginRight: 60,
      marginLeft: 60,
    },
  },
});
