import { Identifiers } from "../enums";
import { Translation } from "../Translation.Service";
import { LanguagePreference } from "../../../shared/types";

const sharedTranslations: Translation = {
  [Identifiers.ContinueButton]: {
    [LanguagePreference.English]: "Continue",
    [LanguagePreference.Spanish]: "Continuar",
    [LanguagePreference.Vietnamese]: "Tiếp tục",
  },
  [Identifiers.BackButton]: {
    [LanguagePreference.English]: "Back",
    [LanguagePreference.Spanish]: "Volver",
    [LanguagePreference.Vietnamese]: "QUAY LẠI",
  },
  [Identifiers.StepOne]: {
    [LanguagePreference.English]: "Step 1: Positive Mindset",
    [LanguagePreference.Spanish]: "Paso 1: Mentalidad positiva",
    [LanguagePreference.Vietnamese]: "BƯỚC 1: SỰ SUY NGHĨ TÍCH CỰC",
  },
  [Identifiers.SubmitButton]: {
    [LanguagePreference.English]: "Submit",
    [LanguagePreference.Spanish]: "Siguiente",
    [LanguagePreference.Vietnamese]: "Xác nhận gửi đi",
  },
  [Identifiers.Step]: {
    [LanguagePreference.English]: "Step",
    [LanguagePreference.Spanish]: "Paso",
    [LanguagePreference.Vietnamese]: "Bước",
  },
  [Identifiers.Week]: {
    [LanguagePreference.English]: "Week",
    [LanguagePreference.Spanish]: "Semana",
    [LanguagePreference.Vietnamese]: "Tuần",
  },
  [Identifiers.Other]: {
    [LanguagePreference.English]: "Other",
    [LanguagePreference.Spanish]: "Otro",
    [LanguagePreference.Vietnamese]: "Khác",
  },
};

export default sharedTranslations;
