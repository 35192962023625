export enum Page {
  Login = "Login",
  CreatePassword = "CreatePassword",
  UserInvited = "UserInvited",
  IntroOneFirstTime = "IntroOneFirstTime",
  IntroOneReturning = "IntroOneReturning",
  HowItWorks = "HowItWorks",
  HowItWorksReturning = "HowItWorksReturning",
  IntroTwoFirstTime = "IntroTwoFirstTime",
  IntroTwoReturning = "IntroTwoReturning",
  IntroThreeFirstTime = "IntroThreeFirstTime",
  IntroThreeReturning = "IntroThreeReturning",
  TopicScreen = "TopicScreen",
  TopicProgress = "TopicProgress",
  Shared = "Shared",
  IntroComplete = "IntroComplete",
  QuestionPage = "QuestionPage",
  StepThreeIntro = "StepThreeIntro",
  DeepBreath = "DeepBreath",
  ReflectionStart = "ReflectionStart",
  ReflectionComplete = "ReflectionComplete",
  ReflectionEnd = "ReflectionEnd",
  Stats = "Stats",
  ReflectionsSummary = "ReflectionsSummary",
  Dashboard = "Dashboard",
  Resources = "Resources",
  Settings = "Settings",
  Menu = "Menu",
  ForgotPassword = "ForgotPassword",
  AboutYourself = "AboutYourself",
  Graphs = "Graphs",
  PersonalizeReflectable = "PersonalizeReflectable",
}

export enum Identifiers {
  About = "About",
  AboutParagraph = "AboutParagraph",
  AboutTitle = "AboutTitle",
  Action = "Action",
  AdditionalFocus = "AdditionalFocus",
  AllTopics = "AllTopics",
  Articles = "Articles",
  BulletOne = "BulletOne",
  BulletTwo = "BulletTwo",
  BulletThree = "BulletThree",
  BackButton = "BackButton",
  Body = "Body",
  BodyOne = "BodyOne",
  BodyTwo = "BodyTwo",
  By = "By",
  Change = "Change",
  CompleteButton = "CompleteButton",
  ConfirmPassword = "ConfirmPassword",
  ConfirmPasswordDefault = "ConfirmPasswordDefault",
  ConfirmPasswordError = "ConfirmPasswordError",
  ContinueButton = "ContinueButton",
  CreateAccountButton = "CreateAccountButton",
  CreatePassword = "CreatePassword",
  CurrentFocus = "CurrentFocus",
  CurrentWeek = "CurrentWeek",
  Dashboard = "Dashboard",
  Edit = "Edit",
  EffectivenessRatingText = "EffectivenessRatingText",
  EffectivenessX = "EffectivenessX",
  EffectivenessY = "EffectivenessY",
  Email = "Email",
  EmailDefault = "EmailDefault",
  EmailError = "EmailError",
  EndOfErrorMessage = "EndOfErrorMessage",
  ExecutiveLeadership = "ExecutiveLeadership",
  FamilyChildCareProvider = "FamilyChildCareProvider",
  FamilyEngagementWorker = "FamilyEngagementWorker",
  FirstSlideTitle = "FirstSlideTitle",
  FirstSlideParagraph = "FirstSlideParagraph",
  FreeTextInput = "FreeTextInput",
  SecondSlideTitle = "SecondSlideTitle",
  SecondSlideParagraph = "SecondSlideParagraph",
  ThirdSlideTitle = "ThirdSlideTitle",
  ThirdSlideParagraph = "ThirdSlideParagraph",
  FourthSlideTitle = "FourthSlideTitle",
  FourthSlideParagraph = "FourthSlideParagraph",
  FocusSelect = "FocusSelect",
  ForgotPassword = "ForgotPassword",
  FullName = "FullName",
  FullNameDefault = "FullNameDefault",
  FullNameError = "FullNameError",
  GettingIt = "GettingIt",
  GotIt = "GotIt",
  HomeVisitor = "HomeVisitor",
  Intro = "Intro",
  IntroParagraph = "IntroParagraph",
  IntroSubtitle = "IntroSubtitle",
  IntroTitle = "IntroTitle",
  Language = "Language",
  LanguagePreference = "LanguagePreference",
  Login = "Login",
  LogOut = "LogOut",
  MyProgress = "MyProgress",
  Nevermind = "Nevermind",
  NextButton = "NextButton",
  Nickname = "Nickname",
  NicknameDefault = "NicknameDefault",
  NicknameError = "NicknameError",
  NotEnough = "NotEnough",
  NotEnoughText = "NotEnoughText",
  NotGettingIt = "NotGettingIt",
  Other = "Other",
  OutOf = "OutOf",
  Paraprofessional = "Paraprofessional",
  Parent = "Parent",
  Password = "Password",
  PasswordDefault = "PasswordDefault",
  PasswordDigitsError = "PasswordDigitsError",
  PasswordError = "PasswordError",
  PasswordLengthError = "PasswordLengthError",
  PasswordLettersError = "PasswordLettersError",
  PasswordSpacesError = "PasswordSpacesError",
  Passwords = "Passwords",
  PersonalInfo = "PersonalInfo",
  PhoneNumber = "PhoneNumber",
  PhoneNumberDefault = "PhoneNumberDefault",
  PhoneError = "PhoneError",
  PleaseNote = "PleaseNote",
  PleaseSpecify = "PleaseSpecify",
  PluralStartOfErrorMessage = "PluralStartOfErrorMessage",
  Preferences = "Preferences",
  Practiced = "Practiced",
  Progress = "Progress",
  QuestionPageStepThreeSubtitle = "QuestionPageStepThreeSubtitle",
  QuestionPageStepThreeTitle = "QuestionPageStepThreeTitle",
  QuestionPageStepTwoSubtitle = "QuestionPageStepTwoSubtitle",
  QuestionPageStepTwoTitle = "QuestionPageStepTwoTitle",
  Reflection = "Reflection",
  ReflectionCardSubTitle = "ReflectionCardSubTitle",
  ReflectionsCompletedCardTitle = "ReflectionsCompletedCardTitle",
  ReflectionsCompletedCardSubTitle = "ReflectionsCompletedCardSubTitle",
  ReflectionCardTitle = "ReflectionCardTitle",
  ReflectionCardTitleButton = "ReflectionCardTitleButton",
  ReflectionIncomplete = "ReflectionIncomplete",
  ReflectionIncompleteText = "ReflectionIncompleteText",
  ReflectionsSummary = "ReflectionsSummary",
  Required = "Required",
  Resources = "Resources",
  Save = "Save",
  SchoolOrSiteDirectorSupervisor = "SchoolOrSiteDirectorSupervisor",
  Settings = "Settings",
  SignUp = "SignUp",
  SignUpParagraph = "SignUpParagraph",
  SignUpTitle = "SignUpTitle",
  SkipButton = "SkipButton",
  SplitTitleOne = "SplitTitleOne",
  SplitTitleTwo = "SplitTitleTwo",
  StartOfErrorMessage = "StartOfErrorMessage",
  Step = "Step",
  StepFive = "StepFive",
  StepFour = "StepFour",
  StepOne = "StepOne",
  StepThree = "StepThree",
  StepTwo = "StepTwo",
  SubTitle = "SubTitle",
  SubTitleFirstTime = "SubTitleFirstTime",
  SubmitButton = "SubmitButton",
  Teacher = "Teacher",
  TechnicalAssistanceProvider = "TechnicalAssistanceProvider",
  TermsAndConditionsAgreement = "TermsAndConditionsAgreement",
  TermsAndConditionsFirst = "TermsAndConditionsFirst",
  TermsAndConditionsSecond = "TermsAndConditionsSecond",
  TermsAndConditionsThird = "TermsAndConditionsThird",
  Time = "Time",
  Times = "Times",
  Title = "Title",
  TopicSelect = "TopicSelect",
  TopicsInclude = "TopicsInclude",
  UnlockedTopicBody = "UnlockedTopicBody",
  UnlockedTopicsBody = "UnlockedTopicsBody",
  UnlockedTitle = "UnlockedTitle",
  UserType = "UserType",
  VerbosePasswordError = "VerbosePasswordError",
  VerbosePhoneError = "VerbosePhoneError",
  Videos = "Videos",
  ViewProgress = "ViewProgress",
  ViewReflections = "ViewReflections",
  Vietnamese = "Vietnamese",
  Websites = "Websites",
  Week = "Week",
  Curriculum = "Curriculum",
  ModalTitle = "ModalTitle",
  ModalFirstParagraph = "ModalFirstParagraph",
  English = "ENGLISH",
  Spanish = "SPANISH",
  Organization = "Organization",
  Account = "Account",
  WelcomeSubtitle = "WelcomeSubtitle",
  Positive = "Positive",
  Challenging = "Challenging",
  WeeksPracticed = "WeeksPracticed",
  Behaviors = "Behaviors",
  Effectiveness = "Effectiveness",
  MyEffectiveness = "MyEffectiveness",

  TopicProgressOne = "TopicProgressOne",
  TopicProgressTwo = "TopicProgressTwo",
  TopicProgressThree = "TopicProgressThree",
  TopicProgressFour = "TopicProgressFour",
}
