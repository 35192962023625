import React, { useState } from "react";
import Text, { Font } from "shared/components/Text";
import { StyleSheet, css } from "aphrodite";
import {
  turquoise,
  buttonBlue,
  mainGrey,
  lightGrey,
  black,
} from "shared/styles/colors";
import Step from "./Step";
import HowItWorksImage from "static/images/HowItWorksImage.png";
import Button from "shared/components/Button";
import { useHistory } from "react-router-dom";
import Spacer from "shared/components/Spacer";
import { ROUTES } from "../../../../constants";
import { TranslationService } from "services/Translation/Translation.Service";
import { Identifiers, Page } from "services/Translation/enums";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import ResponsiveCard from "shared/components/ResposniveCard";
import { useDocumentTitle } from "shared/hooks";
import { FormCheckbox } from "semantic-ui-react";
import styled from "@emotion/styled";
import CookiesService, {
  Identifiers as CookieIdentifiers,
} from "services/Cookie/CookieService";
import ScrollToTopOnMount from "shared/components/ScrollToTopOnMount";
import NavigationHeader from "shared/components/Navigation/NavigationHeader";

interface Props {
  location?: any;
}

const USER_LANGUAGE = gql`
  query UserLanguage {
    userLanguage
  }
`;

const HowItWorks = ({ location }: Props) => {
  useDocumentTitle("How It Works | Reflectable");
  const { data } = useQuery(USER_LANGUAGE);
  const userLanguage = data?.userLanguage ?? "ENGLISH";
  const isFirstTime = location?.state?.isFirstTime;
  const page = isFirstTime ? Page.HowItWorks : Page.HowItWorksReturning;
  const history = useHistory();
  const [isChecked, setIsChecked] = useState(false);

  const onButtonClick = () => {
    if (isChecked) {
      CookiesService.setCookie(
        CookieIdentifiers.HideIntroScreens,
        isChecked.toString()
      );
    }
    history.push(ROUTES.INTRO_ONE, { isFirstTime });
  };

  const translationOptions = { page, userLanguage };
  return (
    <>
      <ScrollToTopOnMount />
      <div className={css(styles.HowItWorks)}>
        <Spacer />
        <ResponsiveCard
          cardStyle={styles.cardStyle}
          contentStyle={styles.cardContentStyle}
        >
          {!isFirstTime && <NavigationHeader hideBackButton />}
          <img
            src={HowItWorksImage}
            alt="Person With Map"
            title="Person With Map"
          />
          <Text font={Font.ProximaNovaBold} style={styles.title}>
            {TranslationService.getStaticText({
              ...translationOptions,
              textIdentifier: Identifiers.Title,
            })}
          </Text>
          <>
            <Step
              stepText={TranslationService.getStaticText({
                page: Page.HowItWorks,
                textIdentifier: Identifiers.StepOne,
                userLanguage,
              })}
            />
            <Step
              stepText={TranslationService.getStaticText({
                page: Page.HowItWorks,
                textIdentifier: Identifiers.StepTwo,
                userLanguage,
              })}
            />
            <Step
              stepText={TranslationService.getStaticText({
                ...translationOptions,
                textIdentifier: Identifiers.StepThree,
              })}
            />
          </>
        </ResponsiveCard>
        <Spacer removeOnDesktop />
        <Button
          ariaLabel={TranslationService.getStaticText({
            textIdentifier: Identifiers.ContinueButton,
            ...translationOptions,
          })}
          text={TranslationService.getStaticText({
            textIdentifier: Identifiers.ContinueButton,
            ...translationOptions,
          })}
          buttonStyle={styles.button}
          textStyle={styles.buttonText}
          onClick={onButtonClick}
        />
        {!isFirstTime && (
          <CheckBoxContainer>
            <StyledCheckBox onClick={() => setIsChecked(!isChecked)} />{" "}
            <Text style={styles.checkText} font={Font.ProximaNovaRegular}>
              {TranslationService.getStaticText({
                ...translationOptions,
                textIdentifier: Identifiers.SkipButton,
              })}
            </Text>
          </CheckBoxContainer>
        )}
        <Spacer />
      </div>
    </>
  );
};

const StyledCheckBox = styled(FormCheckbox)({
  marginRight: 8,
});

const CheckBoxContainer = styled.div({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});
const styles = StyleSheet.create({
  HowItWorks: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minHeight: "100vh",
    padding: "0 10px 75px",
  },
  cardStyle: {
    "@media (min-width: 630px)": {
      marginTop: 10,
    },
    marginBottom: 20,
  },
  cardContentStyle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    "@media (min-width: 630px)": {
      paddingTop: 20,
    },
  },
  image: {
    marginTop: 10,
    marginBottom: 30,
    "@media (max-width: 360px)": {
      marginTop: 40,
      marginBottom: 15,
      width: "50%",
      height: "50%",
    },
  },
  title: {
    fontSize: 36,
    lineHeight: 1.2,
    color: turquoise,
    marginBottom: 33,
    textAlign: "center",
    "@media (max-width: 360px)": {
      fontSize: 30,
    },
  },
  quickTipHeader: {
    display: "flex",
    fontSize: 12,
    lineHeight: 1.2,
    letterSpacing: 2,
    color: mainGrey,
    textTransform: "uppercase",
    marginTop: 23,
    "@media (max-width: 360px)": {
      fontSize: 8,
    },
  },
  quickTipText: {
    textAlign: "center",
    fontSize: 12,
    lineHeight: 1.21,
    color: lightGrey,
    width: 259,
    marginBottom: 20,
    "@media (max-width: 360px)": {
      fontSize: 10,
    },
  },
  button: {
    width: 355,
    height: 60,
    borderRadius: 55,
    backgroundColor: buttonBlue,
    marginBottom: 20,
    "@media (max-width: 360px)": {
      width: "80%",
      height: 45,
    },
  },
  buttonText: {
    fontSize: 20,
    lineHeight: 1.2,
    "@media (max-width: 360px)": {
      fontSize: 15,
    },
  },
  checkText: {
    fontSize: 14,
    color: black,
  },
});

export default HowItWorks;
