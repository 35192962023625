import React from "react";
import { css, StyleSheet } from "aphrodite";
import Text, { Font } from "shared/components/Text";
import { turquoise, mainGrey } from "shared/styles/colors";

interface Props {
  stepText: string;
}

const Step = ({ stepText }: Props) => {
  const adjustedStepText = stepText
    .split("\\n")
    .map((item) => <p key={item}>{item}</p>);
  return (
    <div className={css(styles.StepContainer)}>
      <Text font={Font.ProximaNovaRegular} style={styles.text}>
        {adjustedStepText}
      </Text>
    </div>
  );
};

export default Step;

const styles = StyleSheet.create({
  StepContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: 20,
    maxWidth: 300,
  },
  text: {
    fontSize: 20,
    lineHeight: 1.2,
    textAlign: "center",
    color: mainGrey,
  },
});
