import { Identifiers } from "../enums";
import { Translation } from "../Translation.Service";
import { LanguagePreference } from "../../../shared/types";

const translations: Translation = {
  [Identifiers.Title]: {
    [LanguagePreference.English]: "“Starting is the hardest part...”",
    [LanguagePreference.Spanish]: "“Empezar es la parte más difícil...”",
    [LanguagePreference.Vietnamese]: "“Bắt đầu là phần khó nhất...”",
  },
  [Identifiers.Body]: {
    [LanguagePreference.English]: "...and we’re here to help you.",
    [LanguagePreference.Spanish]: "...y estamos aquí para ayudarte.",
    [LanguagePreference.Vietnamese]: "...và chúng tôi ở đây để giúp bạn.",
  },
  [Identifiers.BodyTwo]: {
    [LanguagePreference.English]: "Use this tool to:",
    [LanguagePreference.Spanish]: "Utilice esta herramienta para:",
    [LanguagePreference.Vietnamese]: "Sử dụng công cụ này để:",
  },
  [Identifiers.BulletOne]: {
    [LanguagePreference.English]: "Remember ideas from training ",
    [LanguagePreference.Spanish]: "Recordar ideas de entrenamiento",
    [LanguagePreference.Vietnamese]: "Ghi nhớ ý tưởng từ buổi đào tạo",
  },
  [Identifiers.BulletTwo]: {
    [LanguagePreference.English]: "Reflect on your practice",
    [LanguagePreference.Spanish]: "Reflexionar sobre su práctica",
    [LanguagePreference.Vietnamese]: " Phản ánh về việc thực hành của bạn",
  },
  [Identifiers.BulletThree]: {
    [LanguagePreference.English]: "Select the next actions to try ",
    [LanguagePreference.Spanish]:
      "Seleccionar las próximas acciones a intentar",
    [LanguagePreference.Vietnamese]: "Chọn các hành động tiếp theo để thử",
  },
  [Identifiers.ContinueButton]: {
    [LanguagePreference.English]: "Continue",
    [LanguagePreference.Spanish]: "Continuar",
    [LanguagePreference.Vietnamese]: "Tiếp tục",
  },
};

export default translations;
