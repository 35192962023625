import { Identifiers } from "../enums";
import { Translation } from "../Translation.Service";
import { LanguagePreference } from "../../../shared/types";

const deepBreathTranslations: Translation = {
  [Identifiers.Title]: {
    [LanguagePreference.English]: "Breathe in, breathe out",
    [LanguagePreference.Spanish]: "Inspira, exhala",
    [LanguagePreference.Vietnamese]: "Hít vào, thở ra",
  },
  [Identifiers.Body]: {
    [LanguagePreference.English]:
      "Re-center yourself. Take 3 more deep, long breaths.",
    [LanguagePreference.Spanish]:
      "Reenfócate. Toma 3 respiraciones más, profundas y largas.",
    [LanguagePreference.Vietnamese]:
      "Tập trung lại bản thân. Hít thở sâu 3 lần nữa.",
  },
};

export default deepBreathTranslations;
