import gql from "graphql-tag";

export const UPDATE_USER_INFO = gql`
  mutation UpdateUserInfo(
    $phoneNumber: String
    $email: String
    $nickname: String
    $fullName: String
    $languagePreference: Language
    $inviteId: String
  ) {
    updateUserInfo(
      phoneNumber: $phoneNumber
      email: $email
      nickname: $nickname
      fullName: $fullName
      languagePreference: $languagePreference
      inviteId: $inviteId
    ) {
      success
      updatedLanguage
    }
  }
`;
