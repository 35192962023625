import React from "react";

const WhiteStatsIcon = () => {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Stats</title>
      <path
        d="M14.5832 11.6696H17.7372V17.3469H14.5832V11.6696Z"
        fill="white"
      />
      <path
        d="M5.75224 7.88523H8.90628V17.3474H5.75224V7.88523Z"
        fill="white"
      />
      <path
        d="M10.1675 4.10025H13.3215V17.3473H10.1675V4.10025Z"
        fill="white"
      />
      <path
        d="M1.33681 0.315262H4.49084V17.3472H1.33681V0.315262Z"
        fill="white"
      />
      <path d="M0.0756912 18.2938H19V18.9246H0.0756912V18.2938Z" fill="white" />
      <path
        d="M14.5835 17.6624H17.7376C17.9117 17.6624 18.053 17.5211 18.053 17.347V11.6697C18.053 11.4956 17.9117 11.3543 17.7376 11.3543H14.5835C14.4095 11.3543 14.2682 11.4956 14.2682 11.6697V17.347C14.2681 17.5211 14.4094 17.6624 14.5835 17.6624ZM17.4222 17.0316H14.8989V11.9851H17.4222V17.0316Z"
        fill="white"
      />
      <path
        d="M5.75338 17.6629H8.90742C9.08151 17.6629 9.22281 17.5216 9.22281 17.3475V7.88535C9.22281 7.71127 9.08151 7.56996 8.90742 7.56996H5.75338C5.57929 7.56996 5.43798 7.71127 5.43798 7.88535V17.3475C5.43798 17.5216 5.57929 17.6629 5.75338 17.6629ZM8.59202 17.0321H6.06877V8.20075H8.59202V17.0321Z"
        fill="white"
      />
      <path
        d="M10.1676 17.6628H13.3216C13.4957 17.6628 13.637 17.5215 13.637 17.3474V4.10037C13.637 3.92628 13.4957 3.78498 13.3216 3.78498H10.1676C9.99351 3.78498 9.8522 3.92628 9.8522 4.10037V17.3474C9.85216 17.5215 9.99347 17.6628 10.1676 17.6628ZM13.0062 17.032H10.483V4.41576H13.0062V17.032Z"
        fill="white"
      />
      <path
        d="M1.33718 17.6627H4.49122C4.66531 17.6627 4.80661 17.5214 4.80661 17.3473V0.315386C4.80665 0.141297 4.66534 -7.62939e-06 4.49125 -7.62939e-06H1.33722C1.16313 -7.62939e-06 1.02182 0.141297 1.02182 0.315386V17.3473C1.02179 17.5214 1.16309 17.6627 1.33718 17.6627ZM4.17583 17.0319H1.65257V0.630816H4.17583V17.0319Z"
        fill="white"
      />
    </svg>
  );
};

export default WhiteStatsIcon;
