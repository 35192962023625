import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import styled from "@emotion/styled";
import gql from "graphql-tag";
import React, { useEffect, useState } from "react";
import { UPDATE_USER_INFO } from "screens/settings/shared/mutations";
import { Dropdown, DropdownItemProps, DropdownProps } from "semantic-ui-react";
import "shared/styles/dropdown.scss";
import { LanguagePreference } from "shared/types";
import { useHistory, useLocation } from "react-router";
import { ROUTES } from "../../../constants";

const LANGUAGE_DATA = gql`
  query CurrentCurriculumLanguages {
    currentCurriculumLanguages
    currentUser {
      languagePreference
    }
  }
`;

const LanguageSelector = () => {
  const history = useHistory();
  const location = useLocation();

  const [userLanguagePreference, setUserLanguagePreference] = useState(
    LanguagePreference.English
  );
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [getLanguageData, { data: userLanguageData, loading }] = useLazyQuery(
    LANGUAGE_DATA,
    {
      fetchPolicy: "no-cache",
      onCompleted: (languageData) => {
        if (
          languageData?.currentUser?.languagePreference &&
          languageData?.currentCurriculumLanguages?.length > 1
        ) {
          setUserLanguagePreference(
            languageData?.currentUser?.languagePreference
          );
        }
      },
    }
  );

  const [updateUserInfo] = useMutation(UPDATE_USER_INFO, {
    onCompleted: () => {
      window.location.reload();
    },
  });

  useEffect(() => {
    // Always check for language data on location change in case user logs out,
    // we want to verify that there should not be any language data
    getLanguageData();
  }, [location, getLanguageData]);

  // This useEffect is used to capture the language changes made via keyboard navigation
  useEffect(() => {
    // used for user that is signed in
    if (isSubmitting && userLanguageData) {
      updateUserInfo({
        variables: {
          languagePreference: userLanguagePreference,
        },
      });
    }

    if (isSubmitting && !userLanguageData) {
      redirectToCorrectLanguage(userLanguagePreference);
      setIsSubmitting(false);
    }
  }, [isSubmitting]);

  const redirectToCorrectLanguage = (language) => {
    history.push(`${location.pathname}?language=${language}`);
  };

  const dropdownOptions: DropdownProps = {
    fluid: true,
    selection: true,
    tabIndex: 0,
    style: {
      width: 133,
    },
  };

  if (loading) {
    return null;
  }
  if (
    userLanguageData &&
    userLanguageData?.currentCurriculumLanguages.length === 1
  ) {
    return null;
  }
  if (
    userLanguageData &&
    userLanguageData?.currentCurriculumLanguages.length > 1
  ) {
    const onDropdrownClose = () => {
      if (
        userLanguagePreference !==
        userLanguageData?.currentUser.languagePreference
      ) {
        setIsSubmitting(true);
      }
    };

    const onDropdownChange = (event, value) => {
      if (
        event.type === "click" &&
        value !== userLanguageData?.currentUser.languagePreference
      ) {
        updateUserInfo({
          variables: {
            languagePreference: value,
          },
        });
      } else {
        setUserLanguagePreference(value);
      }
    };

    const languageOptions: DropdownItemProps[] = userLanguageData?.currentCurriculumLanguages.map(
      (language, index) => ({
        key: index,
        text: language,
        value: language,
      })
    );

    dropdownOptions.onClose = () => onDropdrownClose();
    dropdownOptions.onChange = (e, { value }) => onDropdownChange(e, value);
    dropdownOptions.value = userLanguageData.currentUser.languagePreference;
    dropdownOptions.options = languageOptions;
  } else {
    const languageParamQuery = new URLSearchParams(location.search);
    const currentLanguage =
      languageParamQuery.get("language") ?? LanguagePreference.English;
    const onDropdrownClose = (event) => {
      if (!event) {
        return;
      }
      if (event.type !== "click") {
        setIsSubmitting(true);
      }
    };

    const onDropdownChange = (event, value) => {
      if (
        event.type === "click" &&
        value !== languageParamQuery.get("language")
      ) {
        redirectToCorrectLanguage(value);
      } else {
        setUserLanguagePreference(value);
      }
    };

    const languages = Object.values(LanguagePreference);
    const languageOptions: DropdownItemProps[] = languages.map(
      (language, index) => ({
        key: index,
        text: language,
        value: language,
      })
    );

    dropdownOptions.onClose = (e) => onDropdrownClose(e);
    dropdownOptions.onChange = (e, { value }) => onDropdownChange(e, value);
    dropdownOptions.value = currentLanguage;
    dropdownOptions.options = languageOptions;
  }

  const isInvitationRoute = location.pathname.startsWith(
    `${ROUTES.INVITATION}/`
  );

  const isNewUserRoute = location.pathname.startsWith(`${ROUTES.SIGNUP}`);

  if (isInvitationRoute) {
    return null;
  }

  return (
    <>
      <LanguageSelectorContainer isNewUserRoute={isNewUserRoute}>
        <Dropdown {...dropdownOptions} />
      </LanguageSelectorContainer>
    </>
  );
};

export default LanguageSelector;

const LanguageSelectorContainer = styled.div(
  ({ isNewUserRoute }: { isNewUserRoute: boolean }) => ({
    position: "fixed",
    bottom: isNewUserRoute ? "auto" : 5,
    right: isNewUserRoute ? "auto" : 5,
    top: isNewUserRoute ? 20 : "auto",
    left: isNewUserRoute ? 20 : "auto",
    zIndex: 2,
    maxHeight: 38,
    maxWidth: 133,
    "@media (min-width: 630px)": {
      bottom: 20,
      right: 20,
    },
  })
);
