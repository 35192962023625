import React from "react";
import Text, { Font } from "shared/components/Text";
import { StyleSheet } from "aphrodite";
import { greenBlue, mainGrey } from "shared/styles/colors";

interface Props {
  questionNumber: number;
  questionText: string | undefined;
}

const QuestionText = ({ questionNumber, questionText }: Props) => {
  return (
    <div>
      <Text font={Font.ProximaNovaBold} style={styles.questionNumber}>
        # {questionNumber}
      </Text>
      <Text font={Font.ProximaNovaRegular} style={styles.questionText}>
        {questionText}
      </Text>
    </div>
  );
};

export default QuestionText;

const styles = StyleSheet.create({
  questionNumber: {
    color: greenBlue,
    marginBottom: 5,
    fontSize: 12,
  },
  questionText: {
    fontSize: 16,
    width: 315,
    lineHeight: 1.2,
    color: mainGrey,
    marginBottom: 10,
    "@media (min-width: 630px)": {
      width: "100%",
    },
  },
});
