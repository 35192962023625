import React, { useEffect } from "react";
import { StyleSheet } from "aphrodite";
import Text, { Font } from "shared/components/Text";
import Button from "shared/components/Button";
import {
  turquoise,
  mainGrey,
  greyThree,
  greyAccent,
  white,
} from "shared/styles/colors";
import { gql } from "apollo-boost";
import { useHistory, useLocation } from "react-router-dom";
import { useLazyQuery } from "@apollo/react-hooks";
import { Formik, Form as FormikForm, Field } from "formik";
import { useToasts } from "react-toast-notifications";
import { ROUTES } from "../../../constants";
import styled from "@emotion/styled";
import {
  AuthInputContainer,
  InputLabel,
} from "screens/settings/shared/styledComponents";
import { TranslationService } from "services/Translation/Translation.Service";
import {
  Identifiers as TranslationIdentifiers,
  Page,
} from "services/Translation/enums";
import { LanguagePreference } from "shared/types";
import Div100vh from "react-div-100vh";
import { useDocumentTitle } from "shared/hooks";

const FORGOT_PASSWORD = gql`
  query ForgotPassword($emailOrPhoneNumber: String!) {
    forgotPassword(emailOrPhoneNumber: $emailOrPhoneNumber)
  }
`;

interface Props {
  isModal?: boolean;
}

const ForgotPassword = ({ isModal = false }: Props) => {
  useDocumentTitle("Forgot Password | Reflectable");
  const [forgotPassword, { data, error, loading }] = useLazyQuery(
    FORGOT_PASSWORD,
    {
      fetchPolicy: "no-cache",
    }
  );
  const { addToast } = useToasts();
  const history = useHistory();

  useEffect(() => {
    if (data) {
      history.push(ROUTES.FORGOT_PASSWORD_SUCCESS);
    }
    if (error) {
      addToast("There was an issue requesting your password reset.", {
        appearance: "error",
        autoDismiss: true,
      });
    }
  }, [data, error]);

  const params = useLocation();

  let userLanguage = LanguagePreference.English;
  const page = Page.ForgotPassword;
  const languageParamQuery = new URLSearchParams(params.search);

  if (languageParamQuery.get("language") === "ENGLISH") {
    userLanguage = LanguagePreference.English;
  } else if (languageParamQuery.get("language") === "SPANISH") {
    userLanguage = LanguagePreference.Spanish;
  } else if (languageParamQuery.get("language") === "VIETNAMESE") {
    userLanguage = LanguagePreference.Vietnamese;
  }

  return (
    <Container isModal={isModal}>
      <Formik
        initialValues={{ emailOrPhoneNumber: "" }}
        onSubmit={(values) => {
          if (values.emailOrPhoneNumber) {
            forgotPassword({
              variables: { emailOrPhoneNumber: values.emailOrPhoneNumber },
            });
          }
        }}
      >
        {({ values }) => (
          <Form>
            <InnerForgotPasswordContainer isModal={isModal}>
              <TopBar isModal={isModal} />
              <Text style={styles.header} font={Font.ProximaNovaBold}>
                {TranslationService.getStaticText({
                  page,
                  userLanguage,
                  textIdentifier: TranslationIdentifiers.Title,
                })}
              </Text>
              <Text style={styles.subheader} font={Font.ProximaNovaRegular}>
                {TranslationService.getStaticText({
                  page,
                  userLanguage,
                  textIdentifier: TranslationIdentifiers.SubTitle,
                })}
              </Text>
              <AuthInputContainer>
                <InputLabel
                  id="email-or-phone-number-input-label"
                  htmlFor="email-or-phone-number-input"
                >
                  {TranslationService.getStaticText({
                    page,
                    userLanguage,
                    textIdentifier: TranslationIdentifiers.Email,
                  })}
                </InputLabel>
              </AuthInputContainer>
              <Input
                aria-labelledby="email-or-phone-number-input-label"
                id="email-or-phone-number-input"
                isModal={isModal}
                name="emailOrPhoneNumber"
                placeholder={TranslationService.getStaticText({
                  page,
                  userLanguage,
                  textIdentifier: TranslationIdentifiers.Email,
                })}
                value={values.emailOrPhoneNumber}
              />
              {isModal && (
                <Button
                  ariaLabel={TranslationService.getStaticText({
                    page,
                    userLanguage,
                    textIdentifier: TranslationIdentifiers.SubmitButton,
                  })}
                  text={TranslationService.getStaticText({
                    page,
                    userLanguage,
                    textIdentifier: TranslationIdentifiers.SubmitButton,
                  })}
                  type="submit"
                  loading={loading}
                  disabled={loading}
                />
              )}
            </InnerForgotPasswordContainer>
            {!isModal && (
              <Button
                text={TranslationService.getStaticText({
                  page,
                  userLanguage,
                  textIdentifier: TranslationIdentifiers.SubmitButton,
                })}
                type="submit"
                loading={loading}
                disabled={loading}
                ariaLabel={TranslationService.getStaticText({
                  page,
                  userLanguage,
                  textIdentifier: TranslationIdentifiers.SubmitButton,
                })}
              />
            )}
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default ForgotPassword;

const Container = styled(Div100vh)(({ isModal }: Props) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: white,
  height: "100%",
  "@media (min-width: 630px)": {
    borderRadius: 12,
    width: isModal ? 630 : "auto",
    minHeight: isModal ? 660 : "auto",
    overflow: "hidden",
  },
}));

const Form = styled(FormikForm)({
  textAlign: "center",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  height: "100%",
  justifyContent: "center",
});

const InnerForgotPasswordContainer = styled.div(({ isModal }: Props) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  "@media (min-width: 630px)": {
    boxShadow: "0 2px 2px 1px rgba(0, 0, 0, .1)",
    width: 630,
    minHeight: 660,
    borderRadius: 10,
    marginBottom: isModal ? 0 : 60,
  },
}));

const TopBar = styled.div(({ isModal }: Props) => ({
  display: "none",
  "@media (min-width: 630px)": {
    width: 630,
    height: 10,
    background: greyAccent,
    display: "flex",
    borderRadius: isModal ? 0 : "10px 10px 0 0",
    marginBottom: 20,
    overflow: "hidden",
  },
}));

const Input = styled(Field)(({ isModal }: Props) => ({
  height: 60,
  width: 335,
  borderRadius: 8,
  border: `1px solid ${greyThree}`,
  marginBottom: isModal ? 90 : 20,
  paddingLeft: 20,
  paddingRight: 20,
}));

const styles = StyleSheet.create({
  header: {
    height: 80,
    width: 335,
    fontSize: 36,
    lineHeight: 1.11,
    color: turquoise,
    marginTop: 50,
    marginBottom: 10,
    textAlign: "center",
  },
  subheader: {
    height: 60,
    width: 335,
    lineHeight: 1.25,
    textAlign: "center",
    fontSize: 16,
    color: mainGrey,
    marginBottom: 99,
  },
  button: {
    height: 60,
    width: 335,
  },
});
