/* eslint-disable jsx-a11y/click-events-have-key-events */
import { css, StyleSheet } from "aphrodite";
import { format, parseISO } from "date-fns";
import React from "react";
import Text, { Font } from "shared/components/Text";
import { greyAccent, mainGrey, transparent, white } from "shared/styles/colors";
import {
  hoverStateDropShadow,
  standardDropShadow,
  transitionTime,
} from "shared/styles/constants";
import ForwardArrow from "static/images/ForwardArrow.svg";

interface Props {
  reflectionNumber: number;
  completionDate: string;
  topicName: string;
  topicColor: string;
  onClick: () => void;
  reflectionTranslation: string;
}

const ReflectionSummaryCard = ({
  reflectionNumber,
  completionDate,
  topicName,
  topicColor,
  onClick,
  reflectionTranslation,
}: Props) => {
  return (
    <button
      className={css(styles.ReflectionSummaryCard)}
      onClick={() => onClick()}
    >
      <div className={css(styles.greyAccent)} />
      <div className={css(styles.contentContainer)}>
        <div className={css(styles.reflectionTextContainer)}>
          <Text style={styles.reflectionText}>
            {reflectionTranslation} {reflectionNumber}
          </Text>
          <Text font={Font.ProximaNovaRegular} style={styles.date}>
            {format(parseISO(completionDate), "M/d")}
          </Text>
        </div>
        <div
          className={css(styles.topicContainer)}
          style={{
            backgroundColor: topicColor,
          }}
        >
          <Text style={styles.topicText}>{topicName}</Text>
        </div>
      </div>
      <div className={css(styles.arrowContainer)}>
        <img
          className={css(styles.forwardArrow)}
          src={ForwardArrow}
          alt="Forward Arrow"
          title="Forward Arrow"
        />
      </div>
    </button>
  );
};

export default ReflectionSummaryCard;

const styles = StyleSheet.create({
  ReflectionSummaryCard: {
    width: "100%",
    backgroundColor: white,
    height: 64,
    borderRadius: 10,
    boxShadow: standardDropShadow,
    marginBottom: 10,
    display: "flex",
    overflow: "hidden",
    cursor: "pointer",
    "@media (min-width: 630px)": {
      width: "75%",
    },
    background: transparent,
    padding: 0,
    ":hover": {
      boxShadow: hoverStateDropShadow,
    },
    transition: `${transitionTime} box-shadow`,
  },
  greyAccent: {
    backgroundColor: greyAccent,
    height: "100%",
    width: 10,
    marginRight: 10,
  },
  contentContainer: {
    paddingTop: 10,
  },
  reflectionTextContainer: {
    display: "flex",
    alignItems: "flex-end",
    marginBottom: 6,
  },
  reflectionText: {
    color: mainGrey,
    fontSize: 16,
    marginRight: 10,
  },
  date: {
    fontSize: 12,
    color: mainGrey,
  },
  topicContainer: {
    padding: "3px 10px",
    minHeight: 19,
    borderRadius: 50,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  topicText: {
    color: mainGrey,
    fontSize: 12,
  },
  forwardArrow: {
    width: 6,
    height: 14,
  },
  arrowContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    position: "relative",
    flexGrow: 1,
    paddingRight: 24,
    alignSelf: "center",
  },
});
