import { useLazyQuery } from "@apollo/react-hooks";
import { css, StyleSheet } from "aphrodite";
import gql from "graphql-tag";
import React, { useEffect } from "react";
import Card from "shared/components/Card";
import { buttonBlue, darkGreenBlue, turquoise } from "shared/styles/colors";
import Top3Info from "./Top3Info";
import Spinner from "react-spinkit";

interface Props {
  topicId: string;
  cohortId: string;
  questionId: string;
  questionTitle: string;
}

const QUERY = gql`
  query($topicId: String!, $cohortId: String!, $questionId: String!) {
    cohortAdminTop3Challenges(
      topicId: $topicId
      cohortId: $cohortId
      questionId: $questionId
    ) {
      text
      timesSelected
    }
  }
`;

const Top3ChallengesCard = ({
  topicId,
  cohortId,
  questionId,
  questionTitle,
}) => {
  const [top3ChallengesQuery, { data, loading, error }] = useLazyQuery(QUERY, {
    fetchPolicy: "no-cache",
    variables: { topicId, cohortId, questionId },
  });

  useEffect(() => {
    top3ChallengesQuery();
  }, [topicId]);

  let content;
  if (loading || error) {
    content = (
      <div className={css(styles.loadingContainer)}>
        <Spinner color={buttonBlue} name="line-scale" fadeIn="none" />
      </div>
    );
  }

  if (data) {
    const { cohortAdminTop3Challenges } = data;
    content = (
      <Top3Info
        dataArray={cohortAdminTop3Challenges}
        title={questionTitle}
        titleColor={turquoise}
        textColor={darkGreenBlue}
        primaryTextStyle={styles.primaryTextStyle}
        secondaryTextStyle={styles.secondaryTextStyle}
        numberStyle={styles.numberStyle}
      />
    );
  }
  return (
    <Card cardStyle={styles.cardStyle} contentStyle={styles.cardContent}>
      {content}
    </Card>
  );
};

export default Top3ChallengesCard;

const styles = StyleSheet.create({
  cardStyle: {
    minHeight: 370,
    width: 390,
    marginBottom: 25,
  },
  cardContent: {
    paddingTop: 31,
    paddingLeft: 24,
    paddingRight: 24,
  },
  loadingContainer: {
    height: "100%",
    width: "100%",
    paddingBottom: 62,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  primaryTextStyle: {
    fontSize: 19,
    lineHeight: 1,
    marginBottom: 4,
  },
  secondaryTextStyle: {
    fontSize: 18,
    lineHeight: 1.25,
    marginBottom: 15,
  },
  numberStyle: {
    fontSize: 23,
    lineHeight: 0.7,
    width: 12,
  },
  //
});
