import { StyleSheet } from "aphrodite";
import React from "react";
import { Identifiers, Page } from "services/Translation/enums";
import { TranslationService } from "services/Translation/Translation.Service";
import Card from "shared/components/Card";
import Text, { Font } from "shared/components/Text";
import { mainGrey, turquoise } from "shared/styles/colors";
import { LanguagePreference } from "shared/types";

interface Props {
  topicsArray: any[];
  userLanguage: LanguagePreference;
}

const TopicsIncludedCard = ({ topicsArray, userLanguage }: Props) => {
  return (
    <Card
      cardStyle={styles.TopicsIncludedCard}
      contentStyle={styles.topicContent}
    >
      <Text font={Font.ProximaNovaBold} style={styles.title}>
        {TranslationService.getStaticText({
          page: Page.Stats,
          userLanguage,
          textIdentifier: Identifiers.TopicsInclude,
        })}
      </Text>
      <Text font={Font.ProximaNovaRegular} style={styles.topics}>
        {topicsArray.map((topic, index) => {
          return `${topic.translationData.name}${
            index !== topicsArray.length - 1 ? ", " : ""
          }`;
        })}
      </Text>
    </Card>
  );
};

export default TopicsIncludedCard;

const styles = StyleSheet.create({
  TopicsIncludedCard: {
    width: 355,
    marginBottom: 21,
  },
  topicContent: {
    width: 355,
    padding: "30px 20px",
  },
  title: {
    color: turquoise,
    textTransform: "uppercase",
    marginBottom: 10,
    fontSize: 12,
    letterSpacing: 2,
  },
  topics: {
    fontSize: 16,
    lineHeight: "120%",
    color: mainGrey,
    marinBottom: 7,
  },
  //
});
