import { useQuery } from "@apollo/react-hooks";
import { css, StyleSheet } from "aphrodite";
import gql from "graphql-tag";
import React, { useEffect } from "react";
import GraphqlErrorScreen from "shared/components/ErrorBoundary/GraphqlErrorScreen";
import Text, { Font } from "shared/components/Text";
import {
  adminOrange,
  buttonBlue,
  turquoise,
  white,
} from "shared/styles/colors";
import { differenceInCalendarWeeks, format, parseISO } from "date-fns";
import adminImage from "static/images/adminImage.png";
import CohortInformation from "./CohortInformation";
import Card from "shared/components/Card";
import Top3Info from "./Top3Info";
import Spinner from "react-spinkit";

interface Props {
  cohortId: string;
  enoughData: boolean;
  setEnoughData: any;
}

const COHORT_BY_ID = gql`
  query CohortById($id: String!) {
    cohortById(id: $id) {
      id
      name
      startDate
      numberOfReflections
    }
    userLanguage
    isCohortAdmin
    reflectionsCompletedDataByCohort(id: $id) {
      numberOfUsers
      reflectionsCompleted
    }
    topThreeTopicsAndFocusesByCohort(id: $id) {
      focusData {
        text
        timesSelected
      }
      topicsData {
        text
        timesSelected
      }
    }
  }
`;

const UsageOverview = ({ cohortId, enoughData, setEnoughData }: Props) => {
  const { data, loading, error } = useQuery(COHORT_BY_ID, {
    variables: {
      id: cohortId,
    },
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    setEnoughData(
      data?.reflectionsCompletedDataByCohort.reflectionsCompleted > 0
    );
  }, [data]);

  if (loading) {
    return (
      <div className={css(styles.loadingContatiner)}>
        <Spinner name="line-scale" color={buttonBlue} fadeIn="none" />
      </div>
    );
  }

  if (error) {
    return <GraphqlErrorScreen error={error} />;
  }

  if (data) {
    const {
      reflectionsCompletedDataByCohort: { numberOfUsers, reflectionsCompleted },
      cohortById: { startDate: cohortStartDate, numberOfReflections },
      topThreeTopicsAndFocusesByCohort: { topicsData, focusData },
    } = data;

    const todaysDate = format(new Date(), "LLL do");
    const startDate = format(parseISO(cohortStartDate), "LLL do");
    const currentNumberOfWeeks = differenceInCalendarWeeks(
      new Date(),
      parseISO(cohortStartDate)
    );

    return (
      <>
        <CohortInformation
          cohortName={data.cohortById.name}
          todaysDate={todaysDate}
          numberOfReflections={numberOfReflections}
          startDate={startDate}
        />
        <Text font={Font.ProximaNovaBold} style={styles.usageText}>
          Usage Overview
        </Text>

        <div className={css(styles.row)}>
          <div className={css(styles.reflectionsContainer)}>
            <Text
              font={Font.ProximaNovaBold}
              style={styles.reflectionsCompleted}
            >
              {reflectionsCompleted} reflection
              {reflectionsCompleted !== 1 && "s"} completed
              {reflectionsCompleted !== 0 && "!"}
            </Text>
            <Text font={Font.ProximaNovaSemibold} style={styles.weeksUsersText}>
              {currentNumberOfWeeks !== 0 &&
                `over ${currentNumberOfWeeks} week`}
              {currentNumberOfWeeks > 1 && "s"}{" "}
              {numberOfUsers !== 0 &&
                `by ${numberOfUsers} user${numberOfUsers > 1 ? "s" : ""}`}
            </Text>
          </div>
          <img
            src={adminImage}
            className={css(styles.image)}
            alt="Person Meditating"
            title="Person Meditating"
          />
        </div>
        {enoughData && (
          <div className={css(styles.row)}>
            <Card
              cardStyle={styles.cardStyle}
              contentStyle={styles.cardContent}
            >
              <Top3Info
                textColor={white}
                titleColor={white}
                title="top 3 topics"
                dataArray={topicsData}
              />
            </Card>
            <div className={css(styles.borderlessCard)}>
              <Top3Info
                textContainerStyle={styles.textContainerStyle}
                textColor={turquoise}
                titleColor={turquoise}
                title="top 3 focuses"
                dataArray={focusData}
              />
            </div>
          </div>
        )}
      </>
    );
  }
  return null;
};

export default UsageOverview;

const styles = StyleSheet.create({
  usageText: {
    textAlign: "center",
    fontSize: 36,
    lineHeight: 1,
    color: turquoise,
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
    width: 1200,
    marginBottom: 17,
  },
  reflectionsCompleted: {
    color: adminOrange,
    fontSize: 45,
    lineHeight: 1.09,
    width: 309,
    textAlign: "center",
    marginBottom: 28,
  },
  weeksUsersText: {
    width: 309,
    color: adminOrange,
    fontSize: 25,
    lineHeight: 1.2,
    textAlign: "center",
  },
  reflectionsContainer: {
    width: 588,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: 104,
  },
  image: {
    height: 350,
    position: "relative",
    bottom: 30,
  },
  cardContent: {
    padding: "30px 72px 52px 42px",
  },
  cardStyle: {
    height: 488,
    background: adminOrange,
    width: 588,
  },
  borderlessCard: {
    padding: "30px 0px 52px 42px",
    minHeight: 488,
  },
  textContainerStyle: {
    width: 415,
  },
  loadingContatiner: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
});
