import React from "react";
import { StyleSheet, css } from "aphrodite";
import Text, { Font } from "shared/components/Text";
import { turquoise, mainGrey, greyAccent } from "shared/styles/colors";
import { useDocumentTitle } from "shared/hooks";

const ForgotPassword = () => {
  useDocumentTitle("Success | Reflectable");
  return (
    <div className={css(styles.ForgotPassword)}>
      <div className={css(styles.innerForgotPassword)}>
        <div className={css(styles.topBar)} />
        <Text style={styles.header} font={Font.ProximaNovaBold}>
          Request Received!
        </Text>
        <Text style={styles.subheader} font={Font.ProximaNovaRegular}>
          If the email entered is registered with Reflectable, you should
          receive a link to reset your password.
        </Text>
      </div>
    </div>
  );
};

export default ForgotPassword;

const styles = StyleSheet.create({
  ForgotPassword: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: 60,
  },
  innerForgotPassword: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: 94,
    "@media (min-width: 630px)": {
      boxShadow: "0 2px 2px 1px rgba(0, 0, 0, .1)",
      width: 630,
      minHeight: 660,
      borderRadius: 10,
      marginBottom: 60,
    },
  },
  topBar: {
    display: "none",
    "@media (min-width: 630px)": {
      width: 630,
      height: 10,
      background: greyAccent,
      display: "flex",
      borderRadius: "10px 10px 0 0",
      marginBottom: 20,
    },
  },
  header: {
    height: 80,
    width: 335,
    fontSize: 36,
    lineHeight: 1.11,
    color: turquoise,
    marginTop: 50,
    marginBottom: 10,
    textAlign: "center",
  },
  subheader: {
    height: 60,
    width: 335,
    lineHeight: 1.25,
    textAlign: "center",
    fontSize: 16,
    color: mainGrey,
    marginBottom: 99,
  },
});
