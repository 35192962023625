import styled from "@emotion/styled";
import React from "react";
import Spinner from "react-spinkit";
import { buttonBlue } from "shared/styles/colors";

const Loader = () => {
  return (
    <LoadingContainer>
      <Spinner name="line-scale" color={buttonBlue} fadeIn="none" />
    </LoadingContainer>
  );
};

export default Loader;

const LoadingContainer = styled.div({
  alignSelf: "center",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
});
