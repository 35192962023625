import { StyleSheet, css } from "aphrodite";
import React from "react";
import { Identifiers, Page } from "services/Translation/enums";
import { TranslationService } from "services/Translation/Translation.Service";
import Text from "./Text";
import { ReactComponent as LogoutIcon } from "static/images/LogoutIcon.svg";
import { transparent, turquoise } from "shared/styles/colors";
import { useHistory } from "react-router";
import { logout } from "shared/utils";

interface Props {
  userLanguage: any;
}
const LogoutButton = ({ userLanguage }: Props) => {
  const history = useHistory();
  return (
    <button
      className={css(styles.buttonContainer)}
      onClick={() => logout(history, userLanguage)}
    >
      <Text style={styles.logoutText}>
        {TranslationService.getStaticText({
          page: Page.Dashboard,
          userLanguage,
          textIdentifier: Identifiers.LogOut,
        })}
      </Text>
      <LogoutIcon />
    </button>
  );
};

export default LogoutButton;

const styles = StyleSheet.create({
  buttonContainer: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    background: transparent,
    ":hover": {
      textDecoration: "underline",
      textDecorationColor: turquoise,
    },
  },
  logoutWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
    maxWidth: 630,
    paddingTop: 12,
    paddingRight: 16,
  },
  logoutText: {
    marginRight: 6,
    fontSize: 16,
    color: turquoise,
  },
});
