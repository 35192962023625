import { css, StyleSheet } from "aphrodite";
import React, { useEffect, useRef } from "react";
import {
  transparent,
  turquoise,
  white,
  teal,
  mainGrey,
  lightestGrey,
} from "shared/styles/colors";
import { Swiper, SwiperSlide } from "swiper/react";
import Text, { Font } from "../Text";
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import SwiperCore, { Keyboard, Navigation } from "swiper";
import "./customSwiperStyles.scss";
import styled from "@emotion/styled";
import CheckmarkIcon from "../../../static/svgs/CheckmarkIcon";
import LockedIcon from "../../../static/svgs/LockedIcon";
import { useAnimation, motion } from "framer-motion/dist/framer-motion";
import { topicCarouselTransition } from "shared/styles/animations";
import { ALL_TOPICS } from "@quick-check/common";
import { format } from "date-fns";

SwiperCore.use([Navigation, Keyboard]);

interface Props {
  onSlideChange: () => void | null;
  selectedTopicIndex: number;
  swiperRef: any;
  topics: any[];
  isTopicChosen?: boolean;
  isStatsTopicSwiper?: boolean;
}

const TopicSwiper = ({
  onSlideChange,
  selectedTopicIndex,
  swiperRef,
  topics,
  isTopicChosen = false,
  isStatsTopicSwiper = false,
}: Props) => {
  const prevButtonRef = useRef(null);
  const nextButtonRef = useRef(null);
  const swiperTransitionControls = useAnimation();

  useEffect(() => {
    if (isStatsTopicSwiper) {
      swiperTransitionControls.start(topicCarouselTransition.fadeIn);
    }
    if (isTopicChosen) {
      swiperTransitionControls.start(topicCarouselTransition.fadeIn);
    }
  }, [isTopicChosen, swiperTransitionControls, isStatsTopicSwiper]);

  useEffect(() => {
    swiperRef.current.slideTo(selectedTopicIndex);
  }, [selectedTopicIndex, swiperRef]);

  return (
    <SwiperContainer
      className="TopicSwiper"
      initial={{ opacity: 0 }}
      animate={swiperTransitionControls}
    >
      <Swiper
        spaceBetween={12}
        slidesPerView={2.25}
        onSwiper={(swiper) => {
          swiperRef.current = swiper;
        }}
        pagination={{ clickable: true }}
        onSlideChange={onSlideChange}
        centeredSlides
        slideToClickedSlide
        initialSlide={selectedTopicIndex}
        keyboard={{ enabled: true, onlyInViewport: false }}
        onInit={(swiper) => {
          // @ts-ignore
          swiper.params.navigation.prevEl = prevButtonRef.current;
          // @ts-ignore
          swiper.params.navigation.nextEl = nextButtonRef.current;
          swiper.navigation.update();
          swiper.params.observer = true;
          swiper.params.observeParents = true;
        }}
      >
        <>
          <PrevSwiperButton
            className="swiper-button-prev"
            ref={prevButtonRef}
          />
          <NextSwiperButton
            className="swiper-button-next"
            ref={nextButtonRef}
          />
        </>
        {topics?.map(
          (
            {
              translationData,
              id,
              color,
              isUnlocked,
              numberOfReflectionsToUnlock,
              numberOfTimesPracticed,
              forcedUnlockDate,
            },
            index
          ) => {
            const { name } = translationData;
            const isSelected = selectedTopicIndex === index;
            const topicPracticed = numberOfTimesPracticed > 0;
            const showTopicMessage =
              (!isUnlocked || topicPracticed) && !isStatsTopicSwiper;
            let timesPracticedCopy = `Practiced ${numberOfTimesPracticed} time`;
            timesPracticedCopy =
              numberOfTimesPracticed > 1
                ? `${timesPracticedCopy}s`
                : timesPracticedCopy;

            return (
              <SwiperSlide
                key={`topic-slide-${id}`}
                className={
                  !isUnlocked && !isStatsTopicSwiper ? "swiper-no-swiping" : ""
                }
                style={{
                  width: "auto",
                  flexShrink: 0,
                  display: "block",
                  height: "auto",
                }}
              >
                <div className={css(styles.swiperItemWrapper)}>
                  <SwiperItem
                    key={id}
                    backgroundColor={id === ALL_TOPICS ? lightestGrey : color}
                    selected={isSelected}
                    isLocked={!isUnlocked && !isTopicChosen}
                    showTopicMessage={showTopicMessage}
                  >
                    <IconContainer>
                      {isSelected && isUnlocked && <CheckmarkIcon />}
                      {!isUnlocked && !isStatsTopicSwiper && <LockedIcon />}
                    </IconContainer>
                    <Text
                      font={Font.ProximaNovaSemibold}
                      style={styles.swiperItemText}
                    >
                      {name}
                    </Text>
                    {showTopicMessage && (
                      <SwiperItemMessage>
                        <Text
                          font={Font.ProximaNovaSemibold}
                          style={styles.swiperItemMessage}
                        >
                          {!isUnlocked && !forcedUnlockDate
                            ? `Unlock after ${numberOfReflectionsToUnlock} Reflections`
                            : forcedUnlockDate &&
                              `Unlock after ${format(
                                new Date(forcedUnlockDate),
                                "M/d/y"
                              )}`}
                          {topicPracticed && timesPracticedCopy}
                        </Text>
                      </SwiperItemMessage>
                    )}
                  </SwiperItem>
                </div>
              </SwiperSlide>
            );
          }
        )}
      </Swiper>
    </SwiperContainer>
  );
};

export default TopicSwiper;

const SwiperButton = styled.button({
  background: transparent,
});

const NextSwiperButton = styled(SwiperButton)({
  color: turquoise,
  top: 22,
  marginRight: "32%",
  position: "absolute",
});

const PrevSwiperButton = styled(SwiperButton)({
  color: turquoise,
  top: 22,
  marginLeft: "32%",
  position: "absolute",
});

const SwiperContainer = styled(motion.div)({
  marginBottom: 46,
  position: "absolute",
  width: "100%",
  boxSizing: "border-box",
});

const SwiperItem = styled.div<{
  backgroundColor: string;
  selected: boolean;
  isLocked: boolean;
  showTopicMessage: boolean;
}>(({ backgroundColor, selected, isLocked, showTopicMessage }) => ({
  backgroundColor,
  borderRadius: 10,
  position: "relative",
  border: selected ? `3px solid ${teal}` : "3px solid transparent",
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  cursor: isLocked ? "not-allowed" : "pointer",
  padding: showTopicMessage ? "58px 12px 13px" : "44px 12px 44px",
  transition: "0.3s",
  height: "100%",
}));

const IconContainer = styled.div({
  position: "absolute",
  height: 26,
  width: 26,
  top: 8,
  left: 10,
});

const SwiperItemMessage = styled.div({
  backgroundColor: white,
  padding: "6px 0",
  marginTop: 21,
  width: "100%",
});

const styles = StyleSheet.create({
  TopicSwiper: {},
  swiperItemWrapper: {
    width: "100%",
    height: "100%",
    textAlign: "center",
  },
  swiperItemText: {
    fontSize: 14,
    color: mainGrey,
    lineHeight: 1.2,
  },
  swiperItemMessage: {
    color: teal,
    fontSize: 9,
    lineHeight: 1.2,
  },
});
