import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import gql from "graphql-tag";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { routeToQuestionPageFromPageId } from "shared/utils";
import { ROUTES } from "../../constants";
import GraphqlErrorScreen from "shared/components/ErrorBoundary/GraphqlErrorScreen";
import { TranslationService } from "services/Translation/Translation.Service";
import { Identifiers, Page } from "services/Translation/enums";
import StepIntro from "shared/components/StepIntro/StepIntro";
import { useToasts } from "react-toast-notifications";
import { ReflectionUpdateType } from "@quick-check/common";
import { useDocumentTitle } from "shared/hooks";

const FIRST_SURVEY_PAGE_ID_OF_STEP_3_QUERY = gql`
  query FirstSurveyPageIdOfStep3Query($order: Int!) {
    firstSurveyPageIdOfStep3
    userLanguage
    step(order: $order) {
      id
      translationData {
        bodyTexts
        description
      }
    }
  }
`;

const SKIP_STEP_3 = gql`
  mutation UpdateReflectionStatus($reflectionUpdateType: String) {
    updateReflectionStatus(reflectionUpdateType: $reflectionUpdateType) {
      success
    }
  }
`;

const Step3Intro = () => {
  useDocumentTitle("Step 3 Intro | Reflectable");
  const history = useHistory();
  const { addToast } = useToasts();
  const { data, error, loading } = useQuery(
    FIRST_SURVEY_PAGE_ID_OF_STEP_3_QUERY,
    {
      variables: { order: 3 },
    }
  );

  const [
    skipStep3,
    { data: skipData, error: skipError, loading: skipLoading },
  ] = useMutation(SKIP_STEP_3, {
    variables: {
      reflectionUpdateType: ReflectionUpdateType.SKIP_STEP_THREE,
    },
    onError: () => {
      //
    },
  });
  const [
    completeStepTwo,
    {
      data: completeStepTwoData,
      error: completeStepTwoError,
      loading: completeStepTwoLoading,
    },
  ] = useMutation(SKIP_STEP_3, {
    variables: {
      reflectionUpdateType: ReflectionUpdateType.COMPLETED_STEP_TWO,
    },
    onError: () => {
      //
    },
  });

  const onContinueClick = () => {
    completeStepTwo();
  };

  const onSkipClick = () => {
    skipStep3();
  };

  useEffect(() => {
    if (skipData?.updateReflectionStatus?.success) {
      history.push(ROUTES.REFLECTION_COMPLETE);
    }
    if (skipError) {
      addToast(skipError.graphQLErrors[0].message, {
        appearance: "error",
        autoDismiss: true,
      });
    }
  }, [skipData, skipError]);

  useEffect(() => {
    if (completeStepTwoData?.updateReflectionStatus?.success && data) {
      routeToQuestionPageFromPageId(history, data.firstSurveyPageIdOfStep3);
    }
    if (completeStepTwoError) {
      addToast(completeStepTwoError.graphQLErrors[0].message, {
        appearance: "error",
        autoDismiss: true,
      });
    }
  }, [completeStepTwoData, completeStepTwoError]);

  if (loading) {
    return null;
  }

  if (error) {
    return <GraphqlErrorScreen error={error} />;
  }

  const page = Page.StepThreeIntro;
  const userLanguage = data?.userLanguage;
  const { bodyTexts, description } = data?.step?.translationData;

  return (
    <StepIntro
      bodyTexts={bodyTexts}
      subTitle={description}
      skipButton
      skipButtonText={TranslationService.getStaticText({
        page,
        textIdentifier: Identifiers.SkipButton,
        userLanguage,
      })}
      onSkipClick={onSkipClick}
      onContinueClick={onContinueClick}
      continueButtonText={TranslationService.getStaticText({
        page: Page.Shared,
        textIdentifier: Identifiers.ContinueButton,
        userLanguage,
      })}
      isLoading={skipLoading || completeStepTwoLoading}
    />
  );
};

export default Step3Intro;
