import React, { useState } from "react";
import SignUpText from "./InvitationText";
import { StyleSheet, css } from "aphrodite";
import {
  Input,
  InputLabel,
  Form,
} from "../../screens/settings/shared/styledComponents";
import Dropdown from "./InvitationDropDown";
import Text from "shared/components/Text";
import styled from "@emotion/styled";
import { TranslationService } from "services/Translation/Translation.Service";
import {
  Page,
  Identifiers as TextIdentifiers,
} from "services/Translation/enums";
import { LanguagePreference } from "shared/types";
import ErrorMessage from "screens/auth/CreatePassword/ErrorMessage";
import { redAlpha, redOrange } from "shared/styles/colors";
import { phoneNumberValidator } from "../../shared/utils";

interface Props {
  userFullName: string;
  userNickname: string;
  userPhoneNumber: string;
  onUserInfoChange: (
    name: string,
    nickname: string,
    phoneNumber: string,
    languagePreference: LanguagePreference
  ) => void;
  userLanguage: LanguagePreference;
  checkAboutPageErrors: (aboutPageErrors) => void;
}

const AboutScreen = ({
  userFullName,
  userNickname,
  userPhoneNumber,
  onUserInfoChange,
  userLanguage,
  checkAboutPageErrors,
}: Props) => {
  const [name, setName] = useState<string>(userFullName);
  const [nickname, setNickname] = useState<string>(userNickname);
  const [phoneNumber, setPhoneNumber] = useState<string>(userPhoneNumber);
  const [fullNameLengthError, setFullNameLengthError] = useState<string>("");
  const [nicknameLengthError, setNicknameLengthError] = useState<string>("");
  const [phoneValidationError, setPhoneValidationError] = useState<string>("");

  const titleTextFontSize = 30;
  const paragraphTextFontSize = 16;
  const handleNameChange = (event) => {
    const newName = event.target.value;
    setName(newName);
    onUserInfoChange(newName, nickname, phoneNumber, userLanguage);
  };
  const handleNicknameChange = (event) => {
    const newNickname = event.target.value;
    setNickname(newNickname);
    onUserInfoChange(name, newNickname, phoneNumber, userLanguage);
  };
  const handlePhoneNumberChange = (event) => {
    const newPhoneNumber = event.target.value;
    setPhoneNumber(newPhoneNumber);
    onUserInfoChange(name, nickname, newPhoneNumber, userLanguage);
  };
  const handleLanguagePreferenceChange = (currentLanguagePreference) => {
    onUserInfoChange(name, nickname, phoneNumber, currentLanguagePreference);
  };

  const translationOptions = {
    page: Page.UserInvited,
    userLanguage,
  };

  const titleText = TranslationService.getStaticText({
    textIdentifier: TextIdentifiers.AboutTitle,
    ...translationOptions,
  });
  const fullName = TranslationService.getStaticText({
    textIdentifier: TextIdentifiers.FullName,
    ...translationOptions,
  });
  const required = TranslationService.getStaticText({
    textIdentifier: TextIdentifiers.Required,
    ...translationOptions,
  });
  const nicknameRequired = TranslationService.getStaticText({
    textIdentifier: TextIdentifiers.Nickname,
    ...translationOptions,
  });
  const languagePreference = TranslationService.getStaticText({
    textIdentifier: TextIdentifiers.LanguagePreference,
    ...translationOptions,
  });
  const phoneNumberRequired = TranslationService.getStaticText({
    textIdentifier: TextIdentifiers.PhoneNumber,
    ...translationOptions,
  });
  const fullNameError = TranslationService.getStaticText({
    textIdentifier: TextIdentifiers.FullNameError,
    ...translationOptions,
  });
  const nicknameError = TranslationService.getStaticText({
    textIdentifier: TextIdentifiers.NicknameError,
    ...translationOptions,
  });
  const phoneError = TranslationService.getStaticText({
    textIdentifier: TextIdentifiers.PhoneError,
    ...translationOptions,
  });
  const fullNamePlaceholder = TranslationService.getStaticText({
    textIdentifier: TextIdentifiers.FullNameDefault,
    ...translationOptions,
  });
  const nicknamePlaceholder = TranslationService.getStaticText({
    textIdentifier: TextIdentifiers.NicknameDefault,
    ...translationOptions,
  });
  const phonePlaceholder = TranslationService.getStaticText({
    textIdentifier: TextIdentifiers.PhoneNumberDefault,
    ...translationOptions,
  });
  const paragraphText = TranslationService.getStaticText({
    textIdentifier: TextIdentifiers.WelcomeSubtitle,
    ...translationOptions,
  });

  return (
    <AboutScreenWrapper>
      <SignUpText
        titleText={titleText}
        titleTextFontSize={titleTextFontSize}
        paragraphText={paragraphText}
        paragraphTextFontSize={paragraphTextFontSize}
      />
      <Form className={css(styles.formContainer)}>
        <InputRequiredContainer>
          <InputLabel htmlFor="nickname-input" id="nickname-input-label">
            {nicknameRequired}
          </InputLabel>
          <Text style={styles.inputeRequired}>{required}</Text>
        </InputRequiredContainer>
        <Input
          value={nickname}
          placeholder={nicknamePlaceholder}
          onChange={handleNicknameChange}
          className={
            nicknameLengthError ? css(styles.errorInput) : css(styles.input)
          }
          isActive
          onBlur={() => {
            if (nickname.length < 1) {
              setNicknameLengthError(nicknameError);
              checkAboutPageErrors(true);
            } else {
              setNicknameLengthError("");
              checkAboutPageErrors(false);
            }
          }}
        />
        {nicknameLengthError && (
          <div className={css(styles.errorContainer)}>
            <ErrorMessage error={nicknameLengthError} />
          </div>
        )}
        <InputLabel htmlFor="name-input" id="name-input-label">
          {fullName}
        </InputLabel>
        <Input
          value={name}
          placeholder={fullNamePlaceholder}
          onChange={handleNameChange}
          className={
            fullNameLengthError ? css(styles.errorInput) : css(styles.input)
          }
          isActive
          onBlur={() => {
            if (name.length < 1) {
              setFullNameLengthError(fullNameError);
              checkAboutPageErrors(true);
            } else {
              setFullNameLengthError("");
              checkAboutPageErrors(false);
            }
          }}
        />
        {fullNameLengthError && (
          <div className={css(styles.errorContainer)}>
            <ErrorMessage error={fullNameLengthError} />
          </div>
        )}

        <InputLabel htmlFor="language-input" id="language-input-label">
          {languagePreference}
        </InputLabel>
        <Dropdown
          userLanguage={userLanguage}
          handleLanguagePreferenceChange={handleLanguagePreferenceChange}
        />
        <InputLabel htmlFor="phone-number-input" id="phone-number-input-label">
          {phoneNumberRequired}
        </InputLabel>
        <Input
          value={phoneNumber}
          placeholder={phonePlaceholder}
          onChange={handlePhoneNumberChange}
          className={css(styles.input)}
          isActive
          onBlur={() => {
            if (phoneNumberValidator(phoneNumber)) {
              setPhoneValidationError("");
              checkAboutPageErrors(false);
            } else {
              setPhoneValidationError(phoneError);
              checkAboutPageErrors(true);
            }
          }}
        />
        {phoneValidationError && (
          <div className={css(styles.errorContainer)}>
            <ErrorMessage error={phoneValidationError} />
          </div>
        )}
      </Form>
    </AboutScreenWrapper>
  );
};

export default AboutScreen;

const AboutScreenWrapper = styled.div({
  marginTop: 19,
  marginBottom: 28,
  width: "100%",
  maxWidth: 335,
});

const InputRequiredContainer = styled.div({
  display: "flex",
  justifyContent: "space-between",
});

const styles = StyleSheet.create({
  aboutScreen: {
    marginTop: 19,
    marginBottom: 28,
  },
  formContainer: {
    marginTop: 20,
  },
  input: {
    height: 60,
  },
  errorInput: {
    border: `1px solid ${redOrange}`,
    height: 60,
  },
  inputRequiredContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  inputeRequired: {
    fontSize: 12,
  },
  errorContainer: {
    background: redAlpha,
    height: "100%",
    width: 335,
    marginBottom: 10,
    borderRadius: 8,
    padding: "10px 20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
});
