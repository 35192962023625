import styled from "@emotion/styled";
import React from "react";
import ModalBase from "shared/components/ModalBase";
import { mainGrey } from "shared/styles/colors";
import SignUpText from "./InvitationText";
import { StyleSheet } from "aphrodite";
import Button, { ButtonType } from "./InvitationButton";
import { LanguagePreference } from "shared/types";
import { TranslationService } from "services/Translation/Translation.Service";
import {
  Page,
  Identifiers as TextIdentifiers,
} from "services/Translation/enums";
import { ROUTES } from "../../constants";
import { useHistory } from "react-router";
import highFiveImage from "../../static/images/high-five.png";

interface PasswordCreateData {
  createPassword: {
    token: string;
    isAdmin: boolean;
  };
}
interface Props {
  modalIsOpen: boolean;
  setModalIsOpen: (modalIsOpen: boolean) => void;
  userLanguage: LanguagePreference;
  passwordCreateData: PasswordCreateData;
}

const ConfirmationModal = ({
  modalIsOpen,
  setModalIsOpen,
  userLanguage,
  passwordCreateData,
}: Props) => {
  const page = Page.UserInvited;
  const titleText = TranslationService.getStaticText({
    page,
    userLanguage,
    textIdentifier: TextIdentifiers.ModalTitle,
  });
  const paragraphText = TranslationService.getStaticText({
    page,
    userLanguage,
    textIdentifier: TextIdentifiers.ModalFirstParagraph,
  });
  const buttonText = TranslationService.getStaticText({
    page: Page.Shared,
    textIdentifier: TextIdentifiers.ContinueButton,
    userLanguage,
  });
  const titleTextFontSize = 36;
  const paragraphTextFontSize = 16;

  const history = useHistory();
  const handleGetStarted = () => {
    setModalIsOpen(false);
    document.body.classList.remove("hidden-body");
    if (passwordCreateData?.createPassword?.token) {
      if (passwordCreateData?.createPassword?.isAdmin) {
        history.push(ROUTES.ADMIN);
      } else {
        history.push(ROUTES.HOW_IT_WORKS, { isFirstTime: true });
      }
    }
  };

  return (
    <ModalBase
      modalIsOpen={modalIsOpen}
      closeModal={() => handleGetStarted()}
      setModalIsOpen={() => setModalIsOpen(true)}
      contentLabel="Unlocked Topic"
      hideCloseButton
    >
      <Container>
        <ImageContainer src={highFiveImage} alt="flowers and hands" />
        <TextContainer>
          <SignUpText
            titleText={titleText}
            titleTextFontSize={titleTextFontSize}
            paragraphText={paragraphText}
            paragraphTextFontSize={paragraphTextFontSize}
            isModal={modalIsOpen}
            titleCustomMargins="0px 0px 10px 0px"
          />
        </TextContainer>
        <Button
          text={buttonText}
          buttonType={ButtonType.Primary}
          buttonStyle={styles.getStartedButton}
          onClick={handleGetStarted}
        />
      </Container>
    </ModalBase>
  );
};

export default ConfirmationModal;

const Container = styled.div({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  width: 345,
  height: "auto",
  color: mainGrey,
  "@media (min-width: 630px)": {
    minWidth: 345,
  },
});

const ImageContainer = styled.img({
  height: "auto",
  width: 154,
  marginBottom: 4,
  marginTop: 52,
});

const TextContainer = styled.div({
  width: 284,
  maxHeight: 231,
});

const styles = StyleSheet.create({
  getStartedButton: {
    height: 60,
    width: 235,
    marginTop: 36,
    marginBottom: 66,
  },
});
