"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Environments = exports.ALL_TOPICS = exports.FINAL_PAGE_IN_STEP_TWO = void 0;
exports.FINAL_PAGE_IN_STEP_TWO = "FINAL_PAGE_IN_STEP_TWO";
exports.ALL_TOPICS = "ALL_TOPICS";
var Environments;
(function (Environments) {
    Environments["Staging"] = "staging";
    Environments["Production"] = "production";
})(Environments = exports.Environments || (exports.Environments = {}));
