import styled from "@emotion/styled";

const Spacer = styled.div(
  ({
    flexGrow = 1,
    removeOnDesktop = false,
    height = "auto",
  }: {
    flexGrow?: number;
    removeOnDesktop?: boolean;
    height?: number | string;
  }) => ({
    flexGrow,
    height,
    "@media (min-width: 630px)": {
      display: removeOnDesktop ? "none" : "flex",
    },
  })
);

export default Spacer;
