import { css, StyleSheet } from "aphrodite";
import React, { useEffect, useRef, useState } from "react";
import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import SwiperCore from "swiper";
import TopicSwiper from "shared/components/Swiper/TopicSwiper";
import PieGraph from "./PieGraph";
import Card from "shared/components/Card";
import Text, { Font } from "shared/components/Text";
import {
  buttonBlue,
  greyAccent,
  mainGrey,
  turquoise,
  white,
} from "shared/styles/colors";
import LineGraph from "./LineGraph";
import DoubleLineGraph from "./DoubleLineGraph";
import useForceUpdate from "use-force-update";
import GraphqlErrorScreen from "shared/components/ErrorBoundary/GraphqlErrorScreen";
import ResponsiveCard from "shared/components/ResposniveCard";
import Spinner from "react-spinkit";
import { Identifiers, Page } from "services/Translation/enums";
import { TranslationService } from "services/Translation/Translation.Service";
import MissingDataCard from "./MissingDataCard";
import { ALL_TOPICS } from "@quick-check/common";
import TopicsIncludedCard from "./TopicsIncludedCard";
import HamburgerMenu from "shared/components/HamburgerMenu/HamburgerMenu";
import { useDocumentTitle } from "shared/hooks";
import TopicProgress from "shared/components/TopicProgress/TopicProgress";
import {
  lowercaseChallegingBehaviorTitleOptions,
  lowercasePostiveBehaviorTitleOptions,
} from "./constants";
import { GRAPH_QUESTIONS_QUERY, TOPIC_USER_DATA } from "./queries";

interface Topic {
  id: string;
  color: string;
  order: number;
  translationData: {
    name: string;
  };
}

const Stats = () => {
  useDocumentTitle("Stats | Reflectable");
  const [isRendered, setIsRendered] = useState<boolean>(false);
  const [selectedTopicIndex, setSelectedTopicIndex] = useState<number>(0);
  const [currentTopic, setCurrentTopic] = useState<Topic | null>(null);
  const { data, loading, error } = useQuery(TOPIC_USER_DATA, {
    fetchPolicy: "no-cache",
  });
  const topics = data?.topics;
  const page = Page.Stats;
  const userLanguage = data?.userLanguage;

  const [
    questionsQuery,
    { data: questionsData, loading: questionsLoading },
  ] = useLazyQuery(GRAPH_QUESTIONS_QUERY, {
    variables: {
      topicId: currentTopic?.id,
    },
    fetchPolicy: "no-cache",
  });
  useEffect(() => {
    setTimeout(() => setIsRendered(true), 1200);
    if (topics) {
      topics.unshift({
        id: ALL_TOPICS,
        color: mainGrey,
        translationData: {
          name: TranslationService.getStaticText({
            page,
            userLanguage,
            textIdentifier: Identifiers.AllTopics,
          }),
        },
      });
    }
  }, [data, topics, page, userLanguage]);

  useEffect(() => {
    if (currentTopic) {
      questionsQuery();
    } else if (data) {
      setCurrentTopic(topics[0]);
    }
  }, [currentTopic, data, topics, questionsQuery]);

  const forceUpdate = useForceUpdate();

  const swiperRef = useRef<SwiperCore | null>(null);
  const [insufficientData, setInsufficientData] = useState(true);

  const onSlideChange = () => {
    if (swiperRef?.current) {
      setSelectedTopicIndex(swiperRef.current.activeIndex);
      setCurrentTopic(topics[swiperRef.current.activeIndex]);
      forceUpdate();
      setInsufficientData(true); // reset insufficientData flag so we can check if there is enough data before checking in line graph component if there are enough responses
    }
  };

  if (loading) {
    return null;
  }

  if (error) {
    return <GraphqlErrorScreen error={error} />;
  }

  const {
    multiGraphedPieQuestions,
    singleGraphedPieQuestions,
    singleGraphedLineQuestions,
    statsFocusData,
    effectivenessRatingQuestions,
    comparisonSingleGraphedLineOne,
    comparisonSingleGraphedLineTwo,
  } = questionsData || {};

  const legacyPositiveBehaviorQuestion = singleGraphedLineQuestions?.filter(
    (item) =>
      lowercasePostiveBehaviorTitleOptions.includes(
        item?.graphTranslations?.graphTitle.toLowerCase()
      )
  );
  const legacyChalleningBehaviorQuestion = singleGraphedLineQuestions?.filter(
    (item) =>
      lowercaseChallegingBehaviorTitleOptions.includes(
        item?.graphTranslations?.graphTitle.toLowerCase()
      )
  );
  const topicColor = currentTopic?.color ?? "#9924DD";

  const sharedDoubleLineGraphProps = {
    questionTitle: TranslationService.getStaticText({
      page: Page.Graphs,
      textIdentifier: Identifiers.Behaviors,
      userLanguage,
    }),
    textColor: topicColor,
    setInsufficientData,
    userLanguage,
  };

  let content;
  if (isRendered) {
    content = (
      <>
        <TopicSwiper
          isTopicChosen
          isStatsTopicSwiper
          swiperRef={swiperRef}
          onSlideChange={onSlideChange}
          selectedTopicIndex={selectedTopicIndex}
          topics={topics}
        />
        {currentTopic && (
          <div className={css(styles.questionsContainer)}>
            {currentTopic?.id === ALL_TOPICS &&
              questionsData?.topicsIncluded.length > 1 && (
                <TopicsIncludedCard
                  userLanguage={userLanguage}
                  topicsArray={questionsData.topicsIncluded}
                />
              )}
            {currentTopic?.id !== ALL_TOPICS && statsFocusData?.topicCount > 1 && (
              <Card
                cardStyle={styles.questionsContainer}
                contentStyle={styles.statsFocusData}
              >
                <TopicProgress
                  topic={currentTopic?.translationData?.name}
                  timesCompleted={statsFocusData?.topicCount}
                  topicOrder={currentTopic?.order}
                  userLanguage={userLanguage}
                />
                <div className={css(styles.statsFocusColumn)}>
                  <Text
                    font={Font.ProximaNovaBold}
                    style={styles.statsFocusDataTitle}
                  >
                    {TranslationService.getStaticText({
                      page,
                      userLanguage,
                      textIdentifier: Identifiers.FocusSelect,
                    })}
                  </Text>
                  {statsFocusData?.selectedFocuses?.map((focus, index) => (
                    <div
                      className={css(styles.bulletContainer)}
                      key={focus?.translationData?.id}
                    >
                      <div className={css(styles.bulletPointContainer)}>
                        <div className={css(styles.bulletPoint)} />
                      </div>
                      <Text
                        font={Font.ProximaNovaRegular}
                        style={
                          index === statsFocusData?.selectedFocuses?.length - 1
                            ? styles.lastBulletText
                            : styles.bulletText
                        }
                      >
                        {focus?.translationData?.name}
                      </Text>
                    </div>
                  ))}
                  <div className={css(styles.line)} />
                  <Text
                    font={Font.ProximaNovaBold}
                    style={styles.statsFocusDataTitle}
                  >
                    {TranslationService.getStaticText({
                      page,
                      userLanguage,
                      textIdentifier: Identifiers.AdditionalFocus,
                    })}
                  </Text>
                  {statsFocusData?.unselectedFocuses?.map((focus) => (
                    <div
                      className={css(styles.bulletContainer)}
                      key={focus?.translationData?.id}
                    >
                      <div className={css(styles.bulletPointContainer)}>
                        <div className={css(styles.bulletPoint)} />
                      </div>
                      <Text
                        font={Font.ProximaNovaRegular}
                        style={styles.bulletText}
                      >
                        {focus?.translationData?.name}
                      </Text>
                    </div>
                  ))}
                </div>
              </Card>
            )}
            {questionsLoading && (
              <Spinner name="line-scale" color={buttonBlue} fadeIn="none" />
            )}
            <Card
              cardStyle={
                insufficientData
                  ? styles.displayNone
                  : styles.questionsContainer
              }
              contentStyle={styles.questionsContainerContent}
            >
              {effectivenessRatingQuestions?.map((item, index) => (
                <LineGraph
                  key={`${item.id}-${index}`}
                  questionId={item.id}
                  questionTitle={TranslationService.getStaticText({
                    page: Page.Graphs,
                    textIdentifier: Identifiers.Effectiveness,
                    userLanguage,
                  })}
                  textColor={topicColor}
                  topicId={currentTopic.id}
                  topAxis={item?.graphTranslations?.topOfAxis}
                  bottomAxis={item?.graphTranslations?.bottomOfAxis}
                  setInsufficientData={setInsufficientData}
                  userLanguage={userLanguage}
                />
              ))}
              {comparisonSingleGraphedLineOne?.length
                ? comparisonSingleGraphedLineOne?.map((item, index) => (
                    // eslint-disable-next-line react/jsx-indent
                    <DoubleLineGraph
                      {...sharedDoubleLineGraphProps}
                      key={`${item.id}-${index}`}
                      questionId={item.id}
                      topicId={currentTopic.id}
                      topAxis={item?.graphTranslations?.topOfAxis}
                      bottomAxis={item?.graphTranslations?.bottomOfAxis}
                      secondQuestionId={comparisonSingleGraphedLineTwo[0]?.id}
                    />
                  ))
                : legacyPositiveBehaviorQuestion?.map((item, index) => (
                    // eslint-disable-next-line react/jsx-indent
                    <DoubleLineGraph
                      {...sharedDoubleLineGraphProps}
                      key={`${item.id}-${index}`}
                      questionId={item.id}
                      topicId={currentTopic.id}
                      topAxis={item?.graphTranslations?.topOfAxis}
                      bottomAxis={item?.graphTranslations?.bottomOfAxis}
                      secondQuestionId={legacyChalleningBehaviorQuestion[0]?.id}
                    />
                  ))}
              {multiGraphedPieQuestions?.map((item, index) => (
                <PieGraph
                  key={`${item.id}-${index}`}
                  questionId={item.id}
                  questionTitle={item?.graphTranslations?.graphTitle}
                  textColor={topicColor}
                  topicId={currentTopic.id}
                />
              ))}
              {singleGraphedPieQuestions?.map((item, index) => (
                <PieGraph
                  key={`${item.id}-${index}`}
                  questionId={item.id}
                  questionTitle={item?.graphTranslations?.graphTitle}
                  textColor={topicColor}
                  topicId={currentTopic.id}
                />
              ))}
            </Card>
            {insufficientData && !questionsLoading && (
              <MissingDataCard page={page} userLanguage={userLanguage} />
            )}
          </div>
        )}
      </>
    );
  } else {
    content = (
      <div className={css(styles.loadingContainer)}>
        <Spinner name="line-scale" color={buttonBlue} fadeIn="none" />
      </div>
    );
  }

  return (
    <>
      <HamburgerMenu logoIsVisible />
      <section className={css(styles.Stats)}>
        <div className={css(styles.topContainer)}>
          <Text style={styles.statsText}>
            {TranslationService.getStaticText({
              page,
              userLanguage,
              textIdentifier: Identifiers.MyProgress,
            })}
          </Text>
          <Text style={styles.subTitleText} font={Font.ProximaNovaSemibold}>
            {TranslationService.getStaticText({
              page,
              userLanguage,
              textIdentifier: Identifiers.SubTitle,
            })}
          </Text>
        </div>
        <ResponsiveCard contentStyle={styles.responsiveCardContentStyle}>
          {content}
        </ResponsiveCard>
      </section>
    </>
  );
};

export default Stats;

const styles = StyleSheet.create({
  Stats: {
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 78,
    paddingBottom: 20,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  questionsContainerContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: 355,
    paddingTop: 35,
  },
  questionsContainer: {
    marginBottom: 20,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  displayNone: { display: "none" },
  statsText: {
    marginLeft: 10,
    fontSize: 32,
    marginBottom: 20,
    color: turquoise,
    textTransform: "capitalize",
  },
  subTitleText: {
    textAlign: "center",
    fontSize: 12,
    textTransform: "uppercase",
    color: turquoise,
    letterSpacing: 2,
    marginBottom: 21,
  },
  statsFocusData: {
    width: 355,
  },
  statsFocusDataTitle: {
    color: turquoise,
    textTransform: "uppercase",
    marginBottom: 10,
    fontSize: 12,
    letterSpacing: 2,
  },
  topicCount: {
    fontSize: 24,
    color: mainGrey,
    marginBottom: 10,
  },
  statsFocusColumn: {
    padding: "30px 20px",
  },
  line: {
    marginBottom: 20,
    width: "100%",
    height: 1,
    backgroundColor: greyAccent,
  },
  bulletContainer: {
    display: "flex",
  },
  bulletText: {
    color: mainGrey,
    fontSize: 16,
    marginBottom: 5,
  },
  bulletPoint: {
    width: 5,
    height: 1,
    backgroundColor: mainGrey,
    marginTop: 5,
  },
  lastBulletText: {
    color: mainGrey,
    fontSize: 16,
    marginBottom: 19,
  },
  bulletPointContainer: {
    width: 5,
    marginRight: 5,
  },
  topContainer: {
    width: "100%",
    "@media (min-width: 630px)": {
      width: 630,
    },
  },
  responsiveCardContentStyle: {
    overflow: "hidden",
    "@media (min-width: 630px)": {
      paddingTop: 20,
    },
  },
  loadingContainer: {
    width: "100%",
    background: white,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 96,
  },
});
