import { css, StyleSheet } from "aphrodite";
import React from "react";
import { useHistory } from "react-router-dom";
import Button, { ButtonType } from "shared/components/Button";
import Text, { Font } from "shared/components/Text";
import {
  mainGrey,
  turquoise,
  greyAccent,
  backgroundWhite,
} from "shared/styles/colors";
import Spacer from "./Spacer";
import Div100vh from "react-div-100vh";
import { standardDropShadow } from "shared/styles/constants";
import NavigationHeader from "./Navigation/NavigationHeader";

interface Props {
  imageSrc?: string;
  videoSrc?: string;
  title: string;
  body: string;
  bodyOne?: string;
  bodyTwo?: string;
  buttonLocation: string;
  buttonText: string;
  hasSecondaryButton: boolean;
  secondaryButtonText?: string;
  secondaryButtonLocation?: string;
  onClick?: (() => Promise<void>) | (() => void) | undefined;
  loading?: any;
  stateProps?: any;
  hasPrimaryButton: boolean;
  disabled: boolean;
  overflowVh: boolean;
  imageAltText: string;
  showNavHeader?: boolean;
  children?: React.ReactNode | null;
}

const IllustrationScreen = ({
  imageSrc,
  title,
  body,
  bodyOne,
  bodyTwo,
  buttonText,
  buttonLocation,
  hasSecondaryButton,
  secondaryButtonText,
  secondaryButtonLocation,
  onClick,
  loading,
  stateProps,
  hasPrimaryButton,
  videoSrc,
  disabled,
  overflowVh,
  imageAltText,
  showNavHeader = false,
  children,
}: Props) => {
  const history = useHistory();
  const onButtonPress = () => {
    if (stateProps && buttonLocation) {
      history.push(buttonLocation, stateProps);
    } else if (!stateProps && buttonLocation) {
      history.push(buttonLocation);
    } else {
      history.push("/");
    }
  };

  return (
    <Div100vh
      className={css(styles.IllustrationScreen)}
      style={{ minHeight: overflowVh ? "100vh" : "none" }}
    >
      <div className={css(styles.innerContainer)}>
        <div className={css(styles.topAccent)} />
        <div className={css(styles.contentContainer)}>
          {showNavHeader ? (
            <NavigationHeader />
          ) : (
            <Spacer height={64} flexGrow={0} />
          )}
          {imageSrc && (
            <img
              className={css(styles.image)}
              src={imageSrc}
              alt={imageAltText ?? "Illustration"}
            />
          )}
          {videoSrc && (
            <video
              className={css(styles.video)}
              autoPlay
              loop
              muted
              playsInline
            >
              <source src={videoSrc} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          )}
          <Text style={styles.title} font={Font.ProximaNovaBold}>
            {title}
          </Text>
          <Text style={styles.bodyText} font={Font.ProximaNovaRegular}>
            {body}
          </Text>
          {bodyOne && (
            <Text style={styles.bodyText} font={Font.ProximaNovaRegular}>
              {bodyOne}
            </Text>
          )}
          {bodyTwo && (
            <Text style={styles.bodyText} font={Font.ProximaNovaRegular}>
              {bodyTwo}
            </Text>
          )}
          {children}
        </div>
      </div>
      <Spacer removeOnDesktop />
      {hasPrimaryButton && (
        <Button
          text={buttonText}
          ariaLabel={buttonText}
          onClick={onClick ? () => onClick() : () => onButtonPress()}
          loading={loading}
          disabled={disabled}
          buttonStyle={styles.button}
        />
      )}
      {hasSecondaryButton && (
        <Button
          buttonType={ButtonType.Secondary}
          text={secondaryButtonText}
          ariaLabel={secondaryButtonText}
          onClick={() => history.push(secondaryButtonLocation ?? "/")}
        />
      )}
      <Spacer height={30} flexGrow={0} />
    </Div100vh>
  );
};

IllustrationScreen.defaultProps = {
  hasSecondaryButton: false,
  hasPrimaryButton: true,
  disabled: false,
  overflowVh: false,
} as Partial<Props>;

export default IllustrationScreen;

const styles = StyleSheet.create({
  IllustrationScreen: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "0 10px 5px",
    "@media (min-width: 630px)": {
      justifyContent: "center",
      paddingBottom: 0,
    },
    backgroundColor: backgroundWhite,
  },
  innerContainer: {
    borderRadius: 10,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: 10,
    width: "100%",
    "@media (min-width: 630px)": {
      width: 630,
      minHeight: 660,
      boxShadow: standardDropShadow,
      marginBottom: 40,
    },
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    "@media (min-width: 630px)": {
      padding: "20px 0 20px",
    },
  },
  topAccent: {
    display: "none",
    height: 10,
    width: "100%",
    borderRadius: "10px 10px 0 0",
    backgroundColor: greyAccent,
    "@media (min-width: 630px)": {
      display: "flex",
    },
  },
  button: {
    minHeight: 60,
    marginBottom: 10,
  },
  image: {
    height: 286,
    width: "auto",
    marginBottom: 10,
    "@media (min-width: 630px)": {
      height: 350,
      marginBottom: 30,
    },
    "@media (max-height: 750px)": {
      height: 200,
    },
  },
  title: {
    fontSize: 36,
    lineHeight: 1.2,
    color: turquoise,
    marginBottom: 23,
    textAlign: "center",
    maxWidth: 475,
    "@media (max-width: 360px)": {
      fontSize: 30,
      marginBottom: 10,
    },
    "::first-letter": {
      textTransform: "capitalize",
    },
  },
  bodyText: {
    width: "100%",
    maxWidth: 335,
    fontSize: 20,
    lineHeight: 1.4,
    color: mainGrey,
    textAlign: "center",
    marginBottom: 10,
    "@media (max-width: 360px)": {
      fontSize: 18,
    },
    "@media (min-width: 630px)": {
      maxWidth: 510,
    },
  },
  video: {
    width: "60%",
    height: "auto",
    marginBottom: 62,
  },
});
