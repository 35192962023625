import React from "react";
import { white } from "shared/styles/colors";
import { IconProps as Props } from "shared/types/index";

const XIcon = ({ color, height, width }: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Close</title>
      <path
        d="M2.18427 0.356424C1.9448 0.125135 1.62407 -0.00284494 1.29116 4.79985e-05C0.958241 0.00294094 0.639781 0.136476 0.404365 0.371891C0.16895 0.607306 0.0354152 0.925766 0.0325223 1.25868C0.0296294 1.5916 0.157609 1.91233 0.388898 2.1518L7.7215 9.4844L0.387628 16.817C0.266358 16.9341 0.169628 17.0742 0.103083 17.2291C0.036539 17.384 0.00151296 17.5507 4.79407e-05 17.7193C-0.00141708 17.8878 0.0307086 18.055 0.0945509 18.2111C0.158393 18.3671 0.252674 18.5089 0.37189 18.6281C0.491107 18.7473 0.632873 18.8416 0.788917 18.9054C0.94496 18.9693 1.11215 19.0014 1.28075 19C1.44934 18.9985 1.61595 18.9635 1.77086 18.8969C1.92577 18.8304 2.06588 18.7336 2.183 18.6124L9.51687 11.2798L16.8495 18.6124C17.0889 18.8437 17.4097 18.9716 17.7426 18.9687C18.0755 18.9659 18.394 18.8323 18.6294 18.5969C18.8648 18.3615 18.9983 18.043 19.0012 17.7101C19.0041 17.3772 18.8761 17.0565 18.6448 16.817L11.3122 9.4844L18.6448 2.1518C18.8761 1.91233 19.0041 1.5916 19.0012 1.25868C18.9983 0.925766 18.8648 0.607306 18.6294 0.371891C18.394 0.136476 18.0755 0.00294094 17.7426 4.79985e-05C17.4097 -0.00284494 17.0889 0.125135 16.8495 0.356424L9.51687 7.68902L2.18427 0.355154V0.356424Z"
        fill={color}
      />
    </svg>
  );
};

XIcon.defaultProps = {
  color: white,
  height: 20,
  width: 19,
};

export default XIcon;
