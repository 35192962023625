/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { css, StyleSheet } from "aphrodite";
import React from "react";
import { Style } from "shared/types";
import { transparent } from "shared/styles/colors";
import LeftArrowBack from "static/svgs/LeftArrowBack";

interface Props {
  onButtonClick: () => void | null;
  containerStyle?: Style | Style[];
}

const BackButton = ({ onButtonClick, containerStyle }: Props) => {
  return (
    <button
      className={css([styles.backContainer, containerStyle])}
      onClick={() => onButtonClick()}
    >
      <LeftArrowBack />
    </button>
  );
};

export default BackButton;

const styles = StyleSheet.create({
  backContainer: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    background: transparent,
    ":hover": {
      textDecoration: "underline",
    },
  },
});
