import { LanguagePreference } from "shared/types";
import { Translation } from "../Translation.Service";
import { Identifiers } from "../enums";

const translations: Translation = {
  [Identifiers.Title]: {
    [LanguagePreference.English]: "Let’s get you set up",
    [LanguagePreference.Spanish]: "Vamos a configurarte",
    [LanguagePreference.Vietnamese]: "Hãy để chúng tôi giúp bạn thiết lập",
  },
  [Identifiers.Body]: {
    [LanguagePreference.English]:
      "You can customize the language of the curriculum content.",
    [LanguagePreference.Spanish]:
      "Puedes personalizar el idioma del contenido del plan de estudios.",
    [LanguagePreference.Vietnamese]:
      "Bạn có thể tùy chỉnh ngôn ngữ của nội dung chương trình học.",
  },
  [Identifiers.Required]: {
    [LanguagePreference.English]: "*Required",
    [LanguagePreference.Spanish]: "*Requerido",
    [LanguagePreference.Vietnamese]: "*Cần thiết",
  },
  [Identifiers.LanguagePreference]: {
    [LanguagePreference.English]: "Language",
    [LanguagePreference.Spanish]: "Idioma",
    [LanguagePreference.Vietnamese]: "Ngôn ngữ",
  },
  [Identifiers.UserType]: {
    [LanguagePreference.English]: "Role",
    [LanguagePreference.Spanish]: "Rol",
    [LanguagePreference.Vietnamese]: "Vai trò",
  },
};

export default translations;
