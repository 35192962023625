import styled from "@emotion/styled";
import React from "react";
import BackButton from "./BackButton";
import { useHistory } from "react-router";
import XIcon from "static/svgs/XIconComponent";
import { darkGreenBlue, transparent } from "shared/styles/colors";
import { ROUTES } from "../../../constants";
import Spacer from "../Spacer";

interface Props {
  hideBackButton?: boolean;
  customClose?: () => void;
  onBackButtonClick?: () => void;
  isFullWidth?: boolean;
}

const NavigationHeader = ({
  hideBackButton = false,
  customClose,
  onBackButtonClick,
  isFullWidth = false,
}: Props) => {
  const history = useHistory();
  return (
    <NavigationHeaderContainer isFullWidth={isFullWidth}>
      {hideBackButton ? (
        <Spacer />
      ) : (
        <BackButton
          onButtonClick={
            onBackButtonClick
              ? () => onBackButtonClick()
              : () => history.goBack()
          }
        />
      )}
      <Button
        onClick={
          customClose
            ? () => customClose()
            : () => history.push(ROUTES.DASHBOARD)
        }
      >
        <XIcon color={darkGreenBlue} height={28} width={28} />
      </Button>
    </NavigationHeaderContainer>
  );
};

export default NavigationHeader;

const Button = styled.button({
  background: transparent,
});

const NavigationHeaderContainer = styled.div(
  ({ isFullWidth }: { isFullWidth: boolean }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: isFullWidth ? 0 : "0 20px",
    width: "100%",
    height: 64,
  })
);
