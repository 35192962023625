import { css, StyleSheet } from "aphrodite";
import React from "react";
import Text, { Font } from "shared/components/Text";
import { grey, turquoise, mainGrey, greyAccent } from "shared/styles/colors";
import Spacer from "shared/components/Spacer";
import Button, { ButtonType } from "shared/components/Button";
import { standardDropShadow } from "shared/styles/constants";
import NavigationHeader from "../Navigation/NavigationHeader";

interface Props {
  title?: string;
  subTitle: string;
  continueButtonText: string;
  onContinueClick: () => void;
  skipButton: boolean;
  skipButtonText?: string;
  onSkipClick?: () => void;
  bodyTexts: string[];
  isLoading?: boolean;
}

const StepIntro = ({
  title,
  onContinueClick,
  onSkipClick,
  subTitle,
  skipButtonText,
  continueButtonText,
  skipButton,
  bodyTexts,
  isLoading,
}: Props) => {
  return (
    <section className={css(styles.StepIntro)}>
      <NavigationHeader />
      <Spacer removeOnDesktop />
      <div className={css(styles.innerContainer)}>
        <div className={css(styles.topAccent)} />
        <Spacer />
        {title && (
          <Text font={Font.ProximaNovaBold} style={styles.label}>
            {title}
          </Text>
        )}
        <Text font={Font.ProximaNovaBold} style={styles.header}>
          {subTitle}
        </Text>
        <Spacer />
        <div className={css(styles.bodyContainer)}>
          {bodyTexts?.map((text, index) => (
            <Text
              key={index}
              font={Font.ProximaNovaRegular}
              style={styles.bodyText}
            >
              {text}
            </Text>
          ))}
        </div>
        <Spacer />
      </div>
      <Spacer removeOnDesktop />
      {skipButton && (
        <Button
          buttonStyle={styles.topButton}
          buttonType={ButtonType.Secondary}
          onClick={onSkipClick}
          text={skipButtonText}
          ariaLabel={skipButtonText}
          loading={isLoading}
        />
      )}
      <Button
        buttonStyle={styles.continueButton}
        text={continueButtonText}
        ariaLabel={continueButtonText}
        onClick={onContinueClick}
      />
    </section>
  );
};

export default StepIntro;

const styles = StyleSheet.create({
  StepIntro: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minHeight: "100vh",
    paddingBottom: 40,
    justifyContent: "center",
    "@media (min-width: 630px)": {
      paddingBottom: 0,
    },
  },
  innerContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "100%",
    "@media (min-width: 630px)": {
      width: 630,
      minHeight: 600,
      boxShadow: standardDropShadow,
      marginTop: 20,
      borderRadius: 10,
      overflow: "hidden",
      paddingBottom: 60,
      marginBottom: 40,
    },
  },
  topAccent: {
    display: "none",
    height: 10,
    width: "100%",
    backgroundColor: greyAccent,
    "@media (min-width: 630px)": {
      display: "block",
      marginBottom: 20,
    },
  },
  label: {
    color: grey,
    fontSize: 24,
    marginBottom: 10,
    textTransform: "capitalize",
  },
  header: {
    fontSize: 36,
    color: turquoise,
    textTransform: "lowercase",
    marginBottom: 40,
    textAlign: "center",
    "@media (min-width: 630px)": {
      maxWidth: 350,
    },
    "::first-letter": {
      textTransform: "capitalize",
    },
  },
  bodyContainer: {},
  bodyText: {
    color: mainGrey,
    fontSize: 16,
    lineHeight: 1.6,
    marginBottom: 32,
    width: "100%",
    maxWidth: 530,
    padding: "0 20px",
    "@media (min-width: 630px)": {
      fontSize: 20,
    },
  },
  topButton: {
    marginBottom: 10,
  },
  link: {
    width: "100%",
  },
  continueButton: {},
});
