import React, { useState } from "react";
import SignUpText from "./InvitationText";
import { StyleSheet, css } from "aphrodite";
import {
  Input,
  InputLabel,
} from "../../screens/settings/shared/styledComponents";
import Text, { Font } from "shared/components/Text";
import { redAlpha, redOrange } from "shared/styles/colors";
import ErrorMessage from "../../screens/auth/CreatePassword/ErrorMessage";
import styled from "@emotion/styled";
import openEye from "../../static/images/openEye.png";
import closedEye from "../../static/images/closedEye.png";
import PasswordValidator from "password-validator";
import Checkbox from "./Checkbox";
import { TranslationService } from "services/Translation/Translation.Service";
import {
  Page,
  Identifiers as TextIdentifiers,
} from "services/Translation/enums";
import { LanguagePreference } from "shared/types";
import * as EmailValidator from "email-validator";
import TermsAndConditionsModal from "shared/components/TermsAndConditionsModal";

interface Props {
  userEmail?: string;
  onEmailAndPasswordChange: (
    email: string,
    password: string,
    confirmPassword: string
  ) => void;
  onCheckboxIsChecked: (isChecked: boolean) => void;
  userLanguage: LanguagePreference;
  isChecked: boolean;
}

const SignUpScreen = ({
  userEmail = "",
  onEmailAndPasswordChange,
  onCheckboxIsChecked,
  userLanguage,
  isChecked,
}: Props) => {
  const [email, setEmail] = useState<string>(userEmail);
  const [emailError, setEmailError] = useState<string>("");
  const [digitsError, setDigitsError] = useState<string>("");
  const [lengthError, setLengthError] = useState<string>("");
  const [spacesError, setSpacesError] = useState<string>("");
  const [lettersError, setLettersError] = useState<string>("");
  const [matchingError, setMatchingError] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [eyeImage, setEyeImage] = useState(openEye);
  const [confirmEyeImage, setConfirmEyeImage] = useState(openEye);
  const [isTermsModalOpen, setIsTermsModalOpen] = useState<boolean>(false);

  const titleText = TranslationService.getStaticText({
    page: Page.UserInvited,
    textIdentifier: TextIdentifiers.SignUpTitle,
    userLanguage,
  });
  const paragraphText = TranslationService.getStaticText({
    page: Page.UserInvited,
    textIdentifier: TextIdentifiers.SignUpParagraph,
    userLanguage,
  });
  const requiredText = TranslationService.getStaticText({
    page: Page.UserInvited,
    textIdentifier: TextIdentifiers.Required,
    userLanguage,
  });
  const emailAddress = TranslationService.getStaticText({
    page: Page.UserInvited,
    textIdentifier: TextIdentifiers.Email,
    userLanguage,
  });
  const passwordText = TranslationService.getStaticText({
    page: Page.UserInvited,
    textIdentifier: TextIdentifiers.Password,
    userLanguage,
  });
  const passwordPlaceholderText = TranslationService.getStaticText({
    page: Page.UserInvited,
    textIdentifier: TextIdentifiers.PasswordDefault,
    userLanguage,
  });
  const confirmPasswordText = TranslationService.getStaticText({
    page: Page.UserInvited,
    textIdentifier: TextIdentifiers.ConfirmPassword,
    userLanguage,
  });
  const confirmPasswordPlaceholderText = TranslationService.getStaticText({
    page: Page.UserInvited,
    textIdentifier: TextIdentifiers.ConfirmPasswordDefault,
    userLanguage,
  });
  const termsAndConditionsTextFirst = TranslationService.getStaticText({
    page: Page.UserInvited,
    textIdentifier: TextIdentifiers.TermsAndConditionsFirst,
    userLanguage,
  });
  const termsAndConditionsTextSecond = TranslationService.getStaticText({
    page: Page.UserInvited,
    textIdentifier: TextIdentifiers.TermsAndConditionsSecond,
    userLanguage,
  });
  const termsAndConditionsTextThird = TranslationService.getStaticText({
    page: Page.UserInvited,
    textIdentifier: TextIdentifiers.TermsAndConditionsThird,
    userLanguage,
  });
  const emailPlaceholderText = TranslationService.getStaticText({
    page: Page.UserInvited,
    textIdentifier: TextIdentifiers.EmailDefault,
    userLanguage,
  });
  const passwordLengthError = TranslationService.getStaticText({
    page: Page.UserInvited,
    textIdentifier: TextIdentifiers.PasswordLengthError,
    userLanguage,
  });
  const passwordDigitsError = TranslationService.getStaticText({
    page: Page.UserInvited,
    textIdentifier: TextIdentifiers.PasswordDigitsError,
    userLanguage,
  });
  const passwordSpacesError = TranslationService.getStaticText({
    page: Page.UserInvited,
    textIdentifier: TextIdentifiers.PasswordSpacesError,
    userLanguage,
  });
  const passwordLettersError = TranslationService.getStaticText({
    page: Page.UserInvited,
    textIdentifier: TextIdentifiers.PasswordLettersError,
    userLanguage,
  });
  const confirmPasswordError = TranslationService.getStaticText({
    page: Page.UserInvited,
    textIdentifier: TextIdentifiers.ConfirmPasswordError,
    userLanguage,
  });

  const titleTextFontSize = 30;
  const paragraphTextFontSize = 16;

  const handleEmailChange = (event) => {
    const newEmail = event.target.value;
    setEmail(newEmail);
    onEmailAndPasswordChange(newEmail, password, confirmPassword);
  };
  const handlePasswordChange = (event) => {
    const userPassword = event.target.value;
    setPassword(userPassword);
    onEmailAndPasswordChange(email, userPassword, confirmPassword);
  };
  const handleConfirmPasswordChange = (event) => {
    const userConfirmPassword = event.target.value;
    setConfirmPassword(userConfirmPassword);
    onEmailAndPasswordChange(email, password, userConfirmPassword);
  };

  const fullPasswordValidator = new PasswordValidator();
  const digitsValidator = new PasswordValidator();
  const spacesValidator = new PasswordValidator();
  const lettersValidator = new PasswordValidator();
  fullPasswordValidator.has().letters().has().digits().has().not().spaces();
  digitsValidator.has().digits();
  spacesValidator.has().spaces();
  lettersValidator.has().letters();

  const formErrors = lengthError || digitsError || spacesError || lettersError;

  const formMatchingError = matchingError;

  const handleCheckboxChange = () => {
    onCheckboxIsChecked(!isChecked);
  };

  const handleEyeClick = () => {
    return eyeImage === openEye ? setEyeImage(closedEye) : setEyeImage(openEye);
  };

  const handleConfirmEyeClick = () => {
    return confirmEyeImage === openEye
      ? setConfirmEyeImage(closedEye)
      : setConfirmEyeImage(openEye);
  };

  // TODO: move this styled div below with the rest of them with proper state handling (culprit might be object literal syntax)
  // These styled components are here so they'll actually update on state change
  const EyeImage = styled.img({
    position: "absolute",
    right: 22,
    top: eyeImage === openEye ? 21 : 18,
    height: eyeImage === openEye ? 15 : 21,
    width: 22,
  });

  const ConfirmEyeImage = styled.img({
    position: "absolute",
    right: 22,
    top: confirmEyeImage === openEye ? 21 : 18,
    height: confirmEyeImage === openEye ? 15 : 21,
    width: 22,
  });

  if (!userLanguage) {
    return null;
  }

  return (
    <OuterContainer>
      <TermsAndConditionsModal
        userLanguage={userLanguage}
        isOpen={isTermsModalOpen}
        setIsOpen={setIsTermsModalOpen}
      />
      <SignUpText
        titleText={titleText}
        titleTextFontSize={titleTextFontSize}
        paragraphText={paragraphText}
        paragraphTextFontSize={paragraphTextFontSize}
      />
      <FormContainer>
        <InputRequiredContainer>
          <InputLabel
            className={css(styles.inputLabel)}
            htmlFor="email-input"
            id="email-input-label"
          >
            {emailAddress}
          </InputLabel>
          <Text style={styles.inputeRequired}>{requiredText}</Text>
        </InputRequiredContainer>
        <Input
          value={email}
          placeholder={emailPlaceholderText}
          onChange={handleEmailChange}
          className={css(styles.input)}
          isActive
          onBlur={() => {
            if (EmailValidator.validate(email)) {
              setEmailError("");
            } else {
              setEmailError("Please enter a valid email");
            }
          }}
        />
        {emailError && (
          <div className={css(styles.errorContainer)}>
            {emailError && <ErrorMessage error={emailError} />}
          </div>
        )}
        <InputLabelContainer>
          <InputLabel
            className={css(styles.inputLabel)}
            htmlFor="password-input"
            id="password-input-label"
          >
            {passwordText}
          </InputLabel>
        </InputLabelContainer>
        <InputContainer>
          <Input
            className={
              lengthError || digitsError || spacesError || lettersError
                ? css(styles.errorInput)
                : css(styles.input)
            }
            type={eyeImage === openEye ? "password" : "text"}
            value={password}
            placeholder={passwordPlaceholderText}
            isActive
            isNewUser
            onChange={handlePasswordChange}
            onBlur={() => {
              if (password.length < 8) {
                setLengthError(passwordLengthError);
              } else {
                setLengthError("");
              }
              if (!digitsValidator.validate(password)) {
                setDigitsError(passwordDigitsError);
              } else {
                setDigitsError("");
              }
              if (spacesValidator.validate(password)) {
                setSpacesError(passwordSpacesError);
              } else {
                setSpacesError("");
              }
              if (!lettersValidator.validate(password)) {
                setLettersError(passwordLettersError);
              } else {
                setLettersError("");
              }
            }}
          />
          <EyeImage
            src={eyeImage}
            alt="clickable eye"
            onClick={handleEyeClick}
          />
          {formErrors && (
            <div className={css(styles.errorContainer)}>
              {lengthError && <ErrorMessage error={lengthError} />}
              {digitsError && <ErrorMessage error={digitsError} />}
              {spacesError && <ErrorMessage error={spacesError} />}
              {lettersError && <ErrorMessage error={lettersError} />}
            </div>
          )}
        </InputContainer>
        <InputLabelContainer>
          <InputLabel
            htmlFor="confirm-password-input"
            id="confirm-password-input-label"
          >
            {confirmPasswordText}
          </InputLabel>
        </InputLabelContainer>
        <InputContainer>
          <Input
            className={css(styles.input)}
            type={confirmEyeImage === openEye ? "password" : "text"}
            value={confirmPassword}
            placeholder={confirmPasswordPlaceholderText}
            onChange={handleConfirmPasswordChange}
            onBlur={() => {
              if (password !== confirmPassword) {
                setMatchingError(confirmPasswordError);
              } else {
                setMatchingError("");
              }
            }}
            isActive
            isNewUser
          />
          <ConfirmEyeImage
            src={confirmEyeImage}
            alt="confirm clickable eye"
            onClick={handleConfirmEyeClick}
          />
          {formMatchingError && (
            <div className={css(styles.errorContainer)}>
              {matchingError && <ErrorMessage error={matchingError} />}
            </div>
          )}
        </InputContainer>
        <CheckboxContainer>
          <Checkbox isChecked={isChecked} onChange={handleCheckboxChange} />
          <TermsAndConditionsContainer>
            <Text
              style={styles.termsAndConditionsText}
              font={Font.ProximaNovaRegular}
            >
              {termsAndConditionsTextFirst}
              <a
                aria-label="Open Terms Button"
                href="https://www.startearly.org/terms-of-use/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Text
                  style={[styles.termsAndConditionsText, styles.zeroMargin]}
                  font={Font.ProximaNovaBold}
                >
                  {termsAndConditionsTextSecond}&nbsp;
                </Text>
              </a>
              {termsAndConditionsTextThird}
            </Text>
          </TermsAndConditionsContainer>
        </CheckboxContainer>
      </FormContainer>
    </OuterContainer>
  );
};

export default SignUpScreen;

const FormContainer = styled.div({
  marginTop: 20,
});

const OuterContainer = styled.div({
  marginTop: 19,
  marginBottom: 28,
  maxWidth: 335,
});

const InputRequiredContainer = styled.div({
  display: "flex",
  justifyContent: "space-between",
});

const InputLabelContainer = styled.div({
  display: "flex",
});

const CheckboxContainer = styled.label({
  display: "flex",
  alignItems: "center",
});

const InputContainer = styled.div({
  position: "relative",
});

const TermsAndConditionsContainer = styled.div({
  display: "flex",
  flexWrap: "wrap",
  alignItems: "flex-start",
  width: "100%",
});

const styles = StyleSheet.create({
  aboutScreen: {
    marginTop: 19,
    marginBottom: 28,
  },
  formContainer: {
    marginTop: 20,
    marginLeft: 29,
    marginRight: 26,
  },
  input: {
    height: 60,
  },
  inputLabel: {
    alignSelf: "auto",
  },
  errorInput: {
    border: `1px solid ${redOrange}`,
    height: 60,
  },
  inputeRequired: {
    fontSize: 12,
  },
  errorContainer: {
    background: redAlpha,
    height: 78,
    width: 335,
    marginBottom: 10,
    borderRadius: 8,
    padding: "10px 20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  termsAndConditionsText: {
    marginRight: 3,
    display: "inline",
    textAlign: "left",
  },
  zeroMargin: {
    margin: 0,
  },
});
