import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import React from "react";
import { Page, Identifiers } from "services/Translation/enums";
import { TranslationService } from "services/Translation/Translation.Service";
import GraphqlErrorScreen from "shared/components/ErrorBoundary/GraphqlErrorScreen";
import IllustrationScreen from "shared/components/IllustrationScreen";
import { useDocumentTitle } from "shared/hooks";
import ReflectionCompleteImage from "static/images/ReflectionCompleteImage.png";
import { ROUTES } from "../../../constants";
import { useHistory } from "react-router";

const CURRENT_USER = gql`
  query CurrentUser {
    currentUser {
      nickname
      languagePreference
    }
  }
`;

const IntroComplete = () => {
  useDocumentTitle("Intro Complete | Reflectable");
  const { data, error } = useQuery(CURRENT_USER);
  const page = Page.IntroComplete;
  const userLanguage = data?.currentUser?.languagePreference ?? "ENGLISH";
  const name = data?.currentUser?.nickname;
  const history = useHistory();
  if (error) {
    return <GraphqlErrorScreen error={error} />;
  }
  return (
    <IllustrationScreen
      imageSrc={ReflectionCompleteImage}
      imageAltText="Intro Complete"
      title={TranslationService.getStaticText({
        page,
        textIdentifier: Identifiers.SplitTitleOne,
        userLanguage,
      })}
      body={TranslationService.getStaticText({
        page,
        userLanguage,
        textIdentifier: Identifiers.Body,
      })}
      bodyOne={TranslationService.getStaticText({
        page,
        userLanguage,
        textIdentifier: Identifiers.BodyOne,
      })}
      hasPrimaryButton
      onClick={() => history.push(ROUTES.DASHBOARD)}
      buttonText={TranslationService.getStaticText({
        page,
        userLanguage,
        textIdentifier: Identifiers.ContinueButton,
      })}
    />
  );
};

export default IntroComplete;
