import { css, StyleSheet } from "aphrodite";
import React from "react";
import Text, { Font } from "shared/components/Text";
import { mainGrey } from "shared/styles/colors";

interface Props {
  cohortName: string;
  todaysDate: string;
  startDate: string;
  numberOfReflections: string;
}

const CohortInformation = ({
  cohortName,
  todaysDate,
  startDate,
  numberOfReflections,
}: Props) => {
  return (
    <div className={css(styles.cohortInfo)}>
      <Text font={Font.ProximaNovaSemibold} style={styles.cohortName}>
        {cohortName} dashboard
      </Text>
      <Text font={Font.ProximaNovaRegular}>
        Today&apos;s Date: {todaysDate} - Start Date: {startDate} - Duration:{" "}
        {numberOfReflections} Weeks
      </Text>
    </div>
  );
};

export default CohortInformation;

const styles = StyleSheet.create({
  cohortInfo: {
    height: 40,
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 22,
    marginBottom: 82,
  },
  cohortInfoText: {
    fontSize: 18,
    lineHeight: 1,
    color: mainGrey,
  },
  cohortName: {
    fontSize: 40,
    lineHeight: 1,
    letterSpacing: 0,
    color: mainGrey,
    marginRight: 10,
  },
  //
});
