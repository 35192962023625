export const ROUTES = {
  LOGIN: "/login",
  INVITATION: "/invitation",
  SIGNUP: "/signup",
  HOW_IT_WORKS: "/how-it-works",
  INTRO_ONE: "/intro-1",
  INTRO_TWO: "/intro-2",
  INTRO_THREE: "/intro-3",
  DASHBOARD: "/",
  QUESTIONS: "/questions",
  TOPIC: "/topic",
  STEP_TWO_INTRO: "/step-2-intro",
  STEP_THREE_INTRO: "/step-3-intro",
  DEEP_BREATH: "/deep-breath",
  FIRST_QUESTION: "/first-question",
  CREATE_PASSWORD: "/create-password",
  RESET_PASSWORD: "/reset-password",
  REFLECTION_START: "/reflection-start",
  REFLECTION_COMPLETE: "/reflection-complete",
  INTRO_COMPLETE: "/intro-complete",
  REFLECTION_END: "/reflection-end",
  STATS: "/stats",
  REFLECTIONS_SUMMARY: "/reflections-summary",
  REFLECTION_DETAILS: "/reflection-details",
  ADMIN: "/admin",
  FORGOT_PASSWORD: "/forgot-password",
  FORGOT_PASSWORD_SUCCESS: "/forgot-password-success",
  COHORT_SELECT: "/cohort-select",
  COHORT_ADMIN: "/cohort-admin",
  RESOURCES: "/resources",
  SETTINGS: "/settings",
};
