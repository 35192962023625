import { Identifiers } from "../enums";
import { Translation } from "../Translation.Service";
import { LanguagePreference } from "../../../shared/types";

const menuTranslations: Translation = {
  [Identifiers.Title]: {
    [LanguagePreference.English]: "Hey",
    [LanguagePreference.Spanish]: "Oye",
    [LanguagePreference.Vietnamese]: "Này",
  },
  [Identifiers.Dashboard]: {
    [LanguagePreference.English]: "Dashboard",
    [LanguagePreference.Spanish]: "Tablero de mandos",
    [LanguagePreference.Vietnamese]: "bảng điều khiển",
  },
  [Identifiers.Progress]: {
    [LanguagePreference.English]: "Progress",
    [LanguagePreference.Spanish]: "Progreso",
    [LanguagePreference.Vietnamese]: "Phát triển",
  },
  [Identifiers.Reflection]: {
    [LanguagePreference.English]: "Reflections",
    [LanguagePreference.Spanish]: "Reflexiones",
    [LanguagePreference.Vietnamese]: "Suy ngẫm",
  },
  [Identifiers.Resources]: {
    [LanguagePreference.English]: "Resources",
    [LanguagePreference.Spanish]: "Recursos",
    [LanguagePreference.Vietnamese]: "Tài nguyên",
  },
  [Identifiers.Settings]: {
    [LanguagePreference.English]: "Settings",
    [LanguagePreference.Spanish]: "Ajustes",
    [LanguagePreference.Vietnamese]: "Cài đặt",
  },
  [Identifiers.LogOut]: {
    [LanguagePreference.English]: "log out",
    [LanguagePreference.Spanish]: "cerrar sesión",
    [LanguagePreference.Vietnamese]: "đăng xuất",
  },
};

export default menuTranslations;
