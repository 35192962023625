import React, { useEffect } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { css, StyleSheet } from "aphrodite";
import { Formik, Form, Field } from "formik";
import { useToasts } from "react-toast-notifications";

import Text, { Font } from "shared/components/Text";
import Button from "shared/components/Button";
import { mainGrey, turquoise, greyThree } from "shared/styles/colors";

const COHORTS_QUERY = gql`
  query {
    cohorts {
      name
      id
      startDate
      curriculum {
        name
      }
      organization {
        name
      }
    }
  }
`;

const EXPORT_DATA = gql`
  mutation ExportData($cohortId: String!) {
    exportData(cohortId: $cohortId) {
      success
    }
  }
`;

const ExportData = () => {
  const { data } = useQuery(COHORTS_QUERY, {
    fetchPolicy: "no-cache",
  });
  const cohorts = data?.cohorts;

  const [
    exportData,
    { data: exportDataData, error: exportDataError, loading },
  ] = useMutation(EXPORT_DATA);

  const handleSubmit = (cohortId: string) => {
    exportData({
      variables: {
        cohortId,
      },
    });
  };

  const { addToast } = useToasts();

  useEffect(() => {
    if (exportDataError) {
      addToast(exportDataError.message, {
        appearance: "error",
        autoDismiss: false,
      });
    }
    if (exportDataData) {
      addToast(
        "Success! Please check your email for your export. It may take a few minutes.",
        {
          appearance: "success",
          autoDismiss: false,
        }
      );
    }
  }, [exportDataError, exportDataData]);

  return (
    <section className={css(styles.ExportData)}>
      <Text font={Font.ProximaNovaBold} style={styles.title}>
        Export Data
      </Text>
      <Formik
        initialValues={{
          cohortId: "",
        }}
        onSubmit={(values, { setSubmitting }) => {
          if (values.cohortId) {
            handleSubmit(values.cohortId);
          }
        }}
      >
        {({ isSubmitting, values, handleChange }) => (
          <Form className={css(styles.form)}>
            <div className={css(styles.row)}>
              <Field
                as="select"
                name="cohortId"
                placeholder=""
                value={values.cohortId}
                className={css(styles.input)}
                onChange={handleChange}
              >
                <option value="">Select cohort...</option>
                {cohorts &&
                  cohorts.map((cohort) => {
                    const date = new Date(cohort.startDate);
                    const dateDisplay = `${
                      date.getMonth() + 1
                    }/${date.getDate()}/${date.getFullYear()}`;
                    return (
                      <option value={cohort.id} key={cohort.id}>
                        {dateDisplay} - Cohort: {cohort.name}, Org:{" "}
                        {cohort.organization.name}, Curriculum:
                        {cohort.curriculum.name}
                      </option>
                    );
                  })}
              </Field>
            </div>

            <Text font={Font.ProximaNovaRegular} style={styles.text}>
              To export data, please select a cohort.
            </Text>

            <Button
              text="Export Data"
              type="submit"
              loading={loading}
              disabled={loading}
              ariaLabel="Export Data"
            />

            <Text
              font={Font.ProximaNovaRegular}
              style={[styles.text, styles.warningText]}
            >
              Note: it may take some time to export. Please stay on this page
              until you receive a message.
            </Text>
          </Form>
        )}
      </Formik>
    </section>
  );
};

const styles = StyleSheet.create({
  ExportData: {
    paddingTop: 10,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    width: "80%",
    alignItems: "center",
    textAlign: "center",
  },
  title: {
    fontSize: 30,
    marginBottom: 25,
    marginTop: 20,
    color: turquoise,
  },
  form: {
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  input: {
    height: 60,
    borderRadius: 8,
    border: `1px solid ${greyThree}`,
    marginBottom: 20,
    marginRight: 20,
    paddingLeft: 20,
    paddingRight: 20,
  },
  text: {
    fontSize: 16,
    color: mainGrey,
    width: 200,
    marginBottom: 30,
  },
  warningText: {
    marginTop: 20,
  },
});

export default ExportData;
