import React from "react";
import Text, { Font } from "shared/components/Text";
import { StyleSheet } from "aphrodite";
import { buttonBlue, offBlack } from "shared/styles/colors";
import styled from "@emotion/styled";

interface Props {
  titleText: string;
  titleTextFontSize?: number;
  subtitleText?: string;
  subTitleTextFontSize?: number;
  paragraphText: string;
  paragraphTextFontSize?: number;
  secondParagraphText?: string;
  secondParagraphTextFontSize?: number;
  thirdParagraphText?: string;
  thirdParagraphTextFontSize?: number;
  isModal?: boolean;
  titleCustomMargins?: string;
  paragraphCustomMargins?: string;
}

const SignUpText = ({
  titleText,
  titleTextFontSize = 30,
  subtitleText,
  subTitleTextFontSize,
  paragraphText,
  paragraphTextFontSize = 16,
  secondParagraphText,
  thirdParagraphText,
  isModal,
  titleCustomMargins,
  paragraphCustomMargins,
}: Props) => {
  const styles = StyleSheet.create({
    titleText: {
      fontSize: titleTextFontSize,
      margin: titleCustomMargins || "0px 0px 0px 0px",
      textAlign: "center",
    },
    subtitleText: {
      fontSize: subTitleTextFontSize,
      margin: "22px 80px 12px 87px",
      textAlign: "center",
    },
    paragraphText: {
      fontSize: paragraphTextFontSize,
      lineHeight: 1.2,
      margin: paragraphCustomMargins || "8px 29px 5px 24px",
      textAlign: "center",
    },
    modalParagraphText: {
      fontSize: paragraphTextFontSize,
      lineHeight: 1.2,
      marginBottom: 17,
      textAlign: "center",
    },
    thirdParagraphText: {
      fontSize: paragraphTextFontSize,
      lineHeight: 1.2,
      textAlign: "center",
    },
  });

  return (
    <TextWrapper>
      <Text
        style={styles.titleText}
        color={buttonBlue}
        font={Font.ProximaNovaBold}
      >
        {titleText}
      </Text>
      {subtitleText && (
        <Text
          style={styles.subtitleText}
          color={offBlack}
          font={Font.ProximaNovaSemibold}
        >
          {subtitleText}
        </Text>
      )}
      {isModal ? (
        <Text
          style={styles.modalParagraphText}
          color={offBlack}
          font={Font.ProximaNovaRegular}
        >
          {paragraphText}
        </Text>
      ) : (
        <Text
          style={styles.paragraphText}
          color={offBlack}
          font={Font.ProximaNovaRegular}
        >
          {paragraphText}
        </Text>
      )}
      {secondParagraphText && (
        <Text
          style={styles.modalParagraphText}
          color={offBlack}
          font={Font.ProximaNovaRegular}
        >
          {secondParagraphText}
        </Text>
      )}
      {thirdParagraphText && (
        <Text
          style={styles.thirdParagraphText}
          color={offBlack}
          font={Font.ProximaNovaSemibold}
        >
          {thirdParagraphText}
        </Text>
      )}
    </TextWrapper>
  );
};

const TextWrapper = styled.div({
  display: "flex",
  ustifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
});

export default SignUpText;
