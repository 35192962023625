import { QuestionType } from "@quick-check/common";

// eslint-disable-next-line @typescript-eslint/ban-types
export type Style = object | undefined | null | false;
export type StyleArray = Array<Record<string, unknown> | false>;

export interface QuestionItem {
  type: QuestionType;
  text?: string;
  translationData: any;
  answersArray: string[];
  choices?: any; // TODO declare type
  questionText: string;
  id: string;
  withOther?: boolean;
}

export enum ScaleType {
  ScaleFive = "SCALE_FIVE",
  ScaleTen = "SCALE_TEN",
  EffectivenessRating = "EFFECTIVENESS_RATING",
}

export enum LanguagePreference {
  English = "ENGLISH",
  Spanish = "SPANISH",
  Vietnamese = "VIETNAMESE",
}

export enum UserType {
  ASSISTANT_TEACHER = "ASSISTANT_TEACHER",
  EXECUTIVE_LEADERSHIP = "EXECUTIVE_LEADERSHIP",
  FAMILY_CHILD_CARE_PROVIDER = "FAMILY_CHILD_CARE_PROVIDER",
  FAMILY_ENGAGEMENT_WORKER = "FAMILY_ENGAGEMENT_WORKER",
  HOME_VISITOR = "HOME_VISITOR",
  PARAPROFESSIONAL = "PARAPROFESSIONAL",
  PARENT = "PARENT",
  SCHOOL_OR_SITE_DIRECTOR_SUPERVISOR = "SCHOOL_OR_SITE_DIRECTOR/SUPERVISOR",
  TEACHER = "TEACHER",
  TECHNICAL_ASSISTANCE_PROVIDER = "TECHNICAL_ASSISTANCE_PROVIDER",
}

export interface IconProps {
  color?: string;
  height?: number | string;
  width?: number | string;
}

export type SignUpComponentName =
  | "introScreen"
  | "carousel"
  | "aboutScreen"
  | "signUpScreen"
  | "welcomeScreen"
  | "personalizeScreen";

export type UserData = {
  fullName: string;
  nickname: string;
  phoneNumber: string;
  email: string;
  password: string;
  confirmPassword: string;
  languagePreference: string;
  inviteId?: string;
  userType?: string;
};

export type UserDataKeys = keyof UserData;

export enum Direction {
  Next = "Next",
  Prev = "Prev",
}

export enum UserFlow {
  InvitedUser = "InvitedUser",
  NewUser = "NewUser",
}

export interface GraphProps {
  questionId: string;
  textColor: string;
  questionTitle: string;
  topicId: string | null;
  bottomAxis?: string;
  topAxis?: string;
  setInsufficientData: any;
  secondQuestionId?: string;
  userLanguage: LanguagePreference;
}
