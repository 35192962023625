import React from "react";
import { white } from "shared/styles/colors";
import { IconProps as Props } from "shared/types/index";

const BackCarrat = ({ color, height, width }: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 9 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Back Button</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.41421 7L8.70711 12.5063C9.09763 12.848 9.09763 13.402 8.70711 13.7437C8.31658 14.0854 7.68342 14.0854 7.29289 13.7437L0.292893 7.61872C-0.0976305 7.27701 -0.0976305 6.72299 0.292893 6.38128L7.29289 0.256282C7.68342 -0.0854272 8.31658 -0.0854272 8.70711 0.256282C9.09763 0.59799 9.09763 1.15201 8.70711 1.49372L2.41421 7Z"
        fill={color}
      />{" "}
    </svg>
  );
};

BackCarrat.defaultProps = {
  color: "#4F4F4F",
  height: 14,
  width: 9,
};

export default BackCarrat;
