import CookieService, { Identifiers } from "services/Cookie/CookieService";
import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { createUploadLink } from "apollo-upload-client";
import { ApolloLink, Observable } from "apollo-link";

const request = async (operation) => {
  const token = CookieService.getCookie(Identifiers.AccessToken);
  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : null,
    },
  });
};

const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable((observer) => {
      let handle;
      Promise.resolve(operation)
        .then((oper) => request(oper))
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          });
        })
        .catch(observer.error.bind(observer));
      return () => {
        if (handle) handle.unsubscribe();
      };
    })
);

const client = new ApolloClient({
  link: ApolloLink.from([
    requestLink,
    createUploadLink({
      uri: `${process.env.REACT_APP_BACKEND_API_URL}/api/graphql`,
      // credentials: "same-origin",
    }),
  ]),
  cache: new InMemoryCache(),
});

export default client;
