import React from "react";
import { teal, white } from "../../shared/styles/colors";

const CheckmarkIcon = () => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="13" cy="13" r="13" fill={teal} />
      <path
        d="M11.396 17.033a.75.75 0 0 1-.548-.24l-3.645-3.878a.75.75 0 1 1 1.095-1.027l3.09 3.292 6.308-6.9a.75.75 0 1 1 1.11 1.005l-6.855 7.5a.75.75 0 0 1-.548.248h-.007z"
        fill={white}
      />
    </svg>
  );
};

export default CheckmarkIcon;
