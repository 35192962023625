import { useQuery } from "@apollo/react-hooks";
import { css, StyleSheet } from "aphrodite";
import { ROUTES } from "../../../constants";
import gql from "graphql-tag";
import React, { useState } from "react";
import { Redirect, useParams } from "react-router";
import GraphqlErrorScreen from "shared/components/ErrorBoundary/GraphqlErrorScreen";
import CohortHeader from "../CohortHeader";
import UsageOverview from "../UsageOverview";
import ProgressHighlights from "../ProgressHighlights";
import { useDocumentTitle } from "shared/hooks";

const COHORT_BY_ID = gql`
  query {
    userLanguage
    isCohortAdmin
  }
`;

const CohortAdmin = () => {
  useDocumentTitle("Cohort Admin | Reflectable");
  const params = useParams<{ cohortId: string }>();
  const { data, loading, error } = useQuery(COHORT_BY_ID);
  const [enoughData, setEnoughData] = useState<boolean>(false);

  if (loading) {
    return null;
  }

  if (error) {
    return <GraphqlErrorScreen error={error} />;
  }
  if (!data?.isCohortAdmin) {
    return <Redirect to={ROUTES.DASHBOARD} />;
  }

  const userLanguage = data?.userLanguage ?? "ENGLISH";
  return (
    <section className={css(styles.CohortAdmin)}>
      <div className={css(styles.topContainer)}>
        <CohortHeader userLanguage={userLanguage} />
        <UsageOverview
          setEnoughData={setEnoughData}
          enoughData={enoughData}
          cohortId={params.cohortId}
        />
      </div>
      {enoughData && <ProgressHighlights cohortId={params.cohortId} />}
    </section>
  );
};

export default CohortAdmin;

const styles = StyleSheet.create({
  CohortAdmin: {
    width: "100vw",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  topContainer: {
    width: 1200,
  },
});
