import React, { useRef, useEffect, useState } from "react";
import { css, StyleSheet } from "aphrodite";
import Text, { Font } from "shared/components/Text";
import Button from "shared/components/Button";
import { turquoise, mainJade, mainGrey, redOrange } from "shared/styles/colors";
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";
import { useToasts } from "react-toast-notifications";

const CREATE_CURRICULUM = gql`
  mutation CreateCurriculum($csvFile: Upload!) {
    createCurriculum(csvFile: $csvFile) {
      success
      message
    }
  }
`;

const UploadCurriculum = () => {
  const [curriculumSuccess, setCurriculumSuccess] = useState(true);
  const [curriculumMessage, setCurriculumMessage] = useState("");
  const { addToast } = useToasts();
  const [
    createCurriculum,
    { data: csvData, error: csvError, loading },
  ] = useMutation(CREATE_CURRICULUM, { onError: () => {} });

  useEffect(() => {
    if (csvData) {
      setCurriculumSuccess(csvData.createCurriculum.success);
      setCurriculumMessage(csvData.createCurriculum.message);
    }

    if (csvError) {
      addToast(csvError.message, {
        appearance: "error",
        autoDismiss: false,
      });
    }
  }, [csvData, csvError]);

  const inputRef = useRef<HTMLInputElement | null>(null);
  const handleSubmit = () => {
    if (inputRef?.current?.files) {
      createCurriculum({
        variables: {
          csvFile: inputRef.current.files[0],
        },
      });
    }
  };

  return (
    <div className={css(styles.UploadCurriculum)}>
      <Text font={Font.ProximaNovaBold} style={styles.title}>
        Upload Curriculum
      </Text>
      <Text font={Font.ProximaNovaRegular} style={styles.text}>
        To upload new curriculum, please first select a csv of the curriculum
      </Text>
      <input className={css(styles.fileInput)} type="file" ref={inputRef} />
      {curriculumMessage !== "" && curriculumSuccess === true && (
        <Text font={Font.ProximaNovaBold} style={styles.idText}>
          Curriculum ID: {curriculumMessage}
        </Text>
      )}
      {curriculumMessage !== "" && curriculumSuccess === false && (
        <Text font={Font.ProximaNovaBold} style={styles.errorText}>
          Error: {curriculumMessage}
        </Text>
      )}
      <Button
        text="Submit Curriculum"
        onClick={() => handleSubmit()}
        loading={loading}
        disabled={loading}
      />
    </div>
  );
};

const styles = StyleSheet.create({
  UploadCurriculum: {
    paddingTop: 10,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
  },
  fileInput: {
    fontSize: 18,
    marginBottom: 200,
    textAlign: "center",
  },
  title: {
    fontSize: 30,
    marginBottom: 25,
    marginTop: 20,
    color: turquoise,
  },
  text: {
    fontSize: 16,
    color: mainGrey,
    width: 200,
    marginBottom: 30,
  },
  idText: {
    fontSize: 20,
    color: mainJade,
    width: 350,
    marginBottom: 30,
  },
  errorText: {
    fontSize: 20,
    color: redOrange,
    width: 350,
    marginBottom: 30,
  },
});

export default UploadCurriculum;
