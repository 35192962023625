import { useMutation, useQuery } from "@apollo/react-hooks";
import { css, StyleSheet } from "aphrodite";
import gql from "graphql-tag";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";
import { Dropdown, DropdownProps } from "semantic-ui-react";
import { Identifiers, Page } from "services/Translation/enums";
import { TranslationService } from "services/Translation/Translation.Service";
import Button from "shared/components/Button";
import Loader from "shared/components/Loader";
import Text, { Font } from "shared/components/Text";
import { greenBlue, inactiveBackground, turquoise } from "shared/styles/colors";
import { LanguagePreference } from "shared/types";
import SettingsCard from "../SettingsCard";
import { UPDATE_USER_INFO } from "../shared/mutations";
import { sharedStyles } from "../shared/styledComponents";

const LANGUAGE_QUERY = gql`
  query CurrentCurriculumLanguages {
    currentCurriculumLanguages
  }
`;

interface Props {
  currentLanguage: LanguagePreference;
  setCurrentLanguage: Dispatch<SetStateAction<any>>;
}

const LanguagePreferencesForm = ({
  currentLanguage,
  setCurrentLanguage,
}: Props) => {
  const { data, loading, error } = useQuery(LANGUAGE_QUERY, {
    fetchPolicy: "no-cache",
  });
  const [editLanguageInfo, setEditLanguageInfo] = useState(false);
  const { addToast } = useToasts();

  const [
    updateUserInfo,
    {
      data: updateUserData,
      error: updateUserError,
      loading: updateUserLoading,
    },
  ] = useMutation(UPDATE_USER_INFO);

  useEffect(() => {
    let message;
    if (updateUserData) {
      addToast("Success", {
        appearance: "success",
        autoDismiss: true,
      });
    }
    if (updateUserError) {
      message = "There was an error updating your settings";
      if (
        updateUserError.graphQLErrors &&
        updateUserError.graphQLErrors.length > 0
      ) {
        message = updateUserError.graphQLErrors[0].message;
      }
      addToast(message, {
        appearance: "error",
        autoDismiss: true,
      });
    }
    if (updateUserData && updateUserData.updateUserInfo?.updatedLanguage) {
      window.location.reload();
    }
  }, [updateUserData, updateUserError]);

  const selectableArray = data?.currentCurriculumLanguages.map(
    (language, index) => ({
      key: index,
      text: language,
      value: language,
    })
  );

  const dropdownOptions: DropdownProps = {
    fluid: true,
    className: `${css(styles.dropDown)} react-dropdown ${
      !editLanguageInfo && css(styles.dropDownDark)
    }`,
    placeholder: "Please select",
    selection: true,
    compact: true,
    options: selectableArray,
    value: currentLanguage,
    onChange: (e, { value }) => setCurrentLanguage(value),
  };

  let content;
  const page = Page.Settings;
  const userLanguage = currentLanguage;

  if (loading || error) {
    content = <Loader />;
  }

  if (data) {
    content = (
      <>
        <Text
          color={turquoise}
          style={sharedStyles.inputLabel}
          font={Font.ProximaNovaBold}
        >
          {TranslationService.getStaticText({
            page,
            userLanguage,
            textIdentifier: Identifiers.Language,
          })}
        </Text>
        <Dropdown disabled={!editLanguageInfo} selection {...dropdownOptions} />
        {editLanguageInfo && (
          <Button
            ariaLabel={TranslationService.getStaticText({
              page,
              userLanguage,
              textIdentifier: Identifiers.Save,
            })}
            text={TranslationService.getStaticText({
              page,
              userLanguage,
              textIdentifier: Identifiers.Save,
            })}
            buttonStyle={sharedStyles.buttonStyle}
            loading={updateUserLoading}
            onClick={() => {
              updateUserInfo({
                variables: {
                  languagePreference: currentLanguage,
                },
              });
              setEditLanguageInfo(false);
            }}
          />
        )}
      </>
    );
  }

  return (
    <SettingsCard
      userLanguage={userLanguage}
      title={TranslationService.getStaticText({
        page,
        userLanguage,
        textIdentifier: Identifiers.Preferences,
      })}
      setEditState={setEditLanguageInfo}
      editState={editLanguageInfo}
    >
      {content}
    </SettingsCard>
  );
};

export default LanguagePreferencesForm;

const styles = StyleSheet.create({
  headerText: {
    marginBottom: 21,
    fontSize: 32,
    lineHeight: 1.2,
  },
  dropDown: {
    width: "100%",
    height: 42,
    borderColor: greenBlue,
    display: "flex",
    alignItems: "center",
    zIndex: 3,
    marginBottom: 27,
    textTransform: "capitalize",
  },
  dropDownDark: {
    background: inactiveBackground,
  },
});
