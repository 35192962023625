import React from "react";
import { teal, white } from "../../shared/styles/colors";

const LockedIcon = () => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="13" cy="13" r="13" fill={teal} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.636 9.962v1.269h-.204c-.38 0-.744.156-1.013.434-.268.277-.419.654-.419 1.046v3.808C9 17.337 9.641 18 10.432 18h6.136c.38 0 .744-.156 1.013-.434.268-.277.419-.654.419-1.047v-3.807c0-.393-.15-.77-.42-1.047a1.408 1.408 0 0 0-1.012-.434h-.204V9.96c0-.785-.302-1.538-.84-2.094A2.816 2.816 0 0 0 13.5 7c-.76 0-1.488.312-2.025.867a3.014 3.014 0 0 0-.839 2.095zM13.5 8.269c-.434 0-.85.179-1.157.496-.307.317-.48.748-.48 1.197v1.269h3.273V9.96c0-.448-.172-.879-.479-1.196a1.61 1.61 0 0 0-1.157-.496z"
        fill={white}
      />
    </svg>
  );
};

export default LockedIcon;
