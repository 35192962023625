import { css, StyleSheet } from "aphrodite";
import React from "react";
import { Identifiers, Page } from "services/Translation/enums";
import { TranslationService } from "services/Translation/Translation.Service";
import Card from "shared/components/Card";
import Text, { Font } from "shared/components/Text";
import {
  greenBlue,
  greyAccent,
  mainBlueBlack,
  mainGrey,
  turquoise,
} from "shared/styles/colors";
import { LanguagePreference } from "shared/types";

interface Props {
  stepOrder: number;
  responses: any[];
  userLanguage: LanguagePreference;
}

const StepCard = ({ stepOrder, responses, userLanguage }: Props) => {
  return (
    <Card
      cardStyle={styles.cardStyle}
      contentStyle={styles.cardContainerStyle}
      accentColor={greenBlue}
    >
      <Text style={styles.stepNumber} font={Font.ProximaNovaBold}>
        {TranslationService.getStaticText({
          page: Page.Shared,
          userLanguage,
          textIdentifier: Identifiers.Step,
        })}{" "}
        {stepOrder}
      </Text>
      {responses.map((response, index) => {
        return (
          <div key={response.id}>
            <Text font={Font.ProximaNovaBold} style={styles.questionNumber}>
              #{index + 1}
            </Text>
            <Text style={styles.question}>
              {response.question.translationData.text}
            </Text>
            {response.choices.length > 1 &&
              response.choices.map((choice) => (
                <div className={css(styles.bulletContainer)} key={choice.id}>
                  <div className={css(styles.bulletPoint)} />
                  <Text font={Font.ProximaNovaRegular} style={styles.arrayItem}>
                    {choice.translationData.text}
                  </Text>
                </div>
              ))}
            {response.choices.length === 1 && (
              <Text font={Font.ProximaNovaRegular} style={styles.userInput}>
                {response.choices[0].translationData.text}
              </Text>
            )}
            {response.answerText && (
              <Text font={Font.ProximaNovaRegular} style={styles.userInput}>
                {response.answerText}
              </Text>
            )}
            {response.answerInteger && (
              <Text font={Font.ProximaNovaRegular} style={styles.userInput}>
                {response.answerInteger}
              </Text>
            )}
            {index !== responses.length - 1 && (
              <div className={css(styles.line)} />
            )}
          </div>
        );
      })}
    </Card>
  );
};

export default StepCard;

const styles = StyleSheet.create({
  cardStyle: {
    width: "100%",
    marginBottom: 20,
    "@media (min-width: 630px)": {
      width: "75%",
    },
  },
  cardContainerStyle: {
    paddingTop: 20,
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 25,
  },
  stepNumber: {
    fontSize: 24,
    lineHeight: "29px",
    color: mainBlueBlack,
    marginBottom: 10,
  },
  questionNumber: {
    color: turquoise,
    fontSize: 12,
    lineHeight: "14px",
    letterSpacing: 2,
    marginBottom: 5,
  },
  question: {
    fontSize: 16,
    lineHeight: "19px",
    marginBottom: 10,
    color: mainGrey,
  },
  answer: {
    fontSize: 16,
    color: mainGrey,
    lineHeight: "19px",
    marginBottom: 20,
  },
  line: {
    width: "100%",
    height: 1,
    background: greyAccent,
    marginBottom: 20,
    marginTop: 15,
  },
  userInput: {
    fontSize: 16,
    lineHeight: "19px",
    color: mainGrey,
    marginBottom: 5,
  },
  bulletContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: 5,
  },
  bulletPoint: {
    height: 4,
    width: 4,
    background: mainGrey,
    borderRadius: 50,
    marginRight: 6,
  },
  arrayItem: {
    fontSize: 16,
    lineHeight: "19px",
    color: mainGrey,
  },
  //
});
