import { LanguagePreference } from "shared/types";
import { Translation } from "../Translation.Service";
import { Identifiers } from "../enums";

const translations: Translation = {
  [Identifiers.Title]: {
    [LanguagePreference.English]: "Choose your path",
    [LanguagePreference.Spanish]: "Elige tu camino",
    [LanguagePreference.Vietnamese]: "Chọn con đường của bạn",
  },
  [Identifiers.Body]: {
    [LanguagePreference.English]: "What would you like to learn first?",
    [LanguagePreference.Spanish]: "¿Qué te gustaría aprender primero?",
    [LanguagePreference.Vietnamese]: "Bạn muốn học gì trước tiên?",
  },
  [Identifiers.Required]: {
    [LanguagePreference.English]: "Required*",
    [LanguagePreference.Spanish]: "Requerido*",
    [LanguagePreference.Vietnamese]: "Cần thiết*",
  },
  [Identifiers.Curriculum]: {
    [LanguagePreference.English]: "Curriculum options*",
    [LanguagePreference.Spanish]: "Opciones de currículo*",
    [LanguagePreference.Vietnamese]: "Tùy chọn chương trình học*",
  },
};

export default translations;
