import styled from "@emotion/styled";
import { StyleSheet } from "aphrodite";
import React from "react";
import Text, { Font } from "shared/components/Text";
import { darkGreenBlue } from "shared/styles/colors";
import ResourcesCard from "./ResourcesCard";

interface Props {
  contentfulEntries: any[];
  cardTitle: string;
  underlineTitle?: boolean;
}

const ResourceLinkCard = ({
  contentfulEntries,
  cardTitle,
  underlineTitle = true,
}: Props) => {
  return (
    <ResourcesCard title={cardTitle}>
      {contentfulEntries.map((entry) => (
        <>
          <Link href={entry.link} rel="noreferrer" target="_blank">
            <Text
              font={Font.ProximaNovaSemibold}
              color={darkGreenBlue}
              style={underlineTitle ? styles.underlinedTitle : styles.title}
            >
              {entry.title}
            </Text>
          </Link>
          <Text
            font={Font.ProximaNovaRegular}
            color={darkGreenBlue}
            style={styles.description}
          >
            {entry.description}
          </Text>
        </>
      ))}
    </ResourcesCard>
  );
};

export default ResourceLinkCard;

const styles = StyleSheet.create({
  title: {
    fontSize: 16,
    marginBottom: 6,
    lineHeight: 1.2,
  },
  underlinedTitle: {
    fontSize: 16,
    marginBottom: 6,
    lineHeight: 1.2,
    textDecoration: "underline",
  },
  description: {
    marginBottom: 20,
    fontSize: 16,
    lineHeight: 1.2,
  },
});

const Link = styled.a({});
