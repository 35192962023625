import styled from "@emotion/styled";
import React from "react";
import { slide as Menu } from "react-burger-menu";
import XIcon from "static/svgs/XIconComponent";
import HamburgerMenuIcon from "static/svgs/HamburgerMenuIcon";
import HorizontalNavLogo from "static/images/HorizontalNavLogo@3x.png";
import CenteredLogo from "static/images/CenteredLogo@3x.png";
import { ROUTES } from "../../../constants";
import { Link, useHistory } from "react-router-dom";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { lightDarkGreen, transparent, white } from "shared/styles/colors";
import Text, { Font } from "../Text";
import { StyleSheet } from "aphrodite";
import Spacer from "../Spacer";
import LinkItem from "./LinkItem";
import WhiteHomeIcon from "static/svgs/WhiteHomeIcon";
import WhiteStatsIcon from "static/svgs/WhiteStatsIcon";
import WhiteReflectionsIcon from "static/svgs/WhiteReflectionsIcon";
import WhiteSettingsIcon from "static/svgs/WhiteSettingsIcon";
import WhiteLogoutIcon from "static/svgs/WhiteLogoutIcon";
import { Identifiers, Page } from "services/Translation/enums";
import { TranslationService } from "services/Translation/Translation.Service";
import WhiteResourcesIcon from "static/svgs/WhiteResourcesIcon";

interface Props {
  logoIsVisible?: boolean;
  menuIsVisible?: boolean;
  logoIsCentered?: boolean;
}

const MENU_QUERY = gql`
  query MenuQuery {
    currentUser {
      nickname
      isAdmin
      languagePreference
    }
    userCompletedReflections {
      totalComplete
    }
    isCohortAdmin
  }
`;

const HamburgerMenu = ({
  logoIsVisible = false,
  menuIsVisible = true,
  logoIsCentered = false,
}: Props) => {
  const { data } = useQuery(MENU_QUERY, {
    fetchPolicy: "no-cache",
  });
  const history = useHistory();
  if (data) {
    const isCohortAdmin = data?.isCohortAdmin;
    const showReflectionsStatsSummary =
      data?.userCompletedReflections?.totalComplete > 0;
    const { nickname, isAdmin, languagePreference } = data.currentUser;
    const userLanguage = languagePreference;

    const isAdminAccount = isCohortAdmin || isAdmin;
    const page = Page.Menu;
    return (
      <>
        {logoIsVisible && !logoIsCentered && (
          <Link to={ROUTES.DASHBOARD}>
            <SmallLogo
              src={HorizontalNavLogo}
              alt="Reflectable Logo"
              title="Reflectable Logo"
            />
          </Link>
        )}
        {logoIsCentered && (
          <Link to={ROUTES.DASHBOARD}>
            <CenteredLogoComponent
              src={CenteredLogo}
              alt="Reflectable Logo"
              title="Reflectable Logo"
            />
          </Link>
        )}
        {menuIsVisible && (
          <Menu
            customBurgerIcon={<HamburgerMenuIcon />}
            customCrossIcon={<XIcon />}
            width={297}
            right
            pageWrapId="root"
            outerContainerId="root"
          >
            <Container>
              <TopSpacer />
              <Text
                font={Font.ProximaNovaRegular}
                color={white}
                style={styles.userText}
              >
                {TranslationService.getStaticText({
                  page,
                  userLanguage,
                  textIdentifier: Identifiers.Title,
                })}{" "}
                {nickname}!
              </Text>
              {isAdminAccount && (
                <Text
                  font={Font.ProximaNovaRegular}
                  color={white}
                  style={styles.adminAccountText}
                >
                  Admin Account
                </Text>
              )}
              {isCohortAdmin && (
                <Link to={ROUTES.COHORT_SELECT}>
                  <Text
                    font={Font.ProximaNovaBold}
                    color={white}
                    style={styles.adminLinkText}
                  >
                    Cohort Admin
                  </Text>
                </Link>
              )}
              {isAdmin && (
                <Link to={ROUTES.ADMIN}>
                  <Text
                    font={Font.ProximaNovaBold}
                    color={white}
                    style={styles.adminLinkText}
                  >
                    Content Admin
                  </Text>
                </Link>
              )}
              {!isAdminAccount && <NonAdminSpacer />}
              <BottomContainer>
                <LinkContainer isShortenedList={!showReflectionsStatsSummary}>
                  <LinkItem
                    route={ROUTES.DASHBOARD}
                    icon={<WhiteHomeIcon />}
                    text={TranslationService.getStaticText({
                      page,
                      userLanguage,
                      textIdentifier: Identifiers.Dashboard,
                    })}
                  />
                  {showReflectionsStatsSummary && (
                    <>
                      <LinkItem
                        route={ROUTES.STATS}
                        icon={<WhiteStatsIcon />}
                        text={TranslationService.getStaticText({
                          page,
                          userLanguage,
                          textIdentifier: Identifiers.Progress,
                        })}
                      />
                      <LinkItem
                        route={ROUTES.REFLECTIONS_SUMMARY}
                        icon={<WhiteReflectionsIcon />}
                        text={TranslationService.getStaticText({
                          page,
                          userLanguage,
                          textIdentifier: Identifiers.Reflection,
                        })}
                      />
                    </>
                  )}
                  <LinkItem
                    route={ROUTES.RESOURCES}
                    icon={<WhiteResourcesIcon />}
                    text={TranslationService.getStaticText({
                      page,
                      userLanguage,
                      textIdentifier: Identifiers.Resources,
                    })}
                  />
                  <LinkItem
                    route={ROUTES.SETTINGS}
                    icon={<WhiteSettingsIcon />}
                    text={TranslationService.getStaticText({
                      page,
                      userLanguage,
                      textIdentifier: Identifiers.Settings,
                    })}
                  />
                  <LinkItem
                    isLogoutButton
                    icon={<WhiteLogoutIcon />}
                    text={TranslationService.getStaticText({
                      page,
                      userLanguage,
                      textIdentifier: Identifiers.LogOut,
                    })}
                    languagePreference={languagePreference}
                    history={history}
                  />
                </LinkContainer>
                <Spacer />
              </BottomContainer>
            </Container>
          </Menu>
        )}
      </>
    );
  }
  return null;
};

export default HamburgerMenu;

const styles = StyleSheet.create({
  userText: {
    fontSize: 25,
    marginLeft: 32,
    marginBottom: 6,
    lineHeight: 1.2,
  },
  adminAccountText: {
    fontSize: 14,
    marginBottom: 33,
    marginLeft: 32,
  },
  adminLinkText: {
    fontSize: 20,
    marginLeft: 32,
    marginBottom: 26,
  },
  primaryText: {
    fontSize: 20,
  },
});

const Container = styled.div({
  display: "flex !important", // using !important to override the libraries inline styles
  flexDirection: "column",
  height: "100%",
});

const BottomContainer = styled.div({
  flexGrow: 1,
  background: lightDarkGreen,
  width: "100%",
  paddingTop: 37,
  paddingLeft: 32,
  display: "flex",
  flexDirection: "column",
});

const SmallLogo = styled.img({
  position: "absolute",
  top: 21,
  left: 13,
  zIndex: 3,
  height: 35,
});

const CenteredLogoComponent = styled.img({
  zIndex: 100,
  height: 115,
  width: "auto",
  position: "absolute",
  left: "50%",
  transform: "translateX(-50%)",
  top: 16,
});

const TopSpacer = styled.div({
  height: 84,
  width: "100%",
  background: transparent,
});

const NonAdminSpacer = styled.div({
  height: 8,
  width: 1,
  background: transparent,
});

const LinkContainer = styled.div(
  ({ isShortenedList }: { isShortenedList: boolean }) => ({
    flexGrow: 1,
    maxHeight: isShortenedList ? 230 : 374,
    display: "flex !important", // using !important to override the libraries inline styles
    flexDirection: "column",
    justifyContent: "space-between",
  })
);
