import { Identifiers } from "../enums";
import { Translation } from "../Translation.Service";
import { LanguagePreference } from "../../../shared/types";

const ForgotPasswordTranslations: Translation = {
  [Identifiers.Title]: {
    [LanguagePreference.English]: "Forgot Password",
    [LanguagePreference.Spanish]: "Has olvidado tu contraseña",
    [LanguagePreference.Vietnamese]: "Quên mật khẩu",
  },
  [Identifiers.SubTitle]: {
    [LanguagePreference.English]:
      "Please enter your email address below and we'll send a link to reset your password",
    [LanguagePreference.Spanish]:
      "Ingrese su dirección de correo electrónico a continuación y le enviaremos un enlace para restablecer su contraseña",
    [LanguagePreference.Vietnamese]:
      "Vui lòng nhập địa chỉ email của bạn dưới đây và chúng tôi sẽ gửi một liên kết để đặt lại mật khẩu của bạn",
  },
  [Identifiers.Email]: {
    [LanguagePreference.English]: "Email or Phone Number",
    [LanguagePreference.Spanish]: "Correo electrónico o número de teléfono",
    [LanguagePreference.Vietnamese]: "Địa chỉ email hoặc số điện thoại",
  },
  [Identifiers.SubmitButton]: {
    [LanguagePreference.English]: "Submit",
    [LanguagePreference.Spanish]: "Entregar",
    [LanguagePreference.Vietnamese]: "Nộp",
  },
};

export default ForgotPasswordTranslations;
