import React from "react";

const WhiteLogoutIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Logout</title>
      <circle
        r="11.8632"
        transform="matrix(-1 0 0 1 11.8632 11.8632)"
        fill="white"
      />
      <path
        d="M12.7105 16.9474H14.9702C15.2698 16.9474 15.5572 16.8283 15.7691 16.6164C15.981 16.4046 16.1 16.1172 16.1 15.8175V7.90877C16.1 7.60912 15.981 7.32175 15.7691 7.10986C15.5572 6.89798 15.2698 6.77895 14.9702 6.77895H12.7105"
        stroke="#148E99"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.75592 14.6877L5.93136 11.8631L8.75592 9.03857"
        stroke="#148E99"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.93126 11.8632H12.7102"
        stroke="#148E99"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default WhiteLogoutIcon;
