import { ApolloProvider } from "@apollo/react-hooks";
import { BrowserRouter as Router } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import client from "./apollo/apollo";
import { ToastProvider } from "react-toast-notifications";
import AuthDataProvider from "shared/components/Auth/AuthDataProvider";
import ErrorBoundary from "./shared/components/ErrorBoundary/ErrorBoundary";
import "chartist-plugin-tooltips-updated/dist/chartist-plugin-tooltip.css";

serviceWorker.unregister();

const render = (Component) => {
  // eslint-disable-next-line react/no-render-return-value
  return ReactDOM.render(
    <ErrorBoundary>
      <ApolloProvider client={client}>
        <ToastProvider
          autoDismiss
          autoDismissTimeout={6000}
          placement="bottom-center"
        >
          <Router>
            <AuthDataProvider>
              <Component />
            </AuthDataProvider>
          </Router>
        </ToastProvider>
      </ApolloProvider>
    </ErrorBoundary>,
    document.getElementById("root")
  );
};

render(App);

if (module.hot) {
  module.hot.accept("./App", () => {
    const NextApp = require("./App").default;
    render(NextApp);
  });
}
