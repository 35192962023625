export const black = "#252525";
export const white = "#ffffff";
export const lowOpacityWhite = "rgba(255, 255, 255, 0.8)";
export const backgroundWhite = "#fcfcff";
export const mainJade = "#489a78";
export const mainBlueBlack = "283f42";
export const mainGrey = "#4f4f4f";
export const signupGrey = "#afafaf";
export const lightGrey = "#828282";
export const lightestGrey = "#EEE";
export const redOrange = "#f05b43";
export const mainGreen = "#46be8c";
export const turquoise = "#129198";
export const buttonBlue = "#0B7D88";
export const hoveredButtonBlue = "#05545B";
export const greyAccent = "#ddd";
export const greenBlue = "#0ea6b4";
export const darkGreenBlue = "#366E7A";
export const grey = "#979797";
export const greyThree = "#bdbdbd";
export const lightBlue = "#eaf6f1";
export const midGrey = "#646464";
export const backgroundGrey = "#F6F6F6";
export const graphGrey = "#C4C4C4";
export const graphTextGrey = "#6A6A6A";
export const graphButtonGrey = "#E0E0E0";
export const disabledGrey = "#848484";
export const teal = "#0B7E88";
export const darkTeal = "#0f555b";
export const greyFour = "#d0d0d0";
export const offBlack = "#323233";
export const arrowGreen = "#387b85";
export const chartBlue = "#24A3FF";
export const chartPointBlue = "#0a69ae";
export const chartOrange = "#FF966F";
export const activeBlue = "#007aff";

export const pieBlue = "#4E81CC";
export const pieLightBlue = "#6DADDD";
export const piePurple = "#835BDA";
export const piePink = "#C060DB";

export const adminOrange = "#E65722";
export const barGraphLightBlue = "#82ECFF";
export const barGraphGreenBlue = "#5AA6B4";
export const barGraphDarkGreenBlue = "#315861";
export const pieOrangeOne = "#F87559";
export const pieOrangeTwo = "#EA5434";
export const pieOrangeThree = "#AE341A";

export const transparent = "transparent";
export const darkGreen = "#148E99";
export const lightDarkGreen = "#2B99A3";
export const inactiveBackground = "#F0FBFC";
export const settingsFormRed = "#E32210";
export const lightGreyBackground = "#f4f4f4";

// Alphas
export const blackAlpha50 = "rgba(0, 0, 0, 0.5)";
export const redAlpha = "rgba(240, 5, 67, 0.1)";
