import React, { useState, useEffect } from "react";
import { StyleSheet } from "aphrodite";
import Text, { Font } from "../../../shared/components/Text";
import {
  mainGrey,
  redOrange,
  backgroundWhite,
  white,
} from "shared/styles/colors";
import { TranslationService } from "services/Translation/Translation.Service";
import { Identifiers, Page } from "services/Translation/enums";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import GraphqlErrorScreen from "shared/components/ErrorBoundary/GraphqlErrorScreen";
import NavigationHeader from "shared/components/Navigation/NavigationHeader";
import styled from "@emotion/styled";
import Spacer from "shared/components/Spacer";

interface Props {
  missingAnswers: number[] | undefined;
  onBackButtonClick: () => void;
  pageTitle: string;
}

const USER_LANGUAGE = gql`
  query UserLanguage {
    userLanguage
  }
`;

const Header = ({ missingAnswers, onBackButtonClick, pageTitle }: Props) => {
  const [isTop, setIsTop] = useState(true);
  const { data, error } = useQuery(USER_LANGUAGE);
  const userLanguage = data?.userLanguage ?? "ENGLISH";
  const page = Page.QuestionPage;

  const checkIfWindowIsTop = () => {
    const windowPosition = window.scrollY;
    if (windowPosition > 0) {
      setIsTop(false);
    } else if (windowPosition <= 0) {
      setIsTop(true);
    }
  };

  useEffect(() => {
    document.addEventListener("scroll", checkIfWindowIsTop);
    return () => {
      document.removeEventListener("scroll", checkIfWindowIsTop);
    };
  }, [setIsTop]);

  let numberErrors;
  let errorMessage;

  const startOfErrorMessage = TranslationService.getStaticText({
    page,
    userLanguage,
    textIdentifier: Identifiers?.StartOfErrorMessage,
  });
  const pluralStartOfErrorMessage = TranslationService.getStaticText({
    page,
    userLanguage,
    textIdentifier: Identifiers?.PluralStartOfErrorMessage,
  });
  const endOfErrorMessage = TranslationService.getStaticText({
    page,
    userLanguage,
    textIdentifier: Identifiers?.EndOfErrorMessage,
  });
  if (missingAnswers) {
    const newArray = missingAnswers.map((item, index) => {
      // check if the current error number is not the last in the array or if it is the only item in the array
      if (
        index !== missingAnswers?.length - 1 ||
        missingAnswers?.length === 1
      ) {
        return `#${item}`;
      }
      // if it is put and before the error number
      return `and #${item}`;
    });
    numberErrors = newArray.join(", ");
    errorMessage = `${
      missingAnswers?.length > 1
        ? pluralStartOfErrorMessage
        : startOfErrorMessage
    } ${numberErrors} ${endOfErrorMessage}`;
  }
  /* eslint-enable */
  if (error) {
    return <GraphqlErrorScreen error={error} />;
  }
  return (
    <HeaderComponent isTop={isTop}>
      <InnerContainer>
        <NavigationHeader isFullWidth onBackButtonClick={onBackButtonClick} />
        <Text style={styles.question} font={Font.ProximaNovaSemibold}>
          {pageTitle}
        </Text>
        {missingAnswers && (
          <>
            <Spacer height={30} flexGrow={0} />
            <Text style={styles.errorMessage} font={Font.ProximaNovaRegular}>
              {errorMessage}
            </Text>
          </>
        )}
      </InnerContainer>
    </HeaderComponent>
  );
};

export default Header;

const HeaderComponent = styled.div(
  ({ isTop = false }: { isTop?: boolean }) => ({
    position: "fixed",
    minHeight: 121,
    width: "100%",
    padding: "0 20px",
    background: backgroundWhite,
    display: "flex",
    justifyContent: "center",
    zIndex: 2,
    transition: "300ms",
    boxShadow: isTop ? "0px transparent" : "0 2px 2px 1px rgba(0, 0, 0, .1)",
    "@media (min-width: 630px)": {
      background: white,
    },
  })
);

const InnerContainer = styled.div({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  "@media (min-width: 630px)": {
    width: 630,
  },
});

const styles = StyleSheet.create({
  question: {
    fontSize: 18,
    lineHeight: 1.2,
    color: mainGrey,
    textAlign: "center",
    "@media (min-width: 630px)": {
      alignSelf: "center",
      paddingBottom: 20,
    },
  },
  errorMessage: {
    color: redOrange,
    fontSize: 12,
    lineHeight: 1.21,
    marginBottom: 10,
  },
});
