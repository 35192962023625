import React from "react";
import IntroOneImage from "static/images/IntroOneImage.png";
import IllustrationScreen from "shared/components/IllustrationScreen";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { ROUTES } from "../../../constants";
import { TranslationService } from "services/Translation/Translation.Service";
import { Identifiers, Page } from "services/Translation/enums";
import GraphqlErrorScreen from "shared/components/ErrorBoundary/GraphqlErrorScreen";
import Loader from "shared/components/Loader";
import { useDocumentTitle } from "shared/hooks";
import Text, { Font } from "shared/components/Text";
import { StyleSheet } from "aphrodite";
import { mainGrey } from "shared/styles/colors";
import styled from "@emotion/styled";
import Spacer from "shared/components/Spacer";

const CURRENT_USER = gql`
  query CurrentUser {
    currentUser {
      languagePreference
    }
    userCompletedReflections {
      totalComplete
    }
    firstSurveyPageId
  }
`;

interface Props {
  location?: any;
}

const IntroOne = ({ location }: Props) => {
  useDocumentTitle("Intro One | Reflectable");
  const { data, loading, error } = useQuery(CURRENT_USER, {
    fetchPolicy: "no-cache",
  });

  if (error) {
    return <GraphqlErrorScreen error={error} />;
  }
  const userHasCompletedReflections =
    data?.userCompletedReflections?.totalComplete >= 1;
  const userLanguage = data?.currentUser?.languagePreference ?? "ENGLISH";
  const isFirstTime = location?.state?.isFirstTime;
  const page =
    isFirstTime || !userHasCompletedReflections
      ? Page.IntroOneFirstTime
      : Page.IntroOneReturning;
  const translationOptions = { page, userLanguage };
  let children: React.ReactNode | null = null;

  if (loading) {
    return <Loader />;
  }

  const bulletPointTexts = [
    TranslationService.getStaticText({
      ...translationOptions,
      textIdentifier: Identifiers.BulletOne,
    }),
    TranslationService.getStaticText({
      ...translationOptions,
      textIdentifier: Identifiers.BulletTwo,
    }),
    TranslationService.getStaticText({
      ...translationOptions,
      textIdentifier: Identifiers.BulletThree,
    }),
  ];

  if (isFirstTime) {
    children = (
      <ul>
        <Spacer flexGrow={0} height={4} />
        {bulletPointTexts.map((text) => (
          <BulletContainer key={text}>
            <Bullet />
            <Text style={styles.text} font={Font.ProximaNovaRegular}>
              {text}
            </Text>
          </BulletContainer>
        ))}
      </ul>
    );
  }

  return (
    <IllustrationScreen
      imageSrc={IntroOneImage}
      title={TranslationService.getStaticText({
        ...translationOptions,
        textIdentifier: Identifiers.Title,
      })}
      body={TranslationService.getStaticText({
        ...translationOptions,
        textIdentifier: Identifiers.Body,
      })}
      bodyTwo={
        isFirstTime
          ? TranslationService.getStaticText({
              ...translationOptions,
              textIdentifier: Identifiers.BodyTwo,
            })
          : ""
      }
      buttonText={TranslationService.getStaticText({
        ...translationOptions,
        textIdentifier: Identifiers.ContinueButton,
      })}
      buttonLocation={ROUTES.INTRO_TWO}
      stateProps={location.state}
      showNavHeader={!isFirstTime}
    >
      {children}
    </IllustrationScreen>
  );
};

export default IntroOne;

const BulletContainer = styled.li({
  display: "flex",
  marginBottom: 8,
  alignItems: "center",
});

const Bullet = styled.div({
  height: 4,
  width: 4,
  background: mainGrey,
  borderRadius: 100,
  marginRight: 10,
});

const styles = StyleSheet.create({
  text: {
    fontSize: 18,
    color: mainGrey,
  },
});
