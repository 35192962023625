import { useQuery } from "@apollo/react-hooks";
import styled from "@emotion/styled";
import { StyleSheet } from "aphrodite";
import gql from "graphql-tag";
import React from "react";
import { Identifiers, Page } from "services/Translation/enums";
import { TranslationService } from "services/Translation/Translation.Service";
import HamburgerMenu from "shared/components/HamburgerMenu/HamburgerMenu";
import Loader from "shared/components/Loader";
import Text, { Font } from "shared/components/Text";
import { useDocumentTitle } from "shared/hooks";
import { mainGrey, turquoise } from "shared/styles/colors";
import ResourceLinkCard from "./components/ResourceLinkCard";
import VideoCard from "./components/VideoCard";

const RESOURCES = gql`
  query Resources {
    currentUser {
      languagePreference
    }
    contentfulResources {
      websites {
        title
        description
        link
      }
      articles {
        title
        description
        link
      }
      pdfs {
        title
        description
        link
      }
      videos {
        title
        description
        link
        image
      }
    }
  }
`;

const Resources = () => {
  useDocumentTitle("Resources | Reflectable");
  const { data, loading, error } = useQuery(RESOURCES);
  let content;
  const page = Page.Resources;
  if (loading || error) {
    content = <Loader />;
  }
  const userLanguage = data?.currentUser?.languagePreference ?? "ENGLISH";
  if (data) {
    const { websites, articles, pdfs, videos } = data.contentfulResources;
    content = (
      <>
        {websites.length ? (
          <ResourceLinkCard
            contentfulEntries={websites}
            cardTitle={TranslationService.getStaticText({
              page,
              userLanguage,
              textIdentifier: Identifiers.Websites,
            })}
          />
        ) : (
          <></>
        )}
        {articles.length ? (
          <ResourceLinkCard
            contentfulEntries={articles}
            cardTitle={TranslationService.getStaticText({
              page,
              userLanguage,
              textIdentifier: Identifiers.Articles,
            })}
          />
        ) : (
          <></>
        )}
        {pdfs.length ? (
          <ResourceLinkCard contentfulEntries={pdfs} cardTitle="PDFS" />
        ) : (
          <></>
        )}
        {videos.length ? (
          <VideoCard
            videoEntries={videos}
            cardTitle={TranslationService.getStaticText({
              page,
              userLanguage,
              textIdentifier: Identifiers.Videos,
            })}
          />
        ) : (
          <></>
        )}
      </>
    );
  }
  return (
    <>
      <HamburgerMenu logoIsVisible />
      <ResourcesContainer>
        <InnerResourcesContainer>
          <Text
            color={turquoise}
            font={Font.ProximaNovaBold}
            style={styles.headerText}
          >
            {TranslationService.getStaticText({
              page,
              userLanguage,
              textIdentifier: Identifiers.Title,
            })}
          </Text>
          <Text
            color={mainGrey}
            font={Font.ProximaNovaRegular}
            style={styles.helpText}
          >
            {TranslationService.getStaticText({
              page,
              userLanguage,
              textIdentifier: Identifiers.SubTitle,
            })}
          </Text>
          {content}
        </InnerResourcesContainer>
      </ResourcesContainer>
    </>
  );
};

export default Resources;

const ResourcesContainer = styled.div({
  padding: "95px 15px 46px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

const InnerResourcesContainer = styled.div({
  maxWidth: 630,
  width: "100%",
});

const styles = StyleSheet.create({
  headerText: {
    marginBottom: 4,
    fontSize: 32,
    lineHeight: 1.2,
  },
  helpText: {
    fontSize: 16,
    marginBottom: 15,
    lineHeight: 1.2,
  },
});
