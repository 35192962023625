import { Identifiers } from "../enums";
import { Translation } from "../Translation.Service";
import { LanguagePreference } from "../../../shared/types";

const translations: Translation = {
  [Identifiers.SplitTitleOne]: {
    [LanguagePreference.English]: "You’ve got this!",
    [LanguagePreference.Spanish]: "¡Lo tienes!",
    [LanguagePreference.Vietnamese]: "Bạn làm được mà!",
  },
  [Identifiers.Body]: {
    [LanguagePreference.English]:
      "Over the next week, practice the action you chose. Notice how, and when, it works.",
    [LanguagePreference.Spanish]:
      "Durante la próxima semana, practica la acción que elegiste. Observa cómo y cuándo funciona.",
    [LanguagePreference.Vietnamese]:
      "Trong tuần tới, hãy thực hành hành động bạn đã chọn. Chú ý xem nó hoạt động như thế nào và khi nào.",
  },
  [Identifiers.BodyOne]: {
    [LanguagePreference.English]:
      "We’ll send you a reminder when it’s time to come back and reflect.",
    [LanguagePreference.Spanish]:
      "Te enviaremos un recordatorio cuando sea el momento de regresar y reflexionar.",
    [LanguagePreference.Vietnamese]:
      "Chúng tôi sẽ gửi bạn một lời nhắc khi đến lúc quay lại và suy ngẫm.",
  },
  [Identifiers.ContinueButton]: {
    [LanguagePreference.English]: "Go to dashboard",
    [LanguagePreference.Spanish]: "Ir al panel de control",
    [LanguagePreference.Vietnamese]: "Đi đến bảng điều khiển",
  },
};

export default translations;
