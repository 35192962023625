import { Identifiers } from "../enums";
import { Translation } from "../Translation.Service";
import { LanguagePreference } from "../../../shared/types";

const translations: Translation = {
  [Identifiers.Title]: {
    [LanguagePreference.English]: "Thanks for giving yourself a break",
    [LanguagePreference.Spanish]: "Gracias por darte un descanso",
    [LanguagePreference.Vietnamese]: "Cảm ơn bạn đã cho mình một khoảng nghỉ",
  },
  [Identifiers.Body]: {
    [LanguagePreference.English]:
      "It’s important to carve out time to refocus and breathe.",
    [LanguagePreference.Spanish]:
      "Es importante tomarse el tiempo para reenfocarse y respirar.",
    [LanguagePreference.Vietnamese]:
      "Việc dành thời gian để tập trung lại và thở là rất quan trọng.",
  },
  [Identifiers.ContinueButton]: {
    [LanguagePreference.English]: "Continue",
    [LanguagePreference.Spanish]: "Continuar",
    [LanguagePreference.Vietnamese]: "Tiếp tục",
  },
};

export default translations;
