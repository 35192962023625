import React from "react";

const WhiteResourcesIcon = () => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Resources</title>
      <path d="M5.83333 3.03333H16.3333V3.9375H5.83333V3.03333Z" fill="white" />
      <path
        d="M16.9167 4.66667H5.75167C5.60281 4.66456 5.45591 4.63232 5.31984 4.5719C5.18378 4.51147 5.06136 4.42412 4.95997 4.31509C4.85858 4.20607 4.78032 4.07765 4.72991 3.93756C4.6795 3.79747 4.65799 3.64863 4.66667 3.5C4.66595 3.20423 4.77759 2.91921 4.97902 2.70262C5.18045 2.48604 5.45662 2.35404 5.75167 2.33333H16.9167C17.0714 2.33333 17.2198 2.27188 17.3292 2.16248C17.4385 2.05308 17.5 1.90471 17.5 1.75C17.5 1.59529 17.4385 1.44692 17.3292 1.33752C17.2198 1.22813 17.0714 1.16667 16.9167 1.16667H5.75167C5.1471 1.18784 4.57438 1.44299 4.1543 1.8783C3.73423 2.31361 3.49964 2.89506 3.50001 3.5C3.49436 3.5952 3.49436 3.69064 3.50001 3.78583C3.49439 3.83233 3.49439 3.87934 3.50001 3.92583V17.5C3.49964 18.1049 3.73423 18.6864 4.1543 19.1217C4.57438 19.557 5.1471 19.8122 5.75167 19.8333H16.9167C17.0714 19.8333 17.2198 19.7719 17.3292 19.6625C17.4385 19.5531 17.5 19.4047 17.5 19.25V5.39583V5.34333V5.29083C17.5003 5.13213 17.4402 4.97926 17.3319 4.86331C17.2235 4.74737 17.075 4.67705 16.9167 4.66667Z"
        fill="white"
      />
    </svg>
  );
};

export default WhiteResourcesIcon;
