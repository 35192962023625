import React, { useState } from "react";
import styled from "@emotion/styled";
import InvitationText from "./InvitationText";
import { TranslationService } from "services/Translation/Translation.Service";
import {
  Page,
  Identifiers as TextIdentifiers,
} from "services/Translation/enums";
import { LanguagePreference } from "shared/types";
import defaultLogo from "../../static/images/Welcome@3x.png";
import Loader from "shared/components/Loader";
import { useParams } from "react-router";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { useToasts } from "react-toast-notifications";

const GET_COHORT_BY_USER_INVITE_ID = gql`
  query GetCohortByUserInviteId($inviteId: String!) {
    getCohortByUserInviteId(inviteId: $inviteId) {
      name
      organization {
        name
        logo
      }
    }
  }
`;

interface Props {
  userLanguage: LanguagePreference;
  userDataLoading: boolean;
}

const Intro = ({ userLanguage, userDataLoading }: Props) => {
  const params = useParams<{ inviteId: string }>();
  const { inviteId } = params;
  const [isValidUrl, setIsValidUrl] = useState<boolean>(false);
  const [isImageLoading, setIsImageLoading] = useState<boolean>(true);
  const { addToast } = useToasts();

  const {
    data: cohortOrgData,
    loading: cohortOrgDataLoading,
    error,
  } = useQuery(GET_COHORT_BY_USER_INVITE_ID, {
    variables: {
      inviteId,
    },
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      const logo = data?.getCohortByUserInviteId?.organization?.logo;
      if (logo) {
        fetch(logo)
          .then((response) => {
            if (response.ok) {
              setIsValidUrl(true);
              setIsImageLoading(false);
            } else {
              setIsValidUrl(false);
              setIsImageLoading(false);
            }
          })
          .catch(() => {
            setIsValidUrl(false);
            setIsImageLoading(false);
          });
      } else {
        setIsValidUrl(false);
        setIsImageLoading(false);
      }
    },
  });

  const cohortName = cohortOrgData?.getCohortByUserInviteId.name;
  const organization = cohortOrgData?.getCohortByUserInviteId.organization;

  const subtitleText =
    TranslationService.getStaticText({
      page: Page.UserInvited,
      textIdentifier: TextIdentifiers.IntroSubtitle,
      userLanguage,
    }) +
    cohortName +
    TranslationService.getStaticText({
      page: Page.UserInvited,
      textIdentifier: TextIdentifiers.By,
      userLanguage,
    }) +
    organization?.name +
    TranslationService.getStaticText({
      page: Page.UserInvited,
      textIdentifier: TextIdentifiers.Organization,
      userLanguage,
    });

  if (userDataLoading || cohortOrgDataLoading) {
    return (
      <LoadingContainer>
        <Loader />
      </LoadingContainer>
    );
  }

  if (error) {
    addToast("Error finding user with inviteId", {
      appearance: "error",
    });
  }

  return (
    <div>
      <ImageContainer>
        {isImageLoading ? (
          <Loader />
        ) : (
          <LogoImageContainer
            src={isValidUrl ? organization?.logo : defaultLogo}
            alt={`${organization?.name} logo`}
          />
        )}
      </ImageContainer>
      <IntroScreen>
        <InvitationText
          titleTextFontSize={36}
          subTitleTextFontSize={16}
          paragraphTextFontSize={18}
          titleText={TranslationService.getStaticText({
            page: Page.UserInvited,
            textIdentifier: TextIdentifiers.IntroTitle,
            userLanguage,
          })}
          subtitleText={subtitleText}
          paragraphText={TranslationService.getStaticText({
            page: Page.UserInvited,
            textIdentifier: TextIdentifiers.IntroParagraph,
            userLanguage,
          })}
        />
      </IntroScreen>
    </div>
  );
};

export default Intro;

const LoadingContainer = styled.div({
  minHeight: 450,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
});

const ImageContainer = styled.div({
  minHeight: 225,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
});

const LogoImageContainer = styled.img({
  height: "auto",
  minHeight: 225,
  width: 225,
  marginTop: 60,
  marginBottom: 37,
});

const IntroScreen = styled.div({
  margin: "6px 0px 50px 0px",
});
