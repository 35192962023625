import React, { useEffect, useState } from "react";
import { css, StyleSheet } from "aphrodite";
import { white, lightGrey, buttonBlue } from "shared/styles/colors";
import { Dropdown, DropdownProps } from "semantic-ui-react";
import "./dropdown.scss";

interface Props {
  questionText: string;
  answersArray: Record<string, any>[];
  index: number;
  values: any;
  id: string;
  hasResponse: boolean;
}

const DropDown = ({ answersArray, values, id, hasResponse }: Props) => {
  const [isRendered, setIsRendered] = useState<boolean>(false);
  const selectableArray = answersArray.map((item, index) => ({
    key: index,
    text: item.translationData.text,
    value: item.id,
  }));

  const handleDropDownChange = (newValue) => {
    values[id] = newValue;
  };

  useEffect(() => {
    if (values[id]) {
      setIsRendered(true);
    }
    setIsRendered(true);
  }, [values[id]]);

  const dropdownOptions: DropdownProps = {
    fluid: true,
    className: `${css(styles.inputSelect)} react-dropdown`,
    placeholder: "Please select",
    search: true,
    selection: true,
    compact: true,
    options: selectableArray,
    onChange: (e, { value }) => handleDropDownChange(value),
    defaultValue: values[id],
  };

  if (hasResponse) {
    if (values[id] && isRendered) {
      return (
        <div className={css(styles.DropDown)}>
          <Dropdown {...dropdownOptions} />
        </div>
      );
    }
  } else {
    return (
      <div className={css(styles.DropDown)}>
        {/* @ts-ignore */}
        <Dropdown {...dropdownOptions} />
      </div>
    );
  }

  return null;
};

export default DropDown;

const styles = StyleSheet.create({
  DropDown: {
    width: "100%",
    height: "100%",
  },
  inputSelect: {
    background: white,
    border: `1px solid ${buttonBlue}`,
    borderRadius: 8,
    minHeight: 42,
    width: "100%",
    padding: "11px 40px 12px 20px",
    color: lightGrey,
    fontSize: 16,
    ":hover": {
      outline: "2px solid dodgerblue",
    },
  },
});
