import React from "react";
import IntroTwoImage from "static/images/IntroTwoImage.png";
import IllustrationScreen from "shared/components/IllustrationScreen";
import { ROUTES } from "../../../constants";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { TranslationService } from "services/Translation/Translation.Service";
import { Identifiers, Page } from "services/Translation/enums";
import { useDocumentTitle } from "shared/hooks";
import Logging from "services/Logging/Logging";

interface Props {
  location?: any;
}

const MINDSET_PROMPT = gql`
  query RandomMindsetPrompt {
    randomPrompt {
      id
      translationData {
        text
      }
    }
    userLanguage
  }
`;

const IntroTwo = ({ location }: Props) => {
  useDocumentTitle("Intro Two | Reflectable");
  const { data, loading, error } = useQuery(MINDSET_PROMPT, {
    fetchPolicy: "no-cache",
  });

  if (loading) {
    return null;
  }

  if (error) {
    Logging.logError(error);
  }

  const page = Page.Shared;
  const userLanguage = data?.userLanguage ?? "ENGLISH";
  const sharedTranslationOptions = { page, userLanguage };
  const isFirstTime = location?.state?.isFirstTime;

  if (data) {
    return (
      <IllustrationScreen
        imageSrc={IntroTwoImage}
        imageAltText="Person In Nature"
        title={TranslationService.getStaticText({
          page: Page.IntroTwoFirstTime,
          textIdentifier: Identifiers.Title,
          userLanguage,
        })}
        body={
          data.randomPrompt?.translationData?.text ?? "[ERROR FINDING TEXT]"
        }
        buttonText={TranslationService.getStaticText({
          ...sharedTranslationOptions,
          textIdentifier: Identifiers.ContinueButton,
        })}
        buttonLocation={ROUTES.INTRO_THREE}
        stateProps={location.state}
        showNavHeader={!isFirstTime}
      />
    );
  }
  return null;
};

export default IntroTwo;
