import { useLazyQuery } from "@apollo/react-hooks";
import { css, StyleSheet } from "aphrodite";
import gql from "graphql-tag";
import React, { useEffect } from "react";
import Card from "shared/components/Card";
import Spinner from "react-spinkit";
import {
  barGraphLightBlue,
  darkGreenBlue,
  turquoise,
  barGraphGreenBlue,
  barGraphDarkGreenBlue,
  mainGrey,
  graphGrey,
  graphTextGrey,
  buttonBlue,
} from "shared/styles/colors";
import Text, { Font } from "shared/components/Text";
import { capitalize, isInteger } from "lodash";
import { VictoryBar, VictoryChart, VictoryAxis } from "victory";

interface Props {
  cohortId: string;
  topicId: string;
}

const ADMIN_EFFECTIVENESS_GRAPH = gql`
  query($id: String!, $topicId: String!) {
    adminEffectivenessRatingByTopicAndCohort(cohortId: $id, topicId: $topicId) {
      responsesData
      percentageData {
        responseName
        maxPercentage
      }
    }
  }
`;

const AdminEffectivenessGraph = ({ cohortId, topicId }: Props) => {
  const [
    graphQuery,
    { data, loading, error },
  ] = useLazyQuery(ADMIN_EFFECTIVENESS_GRAPH, { fetchPolicy: "no-cache" });

  useEffect(() => {
    graphQuery({
      variables: {
        id: cohortId,
        topicId,
      },
    });
  }, [topicId]);

  const loadingState = (
    <div className={css(styles.loadingContent)}>
      <Spinner name="line-scale" color={buttonBlue} fadeIn="none" />
    </div>
  );
  let content;

  if (loading || error) {
    content = loadingState;
  }

  if (data) {
    const {
      adminEffectivenessRatingByTopicAndCohort: {
        responsesData,
        percentageData,
      },
    } = data;

    // removed these from object destructure becuase percentage data is possibly null
    const maxPercentage = percentageData?.maxPercentage;
    const responseName = percentageData?.responseName;

    const barGraphData = [
      { x: "0", y: responsesData["0"] ?? 0 },
      { x: "1", y: responsesData["1"] ?? 0 },
      { x: "2", y: responsesData["2"] ?? 0 },
      { x: "3", y: responsesData["3"] ?? 0 },
      { x: "4", y: responsesData["4"] ?? 0 },
      { x: "5", y: responsesData["5"] ?? 0 },
    ];

    const axisStyles = { stroke: graphGrey };
    const sharedLabelStyles = {
      padding: 5,
      fill: graphTextGrey,
      fontFamily: "ProximaNova",
      fontWeight: 600,
    };

    if (maxPercentage) {
      content = (
        <>
          <div className={css(styles.leftSide)}>
            <Text font={Font.ProximaNovaBold} style={styles.title}>
              effectiveness
            </Text>
            <Text style={styles.percentageText} font={Font.ProximaNovaBold}>
              Users rated their effectiveness as &#8220;
              {capitalize(responseName)}&#8221; {maxPercentage} of the time
            </Text>
          </div>
          <div className={css(styles.chartContainer)}>
            <VictoryChart
              animate={{
                duration: 300,
                onLoad: { duration: 300 },
              }}
              domainPadding={{ x: [41, 35], y: [0, 20] }}
              height={320}
              width={550}
            >
              <VictoryAxis
                style={{
                  axis: axisStyles,
                  tickLabels: {
                    fontSize: 18,
                    ...sharedLabelStyles,
                  },
                }}
                tickValues={["0", "1", "2", "3", "4", "5"]}
              />
              <VictoryAxis
                style={{
                  axis: axisStyles,
                  tickLabels: {
                    fontSize: 12,
                    ...sharedLabelStyles,
                  },
                  axisLabel: {
                    ...sharedLabelStyles,
                    padding: 25,
                    fontSize: 14,
                  },
                }}
                dependentAxis
                tickFormat={(tick) => {
                  if (isInteger(tick)) {
                    return tick;
                  }
                  return null;
                }}
                label="Number of Responses"
              />
              <VictoryBar
                style={{
                  data: {
                    fill: ({ datum }) =>
                      // eslint-disable-next-line no-nested-ternary
                      datum.x === "0" || datum.x === "1"
                        ? barGraphLightBlue
                        : datum.x === "2" || datum.x === "3"
                        ? barGraphGreenBlue
                        : barGraphDarkGreenBlue,
                    width: 52,
                  },
                }}
                data={barGraphData}
              />
            </VictoryChart>
            <div className={css(styles.graphTexts)}>
              <Text style={[styles.notGettingIt, styles.graphLabel]}>
                &#8220;Not Getting It&#8221;
              </Text>
              <Text style={[styles.gettingIt, styles.graphLabel]}>
                &#8220;Getting It&#8221;
              </Text>
              <Text style={styles.graphLabel}>&#8220;Got It&#8221;</Text>
            </div>
          </div>
        </>
      );
    } else {
      content = (
        <div className={css(styles.loadingContent)}>
          <Text
            style={[styles.percentageText, styles.center]}
            font={Font.ProximaNovaBold}
          >
            No reflections have been completed with this selected topic
          </Text>
        </div>
      );
    }
  }
  return (
    <>
      <Card
        cardStyle={styles.AdminEffectivenessGraph}
        contentStyle={styles.cardContent}
      >
        {content}
      </Card>
    </>
  );
};

export default AdminEffectivenessGraph;

const styles = StyleSheet.create({
  AdminEffectivenessGraph: {
    width: "100%",
    height: 367,
    marginBottom: 35,
  },
  loadingContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: 75,
    height: "100%",
    width: "100%",
    paddingLeft: 47,
  },
  cardContent: {
    padding: "28px 90px 0px 33px",
    display: "flex",
    height: "100%",
  },
  leftSide: {},
  title: {
    color: turquoise,
    textTransform: "uppercase",
    fontSize: 12,
    lineHeight: "14px",
    letterSpacing: 2,
    marginBottom: 100,
  },
  percentageText: {
    color: darkGreenBlue,
    fontSize: 35,
    lineHeight: "42px",
    marginLeft: 38,
    width: 490,
    marginRight: 46,
  },
  chartContainer: {
    display: "flex",
    flexDirection: "column",
    minWidth: 503,
  },
  graphTexts: {
    display: "flex",
    position: "relative",
    bottom: 33,
    paddingLeft: 72,
  },
  notGettingIt: {
    marginRight: 72,
  },
  gettingIt: {
    marginRight: 82,
  },
  graphLabel: {
    fontSize: 13,
    lineHeight: "16px",
    color: mainGrey,
  },
  center: {
    textAlign: "center",
    width: 555,
  },
});
