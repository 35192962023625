import { Identifiers } from "../enums";
import { Translation } from "../Translation.Service";
import { LanguagePreference } from "../../../shared/types";

const translations: Translation = {
  [Identifiers.Positive]: {
    [LanguagePreference.English]: "Positive",
    [LanguagePreference.Spanish]: "Positivo",
    [LanguagePreference.Vietnamese]: "Tích cực",
  },
  [Identifiers.Challenging]: {
    [LanguagePreference.English]: "Challenging",
    [LanguagePreference.Spanish]: "Desafiante",
    [LanguagePreference.Vietnamese]: "Thách thức",
  },
  [Identifiers.WeeksPracticed]: {
    [LanguagePreference.English]: "Weeks Practiced",
    [LanguagePreference.Spanish]: "Semanas Practicadas",
    [LanguagePreference.Vietnamese]: "Số tuần luyện tập",
  },
  [Identifiers.Behaviors]: {
    [LanguagePreference.English]: "OVERALL BEHAVIORS",
    [LanguagePreference.Spanish]: "COMPORTAMIENTOS GENERALES",
    [LanguagePreference.Vietnamese]: "HÀNH VI TỔNG QUAN",
  },
  [Identifiers.Effectiveness]: {
    [LanguagePreference.English]: "OVERALL EFFECTIVENESS",
    [LanguagePreference.Spanish]: "EFICACIA GENERAL",
    [LanguagePreference.Vietnamese]: "HIỆU QUẢ TỔNG THỂ",
  },
  [Identifiers.MyEffectiveness]: {
    [LanguagePreference.English]: "My Effectiveness",
    [LanguagePreference.Spanish]: "Mi Eficacia",
    [LanguagePreference.Vietnamese]: "Hiệu Quả Của Tôi",
  },
};

export default translations;
