import React from "react";
import { css, StyleSheet } from "aphrodite";
import { buttonBlue, lightGrey } from "shared/styles/colors";
import { LanguagePreference } from "shared/types";
import { TranslationService } from "services/Translation/Translation.Service";
import { Identifiers, Page } from "services/Translation/enums";

interface Props {
  id: string;
  handleChange: any;
  inputValue: string;
  userLanguage: LanguagePreference;
}

const FreeText = ({ id, handleChange, inputValue, userLanguage }: Props) => {
  return (
    <div className={css(styles.FreeTextContainer)}>
      <textarea
        className={css(styles.input)}
        placeholder={TranslationService.getStaticText({
          page: Page.QuestionPage,
          userLanguage,
          textIdentifier: Identifiers.FreeTextInput,
        })}
        name={id}
        value={inputValue}
        onChange={handleChange}
      />
    </div>
  );
};

export default FreeText;

const styles = StyleSheet.create({
  FreeTextContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  input: {
    width: "100%",
    height: 126,
    marginBottom: 10,
    border: `1px solid ${buttonBlue}`,
    borderRadius: 8,
    fontSize: 16,
    color: lightGrey,
    display: "flex",
    padding: "11px 20px",
    resize: "none",
  },
});
