import { Identifiers } from "../enums";
import { Translation } from "../Translation.Service";
import { LanguagePreference } from "../../../shared/types";

const translations: Translation = {
  [Identifiers.Title]: {
    [LanguagePreference.English]: "Step 3",
    [LanguagePreference.Spanish]: "Paso 3",
    [LanguagePreference.Vietnamese]: "Bước 3",
  },
  [Identifiers.SkipButton]: {
    [LanguagePreference.English]: "Skip",
    [LanguagePreference.Spanish]: "Omitir",
    [LanguagePreference.Vietnamese]: "Bỏ qua",
  },
};

export default translations;
