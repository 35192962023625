import { StyleSheet, css } from "aphrodite";
import React from "react";
import Card from "shared/components/Card";
import Text, { Font } from "shared/components/Text";
import { mainGrey } from "shared/styles/colors";
import arrowRight from "static/images/arrowRight.png";

interface Props {
  onClick: () => void;
  icon: string;
  imgAlt: string;
  text: string;
}

const LinkCard = ({ onClick, icon, imgAlt, text }: Props) => {
  return (
    <Card
      onClick={onClick}
      cardStyle={styles.smallCard}
      contentStyle={styles.smallCardContent}
    >
      <img
        className={css(styles.icon)}
        src={icon}
        alt={imgAlt}
        title={imgAlt}
      />
      <div className={css(styles.smallCardTextContainer)}>
        <Text font={Font.ProximaNovaSemibold} style={styles.smallCardText}>
          {text}
        </Text>
        <img
          className={css(styles.rightArrow)}
          src={arrowRight}
          alt="right arrow"
          title="right arrow"
        />
      </div>
    </Card>
  );
};

export default LinkCard;

const styles = StyleSheet.create({
  smallCard: {
    width: "49%",
    height: 121,
    cursor: "pointer",
  },
  smallCardContent: {
    paddingTop: 13,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  icon: {
    height: 36,
    marginBottom: 12,
  },
  smallCardTextContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: 12,
    padding: "0 10px",
  },
  smallCardText: {
    fontSize: 16,
    lineHeight: 1.2,
    color: mainGrey,
    width: 81,
    marginRight: 10,
    textAlign: "center",
  },
  rightArrow: {
    height: 10,
  },
});
