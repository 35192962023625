import { css, StyleSheet } from "aphrodite";
import React from "react";
import Card from "shared/components/Card";
import { mainGrey } from "shared/styles/colors";
import { ReactComponent as PieChartMissing } from "static/images/PieChartMissing.svg";
import { ReactComponent as CurvedLineMissing } from "static/images/CurvedLineMissing.svg";
import { ReactComponent as BlueLineMissing } from "static/images/BlueLineMissing.svg";
import Text, { Font } from "shared/components/Text";
import { Identifiers } from "services/Translation/enums";
import { TranslationService } from "services/Translation/Translation.Service";

interface Props {
  userLanguage: any;
  page: any;
}

const MissingDataCard = ({ userLanguage, page }: Props) => {
  return (
    <Card
      cardStyle={styles.questionsContainer}
      contentStyle={styles.questionsContainerContent}
    >
      <Text font={Font.ProximaNovaBold} style={styles.notEnough}>
        {TranslationService.getStaticText({
          page,
          userLanguage,
          textIdentifier: Identifiers.NotEnough,
        })}
      </Text>
      <Text font={Font.ProximaNovaRegular} style={styles.completeSuggestion}>
        {TranslationService.getStaticText({
          page,
          userLanguage,
          textIdentifier: Identifiers.NotEnoughText,
        })}
      </Text>
      <div className={css(styles.noDataGraphContainer)}>
        <CurvedLineMissing />
        <PieChartMissing />
        <BlueLineMissing />
      </div>
    </Card>
  );
};

export default MissingDataCard;

const styles = StyleSheet.create({
  questionsContainerContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: 355,
    paddingTop: 35,
  },
  questionsContainer: {
    marginBottom: 20,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  notEnough: {
    textAlign: "center",
    color: mainGrey,
    fontSize: 12,
    marginBottom: 20,
    textTransform: "uppercase",
  },
  completeSuggestion: {
    textAlign: "center",
    marginBottom: 40,
    color: mainGrey,
    width: 305,
  },
  noDataGraphContainer: {
    display: "flex",
    justifyContent: "space-between",
    padding: "0 33px",
    width: "100%",
    marginBottom: 43,
  },
});
