import { css, StyleSheet } from "aphrodite";
import React from "react";
import { greenBlue, lightGrey, white } from "shared/styles/colors";
import LogoutButton from "shared/components/LogoutButton";
import horizontalLogo from "static/images/horizontal-logo.png";
import { LanguagePreference } from "shared/types";
import BackButton from "shared/components/Navigation/BackButton";
import { useHistory } from "react-router";
import { ROUTES } from "../../constants";

interface Props {
  userLanguage: LanguagePreference;
}

const CohortHeader = ({ userLanguage }: Props) => {
  const history = useHistory();
  return (
    <header className={css(styles.header)}>
      <img
        alt="Horizontal Logo"
        title="Horizontal Logo"
        className={css(styles.logo)}
        src={horizontalLogo}
      />
      <div className={css(styles.buttonContainer)}>
        <BackButton
          onButtonClick={() => history.push(ROUTES.DASHBOARD)}
          containerStyle={styles.dashboardButton}
        />
        <LogoutButton userLanguage={userLanguage} />
      </div>
    </header>
  );
};

export default CohortHeader;

const styles = StyleSheet.create({
  header: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    marginBottom: 38,
    paddingTop: 67,
  },
  inputSelect: {
    background: white,
    border: `1px solid ${greenBlue}`,
    borderRadius: 8,
    minHeight: 42,
    width: 197,
    padding: "11px 40px 12px 20px",
    color: lightGrey,
    fontSize: 16,
  },
  logo: {
    height: 53,
  },
  buttonContainer: {
    display: "flex",
    alignItems: "flex-end",
  },
  dashboardButton: {
    marginBottom: 0,
    height: 26,
    marginRight: 30,
  },
  //
});
