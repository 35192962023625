import { css, StyleSheet } from "aphrodite";
import React, { useEffect } from "react";
import Text from "shared/components/Text";
import { greenBlue, mainGrey } from "shared/styles/colors";
import { Dropdown, DropdownProps } from "semantic-ui-react";
import gql from "graphql-tag";
import { useLazyQuery } from "@apollo/react-hooks";
import { ALL_TOPICS } from "@quick-check/common";

interface Props {
  setTopicId: any;
  topicId: string;
  cohortId: string;
}

const TOPICS_QUERY = gql`
  query($cohortId: String!) {
    topicsByCohort(cohortId: $cohortId) {
      id
      color
      translationData {
        name
      }
    }
  }
`;

const TopicSelect = ({ setTopicId, topicId, cohortId }: Props) => {
  const [topicsQuery, { data, loading, error }] = useLazyQuery(TOPICS_QUERY, {
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    topicsQuery({ variables: { cohortId } });
  }, [cohortId]);

  if (loading || error) {
    return null;
  }
  if (data) {
    const selectableArray = data?.topicsByCohort.map((topic, index) => ({
      key: index,
      text: topic?.translationData?.name,
      value: topic.id,
    }));
    selectableArray.unshift({
      value: ALL_TOPICS,
      text: "All Topics",
      key: ALL_TOPICS,
    });
    const dropdownOptions: DropdownProps = {
      fluid: true,
      className: `${css(styles.input)} react-dropdown`,
      placeholder: "Please select",
      selection: true,
      compact: true,
      options: selectableArray,
      value: topicId,
      onChange: (e, { value }) => setTopicId(value),
    };
    return (
      <section className={css(styles.TopicSelect)}>
        <Text style={styles.text}>Filter by Topic:</Text>
        <Dropdown selection {...dropdownOptions} />
      </section>
    );
  }
  return null;
};

export default TopicSelect;

const styles = StyleSheet.create({
  TopicSelect: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    height: 42,
    marginBottom: 39,
  },
  text: {
    fontSize: 18,
    lineHeight: 1,
    color: mainGrey,
    marginRight: 23,
  },
  input: {
    width: 250,
    height: 42,
    borderColor: greenBlue,
    display: "flex",
    alignItems: "center",
  },
});
