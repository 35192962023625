import React from "react";
import { Route } from "react-router-dom";
import Login from "modules/login/Login";
import CookiesService, { Identifiers } from "services/Cookie/CookieService";

const PrivateRoute = ({ component, ...options }) => {
  // TODO implement authDataContext
  const token = CookiesService.getCookie(Identifiers.AccessToken);
  const finalComponent = token ? component : Login;

  return <Route {...options} component={finalComponent} />;
};

export default PrivateRoute;
