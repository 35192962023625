import React from "react";

const WhiteReflectionsIcon = () => {
  return (
    <svg
      width="17"
      height="21"
      viewBox="0 0 17 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Reflections</title>
      <path
        d="M1.89671 0H15.0901C16.1466 0 17 0.822817 17 1.84135V19.1586C17 20.1772 16.1466 21 15.0901 21H1.89634C0.853446 21 -1.8897e-07 20.1772 -1.8897e-07 19.1586V1.84172C-1.8897e-07 0.822817 0.853446 0 1.89671 0ZM8.49341 14.2588H14.4672C14.6751 14.2588 14.8438 14.0961 14.8438 13.8957C14.8438 13.6952 14.6751 13.5326 14.4672 13.5326H8.49341C8.28551 13.5326 8.11678 13.6952 8.11678 13.8957C8.11678 14.0961 8.28551 14.2588 8.49341 14.2588ZM5.89277 11.5815H14.4672C14.6751 11.5815 14.8438 11.4189 14.8438 11.2184C14.8438 11.018 14.6751 10.8553 14.4672 10.8553H5.89239C5.68449 10.8553 5.51576 11.018 5.51576 11.2184C5.51576 11.4189 5.68449 11.5815 5.89277 11.5815ZM3.38667 8.81315H14.4672C14.6751 8.81315 14.8438 8.65048 14.8438 8.45004C14.8438 8.2496 14.6751 8.08692 14.4672 8.08692H3.38667C3.17877 8.08692 3.01004 8.2496 3.01004 8.45004C3.01004 8.65048 3.17877 8.81315 3.38667 8.81315ZM2.31666 6.05748H14.4672C14.6751 6.05748 14.8438 5.8948 14.8438 5.69436C14.8438 5.49392 14.6751 5.33125 14.4672 5.33125H2.31628C2.10838 5.33125 1.93965 5.49392 1.93965 5.69436C1.93965 5.8948 2.10838 6.05748 2.31666 6.05748Z"
        fill="white"
      />
    </svg>
  );
};

export default WhiteReflectionsIcon;
