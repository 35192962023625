import React, {
  createContext,
  useState,
  useEffect,
  useMemo,
  useContext,
} from "react";
import CookiesService, {
  Identifiers,
} from "../../../services/Cookie/CookieService";

type AuthContextProps = {
  token: string;
  onLogin: (token: string) => void;
  onLogout: (token: string) => void;
};

export const AuthDataContext = createContext<Partial<AuthContextProps>>({});

const initialAuthData = {};

const AuthDataProvider = (props) => {
  const [authData, setAuthData] = useState(initialAuthData);

  /* The first time the component is rendered, it tries to
   * fetch the auth data from a source, like a cookie or
   * the localStorage.
   */
  useEffect(() => {
    const currentAuthData = {
      token: CookiesService.getCookie(Identifiers.AccessToken),
    };
    if (currentAuthData) {
      setAuthData(currentAuthData);
    }
  }, []);

  const onLogin = (newAuthData) => setAuthData(newAuthData);
  const onLogout = () => setAuthData(initialAuthData);

  const authDataValue = useMemo<any>(
    () => ({ ...authData, onLogin, onLogout }),
    [authData]
  );

  return <AuthDataContext.Provider value={authDataValue} {...props} />;
};

export const useAuthDataContext = () => useContext(AuthDataContext);

export default AuthDataProvider;
