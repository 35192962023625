import { Translation } from "../Translation.Service";
import { LanguagePreference } from "../../../shared/types";
import { Identifiers } from "../enums";

const translations: Translation = {
  [Identifiers.TopicProgressOne]: {
    [LanguagePreference.English]:
      "You have planted the seed. Reflect this week and let your learning take root.",
    [LanguagePreference.Spanish]:
      "Has plantado la semilla. Reflexiona esta semana y deja que tu aprendizaje eche raíces.",
    [LanguagePreference.Vietnamese]:
      "Bạn đã gieo hạt giống. Suy ngẫm trong tuần này và để kiến thức của bạn bén rễ.",
  },
  [Identifiers.TopicProgressTwo]: {
    [LanguagePreference.English]:
      "Nurture your own growth just as you would care for a delicate seedling.",
    [LanguagePreference.Spanish]:
      "Cultiva tu propio crecimiento tal como cuidarías de una plántula delicada.",
    [LanguagePreference.Vietnamese]:
      "Nuôi dưỡng sự phát triển của bản thân giống như bạn chăm sóc cho một cây giống nhỏ d delicate.",
  },
  [Identifiers.TopicProgressThree]: {
    [LanguagePreference.English]:
      "You’re up for the challenge! Keep building on your learning to support future growth.",
    [LanguagePreference.Spanish]:
      "¡Estás listo para el desafío! Continúa construyendo sobre tu aprendizaje para apoyar el crecimiento futuro.",
    [LanguagePreference.Vietnamese]:
      "Bạn sẵn lòng đối mặt với thách thức! Cứ tiếp tục xây dựng kiến thức của mình để hỗ trợ sự phát triển trong tương lai.",
  },
  [Identifiers.TopicProgressFour]: {
    [LanguagePreference.English]:
      "Your plant is fully grown. Pick this topic again to tend to your learning or choose a different topic to watch other plants grow.",
    [LanguagePreference.Spanish]:
      "Tu planta está completamente crecida. Elige este tema de nuevo para atender a tu aprendizaje o escoge un tema diferente para ver crecer otras plantas.",
    [LanguagePreference.Vietnamese]:
      "Cây của bạn đã phát triển hoàn toàn. Chọn lại chủ đề này để chăm sóc kiến thức của bạn hoặc chọn một chủ đề khác để theo dõi sự phát triển của các cây khác.",
  },
};

export default translations;
