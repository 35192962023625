import { StyleSheet } from "aphrodite";
import React from "react";
import {
  redOrange,
  turquoise,
  mainGrey,
  buttonBlue,
  greyAccent,
} from "shared/styles/colors";
import Text, { Font } from "shared/components/Text";
import Button from "shared/components/Button";
import Card from "shared/components/Card";
import { useMutation, useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { ROUTES } from "../../constants";
import { useHistory } from "react-router";
import GraphqlErrorScreen from "shared/components/ErrorBoundary/GraphqlErrorScreen";
import { FINAL_PAGE_IN_STEP_TWO } from "@quick-check/common";
import { TranslationService } from "services/Translation/Translation.Service";
import { Identifiers, Page } from "services/Translation/enums";
import { LanguagePreference } from "shared/types";
import { useToasts } from "react-toast-notifications";
import { CREATE_EXTRA_REFLECTION } from "shared/gql/mutations";
import CookiesService, {
  Identifiers as CookieIdentifiers,
} from "services/Cookie/CookieService";

const CURRENT_PAGE_IN_SURVEY_ID = gql`
  query CurrentPageInSurveyId {
    currentPageInSurveyId
  }
`;

interface Props {
  userLanguage: LanguagePreference;
  canCreateNewReflection: boolean;
}

const ReflectionCard = ({
  userLanguage,
  canCreateNewReflection = false,
}: Props) => {
  const { addToast } = useToasts();
  const { data, loading, error } = useQuery(CURRENT_PAGE_IN_SURVEY_ID, {
    fetchPolicy: "no-cache",
  });
  const [createExtraReflection] = useMutation(CREATE_EXTRA_REFLECTION, {
    onCompleted: () => {
      history.push(ROUTES.TOPIC);
    },
    onError: () => {
      addToast("Error creating new reflection", {
        autoDismiss: true,
        appearance: "error",
      });
    },
  });

  const history = useHistory();

  const onReflectionButtonClick = () => {
    if (canCreateNewReflection) {
      createExtraReflection();
      return;
    }
    if (data?.currentPageInSurveyId) {
      if (data?.currentPageInSurveyId === FINAL_PAGE_IN_STEP_TWO) {
        history.push(ROUTES.STEP_THREE_INTRO);
      } else {
        history.push(`${ROUTES.QUESTIONS}/${data.currentPageInSurveyId}`);
      }
    } else {
      const skipIntro = CookiesService.getCookie(
        CookieIdentifiers.HideIntroScreens
      );
      if (skipIntro === "true") {
        history.push(ROUTES.INTRO_ONE);
      } else {
        history.push(ROUTES.HOW_IT_WORKS, { isFirstTime: false });
      }
    }
  };

  if (loading) {
    return null;
  }

  if (error) {
    return <GraphqlErrorScreen error={error} />;
  }

  const page = Page.Dashboard;

  return (
    <Card
      accentColor={canCreateNewReflection ? greyAccent : redOrange}
      cardStyle={styles.ReflectionCard}
      contentStyle={styles.firstCardContentStyle}
    >
      <Text
        font={Font.ProximaNovaSemibold}
        style={[styles.cardTitle, canCreateNewReflection && styles.shortMargin]}
      >
        {canCreateNewReflection
          ? TranslationService.getStaticText({
              page,
              userLanguage,
              textIdentifier: Identifiers.ReflectionsCompletedCardTitle,
            })
          : TranslationService.getStaticText({
              page,
              userLanguage,
              textIdentifier: Identifiers.ReflectionCardTitle,
            })}
      </Text>
      {canCreateNewReflection && (
        <Text font={Font.ProximaNovaRegular} style={styles.subText}>
          {TranslationService.getStaticText({
            page,
            userLanguage,
            textIdentifier: Identifiers.ReflectionsCompletedCardSubTitle,
          })}
        </Text>
      )}
      <Button
        textStyle={styles.buttonText}
        buttonStyle={styles.buttonStyle}
        text={
          canCreateNewReflection
            ? "Start New Reflection"
            : TranslationService.getStaticText({
                page,
                userLanguage,
                textIdentifier: Identifiers.ReflectionCardTitleButton,
              })
        }
        onClick={onReflectionButtonClick}
        type="submit"
        ariaLabel={TranslationService.getStaticText({
          page,
          userLanguage,
          textIdentifier: Identifiers.ReflectionCardTitleButton,
        })}
      />
    </Card>
  );
};

export default ReflectionCard;

const styles = StyleSheet.create({
  ReflectionCard: {
    marginBottom: 21,
    width: "100%",
    maxWidth: 630,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  cardTitle: {
    fontSize: 12,
    color: turquoise,
    letterSpacing: 2,
    lineHeight: 1.2,
    textTransform: "uppercase",
    marginBottom: 20,
  },
  shortMargin: {
    marginBottom: 10,
  },
  subText: {
    color: mainGrey,
    fontSize: 16,
    marginBottom: 10,
    textAlign: "center",
  },
  cardDescription: {
    color: mainGrey,
    fontSize: 16,
    lineHeight: 1.2,
    marginBottom: 20,
  },
  buttonStyle: {
    height: 42,
    backgroundColor: buttonBlue,
    borderRadius: 8,
    marginBottom: 17,
    maxWidth: 410,
  },
  buttonText: {
    fontSize: 16,
  },
  firstCardContentStyle: {
    paddingTop: 20,
    paddingLeft: 20,
    paddingRight: 20,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  //
});
