import React, { useEffect, useState } from "react";
import { StyleSheet, css } from "aphrodite";
import InvitationButton, { ButtonType } from "./InvitationButton";
import { useHistory, useParams } from "react-router-dom";
import { ReactComponent as ReflectableLogo } from "static/images/ReflectableLogo.svg";
import { LanguagePreference } from "shared/types";
import { useDocumentTitle } from "shared/hooks";
import ProgressBar from "./ProgressBar";
import IntroScreen from "./IntroScreen";
import AboutScreen from "./AboutScreen";
import SignUpScreen from "./SignUpScreen";
import Carousel from "./Carousel";
import styled from "@emotion/styled";
import ConfirmationModal from "./ConfirmationModal";
import { gql } from "apollo-boost";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { UPDATE_USER_INFO } from "screens/settings/shared/mutations";
import { useToasts } from "react-toast-notifications";
import { TranslationService } from "services/Translation/Translation.Service";
import {
  Page,
  Identifiers as TextIdentifiers,
} from "services/Translation/enums";
import CookieService, { Identifiers } from "services/Cookie/CookieService";
import { ROUTES } from "../../constants";
import {
  SignUpComponentName,
  UserData,
  Direction,
  UserFlow,
} from "../../shared/types/index";
import {
  handleComponentChange,
  passwordValidator,
  emailValidator,
} from "../../shared/utils";
import Spacer from "shared/components/Spacer";
import { lowOpacityWhite } from "shared/styles/colors";

const USER_DATA = gql`
  query GetUserByInviteId($inviteId: String!) {
    userHasPassword(inviteId: $inviteId)
    getUserByInviteId(inviteId: $inviteId) {
      fullName
      nickname
      email
      phoneNumber
      languagePreference
    }
    getCohortByUserInviteId(inviteId: $inviteId) {
      id
      name
      organization {
        name
        logo
      }
    }
  }
`;

const CREATE_PASSWORD = gql`
  mutation CreatePassword($inviteId: String!, $password: String!) {
    createPassword(inviteId: $inviteId, password: $password) {
      token
      isAdmin
    }
  }
`;

const DELETE_PASSWORD = gql`
  mutation DeletePassword($inviteId: String!) {
    deletePassword(inviteId: $inviteId) {
      success
    }
  }
`;

const InvitationFrame = () => {
  useDocumentTitle("Invitation | Reflectable");
  const [userData, setUserData] = useState<UserData>({
    fullName: "",
    nickname: "",
    phoneNumber: "",
    email: "",
    password: "",
    confirmPassword: "",
    languagePreference: "",
  });
  const history = useHistory();
  const [currentComponent, setCurrentComponent] = useState<SignUpComponentName>(
    "introScreen"
  );
  const [showModal, setShowModal] = useState(false);
  const [emailValidationError, setEmailValidationError] = useState<boolean>(
    false
  );
  const [checkboxChecked, setCheckboxChecked] = useState<boolean>(false);
  const [checkboxError, setCheckboxError] = useState<boolean>(false);
  const [checkForErrors, setCheckForErrors] = useState<boolean>(false);

  const params: any = useParams();
  const { inviteId } = params;
  const { data, loading: userDataLoading } = useQuery(USER_DATA, {
    variables: {
      inviteId,
    },
    fetchPolicy: "no-cache",
    onCompleted: (dataForUser) => {
      if (dataForUser.userHasPassword) {
        history.push(`${ROUTES.LOGIN}/?language=${userLanguage ?? "ENGLISH"}`);
      } else {
        const user = dataForUser?.getUserByInviteId;
        const initialUserData: UserData = {
          fullName: user?.fullName,
          nickname: user?.nickname,
          phoneNumber: user?.phoneNumber,
          email: user?.email,
          password: "",
          confirmPassword: "",
          languagePreference: user?.languagePreference,
          inviteId,
        };
        setUserData(initialUserData);
      }
    },
  });

  const [deletePassword, { loading: deletePasswordLoading }] = useMutation(
    DELETE_PASSWORD,
    {
      variables: {
        inviteId,
      },
      onCompleted: () => {
        CookieService.removeCookie(Identifiers.AccessToken);
      },
      onError: (error) => {
        addToast(error.message, {
          appearance: "error",
          autoDismiss: true,
        });
      },
    }
  );

  const userLanguage: LanguagePreference =
    data?.getUserByInviteId?.languagePreference;

  const checkAboutPageErrors = (aboutPageErrors: boolean) => {
    if (aboutPageErrors) {
      setCheckForErrors(true);
    }
    if (!aboutPageErrors) {
      setCheckForErrors(false);
    }
  };

  const componentsList: SignUpComponentName[] = [
    "introScreen",
    "carousel",
    "aboutScreen",
    "signUpScreen",
  ];

  const onUserInfoChange = (
    fullName: string,
    nickname: string,
    phoneNumber: string,
    languagePreference: LanguagePreference
  ) => {
    setUserData((prevUserData) => ({
      ...prevUserData,
      fullName,
      nickname,
      phoneNumber,
      languagePreference,
    }));
  };

  const onEmailAndPasswordChange = (
    email: string,
    password: string,
    confirmPassword: string
  ) => {
    setUserData((prevUserData) => ({
      ...prevUserData,
      email,
      password,
      confirmPassword,
    }));
  };

  const [
    updateUserInfo,
    { data: updateUserInfoData, loading: updateUserInfoLoading },
  ] = useMutation(UPDATE_USER_INFO, {
    onCompleted: () => {
      setShowModal(true);
      setEmailValidationError(false);
    },
    onError: async (error) => {
      addToast(error.message, {
        appearance: "error",
        autoDismiss: true,
      });
      await deletePassword();
    },
  });

  const [
    createPassword,
    { data: passwordCreateData, loading: createPassswordLoading },
  ] = useMutation(CREATE_PASSWORD, {
    onCompleted: (successData) => {
      if (successData?.createPassword?.token && !updateUserInfoData) {
        CookieService.setCookie(
          Identifiers.AccessToken,
          successData.createPassword.token
        );
        updateUserInfo({
          variables: userData,
        });
      }
    },
    onError: (error) => {
      addToast(error.message, {
        appearance: "error",
        autoDismiss: true,
      });
    },
  });

  const handleCreateAccount = () => {
    const emailIsValid = emailValidator(userData.email);
    const passwordIsValid = passwordValidator(userData.password);
    const passwordsMatch = userData.password === userData.confirmPassword;
    if (passwordIsValid && passwordsMatch && emailIsValid && checkboxChecked) {
      createPassword({
        variables: {
          inviteId,
          password: userData.password,
        },
      });
    } else if (!emailIsValid) {
      setEmailValidationError(true);
    } else if (!checkboxChecked) {
      setCheckboxError(true);
    }
  };

  const { addToast } = useToasts();

  useEffect(() => {
    if (checkboxError) {
      addToast(
        TranslationService.getStaticText({
          page: Page.UserInvited,
          textIdentifier: TextIdentifiers.TermsAndConditionsAgreement,
          userLanguage,
        }),
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
      setCheckboxError(false);
    }
  }, [addToast, checkboxError, userLanguage]);

  return (
    <OuterSignUp>
      <SignUp id="signup">
        <InnerSignUp>
          {currentComponent === "signUpScreen" && (
            <InvitationButton
              buttonType={ButtonType.Quaternary}
              buttonStyle={styles.noBorderPrevButton}
              onClick={() =>
                handleComponentChange(
                  currentComponent,
                  setCurrentComponent,
                  Direction.Prev,
                  componentsList
                )
              }
            />
          )}
          <ReflectableLogo className={css(styles.reflectablesLogo)} />
          {(currentComponent === "carousel" ||
            currentComponent === "aboutScreen" ||
            currentComponent === "signUpScreen") && (
            <ProgressBar
              component={currentComponent}
              userLanguage={userLanguage}
              userFlow={UserFlow.InvitedUser}
            />
          )}
          {currentComponent === "introScreen" && (
            <IntroScreen
              userLanguage={userLanguage}
              userDataLoading={userDataLoading}
            />
          )}
          {currentComponent === "carousel" && (
            <Carousel userLanguage={userLanguage} />
          )}
          {currentComponent === "aboutScreen" && (
            <AboutScreen
              userFullName={userData.fullName}
              userNickname={userData.nickname}
              userPhoneNumber={userData.phoneNumber}
              onUserInfoChange={onUserInfoChange}
              userLanguage={userLanguage}
              checkAboutPageErrors={checkAboutPageErrors}
            />
          )}
          {currentComponent === "signUpScreen" && (
            <SignUpScreen
              userEmail={userData.email}
              onEmailAndPasswordChange={onEmailAndPasswordChange}
              onCheckboxIsChecked={() => setCheckboxChecked(!checkboxChecked)}
              userLanguage={userLanguage}
              isChecked={checkboxChecked}
            />
          )}
          {currentComponent === "introScreen" && (
            <ButtonWrapper>
              <InvitationButton
                text={TranslationService.getStaticText({
                  page: Page.UserInvited,
                  textIdentifier: TextIdentifiers.CreateAccountButton,
                  userLanguage,
                })}
                buttonType={ButtonType.Primary}
                buttonStyle={styles.createAccountButton}
                onClick={() =>
                  handleComponentChange(
                    currentComponent,
                    setCurrentComponent,
                    Direction.Next,
                    componentsList
                  )
                }
                disabled={userDataLoading}
                loading={userDataLoading}
              />
            </ButtonWrapper>
          )}
          {(currentComponent === "aboutScreen" ||
            currentComponent === "carousel") && (
            <ButtonWrapper>
              <InvitationButton
                buttonType={ButtonType.Secondary}
                buttonStyle={styles.prevButton}
                onClick={() =>
                  handleComponentChange(
                    currentComponent,
                    setCurrentComponent,
                    Direction.Prev,
                    componentsList
                  )
                }
              />
              <InvitationButton
                buttonType={ButtonType.Tertiary}
                buttonStyle={styles.nextButton}
                onClick={() =>
                  handleComponentChange(
                    currentComponent,
                    setCurrentComponent,
                    Direction.Next,
                    componentsList
                  )
                }
              />
            </ButtonWrapper>
          )}
          {currentComponent === "signUpScreen" && (
            <ButtonWrapper>
              <InvitationButton
                text={TranslationService.getStaticText({
                  page: Page.UserInvited,
                  textIdentifier: TextIdentifiers.CompleteButton,
                  userLanguage,
                })}
                buttonType={ButtonType.Primary}
                buttonStyle={styles.createAccountButton}
                onClick={handleCreateAccount}
                loading={
                  createPassswordLoading ||
                  updateUserInfoLoading ||
                  deletePasswordLoading
                }
                disabled={
                  createPassswordLoading ||
                  updateUserInfoLoading ||
                  deletePasswordLoading
                }
              />
            </ButtonWrapper>
          )}
          {showModal && (
            <ConfirmationModal
              modalIsOpen={showModal}
              setModalIsOpen={setShowModal}
              userLanguage={userLanguage}
              passwordCreateData={passwordCreateData}
            />
          )}
        </InnerSignUp>
      </SignUp>
      <Spacer height={73} />
    </OuterSignUp>
  );
};

export default InvitationFrame;

const OuterSignUp = styled.div({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  "@media (min-width: 630px)": {
    paddingBottom: 0,
  },
});

const SignUp = styled.div({
  textAlign: "center",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  flex: 1,
  width: "100%",
});

const InnerSignUp = styled.div({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  height: "100%",
  position: "relative",
  width: "100%",
  "@media (min-width: 630px)": {
    marginBottom: 94,
    width: 390,
    height: "auto",
    borderRadius: 10,
  },
});

const ButtonWrapper = styled.div({
  display: "flex",
  position: "fixed",
  bottom: 0,
  backgroundColor: lowOpacityWhite,
  zIndex: 11,
  width: "100%",
  justifyContent: "center",
});

const styles = StyleSheet.create({
  prevButton: {
    height: 59,
    width: 59,
    marginRight: 8,
    marginBottom: 43,
  },
  noBorderPrevButton: {
    height: 59,
    width: 59,
    position: "absolute",
    top: 0,
    left: 12,
  },
  createAccountButton: {
    height: 59,
    width: 329,
    marginRight: 8,
    marginBottom: 43,
  },
  nextButton: {
    height: 59,
    width: 59,
    marginLeft: 8,
  },
  reflectablesLogo: {
    marginTop: 17,
  },
});
