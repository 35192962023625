import { Identifiers } from "../enums";
import { Translation } from "../Translation.Service";
import { LanguagePreference } from "../../../shared/types";

const reflectionCompleteTranslations: Translation = {
  [Identifiers.Title]: {
    [LanguagePreference.English]: "Way to go!",
    [LanguagePreference.Spanish]: "¡Bien hecho!",
    [LanguagePreference.Vietnamese]: "Bạn làm rất tốt!",
  },
  [Identifiers.Body]: {
    [LanguagePreference.English]: "Keep growing—choose what to practice next.",
    [LanguagePreference.Spanish]:
      "Sigue creciendo—elige qué practicar a continuación.",
    [LanguagePreference.Vietnamese]:
      "Tiếp tục phát triển—chọn điều bạn muốn thực hành tiếp theo.",
  },
  [Identifiers.BodyOne]: {
    [LanguagePreference.English]:
      "Now it’s time to decide what to do during the week ahead. Remember, you can continue working on this Focus or choose something new.",
    [LanguagePreference.Spanish]:
      "Ahora es el momento de decidir qué hacer durante la próxima semana. Recuerde, puede continuar trabajando en este enfoque o elegir algo nuevo.",
    [LanguagePreference.Vietnamese]:
      "Bây giờ đã đến lúc quyết định những việc cần làm trong tuần tới. Hãy nhớ rằng, bạn có thể tiếp tục làm việc với Tiêu điểm này hoặc chọn một điều gì đó mới mẻ.",
  },
  [Identifiers.SkipButton]: {
    [LanguagePreference.English]: "Choose later",
    [LanguagePreference.Spanish]: "Elige más tarde",
    [LanguagePreference.Vietnamese]: "Chọn sau",
  },
};

export default reflectionCompleteTranslations;
