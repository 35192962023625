import { Identifiers } from "../enums";
import { Translation } from "../Translation.Service";
import { LanguagePreference } from "../../../shared/types";

const translations: Translation = {
  [Identifiers.IntroTitle]: {
    [LanguagePreference.English]: "Welcome!",
    [LanguagePreference.Spanish]: "¡Bienvenido!",
    [LanguagePreference.Vietnamese]: "Chào mừng!",
  },
  [Identifiers.IntroSubtitle]: {
    [LanguagePreference.English]: `You have been invited to join `,
    [LanguagePreference.Spanish]: `Has sido invitado/a a unirte a `,
    [LanguagePreference.Vietnamese]: `Bạn đã được mời tham gia `,
  },
  [Identifiers.By]: {
    [LanguagePreference.English]: ` by `,
    [LanguagePreference.Spanish]: ` por la organización `,
    [LanguagePreference.Vietnamese]: ` bởi tổ chức `,
  },
  [Identifiers.Organization]: {
    [LanguagePreference.English]: ` organization`,
    [LanguagePreference.Spanish]: ``,
    [LanguagePreference.Vietnamese]: ``,
  },
  [Identifiers.IntroParagraph]: {
    [LanguagePreference.English]:
      "Reflectable is an online tool that combines human-centered design, behavioral science, and technology to strengthen teacher and caregiver success.",
    [LanguagePreference.Spanish]:
      "Reflectable es una herramienta en línea que combina el diseño centrado en el ser humano, la ciencia del comportamiento y la tecnología para fortalecer el éxito de los maestros y cuidadores.",
    [LanguagePreference.Vietnamese]:
      "Reflectable là một công cụ trực tuyến kết hợp thiết kế dựa trên con người, khoa học hành vi và công nghệ để tăng cường sự thành công của giáo viên và người chăm sóc.",
  },
  [Identifiers.FirstSlideTitle]: {
    [LanguagePreference.English]: "So glad you’re here",
    [LanguagePreference.Spanish]: "Me alegra mucho que estés aquí.",
    [LanguagePreference.Vietnamese]: "Rất vui bạn đã đến đây.",
  },
  [Identifiers.FirstSlideParagraph]: {
    [LanguagePreference.English]:
      "Caring for others, especially young children, can be incredibly tough. We see you’re giving it your all. Co-designed with practitioners and professionals, Reflectable is here to support you.",
    [LanguagePreference.Spanish]:
      "Cuidar de los demás, especialmente de los niños pequeños, puede ser increíblemente difícil. Vemos que estás dando todo de ti. Co-diseñado con profesionales y expertos, Reflectable está aquí para apoyarte.",
    [LanguagePreference.Vietnamese]:
      "Chăm sóc cho người khác, đặc biệt là trẻ nhỏ, có thể rất khó khăn. Chúng tôi thấy bạn đang đưa ra tất cả. Được thiết kế cùng với các chuyên gia và chuyên nghiệp, Reflectable ở đây để hỗ trợ bạn.",
  },
  [Identifiers.SecondSlideTitle]: {
    [LanguagePreference.English]: "Your steps, your choice",
    [LanguagePreference.Spanish]: "Tus pasos, tu elección.",
    [LanguagePreference.Vietnamese]: "Bước đi của bạn, lựa chọn của bạn.",
  },
  [Identifiers.SecondSlideParagraph]: {
    [LanguagePreference.English]:
      "When it comes to your learning, we believe in the wisdom within you. Use Reflectable as your personal stepping stones, to focus your own path.",
    [LanguagePreference.Spanish]:
      "Cuando se trata de tu aprendizaje, creemos en la sabiduría que tienes dentro. Utiliza Reflectable como tus propias piedras de paso personales, para enfocar tu propio camino.",
    [LanguagePreference.Vietnamese]:
      "Khi nói đến việc học của bạn, chúng tôi tin tưởng vào sự khôn ngoan bên trong bạn. Sử dụng Reflectable như những bước đệm cá nhân của bạn, để tập trung vào con đường riêng của mình.",
  },
  [Identifiers.ThirdSlideTitle]: {
    [LanguagePreference.English]: "Treat yourself to a pause",
    [LanguagePreference.Spanish]: "Date un respiro.",
    [LanguagePreference.Vietnamese]:
      "Hãy dành cho bản thân mình một khoảnh khắc dừng lại.",
  },
  [Identifiers.ThirdSlideParagraph]: {
    [LanguagePreference.English]:
      "Gift yourself a 10-minute break each week. Take care of you with mindfulness practice, so you can refill your tank as you help others.",
    [LanguagePreference.Spanish]:
      "Regálate un descanso de 10 minutos cada semana. Cuídate a ti mismo con la práctica de la atención plena, para que puedas llenar tu energía mientras ayudas a los demás.",
    [LanguagePreference.Vietnamese]:
      "Tặng cho bản thân mình một khoảnh khắc nghỉ ngơi 10 phút mỗi tuần. Chăm sóc bản thân bằng thực hành chánh niệm, để bạn có thể làm đầy năng lượng cho mình trong khi giúp đỡ người khác.",
  },
  [Identifiers.FourthSlideTitle]: {
    [LanguagePreference.English]: "See growth in real time",
    [LanguagePreference.Spanish]: "Observa el crecimiento en tiempo real.",
    [LanguagePreference.Vietnamese]: "Thấy sự phát triển trong thời gian thực.",
  },
  [Identifiers.FourthSlideParagraph]: {
    [LanguagePreference.English]:
      "As you dig deeper into the areas that matter most to you, we’ll be there to help you observe your own and others’ growth. We’ll celebrate with you.",
    [LanguagePreference.Spanish]:
      "A medida que profundices en las áreas que más te importan, estaremos aquí para ayudarte a observar tu propio crecimiento y el de los demás. ¡Celebraremos contigo!",
    [LanguagePreference.Vietnamese]:
      "Khi bạn đào sâu vào những lĩnh vực quan trọng nhất đối với bạn, chúng tôi sẽ ở đó để giúp bạn quan sát sự phát triển của chính bạn và của người khác. Chúng tôi sẽ kỷ niệm cùng bạn.",
  },
  [Identifiers.AboutTitle]: {
    [LanguagePreference.English]: "Tell us about yourself",
    [LanguagePreference.Spanish]: "Háblanos de ti",
    [LanguagePreference.Vietnamese]: "Kể về bản thân bạn",
  },
  [Identifiers.AboutParagraph]: {
    [LanguagePreference.English]: "Tell us about yourself",
    [LanguagePreference.Spanish]: "Háblanos de ti",
    [LanguagePreference.Vietnamese]: "Kể về bản thân bạn",
  },
  [Identifiers.FullName]: {
    [LanguagePreference.English]: "Full Name*",
    [LanguagePreference.Spanish]: "Nombre completo*",
    [LanguagePreference.Vietnamese]: "Họ và tên đầy đủ*",
  },
  [Identifiers.Required]: {
    [LanguagePreference.English]: "*Required",
    [LanguagePreference.Spanish]: "*Requerido",
    [LanguagePreference.Vietnamese]: "*Bắt buộc",
  },
  [Identifiers.Nickname]: {
    [LanguagePreference.English]: "Nickname*",
    [LanguagePreference.Spanish]: "Sobrenombre*",
    [LanguagePreference.Vietnamese]: "Bí danh*",
  },
  [Identifiers.LanguagePreference]: {
    [LanguagePreference.English]: "Language Preference*",
    [LanguagePreference.Spanish]: "Preferencia de idioma*",
    [LanguagePreference.Vietnamese]: "Ngôn ngữ ưa thích*",
  },
  [Identifiers.PhoneNumber]: {
    [LanguagePreference.English]: "Phone Number",
    [LanguagePreference.Spanish]: "Número de teléfono",
    [LanguagePreference.Vietnamese]: "Số điện thoại",
  },
  [Identifiers.SignUpTitle]: {
    [LanguagePreference.English]: "Last Step",
    [LanguagePreference.Spanish]: "Último Paso",
    [LanguagePreference.Vietnamese]: "Bước cuối cùng",
  },
  [Identifiers.SignUpParagraph]: {
    [LanguagePreference.English]:
      "Enter the email address and password you would like to use to log in.",
    [LanguagePreference.Spanish]:
      "Ingresa la dirección de correo electrónico y la contraseña que te gustaría usar para iniciar sesión.",
    [LanguagePreference.Vietnamese]:
      "Nhập địa chỉ email và mật khẩu mà bạn muốn sử dụng để đăng nhập.",
  },
  [Identifiers.Email]: {
    [LanguagePreference.English]: "Email Address*",
    [LanguagePreference.Spanish]: "Dirección de correo electrónico*",
    [LanguagePreference.Vietnamese]: "Địa chỉ email*",
  },
  [Identifiers.Password]: {
    [LanguagePreference.English]: "Password*",
    [LanguagePreference.Spanish]: "Contraseña*",
    [LanguagePreference.Vietnamese]: "Mật mã*",
  },
  [Identifiers.PasswordDefault]: {
    [LanguagePreference.English]: "Enter your password",
    [LanguagePreference.Spanish]: "Ingresa tu contraseña",
    [LanguagePreference.Vietnamese]: "Nhập mật khẩu của bạn",
  },
  [Identifiers.ConfirmPassword]: {
    [LanguagePreference.English]: "Confirm Password*",
    [LanguagePreference.Spanish]: "Confirmar Contraseña*",
    [LanguagePreference.Vietnamese]: "Xác nhận mật mã*",
  },
  [Identifiers.ConfirmPasswordDefault]: {
    [LanguagePreference.English]: "Enter your password again",
    [LanguagePreference.Spanish]: "Ingresa tu contraseña nuevamente",
    [LanguagePreference.Vietnamese]: "Nhập lại mật khẩu của bạn",
  },
  [Identifiers.TermsAndConditionsFirst]: {
    [LanguagePreference.English]: "I accept the ",
    [LanguagePreference.Spanish]: "Acepto los ",
    [LanguagePreference.Vietnamese]: "Tôi đồng ý với  ",
  },
  [Identifiers.TermsAndConditionsSecond]: {
    [LanguagePreference.English]: "Terms and Conditions",
    [LanguagePreference.Spanish]: "los Términos y Condiciones",
    [LanguagePreference.Vietnamese]: "tôi đồng ý với các",
  },
  [Identifiers.TermsAndConditionsThird]: {
    [LanguagePreference.English]: "for this site.",
    [LanguagePreference.Spanish]: "de este sitio",
    [LanguagePreference.Vietnamese]: "cho trang web này.",
  },
  [Identifiers.Intro]: {
    [LanguagePreference.English]: "INTRO",
    [LanguagePreference.Spanish]: "INTRO",
    [LanguagePreference.Vietnamese]: "GIỚI THIỆU",
  },
  [Identifiers.About]: {
    [LanguagePreference.English]: "ABOUT",
    [LanguagePreference.Spanish]: "ACERCA DE",
    [LanguagePreference.Vietnamese]: "VỀ CHÚNG TÔI",
  },
  [Identifiers.Curriculum]: {
    [LanguagePreference.English]: "CURRICULUM",
    [LanguagePreference.Spanish]: "CURRÍCULO",
    [LanguagePreference.Vietnamese]: "CHƯƠNG TRÌNH HỌC",
  },
  [Identifiers.SignUp]: {
    [LanguagePreference.English]: "SIGN UP",
    [LanguagePreference.Spanish]: "REGISTRARSE",
    [LanguagePreference.Vietnamese]: "ĐĂNG KÝ",
  },
  [Identifiers.CreateAccountButton]: {
    [LanguagePreference.English]: "Create Account",
    [LanguagePreference.Spanish]: "Crear Cuenta",
    [LanguagePreference.Vietnamese]: "Tạo Tài Khoản",
  },
  [Identifiers.CompleteButton]: {
    [LanguagePreference.English]: "Complete",
    [LanguagePreference.Spanish]: "Completar",
    [LanguagePreference.Vietnamese]: "Hoàn thành",
  },
  [Identifiers.ModalTitle]: {
    [LanguagePreference.English]: "Ready!",
    [LanguagePreference.Spanish]: "¡Listo!",
    [LanguagePreference.Vietnamese]: "Sẵn sàng!",
  },
  [Identifiers.ModalFirstParagraph]: {
    [LanguagePreference.English]: "Let's set your intention for the next week.",
    [LanguagePreference.Spanish]:
      "Establezcamos tu intención para la próxima semana.",
    [LanguagePreference.Vietnamese]: "Hãy đặt ý định của bạn cho tuần tới.",
  },
  [Identifiers.FullNameError]: {
    [LanguagePreference.English]: "Please provide your full name",
    [LanguagePreference.Spanish]: "Por favor, proporcione su nombre completo",
    [LanguagePreference.Vietnamese]:
      "Vui lòng cung cấp họ và tên đầy đủ của bạn",
  },
  [Identifiers.NicknameError]: {
    [LanguagePreference.English]: "Please provide your nickname",
    [LanguagePreference.Spanish]: "Por favor, proporciona tu apodo",
    [LanguagePreference.Vietnamese]: "Vui lòng cung cấp biệt danh của bạn",
  },
  [Identifiers.PhoneError]: {
    [LanguagePreference.English]:
      "Please enter a valid phone number. Phone number should contain no letters and no special characters.",
    [LanguagePreference.Spanish]:
      "Por favor, ingresa un número de teléfono válido. El número de teléfono no debe contener letras ni caracteres especiales.",
    [LanguagePreference.Vietnamese]:
      "Vui lòng nhập số điện thoại hợp lệ. Số điện thoại không nên chứa chữ cái và ký tự đặc biệt.",
  },
  [Identifiers.FullNameDefault]: {
    [LanguagePreference.English]: "Enter your full name",
    [LanguagePreference.Spanish]: "Ingresa tu nombre completo",
    [LanguagePreference.Vietnamese]: "Nhập họ và tên đầy đủ của bạn",
  },
  [Identifiers.NicknameDefault]: {
    [LanguagePreference.English]: "Enter your nickname",
    [LanguagePreference.Spanish]: "Ingresa tu apodo",
    [LanguagePreference.Vietnamese]: "Nhập biệt danh của bạn",
  },
  [Identifiers.PhoneNumberDefault]: {
    [LanguagePreference.English]: "Enter your phone number",
    [LanguagePreference.Spanish]: "Ingresa tu número de teléfono",
    [LanguagePreference.Vietnamese]: "Nhập số điện thoại của bạn",
  },
  [Identifiers.EmailDefault]: {
    [LanguagePreference.English]: "Enter your email address",
    [LanguagePreference.Spanish]: "Ingresa tu dirección de correo electrónico",
    [LanguagePreference.Vietnamese]: "Nhập địa chỉ email của bạn",
  },
  [Identifiers.PasswordLengthError]: {
    [LanguagePreference.English]: "Should have at least 8 characters",
    [LanguagePreference.Spanish]: "Debe tener al menos 8 caracteres",
    [LanguagePreference.Vietnamese]: "Nên có ít nhất 8 ký tự",
  },
  [Identifiers.PasswordDigitsError]: {
    [LanguagePreference.English]: "Should contain at least 1 number",
    [LanguagePreference.Spanish]: "Debe contener al menos 1 número",
    [LanguagePreference.Vietnamese]: "Nên chứa ít nhất 1 số",
  },
  [Identifiers.PasswordSpacesError]: {
    [LanguagePreference.English]: "Should contain no spaces",
    [LanguagePreference.Spanish]: "No debe contener espacios",
    [LanguagePreference.Vietnamese]: "Không nên chứa khoảng trắng",
  },
  [Identifiers.PasswordLettersError]: {
    [LanguagePreference.English]: "Should contain at least 1 letter",
    [LanguagePreference.Spanish]: "Debe contener al menos 1 letra",
    [LanguagePreference.Vietnamese]: "Nên chứa ít nhất 1 chữ cái",
  },
  [Identifiers.ConfirmPasswordError]: {
    [LanguagePreference.English]: "Passwords must match",
    [LanguagePreference.Spanish]: "Las contraseñas deben coincidir",
    [LanguagePreference.Vietnamese]: "Mật khẩu phải khớp",
  },
  [Identifiers.TermsAndConditionsAgreement]: {
    [LanguagePreference.English]: "Please agree to the Terms and Conditions",
    [LanguagePreference.Spanish]:
      "Por favor, acepta los Términos y Condiciones",
    [LanguagePreference.Vietnamese]:
      "Vui lòng đồng ý với Điều khoản và Điều kiện",
  },
  [Identifiers.Account]: {
    [LanguagePreference.English]: "Already have an account? ",
    [LanguagePreference.Spanish]: "¿Ya tienes una cuenta?",
    [LanguagePreference.Vietnamese]: "Bạn đã có tài khoản chưa?",
  },
  [Identifiers.Login]: {
    [LanguagePreference.English]: " Log In",
    [LanguagePreference.Spanish]: " Iniciar sesión",
    [LanguagePreference.Vietnamese]: " Đăng nhập",
  },
  [Identifiers.WelcomeSubtitle]: {
    [LanguagePreference.English]: "How should we address you?",
    [LanguagePreference.Spanish]: "¿Cómo debemos dirigirnos a usted?",
    [LanguagePreference.Vietnamese]: "Chúng tôi nên gọi bạn như thế nào?",
  },
};

export default translations;
