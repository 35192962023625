import { css, StyleSheet } from "aphrodite";
import React from "react";
import Text, { Font } from "../Text";
import Catch from "./FunctionalErrorBoundary";

type Props = {
  children: React.ReactNode;
};

const MyErrorBoundary = Catch(function MyErrorBoundary(
  props: Props,
  error?: Error
) {
  if (error) {
    return (
      <div className={css(styles.ErrorBoundary)}>
        <Text style={styles.errorOccured} font={Font.ProximaNovaBold}>
          An error has occured
        </Text>
        <Text style={styles.errorMessage} font={Font.ProximaNovaSemibold}>
          {error.message}
        </Text>
      </div>
    );
  }
  return <>{props.children}</>;
});

export default MyErrorBoundary;

const styles = StyleSheet.create({
  ErrorBoundary: {},
  errorOccured: {
    fontSize: 20,
  },
  errorMessage: {
    fontSize: 16,
  },
  //
});
