import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import HamburgerMenu from "shared/components/HamburgerMenu/HamburgerMenu";
import Text, { Font } from "shared/components/Text";
import { turquoise } from "shared/styles/colors";
import { StyleSheet } from "aphrodite";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { LanguagePreference } from "shared/types";
import LoginInfoForm from "./components/LoginInfoForm";
import PersonalInfoForm from "./components/PersonalInfoForm";
import LanguagePreferencesForm from "./components/LanguagePreferencesForm";
import Loader from "shared/components/Loader";
import { TranslationService } from "services/Translation/Translation.Service";
import { Identifiers, Page } from "services/Translation/enums";
import { useDocumentTitle } from "shared/hooks";

const USER_DATA = gql`
  query CurrentCurriculumLanguages {
    currentCurriculumLanguages
    currentUser {
      id
      languagePreference
      email
      nickname
      fullName
      phoneNumber
      inviteId
    }
  }
`;

const Settings = () => {
  useDocumentTitle("Settings | Reflectable");
  const { data, loading, error } = useQuery(USER_DATA, {
    fetchPolicy: "no-cache",
  });

  const [currentLanguage, setCurrentLanguage] = useState<LanguagePreference>(
    LanguagePreference.English
  );

  useEffect(() => {
    if (data) {
      setCurrentLanguage(data.currentUser?.languagePreference);
    }
  }, [data]);
  const userLanguage = data?.currentUser?.languagePreference ?? "ENGLISH";

  let content = <></>;
  if (loading || error) {
    content = <Loader />;
  }
  if (data) {
    const {
      email,
      nickname,
      fullName,
      phoneNumber,
      languagePreference,
      inviteId,
    } = data.currentUser;
    content = (
      <>
        <LoginInfoForm
          email={email}
          userLanguage={languagePreference}
          inviteId={inviteId}
        />

        <PersonalInfoForm
          nickname={nickname}
          fullName={fullName}
          phoneNumber={phoneNumber}
          userLanguage={languagePreference}
        />

        <LanguagePreferencesForm
          setCurrentLanguage={setCurrentLanguage}
          currentLanguage={currentLanguage}
        />
      </>
    );
  }
  return (
    <>
      <HamburgerMenu logoIsVisible />
      <SettingsContainer>
        <InnerSettingsContainer>
          <Text
            color={turquoise}
            font={Font.ProximaNovaBold}
            style={styles.headerText}
          >
            {TranslationService.getStaticText({
              page: Page.Settings,
              userLanguage,
              textIdentifier: Identifiers.Title,
            })}
          </Text>
          {content}
        </InnerSettingsContainer>
      </SettingsContainer>
    </>
  );
};

export default Settings;

const styles = StyleSheet.create({
  headerText: {
    marginBottom: 21,
    fontSize: 32,
    lineHeight: 1.2,
  },
});

const SettingsContainer = styled.div({
  padding: "95px 15px 46px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

const InnerSettingsContainer = styled.div({
  maxWidth: 630,
  width: "100%",
});
