import React, { useState, useEffect } from "react";
import { css, StyleSheet } from "aphrodite";
import { buttonBlue, lightGrey, mainGrey } from "shared/styles/colors";
import AnswerButton from "screens/QuestionPage/AnswerButton/AnswerButton";
import Text, { Font } from "../../../shared/components/Text";
import { TranslationService } from "services/Translation/Translation.Service";
import { Identifiers, Page } from "services/Translation/enums";
import { LanguagePreference } from "shared/types";

interface Props {
  questionText: string;
  answersArray: Record<string, any>[]; // TODO Define Type
  index: number;
  values: any;
  id: string;
  withOther: boolean;
  userLanguage: LanguagePreference;
}

const SingleSelect = ({
  answersArray,
  values,
  id,
  withOther,
  userLanguage,
}: Props) => {
  const [selectedAnswer, setSelectedAnswer] = useState("");
  const [inputState, setInputState] = useState("");
  const onAnswerClick = (answerId: string) => {
    setSelectedAnswer(answerId);
    if (withOther) {
      values[id].answer = answerId;
      values[id].submittingWithOther = false;
    } else {
      values[id] = answerId;
    }
  };

  const onChange = (event) => {
    // the answer button will toggle its own state
    setInputState(event.target.value);
    values[id].answer = event.target.value;
    values[id].submittingWithOther = true;
  };

  useEffect(() => {
    if (withOther && values[id]) {
      if (values[id]?.submittingWithOther) {
        setInputState(values[id]?.answer);
      } else if (values[id]?.answer !== inputState) {
        setInputState("");
      }
    }
  }, [values[id]?.answer]);

  return (
    <div className={css(styles.SingleSelect)}>
      {answersArray?.map((answer, idx) => (
        <AnswerButton
          isSingleSelect
          onClick={onAnswerClick}
          key={idx}
          answer={answer.translationData.text}
          singleSelectedAnswer={selectedAnswer}
          answerValue={values[id]}
          answerId={answer.id}
          withOther={withOther}
        />
      ))}
      {withOther && (
        <>
          <Text font={Font.ProximaNovaRegular} style={styles.otherText}>
            {TranslationService.getStaticText({
              page: Page.Shared,
              userLanguage,
              textIdentifier: Identifiers.Other,
            })}
          </Text>
          <textarea
            className={css(styles.input)}
            placeholder={TranslationService.getStaticText({
              page: Page.QuestionPage,
              userLanguage,
              textIdentifier: Identifiers.PleaseSpecify,
            })}
            value={inputState}
            onChange={onChange}
          />
        </>
      )}
    </div>
  );
};

SingleSelect.defaultProps = {
  withOther: false,
};

export default SingleSelect;

const styles = StyleSheet.create({
  SingleSelect: {
    width: "100%",
    height: "100%",
  },
  innerContainer: {
    width: "100%",
  },
  answerButton: {
    width: "100%",
    height: 42,
    borderRadius: 8,
    border: `1px solid ${buttonBlue}`,
    padding: "11px 20px 12px 20px",
  },
  answerButtonText: {
    width: 275,
    fontSize: 16,
    color: lightGrey,
    lineHeight: 1.2,
  },
  otherText: {
    marginBottom: 5,
    fontSize: 16,
    color: mainGrey,
  },
  input: {
    width: "100%",
    height: 126,
    marginBottom: 10,
    border: `1px solid ${buttonBlue}`,
    borderRadius: 8,
    fontSize: 16,
    color: lightGrey,
    display: "flex",
    padding: "11px 20px",
    resize: "none",
  },
});
