import React from "react";
import { css, StyleSheet } from "aphrodite";
import Text, { Font } from "shared/components/Text";
import { mainGrey, mainJade } from "shared/styles/colors";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import gql from "graphql-tag";
import ExportData from "./ExportData";
import UploadCurriculum from "./UploadCurriculum";
import UploadUsers from "./UploadUsers";
import { useQuery } from "@apollo/react-hooks";
import { Redirect } from "react-router-dom";
import GraphqlErrorScreen from "shared/components/ErrorBoundary/GraphqlErrorScreen";
import UploadLateUsers from "./UploadLateUsers";
import ExternalLinkIcon from "static/svgs/ExternalLink";

const ADMIN_QUERY = gql`
  query AdminQuery {
    isAdmin
  }
`;

const Upload = () => {
  const { data, loading, error } = useQuery(ADMIN_QUERY, {
    fetchPolicy: "no-cache",
  });

  const stagingLink =
    "http://qc-pr-publi-34py0o68768i-1143509416.us-west-2.elb.amazonaws.com/_admin";
  const productionLink = "https://database-admin.reflectable.net/_admin";
  if (loading) {
    return null;
  }

  if (error) {
    return <GraphqlErrorScreen error={error} />;
  }

  if (data.isAdmin) {
    return (
      <div className={css(styles.AdminScreen)}>
        <div className={css(styles.Upload)}>
          <a
            className={css(styles.databaseAdminLinkContainer)}
            target="_blank"
            rel="noopener noreferrer"
            href={stagingLink}
          >
            <Text style={styles.databaseAdminLinkText}>
              Staging Database Admin
            </Text>
            <ExternalLinkIcon link={stagingLink} />
          </a>
          <a
            className={css(
              styles.databaseAdminLinkContainer,
              styles.databaseAdminLinkContainerTwo
            )}
            target="_blank"
            rel="noopener noreferrer"
            href={productionLink}
          >
            <Text style={styles.databaseAdminLinkText}>
              Production Database Admin
            </Text>
            <ExternalLinkIcon link={productionLink} />
          </a>
          <Text font={Font.ProximaNovaBold} style={styles.title}>
            Content Admin
          </Text>
          <div className={css(styles.actionsContainer)}>
            <Tabs className={css(styles.tabs)}>
              <TabList>
                <Tab className={`${css(styles.tab)} react-tabs__tab`}>
                  Curriculum
                </Tab>
                <Tab className={`${css(styles.tab)} react-tabs__tab`}>
                  Users
                </Tab>
                <Tab className={`${css(styles.tab)} react-tabs__tab`}>
                  Late Users
                </Tab>
                <Tab className={`${css(styles.tab)} react-tabs__tab`}>
                  Export Data
                </Tab>
              </TabList>

              <TabPanel>
                <UploadCurriculum />
              </TabPanel>
              <TabPanel>
                <UploadUsers />
              </TabPanel>
              <TabPanel>
                <UploadLateUsers />
              </TabPanel>
              <TabPanel>
                <ExportData />
              </TabPanel>
            </Tabs>
          </div>
        </div>
      </div>
    );
  }
  return <Redirect to="/" />;
};

const styles = StyleSheet.create({
  AdminScreen: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    paddingTop: 40,
  },
  Upload: {
    paddingTop: 20,
    maxWidth: 1100,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    alignSelf: "center",
  },
  //
  title: {
    fontSize: 40,
    marginBottom: 45,
    color: mainJade,
  },
  actionsContainer: {
    width: 900,
    padding: "24px",
    borderRadius: 8,
  },
  tabs: {
    width: "100%",
  },
  tab: {
    fontSize: 20,
  },
  databaseAdminLinkContainer: {
    position: "absolute",
    display: "flex",
    alignItems: "center",
    top: 32,
    right: 32,
    cursor: "pointer",
    paddingBottom: 6,
    borderBottom: `1px solid ${mainGrey}`,
  },
  databaseAdminLinkText: {
    fontSize: 20,
    color: mainGrey,
    marginRight: 8,
  },
  databaseAdminLinkContainerTwo: {
    right: 332,
  },
});

export default Upload;
