import { StyleSheet } from "aphrodite";
import React from "react";
import Card from "shared/components/Card";
import Text, { Font } from "shared/components/Text";
import { turquoise } from "shared/styles/colors";

interface Props {
  title: string;
  children;
}

const ResourcesCard = ({ title, children }: Props) => {
  return (
    <Card cardStyle={styles.cardStyle} contentStyle={styles.contentStyle}>
      <Text style={styles.title} color={turquoise} font={Font.ProximaNovaBold}>
        {title}
      </Text>
      {children}
    </Card>
  );
};

export default ResourcesCard;

const styles = StyleSheet.create({
  cardStyle: {
    marginBottom: 19,
  },
  contentStyle: {
    paddingTop: 20,
    paddingBottom: 21,
    padding: "20px 19px 10px",
    display: "flex",
    flexDirection: "column",
  },
  title: {
    fontSize: 16,
    lineHeight: 1.2,
    textTransform: "uppercase",
    marginBottom: 10,
  },
});
