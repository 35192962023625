import { css, StyleSheet } from "aphrodite";
import React from "react";
import { greyAccent, white } from "shared/styles/colors";
import {
  hoverStateDropShadow,
  standardDropShadow,
  transitionTime,
} from "shared/styles/constants";

interface Props {
  accentColor: string;
  children: React.ReactNode;
  cardStyle: object; // eslint-disable-line @typescript-eslint/ban-types
  contentStyle: object; // eslint-disable-line @typescript-eslint/ban-types
  onClick?: () => void;
}

const Card = ({
  accentColor,
  children,
  cardStyle,
  contentStyle,
  onClick,
}: Props) => {
  if (onClick) {
    return (
      <button
        className={css(styles.Card, cardStyle, styles.hoverableCard)}
        onClick={onClick}
      >
        <div
          className={css(styles.topAccent)}
          style={{ backgroundColor: accentColor }}
        />
        <div className={css(contentStyle)}>{children}</div>
      </button>
    );
  }
  return (
    <section className={css(styles.Card, cardStyle)}>
      <div
        className={css(styles.topAccent)}
        style={{ backgroundColor: accentColor }}
      />
      <div className={css(contentStyle)}>{children}</div>
    </section>
  );
};

Card.defaultProps = {
  accentColor: greyAccent,
  cardStyle: {},
  contentStyle: {},
} as Partial<Props>;

export default Card;

const styles = StyleSheet.create({
  Card: {
    borderRadius: 10,
    boxShadow: standardDropShadow,
    overflow: "hidden",
    background: white,
    padding: 0,
    transition: `${transitionTime} box-shadow`,
  },
  //
  topAccent: {
    height: 10,
    width: "100%",
  },
  hoverableCard: {
    ":hover": {
      boxShadow: hoverStateDropShadow,
    },
  },
});
