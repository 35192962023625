import React, { useEffect, useState } from "react";
import { StyleSheet } from "aphrodite";
import styled from "@emotion/styled";
import Text, { Font } from "../Text";
import { mainGrey, teal, white } from "shared/styles/colors";
import CheckmarkIcon from "../../../static/svgs/CheckmarkIcon";
import LockedIcon from "../../../static/svgs/LockedIcon";
import { motion, useAnimation } from "framer-motion/dist/framer-motion";
import SwiperCore from "swiper";
import {
  gridTopicTransition,
  topicGridTransition,
} from "shared/styles/animations";
import { format } from "date-fns";

interface Props {
  selectedTopicIndex: number;
  topics: any[];
  handleTopicSelect: (index: number) => void;
  swiperRef: React.MutableRefObject<SwiperCore | null>;
  isTopicChosen: boolean;
}

const TopicGrid = ({
  selectedTopicIndex,
  topics,
  handleTopicSelect,
  swiperRef,
  isTopicChosen,
}: Props) => {
  const [wrapperWidth, setWrapperWidth] = useState<number>(0);
  const [swiperTranslate, setSwiperTranslate] = useState<string | null>(null);
  const topicGridControls = useAnimation();

  swiperRef.current?.on("slideChange", (swiper) => {
    setGridStyleValues(swiper);
  });

  swiperRef.current?.on("observerUpdate", (swiper) => {
    setGridStyleValues(swiper);
  });

  const setGridStyleValues = (swiper: SwiperCore) => {
    const swiperWrapper = swiper.$wrapperEl[0] as HTMLElement;

    setSwiperTranslate(swiperWrapper.style.transform);
    setWrapperWidth(swiperWrapper.scrollWidth);
  };

  useEffect(() => {
    if (swiperRef.current) {
      setGridStyleValues(swiperRef.current);
    }
  }, [swiperRef]);

  useEffect(() => {
    if (isTopicChosen) {
      topicGridControls.start(topicGridTransition["fadeOut"]);
    }
  }, [isTopicChosen]);

  return (
    <Grid
      initial={{ opacity: 1 }}
      variants={topicGridTransition}
      animate={topicGridControls}
      isTopicChosen={isTopicChosen}
      width={`${wrapperWidth}px`}
      translate={swiperTranslate}
    >
      {topics?.map(
        (
          {
            translationData,
            id,
            color,
            isUnlocked,
            numberOfReflectionsToUnlock,
            numberOfTimesPracticed,
            forcedUnlockDate,
          },
          index
        ) => {
          const { name } = translationData;

          const isSelected = selectedTopicIndex === index;
          const topicPracticed = numberOfTimesPracticed > 0;
          const showTopicMessage = !isUnlocked || topicPracticed;
          let timesPracticedCopy = `Practiced ${numberOfTimesPracticed} time`;
          timesPracticedCopy =
            numberOfTimesPracticed > 1
              ? `${timesPracticedCopy}s`
              : timesPracticedCopy;

          return (
            <Topic
              layout
              variants={gridTopicTransition}
              transition={gridTopicTransition.spring}
              layoutId={`topic-${id}`}
              key={`topic-${id}`}
              backgroundColor={color}
              selected={isSelected}
              isLocked={!isUnlocked}
              showTopicMessage={showTopicMessage}
              disabled={!isUnlocked}
              onClick={() => handleTopicSelect(index)}
            >
              <IconContainer>
                {isSelected && <CheckmarkIcon />}
                {!isUnlocked && <LockedIcon />}
              </IconContainer>
              <Text font={Font.ProximaNovaSemibold} style={styles.topicText}>
                {name}
              </Text>
              {showTopicMessage && (
                <TopicMessage>
                  <Text
                    font={Font.ProximaNovaSemibold}
                    style={styles.topicMessage}
                  >
                    {!isUnlocked && !forcedUnlockDate
                      ? `Unlock after ${numberOfReflectionsToUnlock} Reflections`
                      : forcedUnlockDate &&
                        `Unlock after ${format(
                          new Date(forcedUnlockDate),
                          "M/d/y"
                        )}`}
                    {topicPracticed && timesPracticedCopy}
                  </Text>
                </TopicMessage>
              )}
            </Topic>
          );
        }
      )}
    </Grid>
  );
};

export default TopicGrid;

const Grid = styled(motion.div)<{
  isTopicChosen: boolean;
  width: string;
  translate?: string;
}>(({ isTopicChosen, width, translate }) => ({
  display: "grid",
  ...(isTopicChosen && { width }),
  gridColumnGap: isTopicChosen ? 12 : 20,
  gridRowGap: 24,
  gridAutoRows: "1fr",
  padding: isTopicChosen ? 0 : "0 16px",
  position: isTopicChosen ? "absolute" : "static",
  gridTemplateColumns: isTopicChosen ? "minmax(0, 1fr)" : "repeat(2, 1fr)",
  ...(isTopicChosen && translate && { transform: translate }),
  ...(isTopicChosen && { gridAutoColumns: "1fr" }),
  ...(isTopicChosen && { gridAutoFlow: "column" }),
  "@media (min-width: 700px)": {
    padding: isTopicChosen ? "65px 0 0" : "0 16px",
  },
}));

const Topic = styled(motion.button)<{
  backgroundColor: string;
  selected: boolean;
  isLocked: boolean;
  showTopicMessage: boolean;
}>(({ backgroundColor, selected, isLocked, showTopicMessage }) => ({
  backgroundColor,
  borderRadius: 10,
  position: "relative",
  border: selected ? `3px solid ${teal}` : "3px solid transparent",
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  cursor: isLocked ? "not-allowed" : "pointer",
  padding: showTopicMessage ? "58px 12px 13px" : "44px 12px 44px",
}));

const IconContainer = styled.div({
  position: "absolute",
  height: 26,
  width: 26,
  top: 8,
  left: 10,
});

const TopicMessage = styled.div({
  backgroundColor: white,
  padding: "6px 0",
  marginTop: 21,
  width: "100%",
});

const styles = StyleSheet.create({
  topicText: {
    fontSize: 14,
    color: mainGrey,
    lineHeight: 1.2,
  },
  topicMessage: {
    color: teal,
    fontSize: 9,
    lineHeight: 1.2,
  },
});
