import { TranslationService } from "services/Translation/Translation.Service";
import { Identifiers, Page } from "services/Translation/enums";
import { LanguagePreference } from "shared/types";
import {
  DahliaOne,
  DahliaTwo,
  DahliaThree,
  DahliaFour,
  PoppyOne,
  PoppyTwo,
  PoppyThree,
  PoppyFour,
  SunflowerOne,
  SunflowerTwo,
  SunflowerThree,
  SunflowerFour,
  TulipOne,
  TulipTwo,
  TulipThree,
  TulipFour,
} from "static/images/flowers";

const getTopicFlowerByTimesCompleted = ({
  timesCompleted,
  userLanguage,
  imageOne,
  imageTwo,
  imageThree,
  imageFour,
}: {
  timesCompleted: number;
  userLanguage: LanguagePreference;
  imageOne: string;
  imageTwo: string;
  imageThree: string;
  imageFour: string;
}) => {
  const page = Page.TopicProgress;
  const options = {
    page,
    userLanguage,
  };
  switch (timesCompleted) {
    case 0:
      return {
        imgSrc: imageOne,
        copy: TranslationService.getStaticText({
          ...options,
          textIdentifier: Identifiers.TopicProgressOne,
        }),
      };
    case 1:
      return {
        imgSrc: imageTwo,
        copy: TranslationService.getStaticText({
          ...options,
          textIdentifier: Identifiers.TopicProgressTwo,
        }),
      };
    case 2:
      return {
        imgSrc: imageThree,
        copy: TranslationService.getStaticText({
          ...options,
          textIdentifier: Identifiers.TopicProgressThree,
        }),
      };
    default:
      return {
        imgSrc: imageFour,
        copy: TranslationService.getStaticText({
          ...options,
          textIdentifier: Identifiers.TopicProgressFour,
        }),
      };
  }
};

export function getTopicProgressFlower({
  topicOrder,
  timesCompleted,
  userLanguage,
}: {
  topicOrder: number;
  timesCompleted: number;
  userLanguage: LanguagePreference;
}) {
  let returnObject = { imgSrc: "", copy: "" };
  // Divide by 4 here since there are only 4 flowers to use
  const topicOrderNormalzed = topicOrder % 4;
  const options = { timesCompleted, userLanguage };
  switch (topicOrderNormalzed) {
    case 0:
      returnObject = getTopicFlowerByTimesCompleted({
        imageOne: TulipOne,
        imageTwo: TulipTwo,
        imageThree: TulipThree,
        imageFour: TulipFour,
        ...options,
      });
      break;
    case 1:
      returnObject = getTopicFlowerByTimesCompleted({
        imageOne: SunflowerOne,
        imageTwo: SunflowerTwo,
        imageThree: SunflowerThree,
        imageFour: SunflowerFour,
        ...options,
      });
      break;
    case 2:
      returnObject = getTopicFlowerByTimesCompleted({
        imageOne: PoppyOne,
        imageTwo: PoppyTwo,
        imageThree: PoppyThree,
        imageFour: PoppyFour,
        ...options,
      });
      break;
    case 3:
      returnObject = getTopicFlowerByTimesCompleted({
        imageOne: DahliaOne,
        imageTwo: DahliaTwo,
        imageThree: DahliaThree,
        imageFour: DahliaFour,
        ...options,
      });
      break;
    default:
      returnObject = {
        imgSrc: "",
        copy: "",
      };
  }
  return returnObject;
}
