import React from "react";
import { css, StyleSheet } from "aphrodite";
import Text, { Font } from "shared/components/Text";
import { redOrange, mainGrey } from "shared/styles/colors";

interface Props {
  error: string;
}

const ErrorMessage: React.FC<Props> = ({ error }: Props) => {
  return (
    <div className={css(styles.errorMessageContainer)}>
      <div className={css(styles.bulletPoint)}></div>
      <Text font={Font.ProximaNovaRegular} style={styles.errorMessage}>
        {error}
      </Text>
    </div>
  );
};

export default ErrorMessage;

const styles = StyleSheet.create({
  errorMessageContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: "5px",
  },
  bulletPoint: {
    height: "5px",
    width: "5px",
    background: redOrange,
    borderRadius: "50%",
    marginRight: "5px",
  },
  errorMessage: {
    fontSize: "12px",
    lineHeight: "1.21",
    color: mainGrey,
  },
});
