import { Identifiers } from "../enums";
import { Translation } from "../Translation.Service";
import { LanguagePreference } from "../../../shared/types";

const translations: Translation = {
  [Identifiers.Title]: {
    [LanguagePreference.English]: "Keep breathing",
    [LanguagePreference.Spanish]: "Sigue respirando",
    [LanguagePreference.Vietnamese]: "Hãy tiếp tục thở",
  },
  [Identifiers.Body]: {
    [LanguagePreference.English]:
      "You’re ready to focus your own learning for this coming week.",
    [LanguagePreference.Spanish]:
      "Estás listo para enfocar tu propio aprendizaje para la próxima semana.",
    [LanguagePreference.Vietnamese]:
      "Bạn đã sẵn sàng tập trung vào việc học của mình trong tuần tới.",
  },
  [Identifiers.ContinueButton]: {
    [LanguagePreference.English]: "Continue",
    [LanguagePreference.Spanish]: "Continuar",
    [LanguagePreference.Vietnamese]: "Tiếp tục",
  },
};

export default translations;
