import { Identifiers } from "../enums";
import { Translation } from "../Translation.Service";
import { LanguagePreference } from "../../../shared/types";

const reflectionEndTranslations: Translation = {
  [Identifiers.Title]: {
    [LanguagePreference.English]: "Nicely done",
    [LanguagePreference.Spanish]: "¡Bien hecho",
    [LanguagePreference.Vietnamese]: "Xuất Sắc",
  },
  [Identifiers.Body]: {
    [LanguagePreference.English]:
      "You've completed your reflection and you're ready for next week.",
    [LanguagePreference.Spanish]:
      "Ha completado la reflexión y esa listo/a para la próxima semana.",
    [LanguagePreference.Vietnamese]:
      "Bạn Đã Hoàn Thành Suy Ngẫm Của Mình Và Sẵn Sàng Cho Tuần Tới.",
  },
  [Identifiers.TopicSelect]: {
    [LanguagePreference.English]: "Topic Selected",
    [LanguagePreference.Spanish]: "Tema seleccionado",
    [LanguagePreference.Vietnamese]: "Chủ đề đã chọn",
  },
  [Identifiers.FocusSelect]: {
    [LanguagePreference.English]: "Focus Selected",
    [LanguagePreference.Spanish]: "Enfoque seleccionado",
    [LanguagePreference.Vietnamese]: "Tiêu điểm đã chọn",
  },
  [Identifiers.BodyOne]: {
    [LanguagePreference.English]:
      "Let's head to the dashboard to take a look at your progress.",
    [LanguagePreference.Spanish]:
      "Vamos al tablero de control para ver su progreso.",
    [LanguagePreference.Vietnamese]:
      "Hãy đi đến Trang tổng quan để xem sự tiến bộ của bạn.",
  },
  [Identifiers.ContinueButton]: {
    [LanguagePreference.English]: "Go to Dashboard",
    [LanguagePreference.Spanish]: "Ir al tablero de control",
    [LanguagePreference.Vietnamese]: "Đi đến Trang tổng quan",
  },
};

export default reflectionEndTranslations;
