import React, { useState, useEffect } from "react";
import Text, { Font } from "shared/components/Text";
import { css, StyleSheet } from "aphrodite";
import { buttonBlue, lightGrey, white } from "shared/styles/colors";

interface Props {
  answer: string;
  onClick: (value: string) => void;
  isSingleSelect: boolean;
  singleSelectedAnswer?: string;
  answerValue?: string | any;
  multipleAnswerValues?: string[];
  answerId: string;
  withOther: boolean;
}

const AnswerButton = ({
  answer,
  onClick,
  isSingleSelect,
  singleSelectedAnswer,
  answerValue,
  multipleAnswerValues,
  answerId,
  withOther,
}: Props) => {
  const [isSelected, setIsSelected] = useState<boolean>(false);
  useEffect(() => {
    if (isSingleSelect && withOther) {
      setIsSelected(answerValue?.answer === answerId);
    } else if (isSingleSelect) {
      setIsSelected(answerValue === answerId);
    } else if (multipleAnswerValues) {
      // check if the user has input an "other" answer
      if (typeof multipleAnswerValues === "string") {
        setIsSelected(false);
      } else {
        setIsSelected(multipleAnswerValues.includes(answerId));
      }
    }
  }, [
    singleSelectedAnswer,
    answerValue,
    multipleAnswerValues,
    answerValue?.answer,
  ]);

  return (
    <button
      type="button"
      className={
        isSelected
          ? css(styles.AnswerButton, styles.AnswerButtonSelected)
          : css(styles.AnswerButton)
      }
      onClick={() => onClick(answerId)}
    >
      <Text
        font={isSelected ? Font.ProximaNovaBold : Font.ProximaNovaRegular}
        style={
          isSelected ? styles.answerButtonTextSelected : styles.answerButtonText
        }
      >
        {answer}
      </Text>
    </button>
  );
};

export default AnswerButton;

AnswerButton.defaultProps = {
  isSingleSelect: false,
  withOther: false,
};

const styles = StyleSheet.create({
  AnswerButton: {
    width: 315,
    minHeight: 42,
    borderRadius: 8,
    border: `1px solid ${buttonBlue}`,
    padding: "11px 20px 12px 20px",
    marginBottom: 10,
    backgroundColor: white,
    color: lightGrey,
    textAlign: "left",
    "@media (min-width: 630px)": {
      maxWidth: 510,
      width: "100%",
      ":hover": {
        cursor: "pointer",
        backgroundColor: buttonBlue,
        color: white,
      },
    },
  },
  AnswerButtonSelected: {
    backgroundColor: buttonBlue,
    color: white,
  },
  answerButtonText: {
    width: 275,
    fontSize: 16,
    lineHeight: 1.2,
    "@media (min-width: 630px)": {
      width: "100%",
    },
  },
  answerButtonTextSelected: {
    width: 275,
    fontSize: 16,
    lineHeight: 1.2,
    "@media (min-width: 630px)": {
      width: "100%",
    },
  },
});
