import { Identifiers } from "../enums";
import { Translation } from "../Translation.Service";
import { LanguagePreference } from "../../../shared/types";

const resourceTranslations: Translation = {
  [Identifiers.Title]: {
    [LanguagePreference.English]: "Resources",
    [LanguagePreference.Spanish]: "Recursos",
    [LanguagePreference.Vietnamese]: "Tài nguyên",
  },
  [Identifiers.SubTitle]: {
    [LanguagePreference.English]: "Visit our resources for additional support",
    [LanguagePreference.Spanish]:
      "Visite nuestros recursos para obtener asistencia adicional",
    [LanguagePreference.Vietnamese]:
      "Truy cập tài nguyên của chúng tôi để được hỗ trợ thêm",
  },
  [Identifiers.Websites]: {
    [LanguagePreference.English]: "Websites",
    [LanguagePreference.Spanish]: "Sitios web",
    [LanguagePreference.Vietnamese]: "Trang web",
  },
  [Identifiers.Articles]: {
    [LanguagePreference.English]: "Articles",
    [LanguagePreference.Spanish]: "Artículos",
    [LanguagePreference.Vietnamese]: "Bài viết",
  },
  [Identifiers.Videos]: {
    [LanguagePreference.English]: "Videos",
    [LanguagePreference.Spanish]: "Videos",
    [LanguagePreference.Vietnamese]: "Video",
  },
};

export default resourceTranslations;
