import React from "react";

const WhiteHomeIcon = () => {
  return (
    <svg
      width="17"
      height="19"
      viewBox="0 0 17 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Home</title>
      <path d="M10.475 11.1816H6.74651V17.7064H10.475V11.1816Z" fill="white" />
      <path
        d="M0.762308 7.62085L7.94898 0.27572C8.03563 0.188354 8.13872 0.119009 8.25231 0.0716867C8.3659 0.0243641 8.48773 0 8.61078 0C8.73384 0 8.85567 0.0243641 8.96926 0.0716867C9.08285 0.119009 9.18594 0.188354 9.27259 0.27572L16.4593 7.63017C16.6332 7.80547 16.7705 8.01358 16.8633 8.2424C16.9561 8.47122 17.0025 8.7162 16.9999 8.96311V16.7743C17.0006 17.2515 16.8183 17.7107 16.4906 18.0576C16.1629 18.4044 15.7146 18.6123 15.2382 18.6385H12.3393V10.2494C12.3393 10.0022 12.2411 9.76513 12.0663 9.59033C11.8915 9.41552 11.6544 9.31731 11.4072 9.31731H5.81441C5.5672 9.31731 5.33011 9.41552 5.1553 9.59033C4.9805 9.76513 4.88229 10.0022 4.88229 10.2494V18.6385H1.98339C1.50694 18.6123 1.0587 18.4044 0.730966 18.0576C0.40323 17.7107 0.220955 17.2515 0.221677 16.7743V8.96311C0.220938 8.4625 0.414796 7.98119 0.762308 7.62085V7.62085Z"
        fill="white"
      />
    </svg>
  );
};

export default WhiteHomeIcon;
