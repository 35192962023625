import { StyleSheet } from "aphrodite";
import React from "react";
import { TranslationService } from "services/Translation/Translation.Service";
import { Identifiers, Page } from "services/Translation/enums";
import Card from "shared/components/Card";
import Text, { Font } from "shared/components/Text";
import { mainGrey, turquoise } from "shared/styles/colors";
import { LanguagePreference } from "shared/types";

interface Props {
  totalComplete: number;
  userLanguage: LanguagePreference;
  totalReflections: number;
}

const NumberOfRelfectionsCompletedCard = ({
  totalComplete,
  userLanguage,
  totalReflections,
}: Props) => {
  const page = Page.Dashboard;
  return (
    <Card cardStyle={styles.cardStyle} contentStyle={styles.cardContentStyle}>
      <Text font={Font.ProximaNovaBold} style={styles.cardTitle}>
        {TranslationService.getStaticText({
          page,
          userLanguage,
          textIdentifier: Identifiers.Reflection,
        })}
      </Text>
      <Text font={Font.ProximaNovaRegular} style={styles.cardDescription}>
        {totalComplete}{" "}
        {TranslationService.getStaticText({
          page,
          userLanguage,
          textIdentifier: Identifiers.OutOf,
        })}{" "}
        {totalReflections}
      </Text>
    </Card>
  );
};

export default NumberOfRelfectionsCompletedCard;

const styles = StyleSheet.create({
  cardTitle: {
    fontSize: 12,
    color: turquoise,
    letterSpacing: 2,
    lineHeight: 1.2,
    textTransform: "uppercase",
    marginBottom: 5,
    paddingTop: 20,
    paddingLeft: 15,
    paddingRight: 20,
  },
  cardDescription: {
    color: mainGrey,
    fontSize: 16,
    lineHeight: 1.2,
    marginBottom: 20,
    paddingLeft: 15,
    paddingRight: 20,
  },
  cardStyle: {
    marginBottom: 30,
    width: "100%",
    "@media (min-width: 630px)": {
      maxWidth: 630,
    },
  },
  cardContentStyle: {
    textAlign: "center",
  },
});
