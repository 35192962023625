import { Identifiers } from "../enums";
import { LanguagePreference } from "../../../shared/types";
import { Translation } from "../Translation.Service";

const translations: Translation = {
  [Identifiers.Title]: {
    [LanguagePreference.English]: "It’s easy as 1-2-3",
    [LanguagePreference.Spanish]: "Es fácil como 1-2-3",
    [LanguagePreference.Vietnamese]: "Dễ như 1-2-3",
  },
  [Identifiers.StepOne]: {
    [LanguagePreference.English]:
      "Choose a topic and an associated concrete action.",
    [LanguagePreference.Spanish]:
      "Elegir un tema y una acción concreta asociada.",
    [LanguagePreference.Vietnamese]:
      "Chọn một chủ đề và một hành động cụ thể liên quan.",
  },
  [Identifiers.StepTwo]: {
    [LanguagePreference.English]:
      "Practice the action for a week - just one, let’s keep it simple.",
    [LanguagePreference.Spanish]:
      "Practica la acción durante una semana, solo una, mantengámoslo simple.",
    [LanguagePreference.Vietnamese]:
      "Thực hành hành động trong một tuần - chỉ một, hãy giữ nó đơn giản.",
  },
  [Identifiers.StepThree]: {
    [LanguagePreference.English]:
      "Reflect: Come back in a week to think about how it went!",
    [LanguagePreference.Spanish]:
      "Reflexiona: ¡Vuelve en una semana para pensar cómo fue!",
    [LanguagePreference.Vietnamese]:
      "Phản ánh: Hãy quay lại sau một tuần để suy nghĩ về cách nó diễn ra!",
  },
  [Identifiers.ContinueButton]: {
    [LanguagePreference.English]: "Get started",
    [LanguagePreference.Spanish]: "Empezar",
    [LanguagePreference.Vietnamese]: "Bắt đầu",
  },
  [Identifiers.SkipButton]: {
    [LanguagePreference.English]: "Don’t show me again",
    [LanguagePreference.Spanish]: "No me muestres de nuevo",
    [LanguagePreference.Vietnamese]: "Đừng hiển thị cho tôi nữa",
  },
};

export default translations;
