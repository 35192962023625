import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Navigation, Autoplay } from "swiper";
import styled from "@emotion/styled";
import Slide from "./CarouselSlides/Slide";
import "swiper/swiper.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/navigation/navigation.scss";
import "./swiper-pagination.css";
import { LanguagePreference } from "shared/types";
import { TranslationService } from "services/Translation/Translation.Service";
import {
  Page,
  Identifiers as TextIdentifiers,
} from "services/Translation/enums";
import SlideOne from "../../static/images/SlideOne.png";
import SlideTwo from "../../static/images/SlideTwo.png";
import SlideThree from "../../static/images/SlideThree.png";
import SlideFour from "../../static/images/SlideFour.png";

SwiperCore.use([Pagination, Navigation, Autoplay]);

interface Props {
  userLanguage: LanguagePreference;
}

const Carousel = ({ userLanguage }: Props) => {
  const titleFontSize = 35;
  const paragraphFontSize = 18;

  const firstSlide = {
    key: "first slide",
    titleText: TranslationService.getStaticText({
      page: Page.UserInvited,
      textIdentifier: TextIdentifiers.FirstSlideTitle,
      userLanguage,
    }),
    paragraphText: TranslationService.getStaticText({
      page: Page.UserInvited,
      textIdentifier: TextIdentifiers.FirstSlideParagraph,
      userLanguage,
    }),
    titleTextFontSize: titleFontSize,
    paragraphTextFontSize: paragraphFontSize,
    titleCustomMargins: "0px 40px 0px 40px",
    paragraphCustomMargins: "25px 29px 35px 24px",
    slideImage: SlideOne,
    slideImageCustomMargins: "40px 0px 20px 0px",
    slideImageCustomHeight: "auto",
    slideImageCustomWidth: 250,
    altText: "first image",
  };

  const secondSlide = {
    key: "second slide",
    titleText: TranslationService.getStaticText({
      page: Page.UserInvited,
      textIdentifier: TextIdentifiers.SecondSlideTitle,
      userLanguage,
    }),
    paragraphText: TranslationService.getStaticText({
      page: Page.UserInvited,
      textIdentifier: TextIdentifiers.SecondSlideParagraph,
      userLanguage,
    }),
    titleTextFontSize: titleFontSize,
    paragraphTextFontSize: paragraphFontSize,
    titleCustomMargins: "0px 10px 20px 10px",
    paragraphCustomMargins: "0px 29px 35px 24px",
    slideImage: SlideTwo,
    slideImageCustomMargins: "26px 0px 15px 0px",
    slideImageCustomHeight: "auto",
    slideImageCustomWidth: 220,
    altText: "second image",
  };

  const thirdSlide = {
    key: "third slide",
    titleText: TranslationService.getStaticText({
      page: Page.UserInvited,
      textIdentifier: TextIdentifiers.ThirdSlideTitle,
      userLanguage,
    }),
    paragraphText: TranslationService.getStaticText({
      page: Page.UserInvited,
      textIdentifier: TextIdentifiers.ThirdSlideParagraph,
      userLanguage,
    }),
    titleTextFontSize: titleFontSize,
    paragraphTextFontSize: paragraphFontSize,
    titleCustomMargins: "0px 10px 13px 10px",
    paragraphCustomMargins: "0px 25px 35px 25px",
    slideImage: SlideThree,
    slideImageCustomMargins: "7px 0px 15px 0px",
    slideImageCustomHeight: "auto",
    slideImageCustomWidth: 220,
    altText: "third image",
  };

  const fourthSlide = {
    key: "fourth slide",
    titleText: TranslationService.getStaticText({
      page: Page.UserInvited,
      textIdentifier: TextIdentifiers.FourthSlideTitle,
      userLanguage,
    }),
    paragraphText: TranslationService.getStaticText({
      page: Page.UserInvited,
      textIdentifier: TextIdentifiers.FourthSlideParagraph,
      userLanguage,
    }),
    titleTextFontSize: titleFontSize,
    paragraphTextFontSize: paragraphFontSize,
    titleCustomMargins: "0px 10px 9px 10px",
    paragraphCustomMargins: "0px 40px 35px 40px",
    slideImage: SlideFour,
    slideImageCustomMargins: "43px 0px 26px 0px",
    slideImageCustomHeight: "auto",
    slideImageCustomWidth: 233,
    altText: "fourth image",
  };

  const slidesData = [firstSlide, secondSlide, thirdSlide, fourthSlide];

  return (
    <CarouselContainer>
      <Swiper
        className="custom-pagination-div"
        spaceBetween={50}
        slidesPerView={1}
        pagination={{
          clickable: true,
        }}
        autoplay={{
          delay: 12000,
          disableOnInteraction: true,
          stopOnLastSlide: false,
        }}
        loop
      >
        {slidesData.map((data) => (
          <SwiperSlide key={data.key}>
            <Slide
              altText={data.altText}
              titleText={data.titleText}
              titleTextFontSize={data.titleTextFontSize}
              paragraphText={data.paragraphText}
              paragraphTextFontSize={data.paragraphTextFontSize}
              titleCustomMargins={data.titleCustomMargins}
              paragraphCustomMargins={data.paragraphCustomMargins}
              slideImage={data.slideImage}
              customMargins={data.slideImageCustomMargins}
              customHeight={data.slideImageCustomHeight}
              customWidth={data.slideImageCustomWidth}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </CarouselContainer>
  );
};

export default Carousel;

const CarouselContainer = styled.div({
  width: 390,
  marginBottom: 40,
});
