import { StyleSheet } from "aphrodite";
import React from "react";
import { TranslationService } from "services/Translation/Translation.Service";
import { Identifiers, Page } from "services/Translation/enums";
import Card from "shared/components/Card";
import Text, { Font } from "shared/components/Text";
import TopicProgress from "shared/components/TopicProgress/TopicProgress";
import { mainGrey, turquoise } from "shared/styles/colors";
import { LanguagePreference } from "shared/types";

interface Props {
  userLanguage: LanguagePreference;
  topicText: string;
  numberOfTimesPracticed: number;
  order: number;
  focusText: string;
}

const DashboardTopicProgress = ({
  userLanguage,
  topicText,
  numberOfTimesPracticed,
  order,
  focusText,
}: Props) => {
  const page = Page.Dashboard;

  return (
    <>
      <Text font={Font.ProximaNovaSemibold} style={styles.currentText}>
        {TranslationService.getStaticText({
          page,
          userLanguage,
          textIdentifier: Identifiers.TopicSelect,
        })}
      </Text>

      <Card
        cardStyle={styles.thirdCardStyle}
        contentStyle={styles.thirdCardContentStyle}
      >
        <TopicProgress
          topic={topicText}
          timesCompleted={numberOfTimesPracticed}
          topicOrder={order}
          userLanguage={userLanguage}
        />
        <Text font={Font.ProximaNovaSemibold} style={styles.cardTitle}>
          {TranslationService.getStaticText({
            page,
            userLanguage,
            textIdentifier: Identifiers.Action,
          })}
        </Text>
        <Text font={Font.ProximaNovaRegular} style={styles.cardDescription}>
          {focusText}
        </Text>
      </Card>
    </>
  );
};

export default DashboardTopicProgress;

const styles = StyleSheet.create({
  cardDescription: {
    color: mainGrey,
    fontSize: 16,
    lineHeight: 1.2,
    marginBottom: 20,
    paddingLeft: 15,
    paddingRight: 20,
  },
  cardTitle: {
    fontSize: 12,
    color: turquoise,
    letterSpacing: 2,
    lineHeight: 1.2,
    textTransform: "uppercase",
    marginBottom: 5,
    paddingTop: 20,
    paddingLeft: 15,
    paddingRight: 20,
  },
  currentText: {
    alignSelf: "center",
    textTransform: "uppercase",
    color: turquoise,
    fontSize: 16,
    lineHeight: 1.2,
    marginBottom: 12,
  },
  thirdCardStyle: {
    marginBottom: 18,
    width: "100%",
    "@media (min-width: 630px)": {
      maxWidth: 630,
    },
  },
  thirdCardContentStyle: {},
});
