import { Identifiers } from "../enums";
import { Translation } from "../Translation.Service";
import { LanguagePreference } from "../../../shared/types";

const translations: Translation = {
  [Identifiers.TopicSelect]: {
    [LanguagePreference.English]: "What would you like to work on?",
    [LanguagePreference.Spanish]: "¿En qué te gustaría trabajar?",
    [LanguagePreference.Vietnamese]: "Bạn muốn làm gì?",
  },
  [Identifiers.FocusSelect]: {
    [LanguagePreference.English]: "Which action will you take?",
    [LanguagePreference.Spanish]: "¿Qué acción tomarás?",
    [LanguagePreference.Vietnamese]: "Bạn sẽ thực hiện hành động nào?",
  },
  [Identifiers.ContinueButton]: {
    [LanguagePreference.English]: "Continue",
    [LanguagePreference.Spanish]: "Continuar",
    [LanguagePreference.Vietnamese]: "Tiếp tục",
  },
  [Identifiers.Practiced]: {
    [LanguagePreference.English]: "Practiced",
    [LanguagePreference.Spanish]: "Practicado",
    [LanguagePreference.Vietnamese]: "Đã thực hành",
  },
  [Identifiers.Time]: {
    [LanguagePreference.English]: "Time",
    [LanguagePreference.Spanish]: "vez",
    [LanguagePreference.Vietnamese]: "Thời gian",
  },
  [Identifiers.Times]: {
    [LanguagePreference.English]: "Times",
    [LanguagePreference.Spanish]: "veces",
    [LanguagePreference.Vietnamese]: "lần",
  },
  [Identifiers.UnlockedTitle]: {
    [LanguagePreference.English]: "Fantastic!",
    [LanguagePreference.Spanish]: "¡Fantástico!",
    [LanguagePreference.Vietnamese]: "Tuyệt vời!",
  },
  [Identifiers.UnlockedTopicBody]: {
    [LanguagePreference.English]: "You have unlocked a new topic!",
    [LanguagePreference.Spanish]: "¡Has desbloqueado un nuevo tema!",
    [LanguagePreference.Vietnamese]: "Bạn đã mở khóa một chủ đề mới!",
  },
  [Identifiers.UnlockedTopicsBody]: {
    [LanguagePreference.English]: "You have unlocked new topics!",
    [LanguagePreference.Spanish]: "¡Has desbloqueado nuevos temas!",
    [LanguagePreference.Vietnamese]: "Bạn đã mở khóa các chủ đề mới!",
  },
};

export default translations;
