import { Identifiers } from "../enums";
import { Translation } from "../Translation.Service";
import { LanguagePreference } from "../../../shared/types";

const StatsTranslations: Translation = {
  [Identifiers.EffectivenessX]: {
    [LanguagePreference.English]: "Weeks (vs. weeks practiced)",
    [LanguagePreference.Spanish]: "semanas (vs semanas practicadas)",
    [LanguagePreference.Vietnamese]: "Tuần (so với tuần thực hành)",
  },
  [Identifiers.EffectivenessY]: {
    [LanguagePreference.English]: "My Rating",
    [LanguagePreference.Spanish]: "Mi clasificación",
    [LanguagePreference.Vietnamese]: "HIỆU QUẢ CỦA TÔI",
  },
  [Identifiers.MyProgress]: {
    [LanguagePreference.English]: "Progress",
    [LanguagePreference.Spanish]: "Progreso",
    [LanguagePreference.Vietnamese]: "Tiến bộ",
  },
  [Identifiers.SubTitle]: {
    [LanguagePreference.English]: "CHOOSE A TOPIC TO VIEW:",
    [LanguagePreference.Spanish]: "ELEGIR UN TEMA PARA VER:",
    [LanguagePreference.Vietnamese]: "Chọn một chủ đề để xem:",
  },
  [Identifiers.FocusSelect]: {
    [LanguagePreference.English]: "Action selected",
    [LanguagePreference.Spanish]: "Acción seleccionada",
    [LanguagePreference.Vietnamese]: "Hành động đã chọn",
  },
  [Identifiers.AdditionalFocus]: {
    [LanguagePreference.English]: "MORE ACTIONS",
    [LanguagePreference.Spanish]: "MÁS ACCIONES",
    [LanguagePreference.Vietnamese]: "THÊM HÀNH ĐỘNG",
  },
  [Identifiers.NotEnough]: {
    [LanguagePreference.English]: "NOT ENOUGH DATA",
    [LanguagePreference.Spanish]: "NO HAY SUFICIENTES DATOS",
    [LanguagePreference.Vietnamese]: "KHÔNG ĐỦ DỮ LIỆU",
  },
  [Identifiers.NotEnoughText]: {
    [LanguagePreference.English]:
      "Complete multiple reflections in this topic to chart your progress over time",
    [LanguagePreference.Spanish]:
      "Complete varias reflexiones en este tema para registrar su progreso a lo largo del tiempo",
    [LanguagePreference.Vietnamese]:
      "Hoàn thành nhiều suy ngẫm cho Chủ đề này để lập biểu đồ tiến bộ của bạn theo thời gian",
  },
  [Identifiers.Times]: {
    [LanguagePreference.English]: "times chosen",
    [LanguagePreference.Spanish]: "tiempos elegidos",
    [LanguagePreference.Vietnamese]: "SỐ LẦN ĐÃ CHỌN",
  },
  [Identifiers.TopicsInclude]: {
    [LanguagePreference.English]: "topics included",
    [LanguagePreference.Spanish]: "temas incluidos",
    [LanguagePreference.Vietnamese]: "chủ đề bao gồm",
  },
  [Identifiers.AllTopics]: {
    [LanguagePreference.English]: "All Topics",
    [LanguagePreference.Spanish]: "Todos Los Temas",
    [LanguagePreference.Vietnamese]: "Tất cả Chủ đề",
  },
};

export default StatsTranslations;
