import { Identifiers } from "../enums";
import { Translation } from "../Translation.Service";
import { LanguagePreference } from "../../../shared/types";

const translations: Translation = {
  [Identifiers.Title]: {
    [LanguagePreference.English]: "Welcome to Reflectable",
    [LanguagePreference.Spanish]: "¡Bienvenido a Reflectable!",
    [LanguagePreference.Vietnamese]: "Chào mừng đến với Reflectable",
  },
  [Identifiers.SubTitle]: {
    [LanguagePreference.English]:
      "A self-reflection tool that helps you support children's learning and healthy development as part of",
    [LanguagePreference.Spanish]:
      "Una herramienta de autorreflexión que le ayuda a apoyar el aprendizaje y el desarrollo saludable de los niños como parte de",
    [LanguagePreference.Vietnamese]:
      "Một công cụ để tự suy ngẫm góp phần giúp bạn hỗ trợ việc học tập và phát triển lành mạnh của trẻ em",
  },
  [Identifiers.CreatePassword]: {
    [LanguagePreference.English]: "First, let’s create a password:",
    [LanguagePreference.Spanish]: "Primero, vamos a crear una contraseña:",
    [LanguagePreference.Vietnamese]: "Đầu Tiên, Hãy Tạo Mật Mã:",
  },
  [Identifiers.ConfirmPassword]: {
    [LanguagePreference.English]: "Confirm Password",
    [LanguagePreference.Spanish]: "Confirmar Contraseña",
    [LanguagePreference.Vietnamese]: "Xác nhận mật mã",
  },
  [Identifiers.Password]: {
    [LanguagePreference.English]: "Password",
    [LanguagePreference.Spanish]: "Contraseña",
    [LanguagePreference.Vietnamese]: "Mật mã",
  },
};

export default translations;
