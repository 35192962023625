import { Identifiers } from "../enums";
import { Translation } from "../Translation.Service";
import { LanguagePreference } from "../../../shared/types";

const reflectionStartTranslations: Translation = {
  [Identifiers.Step]: {
    [LanguagePreference.English]: "Step 2: Check & Reflect",
    [LanguagePreference.Spanish]: "Paso 2: Revisar y reflexionar",
    [LanguagePreference.Vietnamese]: "BƯỚC 2: KIỂM TRA & SUY NGẫM",
  },
  [Identifiers.Title]: {
    [LanguagePreference.English]: "Here’s what you’ve been practicing",
    [LanguagePreference.Spanish]: "Aquí está lo que has estado practicando",
    [LanguagePreference.Vietnamese]: "Đây là những gì bạn đã thực hành",
  },
  [Identifiers.TopicSelect]: {
    [LanguagePreference.English]: "Topic",
    [LanguagePreference.Spanish]: "Tema",
    [LanguagePreference.Vietnamese]: "CHỦ ĐỀ",
  },
  [Identifiers.FocusSelect]: {
    [LanguagePreference.English]: "ACTION",
    [LanguagePreference.Spanish]: "ACCIÓN",
    [LanguagePreference.Vietnamese]: "HÀNH ĐỘNG",
  },
  [Identifiers.CurrentWeek]: {
    [LanguagePreference.English]: "Current Week",
    [LanguagePreference.Spanish]: "Semana actual",
    [LanguagePreference.Vietnamese]: "TUẦN NÀY",
  },
};

export default reflectionStartTranslations;
