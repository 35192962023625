import { css, StyleSheet } from "aphrodite";
import React from "react";
import { greyAccent } from "shared/styles/colors";
import { Style } from "shared/types";

interface Props {
  cardStyle?: Style | Style[];
  contentStyle?: Style | Style[];
  children: React.ReactNode;
}

const ResponsiveCard = ({ contentStyle, children, cardStyle }: Props) => {
  return (
    <section className={css(cardStyle, styles.ResponsiveCard)}>
      <div className={css(styles.topAccent)} />
      <div className={css(contentStyle)}>{children}</div>
    </section>
  );
};

export default ResponsiveCard;

const styles = StyleSheet.create({
  ResponsiveCard: {
    borderRadius: 10,
    width: "100%",
    "@media (min-width: 630px)": {
      boxShadow: "0 2px 2px 1px rgba(0, 0, 0, .1)",
      width: 630,
      minHeight: 660,
    },
  },
  topAccent: {
    "@media (min-width: 630px)": {
      width: "100%",
      background: greyAccent,
      height: 10,
      borderRadius: "10px 10px 0 0",
    },
  },
  //
});
