import styled from "@emotion/styled";
import SignUpText from "../InvitationText";
import React, { Dispatch, SetStateAction } from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import Loader from "shared/components/Loader";
import CurriculumCard from "./CurriculumCard";
import ErrorMessage from "screens/auth/CreatePassword/ErrorMessage";
import Text, { Font } from "shared/components/Text";
import { StyleSheet } from "aphrodite";
import { TranslationService } from "services/Translation/Translation.Service";
import { Identifiers, Page } from "services/Translation/enums";
import { LanguagePreference } from "shared/types";
import Spacer from "shared/components/Spacer";

interface Props {
  currentCurriculumId: string;
  setCurrentCurriculumId: Dispatch<SetStateAction<string>>;
  userLanguage: LanguagePreference;
}

const PUBLICLY_AVAILABLE_CURRICULA = gql`
  query PubliclyAvailableCurricula($languagePreference: Language!) {
    publiclyAvailableCurricula(languagePreference: $languagePreference) {
      id
      publicNameTranslations {
        text
        language
      }
      descriptionTranslations {
        text
        language
      }
      imageUrl
    }
  }
`;

const PersonalizeReflectable = ({
  currentCurriculumId,
  setCurrentCurriculumId,
  userLanguage,
}: Props) => {
  const page = Page.PersonalizeReflectable;
  const { data, loading, error } = useQuery(PUBLICLY_AVAILABLE_CURRICULA, {
    variables: {
      languagePreference: userLanguage,
    },
    onCompleted: (queryData) => {
      setCurrentCurriculumId(queryData.publiclyAvailableCurricula[0].id);
    },
  });

  let content;

  if (loading) {
    content = <Loader />;
  }
  if (error) {
    return <ErrorMessage error="Error finding curricula" />;
  }
  if (data) {
    const curricula = data.publiclyAvailableCurricula;
    content = (
      <>
        {curricula.map((item) => (
          <CurriculumCard
            setSelectedCurriculum={setCurrentCurriculumId}
            curriculumId={item.id}
            currentCurriculumId={currentCurriculumId}
            imageUrl={item.imageUrl}
            description={item.descriptionTranslations[0].text}
            title={item.publicNameTranslations[0].text}
            key={item.id}
          />
        ))}
      </>
    );
  }
  return (
    <PersonalizeReflectableContainer>
      <SignUpText
        titleText={TranslationService.getStaticText({
          page,
          userLanguage,
          textIdentifier: Identifiers.Title,
        })}
        paragraphText={TranslationService.getStaticText({
          page,
          userLanguage,
          textIdentifier: Identifiers.Body,
        })}
      />
      <Spacer height={30} />
      <Container>
        <Text style={styles.text}>
          {TranslationService.getStaticText({
            page,
            userLanguage,
            textIdentifier: Identifiers.Curriculum,
          })}
        </Text>
        <Text
          style={[styles.text, styles.requiredText]}
          font={Font.ProximaNovaRegular}
        >
          {TranslationService.getStaticText({
            page,
            userLanguage,
            textIdentifier: Identifiers.Required,
          })}
        </Text>
      </Container>
      {content}
    </PersonalizeReflectableContainer>
  );
};

export default PersonalizeReflectable;

const styles = StyleSheet.create({
  text: {
    fontSize: 16,
    marginBottom: 17,
  },
  requiredText: {
    fontSize: 14,
  },
});

const PersonalizeReflectableContainer = styled.div({
  paddingTop: 22,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginBottom: 21,
  maxWidth: 355,
});

const Container = styled.div({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
});
