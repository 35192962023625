import React from "react";
import IntroThreeImage from "static/images/IntroThreeImage.png";
import IllustrationScreen from "shared/components/IllustrationScreen";
import { ROUTES } from "../../../constants";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { TranslationService } from "services/Translation/Translation.Service";
import { Identifiers, Page } from "services/Translation/enums";
import GraphqlErrorScreen from "shared/components/ErrorBoundary/GraphqlErrorScreen";
import { useDocumentTitle } from "shared/hooks";

interface Props {
  location?: any;
}

const USER_LANGUAGE = gql`
  query Userlanguage {
    userLanguage
  }
`;

const IntroThree = ({ location }: Props) => {
  useDocumentTitle("Intro Three | Reflectable");
  const isFirstTime = location?.state?.isFirstTime;
  const { data, loading, error } = useQuery(USER_LANGUAGE);
  if (loading) {
    return null;
  }

  if (error) {
    return <GraphqlErrorScreen error={error} />;
  }
  const userLanguage = data?.userLanguage;
  const page = isFirstTime
    ? Page.IntroThreeFirstTime
    : Page.IntroThreeReturning;
  return (
    <IllustrationScreen
      imageSrc={IntroThreeImage}
      imageAltText="Person Meditating"
      title={TranslationService.getStaticText({
        page,
        textIdentifier: Identifiers.Title,
        userLanguage,
      })}
      body={TranslationService.getStaticText({
        page,
        textIdentifier: Identifiers.Body,
        userLanguage,
      })}
      buttonText={TranslationService.getStaticText({
        page: Page.Shared,
        userLanguage,
        textIdentifier: Identifiers.ContinueButton,
      })}
      buttonLocation={isFirstTime ? ROUTES.TOPIC : ROUTES.REFLECTION_START}
      stateProps={location.state}
      showNavHeader={!isFirstTime}
    />
  );
};

export default IntroThree;
