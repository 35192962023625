"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReflectionUpdateType = exports.QuestionType = void 0;
var QuestionType;
(function (QuestionType) {
    QuestionType["MultiSelect"] = "MULTI_SELECT";
    QuestionType["Dropdown"] = "DROP_DOWN";
    QuestionType["ScaleFive"] = "SCALE_FIVE";
    QuestionType["ScaleTen"] = "SCALE_TEN";
    QuestionType["FreeText"] = "FREE_TEXT";
    QuestionType["SingleSelect"] = "SINGLE_SELECT";
    QuestionType["SingleSelectWithOther"] = "SINGLE_SELECT_WITH_OTHER";
    QuestionType["MultiSelectWithOther"] = "MULTI_SELECT_WITH_OTHER";
    QuestionType["EffectivenessRating"] = "EFFECTIVENESS_RATING";
    QuestionType["SingleGraphedLine"] = "SINGLE_GRAPHED_LINE";
    QuestionType["SingleGraphedPie"] = "SINGLE_GRAPHED_PIE";
    QuestionType["MultiGraphedPie"] = "MULTI_GRAPHED_PIE";
    QuestionType["ComparisonSingleGraphedLineOne"] = "COMPARISON_SINGLE_GRAPHED_LINE_ONE";
    QuestionType["ComparisonSingleGraphedLineTwo"] = "COMPARISON_SINGLE_GRAPHED_LINE_TWO";
})(QuestionType = exports.QuestionType || (exports.QuestionType = {}));
var ReflectionUpdateType;
(function (ReflectionUpdateType) {
    ReflectionUpdateType["SKIP_STEP_THREE"] = "SKIP_STEP_THREE";
    ReflectionUpdateType["COMPLETED_STEP_TWO"] = "COMPLETED_STEP_TWO";
})(ReflectionUpdateType = exports.ReflectionUpdateType || (exports.ReflectionUpdateType = {}));
