import React, { useEffect } from "react";
import { white, blackAlpha50, turquoise } from "shared/styles/colors";
import Modal, { Styles } from "react-modal";
import "shared/styles/modal.css";
import XIcon from "static/svgs/XIconComponent";
import styled from "@emotion/styled";

interface Props {
  children: JSX.Element;
  modalIsOpen: boolean;
  closeModal: () => void;
  setModalIsOpen: (modalIsOpen: boolean) => void;
  contentLabel: string;
  hideCloseButton?: boolean;
}

const ModalBase = ({
  children,
  modalIsOpen,
  closeModal,
  setModalIsOpen,
  contentLabel,
  hideCloseButton,
}: Props) => {
  const onModalOpen = () => {
    // included because "react-modal" does not prevent app scroll
    document.body.classList.add("hidden-body");
  };

  const onModalClose = () => {
    closeModal();
    document.body.classList.remove("hidden-body");
  };

  const modalStyle: Styles = {
    content: {
      backgroundColor: white,
      zIndex: 3,
      position: "static",
      width: "auto",
      borderRadius: 10,
      padding: 0,
    },
    overlay: {
      background: blackAlpha50,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      zIndex: 500,
    },
  };

  useEffect(() => {
    window.onpopstate = () => {
      setModalIsOpen(!modalIsOpen);
    };
  }, []);

  Modal.setAppElement("#root");

  return (
    <Modal
      isOpen={modalIsOpen}
      onAfterOpen={onModalOpen}
      onRequestClose={onModalClose}
      contentLabel={contentLabel}
      style={modalStyle}
    >
      <ButtonWrapper>
        {!hideCloseButton && (
          <CloseButton onClick={onModalClose}>
            <XIcon color={turquoise} height={28} width={28} />
          </CloseButton>
        )}
      </ButtonWrapper>
      {children}
    </Modal>
  );
};

export default ModalBase;

const CloseButton = styled.button({
  background: "transparent",
  position: "absolute",
  right: 28,
  top: 35,
  cursor: "pointer",
});

const ButtonWrapper = styled.div({
  position: "relative",
});
