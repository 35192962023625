import { Identifiers } from "../enums";
import { Translation } from "../Translation.Service";
import { LanguagePreference } from "../../../shared/types";

const translations: Translation = {
  [Identifiers.QuestionPageStepTwoTitle]: {
    [LanguagePreference.English]: "Step 2: Check & Reflect",
    [LanguagePreference.Spanish]: "Paso 2: Revisar y reflexionar",
    [LanguagePreference.Vietnamese]: "BƯỚC 2: KIỂM TRA & SUY NGẫM",
  },
  [Identifiers.QuestionPageStepThreeTitle]: {
    [LanguagePreference.English]: "Step 3: Take a Closer Look",
    [LanguagePreference.Spanish]: "Paso 3: Mire más de cerca",
    [LanguagePreference.Vietnamese]: "STEP 3: TAKE A CLOSER LOOK",
  },
  [Identifiers.EffectivenessRatingText]: {
    [LanguagePreference.English]: "Here’s Your Current Focus:",
    [LanguagePreference.Spanish]: "Y selecciono este enfoque:",
    [LanguagePreference.Vietnamese]: "ĐÂY LÀ TIÊU ĐIỂM HIỆN TẠI CỦA BẠN:",
  },
  [Identifiers.StartOfErrorMessage]: {
    [LanguagePreference.English]: "Please fill out question",
    [LanguagePreference.Spanish]: "Complete las pregunta",
    [LanguagePreference.Vietnamese]: "",
  },
  [Identifiers.PluralStartOfErrorMessage]: {
    [LanguagePreference.English]: "Please fill out questions",
    [LanguagePreference.Spanish]: "Complete las preguntas",
    [LanguagePreference.Vietnamese]: "Vui lòng điền câu hỏi",
  },
  [Identifiers.EndOfErrorMessage]: {
    [LanguagePreference.English]: "to continue",
    [LanguagePreference.Spanish]: "para continuar",
    [LanguagePreference.Vietnamese]: "tiếp tục",
  },
  [Identifiers.FreeTextInput]: {
    [LanguagePreference.English]: "Type here to record your comments...",
    [LanguagePreference.Spanish]:
      "Escriba aquí para registrar sus comentarios...",
    [LanguagePreference.Vietnamese]:
      "Nhập vào đây để ghi lại bình luận của bạn ...",
  },
  [Identifiers.PleaseSpecify]: {
    [LanguagePreference.English]: "Please specify",
    [LanguagePreference.Spanish]: "Por favor especifica",
    [LanguagePreference.Vietnamese]: "Xin hãy chỉ ra cụ thể",
  },
  [Identifiers.PleaseNote]: {
    [LanguagePreference.English]: "Please note",
    [LanguagePreference.Spanish]: "Tenga en cuenta",
    [LanguagePreference.Vietnamese]: "Xin lưu ý",
  },
  [Identifiers.NotGettingIt]: {
    [LanguagePreference.English]: "Not getting it",
    [LanguagePreference.Spanish]: "No lo entiendo",
    [LanguagePreference.Vietnamese]: "Không hiểu",
  },
  [Identifiers.GettingIt]: {
    [LanguagePreference.English]: "Getting it",
    [LanguagePreference.Spanish]: "Conseguirlo",
    [LanguagePreference.Vietnamese]: "Lấy nó",
  },
  [Identifiers.GotIt]: {
    [LanguagePreference.English]: "Got it!",
    [LanguagePreference.Spanish]: "¡Entendido!",
    [LanguagePreference.Vietnamese]: "Hiểu rồi!",
  },
};

export default translations;
