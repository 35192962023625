/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect } from "react";
import { StyleSheet, css } from "aphrodite";
import {
  turquoise,
  mainGrey,
  greyAccent,
  white,
  buttonBlue,
} from "shared/styles/colors";
import documentIcon from "static/images/documentIcon.png";
import graphIcon from "static/images/graphIcon.png";
import DashboardCompleteImage from "static/images/DashboardCompleteImage.png";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import ReflectionCard from "../ReflectionCard";
import { useHistory } from "react-router";
import { ROUTES } from "../../../constants";
import GraphqlErrorScreen from "shared/components/ErrorBoundary/GraphqlErrorScreen";
import { Identifiers, Page } from "services/Translation/enums";
import { TranslationService } from "services/Translation/Translation.Service";
import HamburgerMenu from "shared/components/HamburgerMenu/HamburgerMenu";
import { useDocumentTitle } from "shared/hooks";
import Spacer from "shared/components/Spacer";
import NumberOfRelfectionsCompletedCard from "../NumberOfReflectionsCompletedCard";
import DashboardTopicProgress from "../DashboardTopicProgress";
import LinkCard from "../LinkCard";

const DASHBOARD_QUERY = gql`
  query DashboardQuery {
    currentUser {
      fullName
      nickname
      languagePreference
      isAdmin
    }
    isCohortAdmin
    activeReflection {
      hasActiveReflection
      activeReflectionHasFocus
      queuedReflectionHasFocus
    }
    currentUserFocus {
      translationData {
        name
      }
      topic {
        translationData {
          name
        }
        order
        numberOfTimesPracticed
      }
    }
    userCompletedReflections {
      totalComplete
      totalReflections
    }
    dashboardStatus {
      allReflectionsComplete
      canCreateNewReflection
    }
  }
`;

const Dashboard = () => {
  useDocumentTitle("Dashboard | Reflectable");
  const { data, loading, error } = useQuery(DASHBOARD_QUERY, {
    fetchPolicy: "no-cache",
  });
  const history = useHistory();
  const hasActiveReflection = data?.activeReflection?.hasActiveReflection;
  const activeReflectionHasFocus =
    data?.activeReflection?.activeReflectionHasFocus;
  const queuedReflectionHasFocus =
    data?.activeReflection?.queuedReflectionHasFocus;
  const reflectionsComplete = data?.dashboardStatus?.allReflectionsComplete;
  useEffect(() => {
    if (data) {
      if (
        (hasActiveReflection && !activeReflectionHasFocus) ||
        (!hasActiveReflection && !queuedReflectionHasFocus)
      ) {
        if (!reflectionsComplete) {
          history.push(ROUTES.TOPIC, { redirectingFromDashboard: true });
        }
      }
    }
  }, [
    data,
    hasActiveReflection,
    activeReflectionHasFocus,
    history,
    queuedReflectionHasFocus,
    reflectionsComplete,
  ]);

  if (loading) {
    return null;
  }

  if (error) {
    return <GraphqlErrorScreen error={error} />;
  }

  if (data) {
    const focusText = data.currentUserFocus?.translationData?.name;
    const topicText = data.currentUserFocus?.topic?.translationData?.name;
    const order = data.currentUserFocus?.topic?.order;
    const numberOfTimesPracticed =
      data.currentUserFocus?.topic?.numberOfTimesPracticed;
    const totalComplete = data.userCompletedReflections?.totalComplete;
    const totalReflections = data.userCompletedReflections?.totalReflections;
    const canCreateNewReflection = data.dashboardStatus?.canCreateNewReflection;
    const userLanguage = data?.currentUser?.languagePreference;
    const page = Page.Dashboard;

    return (
      <>
        <HamburgerMenu logoIsCentered logoIsVisible />
        <div className={css(styles.Dashboard)}>
          <div className={css(styles.header)} />
          {((hasActiveReflection && activeReflectionHasFocus) ||
            canCreateNewReflection) && (
            <ReflectionCard
              canCreateNewReflection={canCreateNewReflection}
              userLanguage={userLanguage}
            />
          )}
          {focusText && (
            <DashboardTopicProgress
              userLanguage={userLanguage}
              topicText={topicText}
              focusText={focusText}
              numberOfTimesPracticed={numberOfTimesPracticed}
              order={order}
            />
          )}
          <NumberOfRelfectionsCompletedCard
            userLanguage={userLanguage}
            totalComplete={totalComplete}
            totalReflections={totalReflections}
          />
          {reflectionsComplete && (
            <img
              src={DashboardCompleteImage}
              alt="Reflections Complete Celebration"
              title="Reflections Complete Celebration"
            />
          )}
          {totalComplete >= 1 && (
            <div className={css(styles.smallCardContainer)}>
              <LinkCard
                onClick={() => history.push(ROUTES.STATS)}
                icon={graphIcon}
                imgAlt="graph icon"
                text={TranslationService.getStaticText({
                  page,
                  userLanguage,
                  textIdentifier: Identifiers.ViewProgress,
                })}
              />
              <LinkCard
                icon={documentIcon}
                onClick={() => history.push(ROUTES.REFLECTIONS_SUMMARY)}
                imgAlt="document icon"
                text={TranslationService.getStaticText({
                  page,
                  userLanguage,
                  textIdentifier: Identifiers.ViewReflections,
                })}
              />
            </div>
          )}
          <Spacer flexGrow={0} height={5} />
        </div>
      </>
    );
  }
  return null;
};

const styles = StyleSheet.create({
  Dashboard: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: 10,
    paddingRight: 10,
    "@media (min-width: 630px)": {
      paddingTop: 162,
    },
    alignItems: "center",
    width: "100%",
    paddingTop: 20,
  },
  header: {
    "@media (min-width: 630px)": {
      boxShadow: "0 2px 10px 0 rgba(0, 0, 0, .1)",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      position: "fixed",
      paddingTop: 0,
      height: 135,
      justifyContent: "flex-end",
    },
    marginBottom: 21,
    top: 0,
    width: "100%",
    backgroundColor: white,
    paddingTop: 95,
  },
  innerHeader: {
    backgroundColor: "red",
  },
  greeting: {
    height: 43,
    fontSize: 36,
    lineHeight: 1.2,
    color: turquoise,
    marginLeft: 10,
    "@media (min-width: 630px)": {
      width: 630,
    },
  },
  message: {
    marginLeft: 10,
    color: mainGrey,
    fontSize: 16,
    lineHeight: 1.2,
    marginBottom: 16,
    "@media (min-width: 630px)": {
      width: 630,
    },
  },
  buttonStyle: {
    height: 42,
    backgroundColor: buttonBlue,
    borderRadius: 8,
    marginBottom: 41,
  },
  buttonText: {
    fontSize: 16,
  },
  firstCardStyle: {
    marginBottom: 15,
  },
  firstCardContentStyle: {
    paddingTop: 20,
    paddingLeft: 20,
    paddingRight: 20,
  },
  line: {
    width: "100%",
    height: 1,
    backgroundColor: greyAccent,
    marginBottom: 21,
  },
  smallCardContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 40,
    width: "100%",
    "@media (min-width: 630px)": {
      width: 630,
    },
  },
  placeholderDiv: {
    width: 255,
    height: 163,
    backgroundColor: greyAccent,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 10,
  },
  logoutContainer: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  logoutWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
    maxWidth: 630,
    paddingTop: 12,
    paddingRight: 16,
  },
  logoutText: {
    marginRight: 6,
    fontSize: 16,
    color: turquoise,
  },
  adminContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    marginRight: 10,
  },
  adminText: {
    marginRight: 6,
    fontSize: 16,
    color: mainGrey,
  },
  adminButton: {
    height: 14,
  },
});

export default Dashboard;
