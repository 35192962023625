import { useLazyQuery } from "@apollo/react-hooks";
import { css, StyleSheet } from "aphrodite";
import React, { useEffect } from "react";
import Spinner from "react-spinkit";
import { buttonBlue } from "shared/styles/colors";
import CohortAdminQuestionCard from "./CohortAdminQuestionCard";
import Top3ChallengesCard from "./Top3ChallengesCard";
import { COHORT_ADMIN_GRAPH_QUESTIONS_QUERY } from "screens/Stats/queries";

interface Props {
  cohortId: string;
  topicId: string;
}

const CohortGraphQuestions = ({ topicId, cohortId }: Props) => {
  const [graphQuestionsQuery, { data, loading, error }] = useLazyQuery(
    COHORT_ADMIN_GRAPH_QUESTIONS_QUERY,
    {
      fetchPolicy: "no-cache",
      variables: { topicId, cohortId },
    }
  );

  useEffect(() => {
    if (cohortId && topicId) {
      graphQuestionsQuery();
    }
  }, [cohortId, graphQuestionsQuery, topicId]);

  if (loading || error) {
    return (
      <div className={css(styles.loadingContent)}>
        <Spinner color={buttonBlue} name="line-scale" fadeIn="none" />
      </div>
    );
  }
  if (data) {
    const {
      multiGraphedPieQuestions,
      comparisonSingleGraphedLineOne,
      comparisonSingleGraphedLineTwo,
      singleGraphedLineQuestions,
    } = data;

    const lineGraphQuestions = [
      ...singleGraphedLineQuestions,
      ...comparisonSingleGraphedLineOne,
      ...comparisonSingleGraphedLineTwo,
    ];

    return (
      <section className={css(styles.CohortGraphQuestions)}>
        {lineGraphQuestions.map((question) => (
          <CohortAdminQuestionCard
            key={question.id}
            topicId={topicId}
            questionId={question.id}
            cohortId={cohortId}
            questionTitle={question.graphTranslations.graphTitle}
          />
        ))}
        {multiGraphedPieQuestions.map((question) => (
          <Top3ChallengesCard
            topicId={topicId}
            questionId={question.id}
            key={question.id}
            cohortId={cohortId}
            questionTitle={question.graphTranslations.graphTitle}
          />
        ))}
      </section>
    );
  }
  return null;
};

export default CohortGraphQuestions;

const styles = StyleSheet.create({
  CohortGraphQuestions: {
    display: "flex",
    justifyContent: "space-between",
    width: 1200,
    flexWrap: "wrap",
  },
  loadingContent: {
    paddingTop: 40,
  },
  //
});
