import { format } from "date-fns";
import {
  chartBlue,
  chartPointBlue,
  chartOrange,
  adminOrange,
  piePurple,
  mainGrey,
} from "shared/styles/colors";
import { Direction } from "shared/types";

/* eslint-disable no-plusplus */
export const styleGraphLines = ({
  series,
  color,
  pointColor,
}: {
  series: any;
  color: string;
  pointColor: string;
}) => {
  for (const seriesItem of series) {
    const lineElements = seriesItem.querySelectorAll(".ct-line");
    const pointElements = seriesItem.querySelectorAll(".ct-point");
    for (const lineElement of lineElements) {
      lineElement.style.stroke = color;
    }
    for (const point of pointElements) {
      point.style.stroke = pointColor;
      point.style.strokeWidth = 14;
    }
  }
};

export const formatDate = (dateTime: string): string => {
  const date = new Date(dateTime);
  const formattedDate: string = format(date, "MM/dd");
  return formattedDate;
};

export const truncateString = (inputString: string): string => {
  if (inputString.length <= 15) {
    return inputString;
  }
  return `${inputString.substring(0, 15)}...`;
};

export const graphListener = {
  draw() {
    // overrides chart labels to be a different color
    const labels = document.querySelectorAll(".ct-label");
    for (let i = 0; i < labels.length; i++) {
      // @ts-ignore
      labels[i].style.color = mainGrey;
    }

    // styles each line in the graph
    const singleSeriesA = document.querySelectorAll(".ct-series-a");
    styleGraphLines({
      series: singleSeriesA,
      color: chartBlue,
      pointColor: chartPointBlue,
    });
    const doubleSeriesA = document.querySelectorAll(".dl-series-a");
    styleGraphLines({
      series: doubleSeriesA,
      color: chartOrange,
      pointColor: adminOrange,
    });
    const doubleSeriesB = document.querySelectorAll(".dl-series-b");
    styleGraphLines({
      series: doubleSeriesB,
      color: piePurple,
      pointColor: piePurple,
    });
  },
};

const DATA_POINTS_TO_SHOW = 5;

export const sliceGraphData = (graphData, startIndex) => {
  const remainingDataPoints = graphData?.length - startIndex;
  const sliceLength =
    remainingDataPoints > DATA_POINTS_TO_SHOW
      ? DATA_POINTS_TO_SHOW
      : remainingDataPoints;

  return graphData?.slice(startIndex, startIndex + sliceLength);
};

export const createFullTicksArray = (questionDataArray: any[]) => {
  const totalItems = questionDataArray?.length;
  const remainder = totalItems % 5;
  const additionalItems = remainder === 0 ? 0 : 5 - remainder;
  const fullTicksArray = Array.from(
    { length: totalItems + additionalItems },
    (_, i) => i + 1
  );
  return fullTicksArray;
};

export const updateDataView = (direction, startIndex, questionDataArray) => {
  const totalDataPoints = questionDataArray.length;
  let newStartIndex;

  if (direction === Direction.Prev) {
    newStartIndex = Math.max(startIndex - DATA_POINTS_TO_SHOW, 0);
  } else if (direction === Direction.Next) {
    newStartIndex = startIndex + DATA_POINTS_TO_SHOW;
    if (totalDataPoints - newStartIndex < DATA_POINTS_TO_SHOW) {
      newStartIndex = totalDataPoints - DATA_POINTS_TO_SHOW;
    }
  }

  return newStartIndex;
};

// Generates graph data for Effectiveness responses. The y-axis labels are a set of numbers.
export const generateEffectivenessValues = (responses: any[]) => {
  const arrayOfAnswers = responses?.map((response) => {
    const topicName = response.reflection.focus.topic.translationData.name;
    return {
      topicName,
      date: formatDate(response?.createdAt),
      answerInteger: response?.answerInteger,
    };
  });
  const labels: number[] = [];
  for (let i = 0; i < arrayOfAnswers?.length; i++) {
    labels.push(i + 1);
  }
  const series = arrayOfAnswers?.map((answerObject) => {
    const dateCompleted = answerObject.date;
    const answer = answerObject?.answerInteger;
    const truncTopicName = answerObject?.topicName;
    const sharedStyles = "font-size: 12px; font-weight: 400; color: #040404";
    return {
      meta: `<div style="${sharedStyles}">Reflection from ${dateCompleted}</div>
               <div style="${sharedStyles}">Topic: ${truncTopicName}</div>`,
      value: answer,
    };
  });

  return {
    labels,
    series: [series],
  };
};

type SeriesItem = {
  meta: string;
  value: number;
};

// Generates data for both positive and negative behaviors. The y-axis is "More" and "Less"
export const generateBehaviorsValues = ({
  responses,
  topAxis,
  bottomAxis,
}: {
  responses: any[];
  topAxis: string;
  bottomAxis: string;
}) => {
  const arrayOfOrderValues = responses?.map((response) => {
    return {
      topicName: response?.reflection.focus.topic.translationData.name,
      date: formatDate(response?.createdAt),
      orderValue: response?.choices[0]?.order,
    };
  });
  const arrayOfAnswers = responses?.map((response) => {
    return response?.answerInteger;
  });

  const labels: number[] = [];
  let series: SeriesItem[] = [];
  let yAxisValue = 20;

  for (let i = 0; i < arrayOfOrderValues?.length; i++) {
    if (arrayOfOrderValues[i].orderValue === 1) {
      yAxisValue += 1;
    } else if (arrayOfOrderValues[i].orderValue === 2) {
      yAxisValue -= 1;
    }

    labels.push(i + 1);

    const dateCompleted = arrayOfOrderValues[i]?.date;
    const truncTopicName = arrayOfOrderValues[i].topicName;
    const sharedStyles = "font-size: 12px; font-weight: 400; color: #040404;";
    const meta = `<div style="${sharedStyles}">Reflection from ${dateCompleted}</div>
                  <div style="${sharedStyles}">Topic: ${truncTopicName}</div>`;

    series.push({
      meta,
      value: yAxisValue,
    });
  }

  if (!topAxis && !bottomAxis) {
    series = arrayOfAnswers;
  }

  return { labels, series: [series] };
};

export const checkForDisabled = ({
  startIndex,
  fullArray,
  maxArrayLength,
}: {
  startIndex: number;
  fullArray: any[];
  maxArrayLength: number;
}) => {
  return (
    maxArrayLength < DATA_POINTS_TO_SHOW ||
    startIndex === fullArray?.length - DATA_POINTS_TO_SHOW
  );
};

export const updateDoubleLineGraphView = ({
  combinedSeries,
  startIndex,
}: {
  combinedSeries: {
    labels: number[];
    series: SeriesItem[][]; // [[{meta: string, value: number}]]
  };
  startIndex: number;
}) => {
  const { labels, series } = combinedSeries;
  const slicedLabels = labels.slice(
    startIndex,
    startIndex + DATA_POINTS_TO_SHOW
  );
  const slicedSeries = series.map((s) =>
    s.slice(startIndex, startIndex + DATA_POINTS_TO_SHOW)
  );

  return {
    labels: slicedLabels,
    series: slicedSeries,
  };
};

export const generateValuesArray = (seriesArray: SeriesItem[]) => {
  return seriesArray.map((item) => {
    return item.value;
  });
};
