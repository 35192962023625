import styled from "@emotion/styled";
import { graphButtonGrey, turquoise } from "shared/styles/colors";

export const GraphButton = styled.button(
  ({
    isDisabled,
    isLeftButton = false,
  }: {
    isDisabled: boolean;
    isLeftButton?: boolean;
  }) => ({
    height: 27,
    width: 27,
    borderRadius: 100,
    backgroundColor: isDisabled ? graphButtonGrey : turquoise,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: isLeftButton ? "0 1px 0 0" : "0 2px 1px 0",
    transform: isLeftButton ? "rotate(0deg)" : "scaleX(-1)",
    cursor: "pointer",
    marginRight: isLeftButton ? 10 : 0,
  })
);
