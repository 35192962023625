import React from "react";
import styled from "@emotion/styled";
import InvitationText from "../InvitationText";

interface Props {
  altText: string;
  titleText: string;
  titleTextFontSize: number;
  paragraphText: string;
  paragraphTextFontSize: number;
  titleCustomMargins: string;
  paragraphCustomMargins: string;
  slideImage: string;
  customMargins: string;
  customHeight: string;
  customWidth: number;
}

const Slide = ({
  altText,
  titleText,
  titleTextFontSize,
  paragraphText,
  paragraphTextFontSize,
  titleCustomMargins,
  paragraphCustomMargins,
  slideImage,
  customMargins,
  customHeight,
  customWidth,
}: Props) => {
  return (
    <div>
      <SlideImageContainer
        customHeight={customHeight}
        customMargins={customMargins}
        customWidth={customWidth}
        src={slideImage}
        alt={altText}
      />
      <SlideStyled>
        <InvitationText
          titleText={titleText}
          titleTextFontSize={titleTextFontSize}
          paragraphText={paragraphText}
          paragraphTextFontSize={paragraphTextFontSize}
          titleCustomMargins={titleCustomMargins}
          paragraphCustomMargins={paragraphCustomMargins}
        />
      </SlideStyled>
    </div>
  );
};

const SlideImageContainer = styled.img(
  ({
    customHeight,
    customMargins,
    customWidth,
  }: {
    customMargins: string;
    customHeight: string;
    customWidth: number;
  }) => ({
    margin: customMargins,
    height: customHeight,
    width: customWidth,
    minHeight: 220,
  })
);

const SlideStyled = styled.div({
  margin: "6px 0px 6px 0px",
});

export default Slide;
