import { Identifiers } from "../enums";
import { Translation } from "../Translation.Service";
import { LanguagePreference } from "../../../shared/types";

const settingsTranslations: Translation = {
  [Identifiers.Title]: {
    [LanguagePreference.English]: "Account Settings",
    [LanguagePreference.Spanish]: "Configuraciones de la cuenta",
    [LanguagePreference.Vietnamese]: "Cài đặt tài khoản",
  },
  [Identifiers.Edit]: {
    [LanguagePreference.English]: "Edit Info",
    [LanguagePreference.Spanish]: "Editar",
    [LanguagePreference.Vietnamese]: "Biên tập",
  },
  [Identifiers.Change]: {
    [LanguagePreference.English]: "Change",
    [LanguagePreference.Spanish]: "Cambio",
    [LanguagePreference.Vietnamese]: "Biến đổi",
  },
  [Identifiers.Nevermind]: {
    [LanguagePreference.English]: "Nevermind",
    [LanguagePreference.Spanish]: "No importa",
    [LanguagePreference.Vietnamese]: "Đừng bận tâm",
  },
  [Identifiers.Login]: {
    [LanguagePreference.English]: "Login Info",
    [LanguagePreference.Spanish]: "Información de inicio de sesión",
    [LanguagePreference.Vietnamese]: "Thông tin đăng nhập",
  },
  [Identifiers.Email]: {
    [LanguagePreference.English]: "Email",
    [LanguagePreference.Spanish]: "Correo electrónico",
    [LanguagePreference.Vietnamese]: "E-mail",
  },
  [Identifiers.Password]: {
    [LanguagePreference.English]: "Password",
    [LanguagePreference.Spanish]: "Contraseña",
    [LanguagePreference.Vietnamese]: "Mật khẩu",
  },
  [Identifiers.ConfirmPassword]: {
    [LanguagePreference.English]: "Confirm Password",
    [LanguagePreference.Spanish]: "Confirmar contraseña",
    [LanguagePreference.Vietnamese]: "Xác nhận mật khẩu",
  },
  [Identifiers.PersonalInfo]: {
    [LanguagePreference.English]: "Personal Info",
    [LanguagePreference.Spanish]: "Información personal",
    [LanguagePreference.Vietnamese]: "Thông tin cá nhân",
  },
  [Identifiers.Nickname]: {
    [LanguagePreference.English]: "Nickname",
    [LanguagePreference.Spanish]: "Apodo",
    [LanguagePreference.Vietnamese]: "tên nick",
  },
  [Identifiers.FullName]: {
    [LanguagePreference.English]: "Full Name",
    [LanguagePreference.Spanish]: "Nombre completo",
    [LanguagePreference.Vietnamese]: "Họ và tên",
  },
  [Identifiers.PhoneNumber]: {
    [LanguagePreference.English]: "Phone Number",
    [LanguagePreference.Spanish]: "número de teléfono",
    [LanguagePreference.Vietnamese]: "số điện thoại",
  },
  [Identifiers.Language]: {
    [LanguagePreference.English]: "Language",
    [LanguagePreference.Spanish]: "Idioma",
    [LanguagePreference.Vietnamese]: "Ngôn ngữ",
  },
  [Identifiers.Preferences]: {
    [LanguagePreference.English]: "Preferences",
    [LanguagePreference.Spanish]: "Preferencias",
    [LanguagePreference.Vietnamese]: "Sở thích",
  },
  [Identifiers.PasswordError]: {
    [LanguagePreference.English]: "Please enter a valid password",
    [LanguagePreference.Spanish]: "Por favor introduce una contraseña válida",
    [LanguagePreference.Vietnamese]: "Vui lòng nhập mật khẩu hợp lệ",
  },
  [Identifiers.EmailError]: {
    [LanguagePreference.English]: "Please enter a valid email address",
    [LanguagePreference.Spanish]:
      "Por favor, introduce una dirección de correo electrónico válida",
    [LanguagePreference.Vietnamese]: "Vui lòng nhập một địa chỉ email hợp lệ",
  },
  [Identifiers.PhoneError]: {
    [LanguagePreference.English]: "Please enter a valid number",
    [LanguagePreference.Spanish]: "Por favor ingrese un número valido",
    [LanguagePreference.Vietnamese]: "Vui lòng nhập một số hợp lệ",
  },
  [Identifiers.VerbosePasswordError]: {
    [LanguagePreference.English]:
      "Password should be 8 characters minimum and contain at least 1 number and 1 letter",
    [LanguagePreference.Spanish]:
      "La contraseña debe tener un mínimo de 8 caracteres y contener al menos 1 número y 1 letra",
    [LanguagePreference.Vietnamese]:
      "Mật khẩu phải có tối thiểu 8 ký tự và chứa ít nhất 1 số và 1 chữ cái",
  },
  [Identifiers.VerbosePhoneError]: {
    [LanguagePreference.English]:
      "Phone number should contain no letters and no special characters",
    [LanguagePreference.Spanish]:
      "El número de teléfono no debe contener letras ni caracteres especiales",
    [LanguagePreference.Vietnamese]:
      "Số điện thoại không được chứa chữ cái và không có ký tự đặc biệt",
  },
  [Identifiers.Save]: {
    [LanguagePreference.English]: "Save Changes",
    [LanguagePreference.Spanish]: "Guardar cambios",
    [LanguagePreference.Vietnamese]: "Lưu thay đổi",
  },
};

export default settingsTranslations;
