import { css, StyleSheet } from "aphrodite";
import { ApolloError } from "apollo-boost";
import { ROUTES } from "../../../constants";
import React from "react";
import { useHistory } from "react-router";
import Button from "../Button";
import Text, { Font } from "../Text";
import { white } from "shared/styles/colors";

interface Props {
  error: ApolloError;
}

const GraphqlErrorScreen = ({ error }: Props) => {
  console.error(error);
  const history = useHistory();
  return (
    <section className={css(styles.GraphqlErrorScreen)}>
      {/* TODO: don't surface this screen in production */}
      <Text style={styles.text} font={Font.ProximaNovaBold}>
        {error.message}
      </Text>
      <Button
        onClick={() => history.push(ROUTES.DASHBOARD)}
        text="Go To Dashboard"
        ariaLabel="Go To Dashboard"
      />
    </section>
  );
};

export default GraphqlErrorScreen;

const styles = StyleSheet.create({
  GraphqlErrorScreen: {
    position: "fixed",
    left: 0,
    display: "flex",
    width: "100vw",
    height: "100vh",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    background: white,
  },
  text: {
    marginBottom: 10,
    textAlign: "center",
  },
  //
});
