import React from "react";
import { css, StyleSheet } from "aphrodite";
import { greyAccent, turquoise, mainGrey } from "shared/styles/colors";
import Text, { Font } from "../../shared/components/Text";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import GraphqlErrorScreen from "shared/components/ErrorBoundary/GraphqlErrorScreen";

interface Props {
  text: string;
}

const FOCUS_TEXT = gql`
  query CurrentFocus {
    currentUserFocus {
      translationData {
        name
      }
    }
  }
`;

const ActionText = ({ text }: Props) => {
  const { data, error } = useQuery(FOCUS_TEXT, { fetchPolicy: "no-cache" });
  const focus = data?.currentUserFocus?.translationData?.name;
  if (error) {
    return <GraphqlErrorScreen error={error} />;
  }
  return (
    <div className={css(styles.actionContainer)}>
      <Text font={Font.ProximaNovaBold} style={styles.lastAction}>
        {text}
      </Text>
      <Text font={Font.ProximaNovaRegular} style={styles.actionText}>
        {focus}
      </Text>
      <div className={css(styles.line)} />
    </div>
  );
};

export default ActionText;

const styles = StyleSheet.create({
  actionContainer: {
    "@media (min-width: 630px)": {
      width: "100%",
      paddingLeft: 60,
      paddingRight: 60,
    },
  },
  lastAction: {
    width: 315,
    marginTop: 12,
    color: turquoise,
    fontSize: 12,
    marginBottom: 6,
    textTransform: "uppercase",
    textAlign: "left",
    letterSpacing: 2,
    lineHeight: 1.2,
    "@media (min-width: 630px)": {
      width: "100%",
    },
  },
  actionText: {
    width: 315,
    color: mainGrey,
    fontSize: 16,
    lineHeight: 1.2,
    marginBottom: 23,
    "@media (min-width: 630px)": {
      width: "100%",
    },
  },
  line: {
    background: greyAccent,
    width: 315,
    height: 1,
    marginBottom: 23,
    "@media (min-width: 630px)": {
      width: "100%",
    },
  },
});
