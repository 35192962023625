import { css, StyleSheet } from "aphrodite";
import React from "react";
import Text, { Font } from "shared/components/Text";
import { Style } from "shared/types";

interface Props {
  title: string;
  titleColor: string;
  textColor: string;
  dataArray: any[];
  textContainerStyle?: Style | Style[];
  primaryTextStyle?: Style;
  secondaryTextStyle?: Style;
  numberStyle?: Style;
}

const Top3Info = ({
  title,
  titleColor,
  textColor,
  dataArray,
  textContainerStyle,
  secondaryTextStyle,
  primaryTextStyle,
  numberStyle,
}: Props) => {
  return (
    <section className={css(styles.Top3Info)}>
      <Text style={styles.title} font={Font.ProximaNovaBold} color={titleColor}>
        {title}
      </Text>
      {dataArray.map((object, index) => (
        <div className={css(styles.textRow)} key={object.text}>
          <Text style={[styles.number, numberStyle]} color={textColor}>
            {index + 1}.
          </Text>
          <div className={css(textContainerStyle)}>
            <Text
              font={Font.ProximaNovaBold}
              style={[styles.text, primaryTextStyle]}
              color={textColor}
            >
              {object.text}
            </Text>
            <Text
              style={[styles.timesSelected, secondaryTextStyle]}
              font={Font.ProximaNovaRegular}
              color={textColor}
            >
              Selected {object.timesSelected} time
              {object.timesSelected === 1 ? "" : "s"}
            </Text>
          </div>
        </div>
      ))}
    </section>
  );
};

export default Top3Info;

const styles = StyleSheet.create({
  Top3Info: {},
  title: {
    fontSize: 12,
    textTransform: "uppercase",
    lineHeight: 1.2,
    letterSpacing: 2,
    marginBottom: 36,
  },
  number: {
    fontSize: 40,
    lineHeight: 0.93,
    letterSpacing: 0,
    marginRight: 15,
    width: 35,
  },
  text: {
    fontSize: 25,
    lineHeight: 1.2,
    marginBottom: 10,
    maxWidth: 420,
  },
  timesSelected: {
    marginBottom: 26,
    fontSize: 25,
  },
  textRow: {
    display: "flex",
  },
  //
});
