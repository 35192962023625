import React, { Dispatch, SetStateAction } from "react";
import styled from "@emotion/styled";
import { LanguagePreference } from "shared/types";
import ModalBase from "./ModalBase";
import Text, { Font } from "./Text";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { StyleSheet } from "aphrodite";
import { useToasts } from "react-toast-notifications";

const TERMS_AND_CONDITIONS = gql`
  query TermsAndConditions($userLanguage: Language!) {
    termsAndConditions(userLanguage: $userLanguage) {
      title
      termsAndConditionsText {
        json
      }
    }
  }
`;

interface Props {
  userLanguage: LanguagePreference;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

const TermsAndConditionsModal = ({
  userLanguage,
  isOpen,
  setIsOpen,
}: Props) => {
  const { addToast } = useToasts();
  const { data, error, loading } = useQuery(TERMS_AND_CONDITIONS, {
    variables: {
      userLanguage,
    },
    onError: () => {
      addToast("Error loading terms and conditions", {
        appearance: "error",
        autoDismiss: true,
      });
    },
  });

  if (!data || loading) {
    return null;
  }

  const documentContent = data.termsAndConditions.termsAndConditionsText.json;
  const richtext = documentToReactComponents(documentContent);

  return (
    <ModalBase
      setModalIsOpen={setIsOpen}
      closeModal={() => setIsOpen(false)}
      modalIsOpen={isOpen}
      contentLabel="terms and conditions"
    >
      <Container>
        <Text font={Font.ProximaNovaBold} style={styles.title}>
          {data?.termsAndConditions?.title}
        </Text>
        <>{richtext}</>
      </Container>
    </ModalBase>
  );
};

export default TermsAndConditionsModal;

const Container = styled.div({
  display: "flex",
  flexDirection: "column",
  padding: "72px 12px 32px",
  height: "90vh",
  width: "95vw",
});

const styles = StyleSheet.create({
  title: {
    textAlign: "center",
    marginBottom: "12px",
    fontSize: 24,
  },
});
