import React, { useEffect, useState } from "react";
import { StyleSheet, css } from "aphrodite";
import Text, { Font } from "shared/components/Text";
import Button from "shared/components/Button";
import {
  turquoise,
  mainGrey,
  greyThree,
  greyAccent,
} from "shared/styles/colors";
import { gql } from "apollo-boost";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useLazyQuery } from "@apollo/react-hooks";
import { Formik, Form, Field } from "formik";
import CookieService, { Identifiers } from "services/Cookie/CookieService";
import { useToasts } from "react-toast-notifications";
import { ReactComponent as ReflectableLogo } from "static/images/ReflectableLogo.svg";
import { ROUTES } from "../../constants";
import { useAuthDataContext } from "shared/components/Auth/AuthDataProvider";
import { TranslationService } from "services/Translation/Translation.Service";
import {
  Page,
  Identifiers as TranslationIdentifiers,
} from "services/Translation/enums";
import Div100vh from "react-div-100vh";
import Spacer from "shared/components/Spacer";
import {
  AuthInputContainer,
  InputLabel,
} from "screens/settings/shared/styledComponents";
import { useDocumentTitle } from "shared/hooks";
import { getLanguageFromUrlParams } from "shared/utils";

const LOGIN = gql`
  query Login($emailOrPhoneNumber: String!, $password: String!) {
    login(emailOrPhoneNumber: $emailOrPhoneNumber, password: $password)
  }
`;

const ROUTING_CHECK = gql`
  query HasUserGoneThroughFirstTimeFlow {
    hasUserGoneThroughFirstTimeFlow
  }
`;

const Login = () => {
  useDocumentTitle("Login | Reflectable");
  const [isCookieSet, setIsCookieSet] = useState(false);
  const { onLogin } = useAuthDataContext();
  const [login, { data, error, loading }] = useLazyQuery(LOGIN);
  const [
    checkHasUserGoneThroughFirstTimeFlow,
    { data: flowData, error: flowError },
  ] = useLazyQuery(ROUTING_CHECK);
  const { addToast } = useToasts();
  const history = useHistory();
  const params: any = useLocation();
  const page = Page.Login;

  const userLanguage = getLanguageFromUrlParams(params);

  useEffect(() => {
    if (data) {
      CookieService.setCookie(Identifiers.AccessToken, data.login);
      // @ts-ignore
      onLogin(data.login);
      setIsCookieSet(true);
    }
    if (error) {
      let message = "Sorry, there was an issue with logging in.";
      if (error.graphQLErrors && error.graphQLErrors.length > 0) {
        message = error.graphQLErrors[0].message;
      }
      addToast(message, {
        appearance: "error",
        autoDismiss: true,
      });
    }
  }, [data, error]);

  useEffect(() => {
    if (isCookieSet) {
      checkHasUserGoneThroughFirstTimeFlow();
    }
  }, [isCookieSet]);

  useEffect(() => {
    if (flowData) {
      if (flowData.hasUserGoneThroughFirstTimeFlow) {
        history.push(ROUTES.DASHBOARD);
      } else {
        history.push(ROUTES.INTRO_ONE, { isFirstTime: true });
      }
    }
    if (flowError) {
      history.push(ROUTES.INTRO_ONE, { isFirstTime: true });
    }
  }, [flowData, flowError]);

  return (
    <Div100vh className={css(styles.Login)}>
      <Formik
        initialValues={{ email: "", password: "" }}
        onSubmit={(values) => {
          if (values.email && values.password) {
            login({
              variables: {
                emailOrPhoneNumber: values.email,
                password: values.password,
              },
            });
          }
        }}
      >
        {({ values }) => (
          <Form className={css(styles.form)} id="login-form">
            <div className={css(styles.innerLogin)}>
              <div className={css(styles.topBar)} />
              <Spacer removeOnDesktop />
              <ReflectableLogo />
              <Text style={styles.header} font={Font.ProximaNovaBold}>
                {TranslationService.getStaticText({
                  page,
                  userLanguage,
                  textIdentifier: TranslationIdentifiers.Title,
                })}
              </Text>
              <Text style={styles.subheader} font={Font.ProximaNovaRegular}>
                {TranslationService.getStaticText({
                  page,
                  userLanguage,
                  textIdentifier: TranslationIdentifiers.SubTitle,
                })}
              </Text>
              <Spacer removeOnDesktop />
              <AuthInputContainer>
                <InputLabel
                  htmlFor="emailOrPhoneNumber"
                  id="email-or-phone-number-input-label"
                >
                  {TranslationService.getStaticText({
                    page,
                    userLanguage,
                    textIdentifier: TranslationIdentifiers.Email,
                  })}
                </InputLabel>
                <Field
                  aria-labelledby="email-or-phone-number-input-label"
                  id="email-or-phone-number-input"
                  name="email"
                  placeholder={TranslationService.getStaticText({
                    page,
                    userLanguage,
                    textIdentifier: TranslationIdentifiers.Email,
                  })}
                  value={values.email}
                  className={css(styles.input)}
                />
                <InputLabel htmlFor="password-input" id="password-input-label">
                  {TranslationService.getStaticText({
                    page,
                    userLanguage,
                    textIdentifier: TranslationIdentifiers.Password,
                  })}
                </InputLabel>
                <Field
                  id="password-input"
                  name="password"
                  placeholder={TranslationService.getStaticText({
                    page,
                    userLanguage,
                    textIdentifier: TranslationIdentifiers.Password,
                  })}
                  type="password"
                  value={values.password}
                  className={css(styles.input)}
                  autoComplete="password"
                />
              </AuthInputContainer>

              <Link
                to={`/forgot-password?language=${userLanguage}`}
                style={styles.forgotPassword}
              >
                {TranslationService.getStaticText({
                  page,
                  userLanguage,
                  textIdentifier: TranslationIdentifiers.ForgotPassword,
                })}
              </Link>
              <Spacer removeOnDesktop />
            </div>
            <Spacer removeOnDesktop />
            <Button
              ariaLabel={TranslationService.getStaticText({
                page,
                userLanguage,
                textIdentifier: TranslationIdentifiers.Login,
              })}
              text={TranslationService.getStaticText({
                page,
                userLanguage,
                textIdentifier: TranslationIdentifiers.Login,
              })}
              type="submit"
              loading={loading}
              disabled={loading}
            />
            <Spacer removeOnDesktop />
          </Form>
        )}
      </Formik>
    </Div100vh>
  );
};

export default Login;

const styles = StyleSheet.create({
  Login: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: 40,
    justifyContent: "center",
    "@media (min-width: 630px)": {
      paddingBottom: 0,
    },
  },
  innerLogin: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "100%",
    "@media (min-width: 630px)": {
      marginBottom: 94,
      boxShadow: "0 2px 2px 1px rgba(0, 0, 0, .1)",
      width: 630,
      height: 660,
      borderRadius: 10,
    },
  },
  topBar: {
    display: "none",
    "@media (min-width: 630px)": {
      width: 630,
      height: 10,
      background: greyAccent,
      display: "flex",
      borderRadius: "10px 10px 0 0",
      marginBottom: 20,
    },
  },
  logo: {
    height: 83,
    width: 120,
    marginBottom: 20,
  },
  header: {
    height: 80,
    width: 335,
    fontSize: 36,
    lineHeight: 1.11,
    color: turquoise,
    marginBottom: 10,
    textAlign: "center",
  },
  subheader: {
    height: 60,
    width: 335,
    lineHeight: 1.25,
    textAlign: "center",
    fontSize: 16,
    color: mainGrey,
    "@media (min-width: 630px)": {
      marginBottom: 99,
    },
  },
  form: {
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
  },
  input: {
    height: 60,
    width: "100%",
    borderRadius: 8,
    border: `1px solid ${greyThree}`,
    marginBottom: 20,
    paddingLeft: 20,
    paddingRight: 20,
  },
  forgotPassword: {
    fontSize: 14,
    lineHeight: 1.14,
    color: turquoise,
    "@media (min-width: 630px)": {
      marginBottom: 94,
    },
  },
  button: {
    height: 60,
    width: 335,
  },
});
