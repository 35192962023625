import styled from "@emotion/styled";
import { StyleSheet } from "aphrodite";
import React from "react";
import Text, { Font } from "shared/components/Text";
import { lightGreyBackground, mainGrey } from "shared/styles/colors";
import ResourcesCard from "./ResourcesCard";

interface Props {
  videoEntries: any[];
  cardTitle: string;
}

const VideoCard = ({ videoEntries, cardTitle }: Props) => {
  return (
    <ResourcesCard title={cardTitle}>
      {videoEntries.map((video) => (
        <Link href={video.link} rel="noreferrer" target="_blank">
          <Image src={video.image.url} alt="thumbnail" title="thumbnail" />
          <TitleContainer>
            <Text
              font={Font.ProximaNovaSemibold}
              color={mainGrey}
              style={styles.title}
            >
              {video.title}
            </Text>
          </TitleContainer>
        </Link>
      ))}
    </ResourcesCard>
  );
};

export default VideoCard;

const styles = StyleSheet.create({
  title: {
    fontSize: 14,
    lineHeight: 1.2,
  },
});

const TitleContainer = styled.div({
  width: "100%",
  height: 37,
  display: "flex",
  alignItems: "center",
  background: lightGreyBackground,
  marginBottom: 25,
  paddingLeft: 15,
  paddingRight: 15,
});

const Image = styled.img({ width: "100%" });

const Link = styled.a({});
