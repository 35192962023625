import { useMutation } from "@apollo/react-hooks";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useToasts } from "react-toast-notifications";
import Button from "shared/components/Button";
import Text, { Font } from "shared/components/Text";
import { settingsFormRed, turquoise } from "shared/styles/colors";
import SettingsCard from "../SettingsCard";
import { UPDATE_USER_INFO } from "../shared/mutations";
import { Form, Input, sharedStyles } from "../shared/styledComponents";
import { Identifiers, Page } from "services/Translation/enums";
import { LanguagePreference } from "shared/types";
import { TranslationService } from "services/Translation/Translation.Service";
import { phoneNumberValidator } from "../../../shared/utils";

interface Props {
  nickname: string;
  fullName: string;
  phoneNumber: string;
  userLanguage: LanguagePreference;
}

const PersonalInfoForm = ({
  nickname,
  fullName,
  phoneNumber,
  userLanguage,
}: Props) => {
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [phoneValidationError, setPhoneValidationError] = useState<boolean>(
    false
  );
  const { register, handleSubmit } = useForm({
    defaultValues: { phoneNumber, fullName, nickname },
  });
  const [
    updateUserInfo,
    {
      data: updateUserData,
      error: updateUserError,
      loading: updateUserLoading,
    },
  ] = useMutation(UPDATE_USER_INFO, { onError: () => {} });
  const { addToast } = useToasts();

  useEffect(() => {
    let message;
    if (updateUserData) {
      addToast("Success", {
        appearance: "success",
        autoDismiss: true,
      });
      setIsEditable(false);
    }
    if (updateUserError) {
      message = "There was an error updating your settings";
      if (
        updateUserError.graphQLErrors &&
        updateUserError.graphQLErrors.length > 0
      ) {
        message = updateUserError.graphQLErrors[0].message;
      }
      addToast(message, {
        appearance: "error",
        autoDismiss: true,
      });
    }
    if (updateUserData && updateUserData.updateUserInfo?.updatedLanguage) {
      window.location.reload();
    }
  }, [updateUserData, updateUserError]);

  const onSubmit = (data) => {
    if (phoneNumberValidator(data.phoneNumber)) {
      updateUserInfo({
        variables: data,
      });
      setPhoneValidationError(false);
    } else {
      setPhoneValidationError(true);
    }
  };

  const page = Page.Settings;

  return (
    <SettingsCard
      userLanguage={userLanguage}
      title={TranslationService.getStaticText({
        page,
        userLanguage,
        textIdentifier: Identifiers.PersonalInfo,
      })}
      setEditState={setIsEditable}
      editState={isEditable}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Text
          color={turquoise}
          style={sharedStyles.inputLabel}
          font={Font.ProximaNovaBold}
        >
          {TranslationService.getStaticText({
            page,
            userLanguage,
            textIdentifier: Identifiers.Nickname,
          })}
        </Text>
        <Input
          disabled={!isEditable}
          isActive={isEditable}
          {...register("nickname")}
        />
        <Text
          color={turquoise}
          style={sharedStyles.inputLabel}
          font={Font.ProximaNovaBold}
        >
          {TranslationService.getStaticText({
            page,
            userLanguage,
            textIdentifier: Identifiers.FullName,
          })}
        </Text>
        <Input
          {...register("fullName")}
          disabled={!isEditable}
          isActive={isEditable}
        />
        <Text
          color={phoneValidationError ? settingsFormRed : turquoise}
          style={sharedStyles.inputLabel}
          font={Font.ProximaNovaBold}
        >
          {TranslationService.getStaticText({
            page,
            userLanguage,
            textIdentifier: Identifiers.PhoneNumber,
          })}
        </Text>
        <Input
          disabled={!isEditable}
          isActive={isEditable}
          {...register("phoneNumber")}
          isError={phoneValidationError}
        />
        {phoneValidationError && (
          <>
            <Text style={sharedStyles.settingsError}>
              {TranslationService.getStaticText({
                page,
                userLanguage,
                textIdentifier: Identifiers.PhoneError,
              })}
            </Text>
            <Text style={sharedStyles.explicitError}>
              {TranslationService.getStaticText({
                page,
                userLanguage,
                textIdentifier: Identifiers.VerbosePhoneError,
              })}
            </Text>
          </>
        )}
        {isEditable && (
          <Button
            ariaLabel={TranslationService.getStaticText({
              page,
              userLanguage,
              textIdentifier: Identifiers.Save,
            })}
            text={TranslationService.getStaticText({
              page,
              userLanguage,
              textIdentifier: Identifiers.Save,
            })}
            buttonStyle={sharedStyles.buttonStyle}
            type="submit"
            loading={updateUserLoading}
          />
        )}
      </Form>
    </SettingsCard>
  );
};

export default PersonalInfoForm;
