/* eslint-disable no-plusplus */
import { useQuery } from "@apollo/react-hooks";
import { css, StyleSheet } from "aphrodite";
import gql from "graphql-tag";
import React, { useEffect, useRef, useState } from "react";
import { white, adminOrange, disabledGrey } from "shared/styles/colors";
import Text from "shared/components/Text";
import GraphSpinner from "./GraphSpinner";
import ChartistGraph from "react-chartist";
import Chartist from "chartist";
import ChartistTooltip from "chartist-plugin-tooltips-updated";
import "chartist-plugin-tooltips";
import "chartist-plugin-legend";
import "./lineGraph.css";
import _ from "lodash";
import {
  createFullTicksArray,
  graphListener,
  sliceGraphData,
  generateBehaviorsValues,
  updateDataView,
  checkForDisabled,
  updateDoubleLineGraphView,
  generateValuesArray,
} from "./utils";
import { TranslationService } from "services/Translation/Translation.Service";
import {
  Page,
  Identifiers as TextIdentifiers,
} from "services/Translation/enums";
import { Direction, GraphProps } from "shared/types";
import styled from "@emotion/styled";
import { GraphButton } from "./styledComponents";
import BackCarrat from "static/svgs/BackCarrat";

const LINE_GRAPH_QUERY = gql`
  query LineGraphQuestionResponsesByUser(
    $questionId: String!
    $topicId: String!
    $secondQuestionId: String!
  ) {
    graphQuestionResponsesByUser(questionId: $questionId, topicId: $topicId) {
      id
      answerInteger
      createdAt
      choices {
        id
        order
        translationData {
          text
        }
      }
      reflection {
        focus {
          topic {
            id
            translationData {
              name
            }
          }
        }
      }
    }
    secondGraphQuestionResponsesByUser: graphQuestionResponsesByUser(
      questionId: $secondQuestionId
      topicId: $topicId
    ) {
      id
      answerInteger
      createdAt
      choices {
        id
        order
        translationData {
          text
        }
      }
      reflection {
        focus {
          topic {
            id
            translationData {
              name
            }
          }
        }
      }
    }
    userLanguage
  }
`;

const DoubleLineGraph = ({
  questionId,
  textColor,
  questionTitle,
  topicId,
  bottomAxis = "",
  topAxis = "",
  setInsufficientData,
  secondQuestionId,
  userLanguage,
}: GraphProps) => {
  const [startIndex, setStartIndex] = useState<number>(0);

  const { data, loading } = useQuery(LINE_GRAPH_QUERY, {
    variables: { questionId, topicId, secondQuestionId },
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (
      data?.graphQuestionResponsesByUser &&
      data.graphQuestionResponsesByUser.length > 5
    ) {
      setStartIndex(data.graphQuestionResponsesByUser.length - 5);
    }
  }, [data]);

  const page = Page.Graphs;
  const chartRef = useRef<ChartistGraph>(null);
  const fullPositiveBehaviorArray: any[] = data?.graphQuestionResponsesByUser;
  const fullNegativeBehaviorArray = data?.secondGraphQuestionResponsesByUser;
  const fullTicksArray = createFullTicksArray(fullPositiveBehaviorArray);
  const slicedTicks = sliceGraphData(fullTicksArray, startIndex);
  const generatedNegativeSeries = generateBehaviorsValues({
    responses: fullNegativeBehaviorArray,
    topAxis,
    bottomAxis,
  });
  const generatedPositiveSeries = generateBehaviorsValues({
    responses: fullPositiveBehaviorArray,
    topAxis,
    bottomAxis,
  });

  // eslint-disable-next-line no-unused-expressions
  generatedPositiveSeries?.series.push(generatedNegativeSeries?.series[0]);

  const graphData = updateDoubleLineGraphView({
    combinedSeries: generatedPositiveSeries,
    startIndex,
  });

  // Get the max and min numbers from both the positive and negative series
  const firstLabelArray = generateValuesArray(
    generatedPositiveSeries?.series[0]
  );
  const secondLabelArray = generateValuesArray(
    generatedNegativeSeries?.series[0]
  );
  const firstMaxLabelValue = _.max(firstLabelArray);
  const firstMinLabelValue = _.min(firstLabelArray);
  const secondMaxLabelValue = _.max(secondLabelArray);
  const secondMinLabelValue = _.min(secondLabelArray);

  // Ensure firstMaxLabelValue and secondMaxLabelValue are numbers during first couple renders so that
  // maxLabelValue won't error
  const firstMaxValue =
    typeof firstMaxLabelValue === "number" ? firstMaxLabelValue : 0;
  const secondMaxValue =
    typeof secondMaxLabelValue === "number" ? secondMaxLabelValue : 0;
  const firstMinValue =
    typeof firstMinLabelValue === "number" ? firstMinLabelValue : 0;
  const secondMinValue =
    typeof secondMinLabelValue === "number" ? secondMinLabelValue : 0;

  // Grab the max and min numbers from whichever series is higher/lower and use as the y-axis low and high values
  const maxLabelValue = Math.max(firstMaxValue, secondMaxValue);
  const minLabelValue = Math.min(firstMinValue, secondMinValue);

  const positiveBehaviorArrayLength = fullPositiveBehaviorArray?.length;
  const negativeBehaviorArrayLength = fullNegativeBehaviorArray?.length;
  const longerBehaviorArray = Math.max(
    positiveBehaviorArrayLength,
    negativeBehaviorArrayLength
  );
  const disabledCheck = checkForDisabled({
    startIndex,
    fullArray: fullPositiveBehaviorArray,
    maxArrayLength: longerBehaviorArray,
  });
  const handleButtonClick = (direction: Direction) => {
    const newStartIndex = updateDataView(
      direction,
      startIndex,
      fullPositiveBehaviorArray
    );
    setStartIndex(newStartIndex);
  };

  useEffect(() => {
    if (data) {
      const hasInsufficientData =
        data.graphQuestionResponsesByUser.length < 2 ?? true;
      setInsufficientData(hasInsufficientData);
    }
  }, [data, setInsufficientData]);

  const selectOptions = () => {
    if (bottomAxis && topAxis) {
      return {
        lineSmooth: true,
        low: minLabelValue,
        high: maxLabelValue,
        axisX: {
          type: Chartist.StepAxis,
          ticks: slicedTicks,
          low: slicedTicks[0],
          high: slicedTicks[slicedTicks.length - 1],
        },
        axisY: {
          labelInterpolationFnc(value, index) {
            if (bottomAxis && topAxis) {
              if (index === 0) {
                return bottomAxis;
              }
              if (value === maxLabelValue) {
                return topAxis;
              }
            }
            return null;
          },
        },
        height: "200px",
        width: "320px",
        classNames: { series: "dl-series" },
        plugins: [
          ChartistTooltip({
            anchorToPoint: true,
            appendToBody: false,
            transformTooltipTextFnc: () => "",
            metaIsHTML: true,
            class: "lg-tooltip",
            tooltipOffset: {
              x: 0,
              y: 20,
            },
          }),
          Chartist.plugins.legend({
            legendNames: [
              TranslationService.getStaticText({
                page: Page.Graphs,
                textIdentifier: TextIdentifiers.Positive,
                userLanguage,
              }),
              TranslationService.getStaticText({
                page: Page.Graphs,
                textIdentifier: TextIdentifiers.Challenging,
                userLanguage,
              }),
            ],
            clickable: false,
          }),
        ],
      };
    }
    return {
      lineSmooth: true,
      ticks: [0, 1, 2, 3, 4, 5],
      low: 0,
      high: 5,
      height: "200px",
      width: "320px",
    };
  };

  const options = selectOptions();

  if (loading) {
    return <GraphSpinner />;
  }

  return (
    <div className={`double-line-graph ${css(styles.LineGraph)}`}>
      <Text style={styles.dynamicText} color={adminOrange}>
        {questionTitle}
      </Text>
      <ChartistGraph
        ref={chartRef}
        data={graphData}
        options={options}
        type="Line"
        listener={graphListener}
        className="chartistGraph"
      />
      <TextAndButtonContainer>
        <Text style={styles.xAxisText} color={textColor}>
          {TranslationService.getStaticText({
            page,
            textIdentifier: TextIdentifiers.WeeksPracticed,
            userLanguage,
          })}
        </Text>
        <ButtonContainer>
          <GraphButton
            onClick={() => handleButtonClick(Direction.Prev)}
            style={{ marginRight: 10 }}
            isLeftButton
            // TODO Consolidate disabled props
            disabled={startIndex === 0}
            isDisabled={startIndex === 0}
          >
            <BackCarrat color={startIndex === 0 ? disabledGrey : white} />
          </GraphButton>
          <GraphButton
            onClick={() => handleButtonClick(Direction.Next)}
            // TODO Consolidate disabled props
            isDisabled={disabledCheck}
            disabled={disabledCheck}
          >
            <BackCarrat color={disabledCheck ? disabledGrey : white} />
          </GraphButton>
        </ButtonContainer>
      </TextAndButtonContainer>
    </div>
  );
};

export default DoubleLineGraph;

const ButtonContainer = styled.div({
  display: "flex",
});

const TextAndButtonContainer = styled.div({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "80%",
  marginLeft: 35,
});

const styles = StyleSheet.create({
  LineGraph: {
    background: white,
    marginTop: 20,
    marginBottom: 20,
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  dynamicText: {
    textTransform: "uppercase",
    fontSize: 12,
    letterSpacing: 2,
    marginBottom: 15,
    width: 315,
  },
  xAxisText: {
    fontSize: 10,
  },
  nextButton: {
    marginLeft: 10,
  },
});
