import { useQuery } from "@apollo/react-hooks";
import { css, StyleSheet } from "aphrodite";
import gql from "graphql-tag";
import React from "react";
import Text, { Font } from "shared/components/Text";
import { useHistory } from "react-router";
import { backgroundWhite, mainGrey, turquoise } from "shared/styles/colors";
import ReflectionSummaryCard from "./ReflectionSummaryCard";
import { ROUTES } from "../../constants";
import GraphqlErrorScreen from "shared/components/ErrorBoundary/GraphqlErrorScreen";
import { TranslationService } from "services/Translation/Translation.Service";
import { Identifiers, Page } from "services/Translation/enums";
import ResponsiveCard from "shared/components/ResposniveCard";
import HamburgerMenu from "shared/components/HamburgerMenu/HamburgerMenu";
import { useDocumentTitle } from "shared/hooks";

const REFLECTIONS_SUMMARY_QUERY = gql`
  query ReflectionsSummaryQuery {
    reflectionsSummary {
      id
      startDate
      completionDate
      order
      focus {
        topic {
          id
          color
          translationData {
            name
          }
        }
      }
    }
    userLanguage
  }
`;

const ReflectionsSummary = () => {
  useDocumentTitle("Reflections Summary | Reflectable");
  const { data, loading, error } = useQuery(REFLECTIONS_SUMMARY_QUERY, {
    fetchPolicy: "no-cache",
  });
  const history = useHistory();

  if (loading) {
    return null;
  }

  if (error) {
    return <GraphqlErrorScreen error={error} />;
  }

  const page = Page.ReflectionsSummary;
  const reflections = data?.reflectionsSummary;
  const userLanguage = data?.userLanguage ?? "ENGLISH";

  return (
    <>
      <HamburgerMenu logoIsVisible />
      <section className={css(styles.ReflectionsSummary)}>
        <div className={css(styles.topContainer)}>
          <Text font={Font.ProximaNovaBold} style={styles.title}>
            {TranslationService.getStaticText({
              page,
              userLanguage,
              textIdentifier: Identifiers.Title,
            })}
          </Text>
          <Text font={Font.ProximaNovaSemibold} style={styles.subTitle}>
            {TranslationService.getStaticText({
              page,
              userLanguage,
              textIdentifier: Identifiers.SubTitle,
            })}
          </Text>
        </div>
        <ResponsiveCard contentStyle={styles.reflectionsContainer}>
          {reflections?.map((reflection, index) => {
            return (
              <ReflectionSummaryCard
                reflectionTranslation={TranslationService.getStaticText({
                  page,
                  userLanguage,
                  textIdentifier: Identifiers.Reflection,
                })}
                reflectionNumber={reflection.order}
                completionDate={reflection?.completionDate}
                key={index}
                topicName={reflection?.focus?.topic?.translationData?.name}
                topicColor={reflection?.focus?.topic?.color}
                onClick={
                  () =>
                    history.push(
                      `${ROUTES.REFLECTION_DETAILS}/${reflection.id}`
                    )
                  // eslint-disable-next-line react/jsx-curly-newline
                }
              />
            );
          })}
        </ResponsiveCard>
      </section>
    </>
  );
};

export default ReflectionsSummary;

const styles = StyleSheet.create({
  ReflectionsSummary: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minHeight: "100vh",
    background: backgroundWhite,
  },
  topContainer: {
    "@media (min-width: 630px)": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      width: 630,
    },
    width: "100%",
    paddingLeft: 20,
    paddingTop: 78,
    marginBottom: 20,
  },
  title: {
    textTransform: "capitalize",
    color: turquoise,
    fontSize: 32,
    width: 345,
    marginBottom: 10,
  },
  subTitle: {
    color: mainGrey,
    fontSize: 16,
    marginBottom: 10,
  },
  reflectionsContainer: {
    paddingLeft: 10,
    paddingRight: 10,
    width: "100%",
    "@media (min-width: 630px)": {
      paddingLeft: 20,
      paddingRight: 20,
      paddingTop: 20,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
  },
});
