import { Identifiers } from "../enums";
import { Translation } from "../Translation.Service";
import { LanguagePreference } from "../../../shared/types";

const DashBoardTranslation: Translation = {
  [Identifiers.Title]: {
    [LanguagePreference.English]: "Dashboard",
    [LanguagePreference.Spanish]: "Tablero",
    [LanguagePreference.Vietnamese]: "Bảng điều khiển",
  },
  [Identifiers.SubTitle]: {
    [LanguagePreference.English]: "Check out what you’ve accomplished.",
    [LanguagePreference.Spanish]: "Revise lo que ha logrado.",
    [LanguagePreference.Vietnamese]: "Kiểm tra những gì bạn đã hoàn thành.",
  },
  [Identifiers.SubTitleFirstTime]: {
    [LanguagePreference.English]: "Here’s what you are working on this week:",
    [LanguagePreference.Spanish]:
      "Esto es en lo que estoy trabajando esta semana: ",
    [LanguagePreference.Vietnamese]:
      "Đây là những gì bạn đang làm trong tuần này:",
  },
  [Identifiers.Reflection]: {
    [LanguagePreference.English]: "Reflections Completed",
    [LanguagePreference.Spanish]: "Reflexiones completadas",
    [LanguagePreference.Vietnamese]: "SUY NGẫM ĐÃ HOÀN THÀNH",
  },
  [Identifiers.ViewProgress]: {
    [LanguagePreference.English]: "Go to Progress",
    [LanguagePreference.Spanish]: "Ir al progreso",
    [LanguagePreference.Vietnamese]: "Đi đến Tiến trình",
  },
  [Identifiers.ViewReflections]: {
    [LanguagePreference.English]: "Go to Reflections",
    [LanguagePreference.Spanish]: "Ir a Reflexiones",
    [LanguagePreference.Vietnamese]: "Đi đến Suy ngẫm",
  },
  [Identifiers.TopicSelect]: {
    [LanguagePreference.English]: "THIS WEEK’S TOPIC",
    [LanguagePreference.Spanish]: "TEMA DE ESTA SEMANA",
    [LanguagePreference.Vietnamese]: "CHỦ ĐỀ TUẦN NÀY",
  },
  [Identifiers.Action]: {
    [LanguagePreference.English]: "Action",
    [LanguagePreference.Spanish]: "Acción",
    [LanguagePreference.Vietnamese]: "Hành động",
  },
  [Identifiers.CurrentFocus]: {
    [LanguagePreference.English]: "current focus",
    [LanguagePreference.Spanish]: "enfoque actual",
    [LanguagePreference.Vietnamese]: "Tiêu điểm hiện tại",
  },
  [Identifiers.OutOf]: {
    [LanguagePreference.English]: "out of",
    [LanguagePreference.Spanish]: "de",
    [LanguagePreference.Vietnamese]: "trên",
  },
  [Identifiers.LogOut]: {
    [LanguagePreference.English]: "log out",
    [LanguagePreference.Spanish]: "cerrar sesión",
    [LanguagePreference.Vietnamese]: "đăng xuất",
  },
  [Identifiers.ReflectionCardTitle]: {
    [LanguagePreference.English]:
      "Time to reflect on your previous topic and action",
    [LanguagePreference.Spanish]:
      "Es momento de reflexionar sobre tu tema y acción anteriores.",
    [LanguagePreference.Vietnamese]:
      "Đã đến lúc suy ngẫm về chủ đề và hành động trước đó của bạn.",
  },
  [Identifiers.ReflectionCardTitleButton]: {
    [LanguagePreference.English]: "Start Reflection",
    [LanguagePreference.Spanish]: "Iniciar Reflexión",
    [LanguagePreference.Vietnamese]: "Bắt đầu Suy ngẫm",
  },
  [Identifiers.ReflectionsCompletedCardTitle]: {
    [LanguagePreference.English]: "Reflection Cycle Is Complete",
    [LanguagePreference.Spanish]: "El ciclo de reflexión está completo",
    [LanguagePreference.Vietnamese]: "Chu kỳ suy ngẫm đã hoàn tất",
  },
  [Identifiers.ReflectionsCompletedCardSubTitle]: {
    [LanguagePreference.English]:
      "You have completed your cycle but feel free to continue",
    [LanguagePreference.Spanish]:
      "Ha completado su ciclo pero siéntase libre de continuar",
    [LanguagePreference.Vietnamese]:
      "Bạn đã hoàn thành chu kỳ của mình nhưng hãy tiếp tục",
  },
};

export default DashBoardTranslation;
