/* eslint-disable react/jsx-curly-newline */
import React, { useEffect, useState } from "react";
import { StyleSheet, css } from "aphrodite";
import Button, { ButtonType } from "../InvitationButton";
import { Link, useHistory, useLocation } from "react-router-dom";
import { ReactComponent as ReflectableLogo } from "static/images/ReflectableLogo.svg";
import { LanguagePreference, UserFlow, UserType } from "shared/types";
import { useDocumentTitle } from "shared/hooks";
import styled from "@emotion/styled";
import { useToasts } from "react-toast-notifications";
import { TranslationService } from "services/Translation/Translation.Service";
import {
  Page,
  Identifiers as TextIdentifiers,
} from "services/Translation/enums";
import {
  SignUpComponentName,
  UserData,
  Direction,
} from "../../../shared/types/index";
import {
  emailValidator,
  getLanguageFromUrlParams,
  handleComponentChange,
} from "../../../shared/utils";
import TellUsAboutYourself from "./TellUsAboutYourself";
import ProgressBar from "../ProgressBar";
import Carousel from "../Carousel";
import Text, { Font } from "shared/components/Text";
import { ROUTES } from "../../../constants";
import { darkGreenBlue, lowOpacityWhite, offBlack } from "shared/styles/colors";
import WelcomeScreen from "./Welcome";
import PersonalizeReflectable from "./PersonalizeReflectable";
import Spacer from "shared/components/Spacer";
import SignUpScreen from "../SignUpScreen";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import CookiesService, { Identifiers } from "services/Cookie/CookieService";

const CREATE_USER = gql`
  mutation CreateUser(
    $phoneNumber: String
    $email: String!
    $nickname: String!
    $fullName: String!
    $languagePreference: Language
    $password: String!
    $userType: String!
    $curriculumId: String!
  ) {
    createUser(
      phoneNumber: $phoneNumber
      email: $email
      nickname: $nickname
      fullName: $fullName
      languagePreference: $languagePreference
      password: $password
      userType: $userType
      curriculumId: $curriculumId
    )
  }
`;

const NewUserFrame = () => {
  useDocumentTitle("Sign Up | Reflectable");
  const [createUser, { loading, data }] = useMutation(CREATE_USER, {
    onError: (error) => {
      addToast(error.message, {
        appearance: "error",
        autoDismiss: true,
      });
    },
    onCompleted: (response) => {
      CookiesService.setCookie(Identifiers.AccessToken, response.createUser);
      history.push(ROUTES.HOW_IT_WORKS, { isFirstTime: true });
    },
  });
  const [userData, setUserData] = useState<UserData>({
    fullName: "",
    nickname: "",
    phoneNumber: "",
    email: "",
    password: "",
    confirmPassword: "",
    languagePreference: LanguagePreference.English,
    userType: UserType.ASSISTANT_TEACHER,
  });
  const [currentCurriculumId, setCurrentCurriculumId] = useState("");
  const history = useHistory();
  const [currentComponent, setCurrentComponent] = useState<SignUpComponentName>(
    "carousel"
  );
  const [checkboxChecked, setCheckboxChecked] = useState<boolean>(false);
  const [checkboxError, setCheckboxError] = useState<boolean>(false);
  const [isNextDisabled, setIsNextDisabled] = useState<boolean>(false);
  const [isPrevDisabled, setIsPrevDisabled] = useState<boolean>(false);

  const params: any = useLocation();

  const userLanguage = getLanguageFromUrlParams(params);

  const handleCarouselButtonState = () => {
    setIsPrevDisabled(true);
    setIsNextDisabled(false);
  };

  useEffect(() => {
    return currentComponent === "carousel"
      ? handleCarouselButtonState()
      : setIsPrevDisabled(false);
  }, [currentComponent]);

  useEffect(() => {
    const userSignedIn = CookiesService.getCookie(Identifiers.AccessToken);
    if (userSignedIn) {
      history.push(ROUTES.DASHBOARD);
    }
  }, [history]);

  const componentsList: SignUpComponentName[] = [
    "carousel",
    "welcomeScreen",
    "aboutScreen",
    "personalizeScreen",
    "signUpScreen",
  ];

  const onUserInfoChange = ({
    fullName,
    nickname,
    phoneNumber,
  }: {
    fullName?: string;
    nickname?: string;
    phoneNumber?: string;
  }) => {
    setUserData((prevUserData) => ({
      ...prevUserData,
      fullName: fullName ?? prevUserData.fullName,
      nickname: nickname ?? prevUserData.nickname,
      phoneNumber: phoneNumber ?? prevUserData.phoneNumber,
    }));
  };

  const tellUsAboutYourselfChange = ({
    newLanguagePreference,
    newUserType,
  }: {
    newLanguagePreference?: LanguagePreference | string;
    newUserType?: UserType | string;
  }) => {
    setUserData((prevUserData) => ({
      ...prevUserData,
      languagePreference:
        newLanguagePreference ?? prevUserData.languagePreference,
      userType: newUserType ?? prevUserData.userType,
    }));
  };

  const onEmailAndPasswordChange = (
    changedEmail: string,
    changedPassword: string,
    changedConfirmPassword: string
  ) => {
    setUserData((prevUserData) => ({
      ...prevUserData,
      email: changedEmail,
      password: changedPassword,
      confirmPassword: changedConfirmPassword,
    }));
  };

  const handleCreateAccount = () => {
    const emailIsValid = emailValidator(userData.email);

    setCheckboxError(!checkboxChecked);

    if (emailIsValid && checkboxChecked && checkboxChecked) {
      createUser({
        variables: {
          phoneNumber: userData.phoneNumber,
          email: userData.email,
          nickname: userData.nickname,
          fullName: userData.fullName,
          languagePreference: userData.languagePreference,
          password: userData.password,
          userType: userData.userType,
          curriculumId: currentCurriculumId,
        },
      });
    }
  };

  const { addToast } = useToasts();

  useEffect(() => {
    if (checkboxError) {
      addToast(
        TranslationService.getStaticText({
          page: Page.UserInvited,
          textIdentifier: TextIdentifiers.TermsAndConditionsAgreement,
          userLanguage,
        }),
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
      setCheckboxError(false);
    }
  }, [addToast, checkboxError, userLanguage]);

  const handleLogIn = () => {
    history.push(`${ROUTES.LOGIN}/?language=${userLanguage ?? "ENGLISH"}`);
  };

  return (
    <OuterSignUp>
      <SignUp id="signup">
        <InnerSignUp>
          {currentComponent === "carousel" && (
            <TextContainer>
              <Text
                style={styles.haveAccountText}
                font={Font.ProximaNovaRegular}
                color={offBlack}
              >
                {TranslationService.getStaticText({
                  page: Page.UserInvited,
                  textIdentifier: TextIdentifiers.Account,
                  userLanguage,
                })}
              </Text>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <Link aria-label="Log In Button" to="#" onClick={handleLogIn}>
                <Text style={styles.logInText} color={darkGreenBlue}>
                  {TranslationService.getStaticText({
                    page: Page.UserInvited,
                    textIdentifier: TextIdentifiers.Login,
                    userLanguage,
                  })}
                </Text>
              </Link>
            </TextContainer>
          )}
          <ReflectableLogo className={css(styles.reflectablesLogo)} />
          {currentComponent === "carousel" && (
            <Carousel userLanguage={userLanguage} />
          )}
          {currentComponent !== "carousel" && (
            <ProgressBar
              component={currentComponent}
              userLanguage={userLanguage}
              userFlow={UserFlow.NewUser}
            />
          )}
          {currentComponent === "welcomeScreen" && (
            <WelcomeScreen
              onUserInfoChange={onUserInfoChange}
              userLanguage={userLanguage}
              setIsNextDisabled={setIsNextDisabled}
              fullName={userData.fullName}
              phoneNumber={userData.phoneNumber}
              nickname={userData.nickname}
            />
          )}
          {currentComponent === "aboutScreen" && (
            <TellUsAboutYourself
              onDropDownChange={tellUsAboutYourselfChange}
              userLanguage={userLanguage}
            />
          )}
          {currentComponent === "personalizeScreen" && (
            <PersonalizeReflectable
              currentCurriculumId={currentCurriculumId}
              setCurrentCurriculumId={setCurrentCurriculumId}
              userLanguage={userLanguage}
            />
          )}
          {currentComponent !== "signUpScreen" ? (
            <>
              <Spacer />
              <ButtonWrapper>
                <Button
                  buttonType={ButtonType.Secondary}
                  buttonStyle={styles.prevButton}
                  onClick={() =>
                    handleComponentChange(
                      currentComponent,
                      setCurrentComponent,
                      Direction.Prev,
                      componentsList
                    )
                  }
                  disabled={isPrevDisabled}
                />
                <Button
                  buttonType={ButtonType.Tertiary}
                  buttonStyle={styles.nextButton}
                  onClick={() =>
                    handleComponentChange(
                      currentComponent,
                      setCurrentComponent,
                      Direction.Next,
                      componentsList
                    )
                  }
                  disabled={isNextDisabled}
                />
              </ButtonWrapper>
              <Spacer height={105} />
            </>
          ) : (
            <>
              <SignUpScreen
                userLanguage={userLanguage}
                onCheckboxIsChecked={() => setCheckboxChecked(!checkboxChecked)}
                onEmailAndPasswordChange={onEmailAndPasswordChange}
                isChecked={checkboxChecked}
              />
              <Spacer height={52} />
              <BottomButtonContainer>
                <Button
                  text={TranslationService.getStaticText({
                    page: Page.UserInvited,
                    textIdentifier: TextIdentifiers.CreateAccountButton,
                    userLanguage,
                  })}
                  buttonType={ButtonType.Primary}
                  buttonStyle={styles.createAccountButton}
                  onClick={handleCreateAccount}
                  loading={loading}
                  disabled={loading || data}
                />
              </BottomButtonContainer>
              <Button
                buttonType={ButtonType.Quaternary}
                buttonStyle={styles.noBorderPrevButton}
                onClick={() =>
                  handleComponentChange(
                    currentComponent,
                    setCurrentComponent,
                    Direction.Prev,
                    componentsList
                  )
                }
              />
            </>
          )}
        </InnerSignUp>
      </SignUp>
    </OuterSignUp>
  );
};

export default NewUserFrame;

const OuterSignUp = styled.div({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  paddingBottom: 40,
  justifyContent: "center",
  minHeight: "100vh",
  "@media (min-width: 630px)": {
    paddingBottom: 0,
    minHeight: "90vh",
  },
});

const SignUp = styled.div({
  textAlign: "center",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  flex: 1,
});

const InnerSignUp = styled.div({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  height: "100%",
  position: "relative",
  "@media (min-width: 630px)": {
    marginBottom: 94,
    width: 390,
    height: "auto",
    borderRadius: 10,
  },
  flex: 1,
});

const ButtonWrapper = styled.div({
  display: "flex",
  background: lowOpacityWhite,
  position: "fixed",
  bottom: 0,
  zIndex: 11,
  width: "100vw",
  justifyContent: "center",
  alignItems: "center",
  height: 105,
});

const TextContainer = styled.div({
  display: "flex",
  marginTop: 60,
  marginBottom: 5,
  "@media (max-width: 500px)": {
    marginTop: 84,
  },
});

const BottomButtonContainer = styled.div({
  position: "fixed",
  bottom: 0,
  background: lowOpacityWhite,
});

const styles = StyleSheet.create({
  prevButton: {
    height: 59,
    width: 59,
    marginRight: 8,
  },
  noBorderPrevButton: {
    height: 59,
    width: 59,
    position: "absolute",
    top: 0,
    left: 12,
  },
  createAccountButton: {
    height: 59,
    width: 329,
    marginRight: 8,
    marginBottom: 13,
  },
  nextButton: {
    height: 59,
    width: 59,
    marginLeft: 8,
  },
  reflectablesLogo: {
    marginTop: 17,
  },
  haveAccountText: {
    marginRight: 5,
  },
  logInText: {
    textDecoration: "underline",
  },
});
