import { css, StyleSheet } from "aphrodite";
import { Style } from "shared/types";
import React from "react";
import "static/fonts/ProximaNova-Bold.ttf";
import "static/fonts/ProximaNova-Semibold.ttf";
import "static/fonts/ProximaNova-Regular.ttf";

interface Props {
  key?: number | string;
  children?: React.ReactNode;
  style?: Style | Style[];
  font?: Font;
  color?: string | undefined;
}
const Text = ({ children, style, font, color }: Props) => {
  return (
    <div
      style={{ color }}
      className={css(styles.text, font && styles[font], style)}
    >
      {children}
    </div>
  );
};
export enum Font {
  ProximaNovaBold = "ProximaNovaBold",
  ProximaNovaRegular = "ProximaNovaRegular",
  ProximaNovaSemibold = "ProximaNovaSemibold",
}
Text.defaultProps = {
  font: Font.ProximaNovaSemibold,
};
// Styles
// ----------------------------------------------------------------------------
const styles = StyleSheet.create({
  // fonts
  [Font.ProximaNovaBold]: {
    fontFamily: "ProximaNova",
    fontWeight: 700,
  },
  [Font.ProximaNovaRegular]: {
    fontFamily: "ProximaNova",
    fontWeight: 400,
  },
  [Font.ProximaNovaSemibold]: {
    fontFamily: "ProximaNova",
    fontWeight: 600,
  },
  //
  text: {
    fontSize: 14,
  },
});
export default Text;
