/* eslint-disable no-plusplus */
import { ROUTES } from "../constants";
import _ from "lodash";
import CookiesService, {
  Identifiers as CookieIdentifiers,
} from "services/Cookie/CookieService";
import { LanguagePreference } from "./types";
import { SignUpComponentName, Direction } from "./types/index";
import Validator from "password-validator";
import * as EmailValidator from "email-validator";

export const isProduction = () => process.env.NODE_ENV === "production";

export function toggleItemInArray(array: Array<string>, item: any) {
  return _.xor(array, [item]);
}

export function routeToQuestionPageFromPageId(history: any, pageId: string) {
  history.push(`${ROUTES.QUESTIONS}/${pageId}`);
}

export function logout(history: any, userLanguage: string) {
  CookiesService.removeCookie(CookieIdentifiers.AccessToken);
  history.push(`${ROUTES.LOGIN}?language=${userLanguage}`);
}

export const languageStylingSwitcher = (
  englishValue: number,
  spanishValue: number,
  vietnameseValue: number,
  userLanguage: string
) => {
  let stylingValue;
  if (userLanguage === LanguagePreference.English) {
    stylingValue = englishValue;
  } else if (userLanguage === LanguagePreference.Spanish) {
    stylingValue = spanishValue;
  } else if (userLanguage === LanguagePreference.Vietnamese) {
    stylingValue = vietnameseValue;
  }
  return stylingValue;
};

export const handleComponentChange = (
  currentComponent: SignUpComponentName,
  setCurrentComponent: (component: SignUpComponentName) => void,
  direction: Direction.Next | Direction.Prev,
  componentsList: SignUpComponentName[]
) => {
  const currentIndex = componentsList.indexOf(currentComponent);

  if (currentIndex === -1) {
    console.error("Invalid current component:", currentComponent);
    return;
  }

  const nextIndex =
    direction === Direction.Next ? currentIndex + 1 : currentIndex - 1;

  if (nextIndex >= 0 && nextIndex < componentsList.length) {
    setCurrentComponent(componentsList[nextIndex]);
  }
};

export const passwordValidator = (password) => {
  const fullPasswordValidator = new Validator();
  fullPasswordValidator.has().letters().has().digits().has().not().spaces();
  return fullPasswordValidator.validate(password);
};

export const emailValidator = (email) => {
  return EmailValidator.validate(email);
};

export const phoneNumberValidator = (phoneNumber) => {
  const fullPhoneNumberValidator = new Validator();
  fullPhoneNumberValidator
    .has()
    .not()
    .letters()
    .has()
    .digits()
    .has()
    .not()
    .spaces()
    .has()
    .not()
    .symbols();
  return (
    ((phoneNumber.length === 10 || phoneNumber.length === 0) &&
      fullPhoneNumberValidator.validate(phoneNumber)) ||
    phoneNumber.length === 0
  );
};

export const getLanguageFromUrlParams = (params) => {
  const languageParamQuery = new URLSearchParams(params.search);
  let userLanguage = LanguagePreference.English;

  if (languageParamQuery.get("language") === "ENGLISH") {
    userLanguage = LanguagePreference.English;
  } else if (languageParamQuery.get("language") === "SPANISH") {
    userLanguage = LanguagePreference.Spanish;
  } else if (languageParamQuery.get("language") === "VIETNAMESE") {
    userLanguage = LanguagePreference.Vietnamese;
  }
  return userLanguage;
};
