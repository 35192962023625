import React from "react";
import { StyleSheet } from "aphrodite";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../constants";
import ReflectionEndImage from "static/images/ReflectionEndImage.png";
import { Identifiers, Page } from "services/Translation/enums";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { TranslationService } from "services/Translation/Translation.Service";
import GraphqlErrorScreen from "shared/components/ErrorBoundary/GraphqlErrorScreen";
import Card from "shared/components/Card";
import Text, { Font } from "shared/components/Text";
import Button from "shared/components/Button";
import {
  mainGrey,
  turquoise,
  greyAccent,
  backgroundWhite,
} from "shared/styles/colors";
import Div100vh from "react-div-100vh";
import { standardDropShadow } from "shared/styles/constants";
import Spacer from "shared/components/Spacer";
import styled from "@emotion/styled";
import { useDocumentTitle } from "shared/hooks";

const REFLECTION_END_QUERY = gql`
  query {
    currentUser {
      nickname
    }
    currentUserFocus {
      translationData {
        name
      }
      topic {
        id
        translationData {
          name
        }
      }
    }
    userLanguage
    userCompletedReflections {
      totalComplete
      totalReflections
    }
  }
`;

const ReflectionEnd = () => {
  useDocumentTitle("Reflection End | Reflectable");
  const { data, loading, error } = useQuery(REFLECTION_END_QUERY, {
    fetchPolicy: "no-cache",
  });
  const history = useHistory();

  if (loading) {
    return null;
  }

  if (error) {
    return <GraphqlErrorScreen error={error} />;
  }

  if (data) {
    const page = Page.ReflectionEnd;
    const userLanguage = data?.userLanguage ?? "ENGLISH";
    const nickname = data?.currentUser?.nickname;
    const titleSubject = nickname ? `, ${nickname}!` : "";
    const topicText = data.currentUserFocus?.topic?.translationData?.name;
    const focusText = data.currentUserFocus?.translationData?.name;

    return (
      <Container>
        <InnerContainer>
          <TopAccent />
          <ContentContainer>
            <Image
              src={ReflectionEndImage}
              alt="Reflection Complete Celebration"
            />
            <Text style={styles.title} font={Font.ProximaNovaBold}>
              {`${TranslationService.getStaticText({
                page,
                userLanguage,
                textIdentifier: Identifiers.Title,
              })}${titleSubject}`}
            </Text>
            <Text
              style={[styles.bodyBase, styles.bodySmall]}
              font={Font.ProximaNovaRegular}
            >
              {TranslationService.getStaticText({
                page,
                userLanguage,
                textIdentifier: Identifiers.Body,
              })}
            </Text>
            <Spacer />
            <Card
              cardStyle={styles.cardStyle}
              contentStyle={styles.cardContentStyle}
            >
              <Text font={Font.ProximaNovaBold} style={styles.cardTitle}>
                {TranslationService.getStaticText({
                  page,
                  userLanguage,
                  textIdentifier: Identifiers.TopicSelect,
                })}
              </Text>
              <Text
                font={Font.ProximaNovaRegular}
                style={styles.cardDescription}
              >
                {topicText}
              </Text>
              <Line />
              <Text font={Font.ProximaNovaBold} style={styles.cardTitle}>
                {TranslationService.getStaticText({
                  page,
                  userLanguage,
                  textIdentifier: Identifiers.FocusSelect,
                })}
              </Text>
              <Text
                font={Font.ProximaNovaRegular}
                style={styles.cardDescription}
              >
                {focusText}
              </Text>
            </Card>
            <Text
              style={[styles.bodyBase, styles.bodyLarge]}
              font={Font.ProximaNovaSemibold}
            >
              {TranslationService.getStaticText({
                page,
                userLanguage,
                textIdentifier: Identifiers.BodyOne,
              })}
            </Text>
          </ContentContainer>
        </InnerContainer>
        <Button
          ariaLabel={TranslationService.getStaticText({
            page,
            userLanguage,
            textIdentifier: Identifiers.ContinueButton,
          })}
          buttonStyle={styles.button}
          text={TranslationService.getStaticText({
            page,
            userLanguage,
            textIdentifier: Identifiers.ContinueButton,
          })}
          onClick={() => history.push(ROUTES.DASHBOARD)}
        />
      </Container>
    );
  }
  return null;
};

export default ReflectionEnd;

const Container = styled.div({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "10px 10px 5px",
  "@media (min-width: 630px)": {
    justifyContent: "center",
    paddingBottom: 0,
  },
  backgroundColor: backgroundWhite,
});

const InnerContainer = styled.div({
  borderRadius: 10,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginBottom: 10,
  width: "100%",
  "@media (min-width: 630px)": {
    width: 630,
    boxShadow: standardDropShadow,
    marginBottom: 40,
  },
});

const ContentContainer = styled.div({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "100%",
  "@media (min-width: 630px)": {
    padding: "20px 0 20px",
  },
});

const TopAccent = styled.div({
  display: "none",
  height: 10,
  width: "100%",
  borderRadius: "10px 10px 0 0",
  backgroundColor: greyAccent,
  "@media (min-width: 630px)": {
    display: "flex",
  },
});

const Image = styled.img({
  height: 286,
  width: "auto",
  marginBottom: 10,
  "@media (min-width: 630px)": {
    height: 350,
    marginBottom: 30,
  },
  "@media (max-height: 700px)": {
    height: 200,
  },
});

const Line = styled.div({
  width: "100%",
  height: 1,
  backgroundColor: greyAccent,
  marginBottom: 21,
});

const styles = StyleSheet.create({
  button: {
    minHeight: 60,
    marginBottom: 10,
  },
  image: {
    height: 286,
    width: "auto",
    marginBottom: 10,
    "@media (min-width: 630px)": {
      height: 350,
      marginBottom: 30,
    },
    "@media (max-height: 700px)": {
      height: 200,
    },
  },
  title: {
    fontSize: 36,
    lineHeight: 1.2,
    color: turquoise,
    marginBottom: 23,
    textTransform: "capitalize",
    textAlign: "center",
    maxWidth: 475,
    "@media (max-width: 360px)": {
      fontSize: 30,
      marginBottom: 10,
    },
  },
  bodyBase: {
    width: "100%",
    maxWidth: 335,
    lineHeight: 1.4,
    color: mainGrey,
    textAlign: "center",
    marginBottom: 10,
    "@media (min-width: 630px)": {
      maxWidth: 510,
    },
  },
  bodyLarge: {
    fontSize: 16,
    "@media (max-width: 375px)": {
      fontSize: 18,
    },
  },
  bodySmall: {
    fontSize: 16,
  },
  cardStyle: {
    marginBottom: 40,
    maxWidth: "550px",
  },
  cardContentStyle: {
    paddingTop: 34,
    paddingLeft: 20,
    paddingRight: 20,
  },
  cardTitle: {
    fontSize: 14,
    color: turquoise,
    letterSpacing: 2,
    lineHeight: 1.2,
    textTransform: "uppercase",
    marginBottom: 5,
  },
  cardDescription: {
    color: mainGrey,
    fontSize: 16,
    lineHeight: 1.2,
    marginBottom: 20,
  },
});
