import { ALL_TOPICS } from "@quick-check/common";
import { css, StyleSheet } from "aphrodite";
import React, { useState } from "react";
import Text, { Font } from "shared/components/Text";
import { backgroundGrey, turquoise } from "shared/styles/colors";
import AdminEffectivenessGraph from "./AdminEffectivenessGraph";
import CohortGraphQuestions from "./CohortGraphQuestions";
import TopicSelect from "./TopicSelect";

interface Props {
  cohortId: string;
}

const ProgressHighlights = ({ cohortId }: Props) => {
  const [topicId, setTopicId] = useState(ALL_TOPICS);
  return (
    <section className={css(styles.ProgressHighlights)}>
      <div className={css(styles.innerContainer)}>
        <Text font={Font.ProximaNovaBold} style={styles.title}>
          Progress Highlights
        </Text>
        <TopicSelect
          cohortId={cohortId}
          topicId={topicId}
          setTopicId={setTopicId}
        />
        <AdminEffectivenessGraph cohortId={cohortId} topicId={topicId} />
        <CohortGraphQuestions cohortId={cohortId} topicId={topicId} />
      </div>
    </section>
  );
};

export default ProgressHighlights;

const styles = StyleSheet.create({
  ProgressHighlights: {
    width: "100%",
    background: backgroundGrey,
    paddingTop: 83,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: 118,
    minHeight: 1164,
  },
  innerContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: 1200,
  },
  title: {
    fontSize: 36,
    lineHeight: 1,
    marginBottom: 47,
    color: turquoise,
    textAlign: "center",
  },
  //
});
