import { css, StyleSheet } from "aphrodite";
import React from "react";
import Spinner from "react-spinkit";
import { buttonBlue } from "shared/styles/colors";

const GraphSpinner = () => {
  return (
    <div className={css(styles.GraphSpinner)}>
      <Spinner name="line-scale" color={buttonBlue} fadeIn="none" />
    </div>
  );
};

export default GraphSpinner;

const styles = StyleSheet.create({
  GraphSpinner: {
    height: 300,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});
