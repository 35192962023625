import { css, StyleSheet } from "aphrodite";
import Text, { Font } from "shared/components/Text";
import React, { useRef, useEffect } from "react";
import Button from "shared/components/Button";
import { gql } from "apollo-boost";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { mainGrey, turquoise, greyThree } from "shared/styles/colors";
import { useToasts } from "react-toast-notifications";
import { Formik, Form, Field } from "formik";
import { Label } from "semantic-ui-react";
import GraphqlErrorScreen from "shared/components/ErrorBoundary/GraphqlErrorScreen";

const ADD_EXISTING_USERS_TO_COHORT = gql`
  mutation AddUsersToExistingCohort($csvFile: Upload!, $cohortId: String!) {
    addUsersToExistingCohort(csvFile: $csvFile, cohortId: $cohortId) {
      success
    }
  }
`;

const COHORTS_QUERY = gql`
  query {
    cohorts {
      id
      startDate
      name
      curriculum {
        name
      }
      organization {
        name
      }
    }
  }
`;

const UploadLateUsers = () => {
  const [
    addUsersToExistingCohort,
    {
      data: addUsersToExistingCohortSuccess,
      error: addUsersToExistingCohortError,
      loading,
    },
  ] = useMutation(ADD_EXISTING_USERS_TO_COHORT);
  const { data, error } = useQuery(COHORTS_QUERY, {
    fetchPolicy: "no-cache",
  });
  const cohorts = data?.cohorts;

  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleSubmit = (cohortId: string) => {
    if (inputRef?.current?.files) {
      addUsersToExistingCohort({
        variables: {
          csvFile: inputRef.current.files[0],
          cohortId,
        },
      });
    }
  };

  const { addToast } = useToasts();

  useEffect(() => {
    if (addUsersToExistingCohortError) {
      addToast(addUsersToExistingCohortError.message, {
        appearance: "error",
        autoDismiss: false,
      });
    }
    if (addUsersToExistingCohortSuccess) {
      addToast("Success!", {
        appearance: "success",
        autoDismiss: false,
      });
    }
  }, [
    addUsersToExistingCohortError,
    addUsersToExistingCohortSuccess,
    addToast,
  ]);

  if (error) {
    return <GraphqlErrorScreen error={error} />;
  }

  return (
    <section className={css(styles.UploadLateUsers)}>
      <Text font={Font.ProximaNovaBold} style={styles.title}>
        Upload Late Users
      </Text>
      <Formik
        initialValues={{
          cohortId: "",
        }}
        onSubmit={(values) => {
          if (values.cohortId) {
            handleSubmit(values.cohortId);
          } else {
            addToast("Make sure every field is filled out", {
              appearance: "error",
              autoDismiss: true,
            });
          }
        }}
      >
        {({ values, handleChange }) => (
          <Form className={css(styles.form)}>
            <div className={css(styles.row)}>
              <div className={css(styles.column)}>
                <Label>Cohort</Label>
                <Field
                  as="select"
                  name="cohortId"
                  placeholder=""
                  value={values.cohortId}
                  className={css(styles.input)}
                  onChange={handleChange}
                >
                  <option value="">Select cohort...</option>
                  {cohorts &&
                    cohorts.map((cohort) => {
                      const date = new Date(cohort.startDate);
                      const dateDisplay = `${
                        date.getMonth() + 1
                      }/${date.getDate()}/${date.getFullYear()}`;
                      return (
                        <option value={cohort.id} key={cohort.id}>
                          {dateDisplay} - {cohort?.organization?.name},{" "}
                          {cohort?.curriculum?.name}: {cohort?.name}
                        </option>
                      );
                    })}
                </Field>
              </div>
            </div>

            <Text font={Font.ProximaNovaRegular} style={styles.text}>
              To add late users, please upload a csv of the users
            </Text>
            <input
              className={css(styles.fileInput)}
              type="file"
              ref={inputRef}
            />
            <Button
              text="Submit Users"
              type="submit"
              loading={loading}
              disabled={loading}
              ariaLabel="Submit"
            />
          </Form>
        )}
      </Formik>
    </section>
  );
};

const styles = StyleSheet.create({
  UploadLateUsers: {
    paddingTop: 10,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    textAlign: "center",
  },
  column: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
  },
  fileInput: {
    fontSize: 18,
    marginBottom: 50,
    textAlign: "center",
  },
  title: {
    fontSize: 30,
    marginBottom: 25,
    marginTop: 20,
    color: turquoise,
  },
  form: {
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  input: {
    height: 60,
    width: 260,
    borderRadius: 8,
    border: `1px solid ${greyThree}`,
    marginBottom: 20,
    marginRight: 10,
    marginLeft: 10,
    paddingLeft: 20,
    paddingRight: 20,
  },
  text: {
    fontSize: 16,
    color: mainGrey,
    width: 200,
    marginBottom: 30,
  },
  inputContainer: {
    marginBottom: 20,
  },
});

export default UploadLateUsers;
