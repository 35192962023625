import { Identifiers } from "../enums";
import { Translation } from "../Translation.Service";
import { LanguagePreference } from "../../../shared/types";

const IntroOneReturningTranslations: Translation = {
  [Identifiers.Title]: {
    [LanguagePreference.English]: "Let’s check in",
    [LanguagePreference.Spanish]: "Vamos a hacer un chequeo",
    [LanguagePreference.Vietnamese]: "Hãy kiểm tra",
  },
  [Identifiers.Body]: {
    [LanguagePreference.English]:
      "How was this past week? Take time to notice any small step successes and what you learned.",
    [LanguagePreference.Spanish]:
      "¿Cómo fue la semana pasada? Tómate el tiempo para notar cualquier pequeño éxito y lo que aprendiste.",
    [LanguagePreference.Vietnamese]:
      "Tuần vừa qua thế nào? Dành thời gian để nhận biết bất kỳ bước tiến nhỏ nào và những gì bạn đã học được.",
  },
  [Identifiers.ContinueButton]: {
    [LanguagePreference.English]: "Continue",
    [LanguagePreference.Spanish]: "Continuar",
    [LanguagePreference.Vietnamese]: "Tiếp tục",
  },
  [Identifiers.SkipButton]: {
    [LanguagePreference.English]: "Skip to Reflection",
    [LanguagePreference.Spanish]: "Saltar a Reflexión",
    [LanguagePreference.Vietnamese]: "Chuyển đến phần Suy ngẫm",
  },
};

export default IntroOneReturningTranslations;
