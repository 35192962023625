export const lowercasePostiveBehaviorTitleOptions = [
  "positive behavior",
  "positive behaviors",
  "positive behavoir",
];

export const lowercaseChallegingBehaviorTitleOptions = [
  "challenging behavior",
  "challenging behaviors",
];
