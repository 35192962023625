import React from "react";
import { darkGreenBlue } from "shared/styles/colors";
import { IconProps as Props } from "shared/types/index";

const LeftArrowBack = ({ color, height, width }: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 39 24"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M.94 10.94a1.5 1.5 0 0 0 0 2.12l9.545 9.547a1.5 1.5 0 1 0 2.122-2.122L4.12 12l8.486-8.485a1.5 1.5 0 1 0-2.122-2.122L.94 10.94zM39 10.5H2v3h37v-3z"
        fill={color}
      />
    </svg>
  );
};

LeftArrowBack.defaultProps = {
  color: darkGreenBlue,
  height: 24,
  width: 39,
};

export default LeftArrowBack;
