import React from "react";
import { StyleSheet } from "aphrodite";
import Text from "shared/components/Text";
import {
  darkGreenBlue,
  greyFour,
  white,
  mainGrey,
  signupGrey,
} from "shared/styles/colors";
import styled from "@emotion/styled";
import { TranslationService } from "services/Translation/Translation.Service";
import {
  Page,
  Identifiers as TextIdentifiers,
} from "services/Translation/enums";
import { LanguagePreference, UserFlow } from "shared/types";
import { languageStylingSwitcher } from "../../shared/utils";
import type { SignUpComponentName } from "../../shared/types/index";

interface Props {
  component: SignUpComponentName;
  userLanguage: LanguagePreference;
  userFlow: string;
}

const ProgressBar = ({ component, userLanguage, userFlow }: Props) => {
  const colorMappings = {
    [UserFlow.InvitedUser]: {
      carousel: {
        firstTextColor: mainGrey,
        secondTextColor: signupGrey,
        thirdTextColor: signupGrey,
      },
      aboutScreen: {
        firstTextColor: darkGreenBlue,
        secondTextColor: mainGrey,
        thirdTextColor: signupGrey,
      },
      signUpScreen: {
        firstTextColor: darkGreenBlue,
        secondTextColor: darkGreenBlue,
        thirdTextColor: mainGrey,
      },
    },
    [UserFlow.NewUser]: {
      welcomeScreen: {
        firstTextColor: mainGrey,
        secondTextColor: signupGrey,
        thirdTextColor: signupGrey,
      },
      aboutScreen: {
        firstTextColor: mainGrey,
        secondTextColor: signupGrey,
        thirdTextColor: signupGrey,
      },
      personalizeScreen: {
        firstTextColor: darkGreenBlue,
        secondTextColor: mainGrey,
        thirdTextColor: signupGrey,
      },
      signUpScreen: {
        firstTextColor: darkGreenBlue,
        secondTextColor: darkGreenBlue,
        thirdTextColor: mainGrey,
      },
    },
  };

  const { firstTextColor, secondTextColor, thirdTextColor } = colorMappings[
    userFlow
  ][component];

  const circleStylingSwitcher = (
    flow,
    userComponent: SignUpComponentName,
    firstColor,
    secondColor
  ) => {
    let colorForBackground;
    if (flow === UserFlow.InvitedUser) {
      if (userComponent === "carousel") {
        colorForBackground = firstColor;
      } else {
        colorForBackground = secondColor;
      }
    } else if (flow === UserFlow.NewUser) {
      if (
        userComponent === "personalizeScreen" ||
        userComponent === "signUpScreen"
      ) {
        colorForBackground = secondColor;
      } else {
        colorForBackground = firstColor;
      }
    }
    return colorForBackground;
  };

  return (
    <ProgresBarWrapper>
      <CircleContainer>
        <FirstCircle />
        {userFlow === UserFlow.InvitedUser && (
          <FirstConnectingLine isRightComponent={component === "carousel"} />
        )}
        {userFlow === UserFlow.NewUser && (
          <HalfLinesContainer>
            <FirstConnectingLineFirstHalf
              isRightComponent={component === "welcomeScreen"}
            />
            <FirstConnectingLineSecondHalf
              isRightComponent={
                component === "welcomeScreen" || component === "aboutScreen"
              }
            />
          </HalfLinesContainer>
        )}
        <SecondCircle
          circleStylingSwitcher={circleStylingSwitcher}
          userFlow={userFlow}
          component={component}
        />
        <SecondConnectingLine isRightComponent={component === "signUpScreen"} />
        <ThirdCircle isRightComponent={component === "signUpScreen"} />
      </CircleContainer>
    </ProgresBarWrapper>
  );
};

export default ProgressBar;

const ProgresBarWrapper = styled.div({});

const CircleContainer = styled.div({
  display: "flex",
  alignItems: "center",
  marginTop: 5,
});

const FirstCircle = styled.div({
  width: 20,
  height: 20,
  backgroundColor: darkGreenBlue,
  borderRadius: "50%",
});

const SecondCircle = styled.div(
  ({
    circleStylingSwitcher,
    userFlow,
    component,
  }: {
    circleStylingSwitcher;
    userFlow: string;
    component: string;
  }) => ({
    width: 20,
    height: 20,
    backgroundColor: circleStylingSwitcher(
      userFlow,
      component,
      white,
      darkGreenBlue
    ),
    border: 1,
    borderStyle: "solid",
    borderColor: circleStylingSwitcher(
      userFlow,
      component,
      greyFour,
      darkGreenBlue
    ),
    borderRadius: "50%",
  })
);

const ThirdCircle = styled.div(
  ({ isRightComponent }: { isRightComponent: boolean }) => ({
    width: 20,
    height: 20,
    backgroundColor: isRightComponent ? darkGreenBlue : white,
    border: 1,
    borderStyle: "solid",
    borderColor: isRightComponent ? darkGreenBlue : greyFour,
    borderRadius: "50%",
  })
);

const FirstConnectingLine = styled.div(
  ({ isRightComponent }: { isRightComponent: boolean }) => ({
    backgroundColor: isRightComponent ? greyFour : darkGreenBlue,
    height: isRightComponent ? 2 : 8,
    width: isRightComponent ? 112 : 116,
    position: "relative",
    top: "50%",
    marginLeft: isRightComponent ? 0 : -2,
    marginRight: isRightComponent ? 0 : -2,
  })
);

const SecondConnectingLine = styled.div(
  ({ isRightComponent }: { isRightComponent: boolean }) => ({
    backgroundColor: isRightComponent ? darkGreenBlue : greyFour,
    height: isRightComponent ? 8 : 2,
    width: isRightComponent ? 116 : 112,
    position: "relative",
    top: "50%",
    marginLeft: isRightComponent ? -2 : 0,
    marginRight: isRightComponent ? -2 : 0,
  })
);

const TextContainer = styled.div({
  display: "flex",
});

const HalfLinesContainer = styled.div({
  display: "flex",
  alignItems: "center",
});

const FirstConnectingLineFirstHalf = styled.div(
  ({ isRightComponent }: { isRightComponent: boolean }) => ({
    backgroundColor: isRightComponent ? greyFour : darkGreenBlue,
    height: isRightComponent ? 2 : 8,
    width: isRightComponent ? 56 : 60,
    position: "relative",
    top: "50%",
    borderRadius: isRightComponent ? 0 : 4,
    marginLeft: isRightComponent ? 0 : -4,
  })
);

const FirstConnectingLineSecondHalf = styled.div(
  ({ isRightComponent }: { isRightComponent: boolean }) => ({
    backgroundColor: isRightComponent ? greyFour : darkGreenBlue,
    height: isRightComponent ? 2 : 8,
    width: isRightComponent ? 56 : 62,
    position: "relative",
    top: "50%",
    marginLeft: isRightComponent ? 0 : -4,
    marginRight: isRightComponent ? 0 : -2,
  })
);

const styles = ({
  userFlow,
  userLanguage,
}: {
  userFlow: string;
  userLanguage: string;
}) =>
  StyleSheet.create({
    firstText: {
      marginRight:
        userFlow === UserFlow.InvitedUser
          ? languageStylingSwitcher(95, 85, 35, userLanguage)
          : languageStylingSwitcher(80, 60, 15, userLanguage),
      marginTop: 7,
      fontSize: 10,
      height: 12,
      width:
        userFlow === UserFlow.InvitedUser
          ? languageStylingSwitcher(35, 35, 75, userLanguage)
          : languageStylingSwitcher(35, 65, 85, userLanguage),
      fontWeight: "bold",
      letterSpacing: 1,
    },
    secondText: {
      marginRight:
        userFlow === UserFlow.InvitedUser
          ? languageStylingSwitcher(95, 50, 50, userLanguage)
          : languageStylingSwitcher(110, 50, 40, userLanguage),
      marginTop: 7,
      fontSize: 10,
      height: 12,
      width:
        userFlow === UserFlow.InvitedUser
          ? languageStylingSwitcher(35, 70, 90, userLanguage)
          : languageStylingSwitcher(35, 70, 120, userLanguage),
      fontWeight: "bold",
      letterSpacing: 1,
    },
    thirdText: {
      fontSize: 10,
      marginTop: 7,
      fontWeight: "bold",
      letterSpacing: 1,
    },
  });
