import { Page, Identifiers } from "./enums";
import { LanguagePreference } from "shared/types";
import LoginTranslations from "./pages/login.translation";
import CreatePasswordTranslations from "./pages/createPassword.translation";
import UserInvitedTranslations from "./pages/userInvited.translation";
import IntroOneFirstTimeTranslations from "./pages/introOneFirstTime.translation";
import HowItWorksTranslations from "./pages/howItWorks.translation";
import HowItWorksReturningTranslations from "./pages/howItWorksReturning.translation";
import IntroTwoFirstTimeTranslations from "./pages/introTwoFirstTime.translation";
import IntroThreeFirstTimeTranslations from "./pages/introThreeFirstTime.translation";
import IntroThreeReturningTranslations from "./pages/introThreeReturning.translation";
import TopicScreenTranslations from "./pages/topicSelect.translation";
import IntroCompleteTranslations from "./pages/introComplete.translation";
import QuestionPageTranslations from "./pages/questionPage.translation";
import StepThreeIntroTranslations from "./pages/stepThreeIntro.translation";
import DeepBreathTranslations from "./pages/deepBreath.translation";
import ReflectionStartTranslations from "./pages/reflectionStart.translation";
import SharedTranslations from "./pages/shared.translation";
import ReflectionCompleteTranslations from "./pages/reflectionComplete.translation";
import ReflectionEndTranslations from "./pages/reflectionEnd.translation";
import StatsTranslations from "./pages/stats.translation";
import ReflectionsSummaryTranslations from "./pages/reflectionsSummary.translation";
import DashBoardTranslation from "./pages/dashboard.translation";
import IntroOneReturningTranslations from "./pages/introOneReturning.translation";
import ResourceTranslations from "./pages/resources.translation";
import MenuTranslations from "./pages/menu.translation";
import SettingsTranslations from "./pages/settings.translation";
import ForgotPasswordTranslations from "./pages/forgotPassword.translation";
import TellUsAboutYourselfTranslations from "./pages/tellUsAboutYourself.translation";
import GraphsTranslations from "./pages/graphs.translation";
import PersonalizeReflectableTranslations from "./pages/personalizeReflectable.translations";
import TopicProgressTranslations from "./miscTranslations/topicProgress.translations";

// prettier-ignore
export type Translation = Partial<Record<Identifiers,Record<LanguagePreference, string>>>;

export class TranslationService {
  private static getTranslationsForPage(page: Page): Translation {
    return {
      [Page.Login]: LoginTranslations,
      [Page.CreatePassword]: CreatePasswordTranslations,
      [Page.UserInvited]: UserInvitedTranslations,
      [Page.IntroOneFirstTime]: IntroOneFirstTimeTranslations,
      [Page.HowItWorks]: HowItWorksTranslations,
      [Page.HowItWorksReturning]: HowItWorksReturningTranslations,
      [Page.IntroTwoFirstTime]: IntroTwoFirstTimeTranslations,
      [Page.IntroThreeFirstTime]: IntroThreeFirstTimeTranslations,
      [Page.IntroThreeReturning]: IntroThreeReturningTranslations,
      [Page.TopicScreen]: TopicScreenTranslations,
      [Page.IntroComplete]: IntroCompleteTranslations,
      [Page.QuestionPage]: QuestionPageTranslations,
      [Page.StepThreeIntro]: StepThreeIntroTranslations,
      [Page.DeepBreath]: DeepBreathTranslations,
      [Page.ReflectionStart]: ReflectionStartTranslations,
      [Page.Shared]: SharedTranslations,
      [Page.ReflectionComplete]: ReflectionCompleteTranslations,
      [Page.ReflectionEnd]: ReflectionEndTranslations,
      [Page.Stats]: StatsTranslations,
      [Page.ReflectionsSummary]: ReflectionsSummaryTranslations,
      [Page.Dashboard]: DashBoardTranslation,
      [Page.IntroOneReturning]: IntroOneReturningTranslations,
      [Page.Resources]: ResourceTranslations,
      [Page.Menu]: MenuTranslations,
      [Page.Settings]: SettingsTranslations,
      [Page.ForgotPassword]: ForgotPasswordTranslations,
      [Page.AboutYourself]: TellUsAboutYourselfTranslations,
      [Page.Graphs]: GraphsTranslations,
      [Page.PersonalizeReflectable]: PersonalizeReflectableTranslations,
      [Page.TopicProgress]: TopicProgressTranslations,
    }[page];
  }

  static getStaticText({
    page,
    textIdentifier,
    userLanguage,
  }: {
    page: Page;
    textIdentifier: Identifiers;
    userLanguage: LanguagePreference;
  }): string {
    const translations = this.getTranslationsForPage(page);
    return (
      translations[textIdentifier]?.[userLanguage] ?? "[Error finding text]"
    );
  }
}
