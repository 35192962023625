import styled from "@emotion/styled";
import { StyleSheet } from "aphrodite";
import Card from "shared/components/Card";
import React, { Dispatch, SetStateAction } from "react";
import { arrowGreen, greyAccent, offBlack } from "shared/styles/colors";
import Text, { Font } from "shared/components/Text";

interface Props {
  setSelectedCurriculum: Dispatch<SetStateAction<string>>;
  curriculumId: string;
  currentCurriculumId: string;
  imageUrl: string;
  description: string;
  title: string;
}

const CurriculumCard = ({
  currentCurriculumId,
  setSelectedCurriculum,
  curriculumId,
  imageUrl,
  description,
  title,
}: Props) => {
  return (
    <Card
      accentColor={
        currentCurriculumId === curriculumId ? arrowGreen : greyAccent
      }
      contentStyle={styles.cardContent}
      cardStyle={styles.card}
      onClick={() => setSelectedCurriculum(curriculumId)}
    >
      <Image src={imageUrl} />
      <InnerContainer>
        <Text style={styles.title} font={Font.ProximaNovaSemibold}>
          {title}
        </Text>
        <Text style={styles.description} font={Font.ProximaNovaRegular}>
          {description}
        </Text>
      </InnerContainer>
    </Card>
  );
};

export default CurriculumCard;

const styles = StyleSheet.create({
  cardContent: {
    minHeight: 115,
    width: 355,
    display: "flex",
    paddingTop: 8,
    paddingLeft: 10,
    paddingRight: 35,
    paddingBottom: 10,
    textAlign: "left",
  },
  card: {
    cursor: "pointer",
    marginBottom: 20,
  },
  title: {
    marginBottom: 5,
    fontSize: 16,
    color: offBlack,
  },
  description: {
    fontSize: 12,
  },
});

const Image = styled.img({
  maxWidth: 75,
  marginRight: 13,
});

const InnerContainer = styled.div({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
});
