/* eslint-disable no-plusplus */
import React, { useState, useEffect } from "react";
import Button from "shared/components/Button";
import { css, StyleSheet } from "aphrodite";
import Question from "screens/QuestionPage/Question/Question";
import { Formik, Form } from "formik";
import Header from "./Header/Header";
import gql from "graphql-tag";
import { useQuery, useMutation, useLazyQuery } from "@apollo/react-hooks";
import { useParams, useHistory } from "react-router";
import { ROUTES } from "../../constants";
import { routeToQuestionPageFromPageId } from "shared/utils";
import ExitModal from "shared/components/ExitModal";
import { QuestionType } from "@quick-check/common";
import { useToasts } from "react-toast-notifications";
import { getInitialValues } from "./utils";
import { sortBy } from "lodash";
import Spacer from "shared/components/Spacer";
import { backgroundWhite, mainGrey, turquoise } from "shared/styles/colors";
import GraphqlErrorScreen from "shared/components/ErrorBoundary/GraphqlErrorScreen";
import ScrollToTopOnMount from "shared/components/ScrollToTopOnMount";
import { TranslationService } from "services/Translation/Translation.Service";
import { Identifiers, Page } from "services/Translation/enums";
import Card from "shared/components/Card";
import Text, { Font } from "shared/components/Text";
import Loader from "shared/components/Loader";
import { useDocumentTitle } from "shared/hooks";

const SURVEY_PAGE = gql`
  query SurveyPage($pageId: String!) {
    currentUserFocus {
      id
    }
    surveyPage(pageId: $pageId) {
      id
      translationData {
        name
        note
      }
      step {
        translationData {
          description
        }
      }
      questions {
        id
        order
        type
        translationData {
          text
        }
        choices {
          id
          translationData {
            text
          }
        }
      }
    }
    pageAnswers(pageId: $pageId) {
      pageHasResponses
      questionResponses {
        id
        answerText
        answerInteger
        choices {
          id
        }
        question {
          id
        }
      }
    }
    userLanguage
  }
`;

const SUBMIT_SURVEY_PAGE = gql`
  mutation SubmitSurveyPage($pageId: String!, $responses: [ResponseInput]) {
    submitSurveyPage(pageId: $pageId, responses: $responses) {
      nextPageId
      isLastPageInSurvey
      isLastPageInStep2
    }
  }
`;

const SURVEY_PAGE_QUESTIONS = gql`
  query SurveyPageQuestions($pageId: String!, $currentFocusId: String!) {
    surveyPageQuestions(pageId: $pageId, currentFocusId: $currentFocusId) {
      id
      order
      type
      translationData {
        text
      }
      choices {
        id
        translationData {
          text
        }
      }
    }
  }
`;

const QuestionPage = () => {
  useDocumentTitle("Survey Page | Reflectable");
  const { addToast } = useToasts();
  const params = useParams<{ pageId: string }>();
  const history = useHistory();

  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [missingAnswers, setMissingAnswers] = useState<number[]>();
  const [isSubmitting, setSubmitting] = useState<boolean>(false);
  const [dynamicInitialValues, setDynamicInitialValues] = useState<
    Record<string, any>
  >();

  const { data, loading, error } = useQuery(SURVEY_PAGE, {
    variables: {
      pageId: params.pageId,
    },
    fetchPolicy: "no-cache",
  });
  const [
    getSurveyPageQuestions,
    { data: questionsData, loading: questionsLoading, error: questionsError },
  ] = useLazyQuery(SURVEY_PAGE_QUESTIONS);
  const [
    submitSurveyPage,
    { loading: submitLoading, error: submitError, data: submitData },
  ] = useMutation(SUBMIT_SURVEY_PAGE, {
    onError: () => {
      //
    },
  });

  useEffect(() => {
    if (data) {
      getSurveyPageQuestions({
        variables: {
          pageId: params.pageId,
          currentFocusId: data.currentUserFocus?.id,
        },
      });
    }
  }, [data]);

  useEffect(() => {
    if (submitData) {
      const {
        nextPageId,
        isLastPageInSurvey,
        isLastPageInStep2,
      } = submitData.submitSurveyPage;

      if (nextPageId) {
        routeToQuestionPageFromPageId(history, nextPageId);
      } else if (isLastPageInStep2) {
        history.push(ROUTES.DEEP_BREATH);
      } else if (isLastPageInSurvey) {
        history.push(ROUTES.REFLECTION_COMPLETE);
      }
    } else if (submitError) {
      addToast(submitError.message, {
        appearance: "error",
        autoDismiss: true,
      });
    }
  }, [submitData, submitError]);

  const questions = questionsData?.surveyPageQuestions;

  useEffect(() => {
    setModalIsOpen(false);
  }, [params]);

  useEffect(() => {
    if (questions) {
      const dynamicData = getInitialValues(questions, data?.pageAnswers);
      setDynamicInitialValues(dynamicData);
    }
  }, [questions]);

  if (error) {
    return <GraphqlErrorScreen error={error} />;
  }

  if (questionsError) {
    return <GraphqlErrorScreen error={questionsError} />;
  }

  if (
    loading ||
    !data ||
    questionsLoading ||
    dynamicInitialValues === undefined
  ) {
    return <Loader />;
  }

  const handleSubmit = (answerValues) => {
    setSubmitting(true);
    // render process was not updating without some kind of state change,
    // causing the useEffect not to run in the Question component
    const missingAnswersNumbers: number[] = [];
    for (let i = 0; i < questions.length; i++) {
      // this logic needs to be fixed
      const question = questions[i];
      const answerValue = answerValues[question.id];
      if (
        [QuestionType.MultiSelectWithOther].includes(question.type) &&
        !answerValue.answer.length &&
        !answerValue.answerText.length
      ) {
        missingAnswersNumbers.push(question.order);
      } else if (answerValue === 0) {
        // Do nothing
      } else if (!answerValue) {
        missingAnswersNumbers.push(question.order);
      }
    }

    if (missingAnswersNumbers.length) {
      setMissingAnswers(sortBy(missingAnswersNumbers));
      return;
    }
    setMissingAnswers(undefined);

    const responses = questions.map((questionItem) => {
      const question = questionItem as Record<string, any>;
      const { id: questionId, type: questionType } = question;
      let answerText;
      let scaleNumber;
      let answersArray;
      let answerId;
      let submittingWithOther = false;
      const answer = answerValues[questionId];
      if (
        [
          QuestionType.EffectivenessRating,
          QuestionType.ScaleFive,
          QuestionType.ScaleTen,
        ].includes(questionType)
      ) {
        scaleNumber = answer;
      } else if (
        [
          QuestionType.SingleSelect,
          QuestionType.Dropdown,
          QuestionType.SingleGraphedLine,
          QuestionType.ComparisonSingleGraphedLineOne,
          QuestionType.ComparisonSingleGraphedLineTwo,
          QuestionType.SingleGraphedPie,
        ].includes(questionType)
      ) {
        answerId = answer;
      } else if (
        [QuestionType.MultiSelect, QuestionType.MultiGraphedPie].includes(
          questionType
        )
      ) {
        answersArray = answer;
      } else if (questionType === QuestionType.FreeText) {
        answerText = answer;
      } else if (questionType === QuestionType.SingleSelectWithOther) {
        if (answer.submittingWithOther) {
          answerText = answer.answer;
          submittingWithOther = true;
        } else {
          answerId = answer.answer;
        }
      } else if (questionType === QuestionType.MultiSelectWithOther) {
        answersArray = answer.answer;
        answerText = answer.answerText;
      }
      return {
        questionId,
        questionType,
        scaleNumber,
        answerId,
        answerText,
        answersArray,
        submittingWithOther,
      };
    });
    if (responses) {
      submitSurveyPage({
        variables: {
          pageId: params.pageId,
          responses,
        },
      });
    }
    setSubmitting(false);
  };

  const onBackButtonClick = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const hasResponses = data?.pageAnswers?.pageHasResponses;
  if (data && questionsData) {
    const { userLanguage } = data;
    const pageTitle =
      data?.surveyPage?.translationData?.name ?? "[Page name missing]";
    const pageNote = data?.surveyPage?.translationData?.note;

    return (
      <>
        <ScrollToTopOnMount />
        <Formik
          enableReinitialize
          initialValues={dynamicInitialValues}
          onSubmit={(answerValues) => {
            handleSubmit(answerValues);
          }}
        >
          {({ values, handleChange }) => {
            return (
              <div className={css(styles.QuestionPage)}>
                <Header
                  onBackButtonClick={onBackButtonClick}
                  missingAnswers={missingAnswers}
                  pageTitle={pageTitle}
                />
                <Form className={css(styles.form)}>
                  <div
                    className={css(styles.questionSectionPadding)}
                    style={
                      missingAnswers ? { marginTop: 40 } : { marginTop: 0 }
                    }
                  >
                    <ExitModal
                      modalIsOpen={modalIsOpen}
                      closeModal={closeModal}
                      setModalIsOpen={setModalIsOpen}
                    />
                    {pageNote && (
                      <Card
                        cardStyle={styles.pleaseNoteCard}
                        contentStyle={styles.pleaseNoteContent}
                      >
                        <Text
                          font={Font.ProximaNovaBold}
                          style={styles.pleaseNoteTitle}
                        >
                          {TranslationService.getStaticText({
                            page: Page.QuestionPage,
                            userLanguage,
                            textIdentifier: Identifiers.PleaseNote,
                          })}
                        </Text>
                        <Text
                          font={Font.ProximaNovaRegular}
                          style={styles.pleaseNoteText}
                        >
                          {pageNote}
                        </Text>
                      </Card>
                    )}
                    {questions.map((question) => (
                      <Question
                        hasResponses={hasResponses}
                        isSubmitting={isSubmitting}
                        values={values}
                        key={question.id}
                        question={question}
                        index={question.order}
                        handleChange={handleChange}
                        handleSubmit={handleSubmit}
                        scaleType={question.type}
                        userLanguage={userLanguage}
                      />
                    ))}
                  </div>
                  <Spacer removeOnDesktop />
                  <Button
                    type="submit"
                    text={TranslationService.getStaticText({
                      page: Page.Shared,
                      userLanguage,
                      textIdentifier: Identifiers.ContinueButton,
                    })}
                    disabled={!questions}
                    loading={!submitData && submitLoading}
                    ariaLabel={TranslationService.getStaticText({
                      page: Page.Shared,
                      userLanguage,
                      textIdentifier: Identifiers.ContinueButton,
                    })}
                  />
                  <Spacer flexGrow={0} height={5} />
                </Form>
              </div>
            );
          }}
        </Formik>
      </>
    );
  }
  return null;
};

export default QuestionPage;

const styles = StyleSheet.create({
  QuestionPage: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: 40,
    minHeight: "100vh",
    flex: 1,
    "@media (min-width: 630px)": {
      background: backgroundWhite,
    },
  },
  questionSectionPadding: {
    paddingTop: 140,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflow: "hidden",
    flex: 1,
    "@media (min-width: 630px)": {
      paddingTop: 170,
    },
  },
  form: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflow: "hidden",
    flex: 1,
  },
  pleaseNoteCard: {
    width: 355,
    boxShadow: "0 2px 2px 1px rgba(0, 0, 0, .1)",
    marginBottom: 20,
    display: "flex",
    flexDirection: "column",
    "@media (min-width: 630px)": {
      maxWidth: 630,
      width: "100%",
    },
  },
  pleaseNoteContent: {
    padding: 20,
    "@media (min-width: 630px)": {
      padding: "20px 60px",
    },
  },
  pleaseNoteTitle: {
    fontSize: 12,
    letterSpacing: 2,
    textTransform: "uppercase",
    marginBottom: 7,
    color: turquoise,
  },
  pleaseNoteText: {
    fontSize: 14,
    color: mainGrey,
    lineHeight: 1.2,
  },
});
