/* eslint-disable no-plusplus */
import { useQuery } from "@apollo/react-hooks";
import { css, StyleSheet } from "aphrodite";
import gql from "graphql-tag";
import React from "react";
import {
  white,
  pieBlue,
  pieLightBlue,
  piePurple,
  piePink,
  black,
  midGrey,
} from "shared/styles/colors";
import { VictoryPie } from "victory";
import Text, { Font } from "shared/components/Text";
import GraphSpinner from "./GraphSpinner";
import { countBy } from "lodash";

const PIE_GRAPH_QUERY = gql`
  query PieGraphQuestionResponsesByUser(
    $questionId: String!
    $topicId: String!
  ) {
    graphQuestionResponsesByUser(questionId: $questionId, topicId: $topicId) {
      id
      choices {
        id
        translationData {
          text
        }
      }
    }
  }
`;

const colors = [pieBlue, pieLightBlue, piePurple, piePink];

interface Props {
  questionId: string;
  textColor: string;
  questionTitle: string;
  topicId: string | null;
}

const PieGraph = ({ questionId, textColor, questionTitle, topicId }: Props) => {
  const { data, loading } = useQuery(PIE_GRAPH_QUERY, {
    variables: { questionId, topicId },
    fetchPolicy: "no-cache",
  });

  const condenseChoices = (responses) => {
    const arrayToBeReturned: any = [];
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const newArray = responses?.map((response) => {
      const choices = response?.choices;
      const condensedChoices = choices?.map((choice) => {
        arrayToBeReturned.push(choice?.translationData?.text);
        return choice?.translationData?.text;
      });
      return condensedChoices;
    });
    return arrayToBeReturned;
  };
  function generatePieGraphValues(arr) {
    const choices: any = [];
    const numberOfTimesSelected: any = [];
    let prev;

    arr.sort();
    for (let i = 0; i < arr.length; i++) {
      if (arr[i] !== prev) {
        choices.push(arr[i]);
        numberOfTimesSelected.push(1);
      } else {
        numberOfTimesSelected[numberOfTimesSelected.length - 1]++;
      }
      prev = arr[i];
    }
    const arrayToBeReturned: any = [];
    for (let i = 0; i < choices.length; i++) {
      arrayToBeReturned.push({
        x: choices[i],
        y: numberOfTimesSelected[i],
      });
    }
    return arrayToBeReturned;
  }

  const condensedChoices = condenseChoices(data?.graphQuestionResponsesByUser);
  const pieGraphData = generatePieGraphValues(condensedChoices);

  if (loading) {
    return <GraphSpinner />;
  }

  const countedChoices = countBy(condensedChoices);

  return (
    <div className={css(styles.PieGraph)}>
      <Text style={styles.dynamicText} color={textColor}>
        {questionTitle}
      </Text>
      <Text style={styles.legendTitleText}>Key:</Text>
      {Object.keys(countedChoices).map((key, index) => {
        return (
          <div key={key} className={css(styles.row)}>
            <div
              className={css(styles.colorBox)}
              style={{ backgroundColor: colors[index] }}
            />
            <Text font={Font.ProximaNovaRegular} style={styles.legendText}>
              {key}
            </Text>
          </div>
        );
      })}
      <VictoryPie
        width={315}
        height={250}
        padding={30}
        colorScale={colors}
        data={pieGraphData}
        labels={() => null}
        style={{
          data: {
            fillOpacity: 1,
            stroke: white,
            strokeWidth: 2,
          },
        }}
      />
    </div>
  );
};

export default PieGraph;

const styles = StyleSheet.create({
  PieGraph: {
    background: white,
    marginBottom: 20,
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "0 20px",
  },
  dynamicText: {
    textTransform: "uppercase",
    fontSize: 12,
    letterSpacing: 2,
    lineHeight: 1.4,
    marginBottom: 15,
    width: 315,
  },
  spinnerContainer: {
    height: 300,
  },
  legendTitleText: {
    fontSize: 13,
    color: midGrey,
    marginBottom: 8,
    width: "100%",
    textAlign: "left",
    textTransform: "uppercase",
  },
  row: {
    display: "flex",
    alignItems: "center",
    marginBottom: 8,
    width: "100%",
  },
  colorBox: {
    height: 20,
    width: 20,
    marginRight: 12,
  },
  legendText: {
    fontSize: 13,
    color: black,
    textAlign: "left",
    width: "100%",
  },
});
