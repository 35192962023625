import { Identifiers } from "../enums";
import { Translation } from "../Translation.Service";
import { LanguagePreference } from "../../../shared/types";

const translations: Translation = {
  [Identifiers.Title]: {
    [LanguagePreference.English]: "Your well-being is important",
    [LanguagePreference.Spanish]: "Tu bienestar es importante",
    [LanguagePreference.Vietnamese]: "Sức khỏe của bạn quan trọng",
  },
  [Identifiers.ContinueButton]: {
    [LanguagePreference.English]: "Continue",
    [LanguagePreference.Spanish]: "Continuar",
    [LanguagePreference.Vietnamese]: "Tiếp tục",
  },
};

export default translations;
