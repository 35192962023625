import { useQuery } from "@apollo/react-hooks";
import { css, StyleSheet } from "aphrodite";
import { ROUTES } from "../../../constants";
import gql from "graphql-tag";
import React, { useState } from "react";
import { Redirect, useHistory } from "react-router";
import { Dropdown, DropdownProps } from "semantic-ui-react";
import GraphqlErrorScreen from "shared/components/ErrorBoundary/GraphqlErrorScreen";
import {
  buttonBlue,
  greenBlue,
  lightGrey,
  mainGrey,
  turquoise,
  white,
} from "shared/styles/colors";
import Text from "shared/components/Text";
import IntroOneImage from "static/images/IntroOneImage.png";
import CohortHeader from "../CohortHeader";
import Spinner from "react-spinkit";
import Button from "shared/components/Button";
import { useDocumentTitle } from "shared/hooks";

const COHORTS_BY_ADMIN = gql`
  query {
    cohortsByAdmin {
      id
      name
    }
    userLanguage
    isCohortAdmin
  }
`;

const CohortSelect = () => {
  useDocumentTitle("Cohort Admin | Select Cohort | Reflectable");
  const { data, loading, error } = useQuery(COHORTS_BY_ADMIN, {
    fetchPolicy: "no-cache",
  });
  const history = useHistory();
  const userLanguage = data?.userLanguage;
  const [selectedCohortId, setSelectedCohortId] = useState("");

  if (loading) {
    return (
      <section className={css(styles.loadingContainer)}>
        <Spinner name="line-scale" color={buttonBlue} fadeIn="none" />
      </section>
    );
  }

  if (error) {
    return <GraphqlErrorScreen error={error} />;
  }
  if (!data?.isCohortAdmin) {
    return <Redirect to={ROUTES.DASHBOARD} />;
  }
  const selectableArray = data?.cohortsByAdmin.map((item, index) => ({
    key: index,
    text: item?.name,
    value: item.id,
  }));

  const handleDropDownChange = (cohortId) => {
    setSelectedCohortId(cohortId);
  };

  const pushToCohortAdmin = () => {
    history.push(`${ROUTES.COHORT_ADMIN}/${selectedCohortId}`);
  };

  const dropdownOptions: DropdownProps = {
    fluid: true,
    className: `${css(styles.inputSelect)} react-dropdown`,
    placeholder: "Please select",
    search: true,
    selection: true,
    compact: true,
    options: selectableArray,
    onChange: (e, { value }) => handleDropDownChange(value),
  };

  return (
    <section className={css(styles.CohortSelect)}>
      <CohortHeader userLanguage={userLanguage} />
      <div className={css(styles.mainContainer)}>
        <Text style={styles.dashboardText}>Dashboard</Text>
        <div className={css(styles.centerContainer)}>
          <Text style={styles.selectText}>
            Select a cohort to view featured data.
          </Text>
          <Dropdown className={css(styles.inputSelect)} {...dropdownOptions} />
          <Button
            ariaLabel="Go"
            text="Go"
            disabled={!selectedCohortId}
            onClick={pushToCohortAdmin}
            buttonStyle={styles.buttonStyle}
          />
          <img
            src={IntroOneImage}
            alt="Proud Person Illustration"
            title="Proud Person Illustration"
          />
        </div>
      </div>
    </section>
  );
};

export default CohortSelect;

const styles = StyleSheet.create({
  CohortSelect: {
    height: "100vh",
    width: "100vw",
    padding: "0 122px 0",
  },
  header: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    marginBottom: 38,
    paddingTop: 67,
  },
  inputSelect: {
    background: white,
    border: `1px solid ${greenBlue}`,
    borderRadius: 8,
    minHeight: 42,
    width: 197,
    padding: "11px 40px 12px 20px",
    color: lightGrey,
    fontSize: 16,
    marginBottom: 24,
  },
  logo: {
    height: 50,
  },
  mainContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  dashboardText: {
    fontSize: 40,
    marginBottom: 85,
    alignSelf: "flex-start",
    color: mainGrey,
  },
  selectText: {
    fontSize: 36,
    marginBottom: 43,
    alignSelf: "flex-start",
    color: turquoise,
  },
  centerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  loadingContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
  },
  buttonStyle: {
    width: 197,
  },
  //
});
