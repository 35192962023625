import { css, StyleSheet } from "aphrodite";
import React, { useState, useRef, useEffect } from "react";
import Card from "shared/components/Card";
import Text, { Font } from "shared/components/Text";
import Button from "shared/components/Button";
import { useQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import {
  mainGrey,
  lightBlue,
  white,
  buttonBlue,
  greenBlue,
  darkTeal,
} from "shared/styles/colors";
import SwiperCore from "swiper";
import { useHistory } from "react-router";
import { ROUTES } from "../../constants";
import { useToasts } from "react-toast-notifications";
import Spinner from "react-spinkit";
import TopicSwiper from "shared/components/Swiper/TopicSwiper";
import { Identifiers, Page } from "services/Translation/enums";
import { TranslationService } from "services/Translation/Translation.Service";
import GraphqlErrorScreen from "shared/components/ErrorBoundary/GraphqlErrorScreen";
import Spacer from "shared/components/Spacer";
import TopicGrid from "shared/components/TopicGrid/TopicGrid";
import {
  motion,
  useAnimation,
  AnimatePresence,
} from "framer-motion/dist/framer-motion";
import { focusListTransition } from "shared/styles/animations";
import UnlockedContentModal from "./components/UnlockedContentModal";
import { useDocumentTitle } from "shared/hooks";

const TOPIC_PAGE_QUERY = gql`
  query {
    topics {
      id
      color
      isUnlocked
      numberOfReflectionsToUnlock
      numberOfTimesPracticed
      forcedUnlockDate
      translationData {
        name
      }
      focuses {
        id
        translationData {
          name
        }
        numberOfTimesPracticed
      }
    }
    userCompletedReflections {
      totalComplete
    }
    previousWeeksFocus {
      translationData {
        name
      }
      topic {
        id
        translationData {
          name
        }
      }
      reflection {
        completionDate
      }
    }
    userLanguage
  }
`;

const SELECT_FOCUS = gql`
  mutation SelectFocus($focusId: String!) {
    selectFocus(focusId: $focusId) {
      success
    }
  }
`;

interface Props {
  location: any;
}

const TopicScreen = ({ location }: Props) => {
  useDocumentTitle("Topic Select | Reflectable");
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [isRendered, setIsRendered] = useState<boolean>(false);
  const [newlyUnlockedTopics, setNewlyUnlockedTopics] = useState<string[]>([]);
  const isFirstTime = location?.state?.isFirstTime;
  const redirectingFromDashboard = location?.state?.redirectingFromDashboard;
  const swiperRef = useRef<SwiperCore | null>(null);
  const history = useHistory();
  const [selectedTopicIndex, setSelectedTopicIndex] = useState<number>(0);
  const [selectedFocusId, setSelectedFocusId] = useState<string>();
  const [isTopicChosen, setIsTopicChosen] = useState(false);
  const [isSelectedTopicUnlocked, setIsSelectedTopicUnlocked] = useState(true);
  const { loading, data, error } = useQuery(TOPIC_PAGE_QUERY, {
    fetchPolicy: "no-cache",
  });
  const { addToast } = useToasts();
  const focusListControls = useAnimation();

  const [
    selectFocus,
    {
      data: selectFocusData,
      error: selectFocusError,
      loading: selectFocusLoading,
    },
  ] = useMutation(SELECT_FOCUS, {
    onError: () => {
      //
    },
  });

  useEffect(() => {
    setTimeout(() => setIsRendered(true), 1200);

    if (data?.previousWeeksFocus) {
      const topics = data?.topics;
      const previousWeeksTopicId = data?.previousWeeksFocus.topic.id;

      const currentTopicIndex = topics.findIndex(
        (topic) => topic.id === previousWeeksTopicId
      );
      setSelectedTopicIndex(currentTopicIndex);
    }

    if (data?.topics && data?.previousWeeksFocus) {
      for (const topic of data.topics) {
        const {
          userCompletedReflections: { totalComplete },
          previousWeeksFocus: { reflection },
        } = data;
        const {
          numberOfReflectionsToUnlock,
          translationData: { name },
        } = topic;

        if (totalComplete && numberOfReflectionsToUnlock === totalComplete) {
          setNewlyUnlockedTopics((unlockedTopics) => [...unlockedTopics, name]);
        } else if (topic.forcedUnlockDate && reflection?.length) {
          const today = new Date();
          const forcedUnlockDate = new Date(topic.forcedUnlockDate);
          const lastCompletedReflectionDate = new Date(
            reflection[0].completionDate
          );

          if (
            today >= forcedUnlockDate &&
            forcedUnlockDate > lastCompletedReflectionDate
          ) {
            setNewlyUnlockedTopics((unlockedTopics) => [
              ...unlockedTopics,
              name,
            ]);
          }
        }
      }
    }
  }, [data]);

  useEffect(() => {
    if (newlyUnlockedTopics.length) {
      setModalIsOpen(true);
    }
  }, [newlyUnlockedTopics]);

  const goToRoute = (route) => {
    history.push(route);
  };

  useEffect(() => {
    if (selectFocusError) {
      addToast(selectFocusError.message, {
        appearance: "error",
        autoDismiss: true,
      });
    }
    if (selectFocusData) {
      if (isFirstTime) {
        goToRoute(ROUTES.INTRO_COMPLETE);
      } else if (redirectingFromDashboard) {
        goToRoute(ROUTES.DASHBOARD);
      } else {
        goToRoute(ROUTES.REFLECTION_END);
      }
    }
  }, [selectFocusData, selectFocusError]);

  useEffect(() => {
    if (isTopicChosen && isSelectedTopicUnlocked) {
      focusListControls.start(focusListTransition.fadeIn);
    }
  }, [isTopicChosen, isSelectedTopicUnlocked]);

  if (loading) {
    return null;
  }

  if (error) {
    return <GraphqlErrorScreen error={error} />;
  }

  if (selectFocusError) {
    return <GraphqlErrorScreen error={selectFocusError} />;
  }

  const topics = data?.topics;
  const focuses = data?.topics[selectedTopicIndex]?.focuses;

  const onSlideChange = () => {
    if (swiperRef?.current) {
      const { isUnlocked } = topics[swiperRef.current.activeIndex];
      setSelectedFocusId(undefined);
      setSelectedTopicIndex(swiperRef.current.activeIndex);
      setIsSelectedTopicUnlocked(isUnlocked);
    }
  };

  const onButtonPress = () => {
    if (isTopicChosen) {
      selectFocus({
        variables: {
          focusId: selectedFocusId,
        },
      });
    } else {
      setIsTopicChosen(true);
    }
  };

  const page = Page.TopicScreen;
  const userLanguage = data?.userLanguage ?? "ENGLISH";

  let content;
  if (isRendered) {
    content = (
      <>
        {!isFirstTime && (
          <UnlockedContentModal
            modalIsOpen={modalIsOpen}
            setModalIsOpen={setModalIsOpen}
            userLanguage={userLanguage}
            page={page}
            topics={newlyUnlockedTopics}
          />
        )}
        <TopicGrid
          selectedTopicIndex={selectedTopicIndex}
          topics={topics}
          handleTopicSelect={(index) => setSelectedTopicIndex(index)}
          swiperRef={swiperRef}
          isTopicChosen={isTopicChosen}
        />
        <TopicSwiper
          swiperRef={swiperRef}
          onSlideChange={onSlideChange}
          selectedTopicIndex={selectedTopicIndex}
          topics={topics}
          isTopicChosen={isTopicChosen}
        />
        <AnimatePresence>
          {isTopicChosen && isSelectedTopicUnlocked && (
            <motion.div
              variants={focusListTransition}
              initial={focusListTransition.hidden}
              animate={focusListControls}
            >
              <Text
                font={Font.ProximaNovaSemibold}
                style={[styles.bodyText, styles.introText2]}
              >
                {TranslationService.getStaticText({
                  page,
                  textIdentifier: Identifiers.FocusSelect,
                  userLanguage,
                })}
              </Text>
              <div className={css(styles.focusesContainer)}>
                {focuses.map(
                  ({ id, translationData, numberOfTimesPracticed }) => {
                    const isFocusActive = id === selectedFocusId;
                    const { name } = translationData;
                    return (
                      <button
                        key={id}
                        type="button"
                        tabIndex={0}
                        aria-hidden="true"
                        className={css(
                          styles.focus,
                          isFocusActive && styles.focusActive
                        )}
                        onClick={() => setSelectedFocusId(id)}
                      >
                        <Text
                          font={Font.ProximaNovaSemibold}
                          style={styles.focusText}
                        >
                          {name}
                        </Text>
                        {numberOfTimesPracticed > 0 && (
                          <Text
                            style={[
                              styles.timesPracticed,
                              isFocusActive && styles.timesPracticedActive,
                            ]}
                          >
                            {TranslationService.getStaticText({
                              page,
                              textIdentifier: Identifiers.Practiced,
                              userLanguage,
                            })}{" "}
                            {numberOfTimesPracticed}{" "}
                            {TranslationService.getStaticText({
                              page,
                              textIdentifier:
                                numberOfTimesPracticed > 1
                                  ? Identifiers.Times
                                  : Identifiers.Time,
                              userLanguage,
                            })}
                          </Text>
                        )}
                      </button>
                    );
                  }
                )}
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </>
    );
  } else {
    content = (
      <div className={css(styles.loadingContainer)}>
        <Spinner name="line-scale" color={buttonBlue} fadeIn="none" />
      </div>
    );
  }

  return (
    <section className={css(styles.TopicScreen)}>
      <Card cardStyle={styles.card} contentStyle={styles.cardContent}>
        <Text
          font={Font.ProximaNovaSemibold}
          style={[styles.bodyText, styles.introText1]}
        >
          {TranslationService.getStaticText({
            page,
            textIdentifier: Identifiers.TopicSelect,
            userLanguage,
          })}
        </Text>
        {content}
      </Card>
      <Spacer removeOnDesktop />
      <div className={css(styles.buttonWrapper)}>
        <Button
          ariaLabel={TranslationService.getStaticText({
            page,
            textIdentifier: Identifiers.ContinueButton,
            userLanguage,
          })}
          text={TranslationService.getStaticText({
            page,
            textIdentifier: Identifiers.ContinueButton,
            userLanguage,
          })}
          disabled={
            (!selectedFocusId && isTopicChosen) ||
            (selectFocusLoading && isTopicChosen) ||
            isRendered === false
          }
          onClick={() => onButtonPress()}
          loading={selectFocusLoading}
        />
        <Spacer flexGrow={0} height={5} />
      </div>
    </section>
  );
};

TopicScreen.defaultProps = {
  isFirstTime: false,
} as Partial<Props>;

export default TopicScreen;

const styles = StyleSheet.create({
  TopicScreen: {
    display: "flex",
    flexDirection: "column",
    padding: "34px 10px 40px",
    minHeight: "100vh",
    alignItems: "center",
    "@media (min-width: 630px)": {
      padding: "30px 10px 20px",
    },
  },
  //
  header: {
    fontSize: 24,
    color: mainGrey,
    width: "100%",
    marginBottom: 20,
    textAlign: "center",
    textTransform: "capitalize",
  },
  card: {
    marginBottom: 20,
    maxWidth: 670,
    width: "100%",
  },
  cardContent: {
    paddingTop: 30,
    paddingBottom: 30,
    position: "relative",
    overflow: "hidden",
    "@media (min-width: 700px)": {
      padding: "30px 40px",
    },
  },
  swiperWrapper: {
    marginBottom: 30,
  },
  swiperItemWrapper: {
    height: 96,
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  swiperItem: {
    display: "flex",
    borderRadius: 10,
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    transition: "0.3s",
    cursor: "pointer",
  },
  swiperItemText: {
    fontSize: 20,
    lineHeight: 1.2,
    color: white,
    textAlign: "center",
    "@media (max-width: 500px)": {
      maxWidth: 180,
    },
  },
  bodyText: {
    fontSize: 16,
    color: mainGrey,
    textAlign: "center",
  },
  introText1: {
    marginBottom: 30,
  },
  introText2: {
    marginBottom: 20,
  },
  focusesContainer: {
    width: "100%",
    padding: "0 20px",
    flex: 1,
    overflow: "hidden",
  },
  focus: {
    padding: "12px 20px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    border: `2px solid ${greenBlue}`,
    transition: "0.3s",
    borderRadius: 10,
    marginBottom: 10,
    cursor: "pointer",
    background: white,
    width: "100%",
    color: mainGrey,
    fontSize: 16,
    textAlign: "center",
    lineHeight: 1.1875,
    ":hover": {
      backgroundColor: buttonBlue,
      color: white,
      border: `2px solid ${buttonBlue}`,
    },
  },
  focusText: {
    fontSize: "inherit",
  },
  focusActive: {
    backgroundColor: buttonBlue,
    color: white,
    border: `2px solid ${buttonBlue}`,
  },
  timesPracticed: {
    width: "100%",
    color: darkTeal,
    height: 22,
    backgroundColor: lightBlue,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 5,
    fontSize: 12,
  },
  timesPracticedActive: {
    backgroundColor: white,
  },
  buttonWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-end",
    maxWidth: 640,
    width: "100%",
  },
  loadingContainer: {
    width: "100%",
    background: white,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 96,
  },
});
