import React from "react";

const HamburgerMenuIcon = () => {
  return (
    <svg
      width="34"
      height="23"
      viewBox="0 0 34 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Main Menu</title>
      <path
        d="M1.5 22H33M1.5 1H33H1.5ZM1.5 11.5H33H1.5Z"
        stroke="#129198"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default HamburgerMenuIcon;
