import { useQuery } from "@apollo/react-hooks";
import { ROUTES } from "../../constants";
import gql from "graphql-tag";
import React from "react";
import { Identifiers, Page } from "services/Translation/enums";
import { TranslationService } from "services/Translation/Translation.Service";
import GraphqlErrorScreen from "shared/components/ErrorBoundary/GraphqlErrorScreen";
import IllustrationScreen from "shared/components/IllustrationScreen";
import dandelionVideo from "static/videos/dandelion.mp4";
import { useDocumentTitle } from "shared/hooks";

const USER_LANGUAGE = gql`
  query UserLanguage {
    userLanguage
  }
`;

const DeepBreathScreen = () => {
  useDocumentTitle("Deep Breath | Reflectable");
  const { data, error } = useQuery(USER_LANGUAGE);
  const page = Page.DeepBreath;
  const userLanguage = data?.userLanguage;
  if (error) {
    return <GraphqlErrorScreen error={error} />;
  }
  return (
    <IllustrationScreen
      videoSrc={`${dandelionVideo}#t=2`}
      title={TranslationService.getStaticText({
        page,
        userLanguage,
        textIdentifier: Identifiers.Title,
      })}
      body={TranslationService.getStaticText({
        page,
        userLanguage,
        textIdentifier: Identifiers.Body,
      })}
      buttonText={TranslationService.getStaticText({
        page: Page.Shared,
        userLanguage,
        textIdentifier: Identifiers.ContinueButton,
      })}
      buttonLocation={ROUTES.STEP_THREE_INTRO}
      showNavHeader
    />
  );
};

export default DeepBreathScreen;
