import "./reset.css";
import "./fonts.css";
import "./shared/styles/toast.scss";
import { Route, Switch } from "react-router";
import * as Sentry from "@sentry/browser";
import React from "react";
import ReactGA from "react-ga4";
import CreatePassword from "screens/auth/CreatePassword/CreatePassword";
import HowItWorks from "screens/onboarding/screens/HowItWorks/HowItWorks";
import IntroOne from "screens/intro/screens/IntroOne";
import IntroTwo from "screens/intro/screens/IntroTwo";
import IntroThree from "screens/intro/screens/IntroThree";
import Dashboard from "screens/Dashboard/screens/Dashboard";
import Upload from "screens/admin/Upload";
import QuestionPage from "screens/QuestionPage/QuestionPage";
import TopicScreen from "screens/TopicScreen/TopicScreen";
import ReflectionStart from "screens/ReflectionStart/ReflectionStart";
import Step3IntroScreen from "screens/Step3/Step3Intro";
import DeepBreathScreen from "screens/Step3/DeepBreath";
import { ROUTES } from "./constants";
import ReflectionComplete from "screens/ReflectionComplete/ReflectionComplete";
import Login from "modules/login/Login";
import Invitation from "modules/signup/InvitationFrame";
import NewUser from "modules/signup/NewUser/NewUserFrame";
import IntroComplete from "screens/intro/screens/IntroComplete";
import ReflectionEnd from "screens/ReflectionComplete/ReflectionEnd";
import PrivateRoute from "shared/components/PrivateRoute/PrivateRoute";
import Stats from "screens/Stats/Stats";
import ReflectionsSummary from "screens/ReflectionsSummary/ReflectionsSummary";
import ReflectionDetails from "screens/ReflectionDetails/ReflectionDetails";
import NotFoundPage from "screens/404";
import ForgotPassword from "screens/auth/ForgotPassword/ForgotPassword";
import ForgotPasswordSuccess from "screens/auth/ForgotPassword/ForgotPasswordSuccess";
import CohortSelect from "screens/CohortAdmin/screens/CohortSelect";
import CohortAdmin from "screens/CohortAdmin/screens/CohortAdmin";
import Settings from "screens/settings/Settings";
import Resources from "screens/Resources/Resources";
import LanguageSelector from "shared/components/LanguageSelector/LanguageSelector";

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({ dsn: process.env.REACT_APP_SENTRY_DSN });
}

if (process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID) {
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID);
}

const App = () => {
  return (
    <>
      <LanguageSelector />
      <Switch>
        {/* Onboarding */}
        <Route
          path={`${ROUTES.CREATE_PASSWORD}/:inviteId`}
          exact
          component={CreatePassword}
        />
        <Route
          path={`${ROUTES.RESET_PASSWORD}/:inviteId`}
          exact
          component={CreatePassword}
        />
        <PrivateRoute path={ROUTES.HOW_IT_WORKS} exact component={HowItWorks} />
        {/* Invited User */}
        <Route
          path={`${ROUTES.INVITATION}/:inviteId`}
          exact
          component={Invitation}
        />
        {/* New User */}
        <Route path={ROUTES.SIGNUP} exact component={NewUser} />
        {/* Login */}
        <Route path={ROUTES.LOGIN} exact component={Login} />
        {/* Forgot Password */}
        <Route path={ROUTES.FORGOT_PASSWORD} exact component={ForgotPassword} />
        <Route
          path={ROUTES.FORGOT_PASSWORD_SUCCESS}
          exact
          component={ForgotPasswordSuccess}
        />
        {/* Dashboard */}
        <PrivateRoute path={ROUTES.DASHBOARD} exact component={Dashboard} />
        <PrivateRoute
          path={`${ROUTES.REFLECTION_DETAILS}/:reflectionId`}
          exact
          component={ReflectionDetails}
        />
        <PrivateRoute
          path={ROUTES.REFLECTIONS_SUMMARY}
          exact
          component={ReflectionsSummary}
        />
        {/* Admin */}
        <PrivateRoute path={ROUTES.ADMIN} exact component={Upload} />
        <PrivateRoute
          path={`${ROUTES.QUESTIONS}/:pageId`}
          exact
          component={QuestionPage}
        />
        {/* Cohort Admin */}
        <PrivateRoute
          path={`${ROUTES.COHORT_SELECT}`}
          exact
          component={CohortSelect}
        />
        <PrivateRoute
          path={`${ROUTES.COHORT_ADMIN}/:cohortId`}
          exact
          component={CohortAdmin}
        />
        {/* --- Reflection --- */}
        {/* Step 1 - Positive Mindset */}
        <PrivateRoute path={ROUTES.INTRO_ONE} exact component={IntroOne} />
        <PrivateRoute path={ROUTES.INTRO_TWO} exact component={IntroTwo} />
        <PrivateRoute path={ROUTES.INTRO_THREE} exact component={IntroThree} />
        {/* Choose Topic & Focus */}
        <PrivateRoute
          path={ROUTES.INTRO_COMPLETE}
          exact
          component={IntroComplete}
        />
        <PrivateRoute
          path={ROUTES.REFLECTION_END}
          exact
          component={ReflectionEnd}
        />
        <PrivateRoute path={ROUTES.TOPIC} exact component={TopicScreen} />
        {/* Step 2 - Reflect */}
        {/* Moved old STEP_TWO_INTRO screen to archived folder */}
        <PrivateRoute
          path={ROUTES.REFLECTION_START}
          exact
          component={ReflectionStart}
        />
        {/* Step 3 - A Closer Look */}
        <PrivateRoute
          path={ROUTES.STEP_THREE_INTRO}
          exact
          component={Step3IntroScreen}
        />
        <PrivateRoute
          path={ROUTES.DEEP_BREATH}
          exact
          component={DeepBreathScreen}
        />
        <PrivateRoute
          path={ROUTES.REFLECTION_COMPLETE}
          exact
          component={ReflectionComplete}
        />
        <PrivateRoute path={ROUTES.SETTINGS} exact component={Settings} />
        <PrivateRoute path={ROUTES.RESOURCES} exact component={Resources} />
        <PrivateRoute path={ROUTES.STATS} exact component={Stats} />
        <Route component={NotFoundPage} />
      </Switch>
    </>
  );
};

export default App;
