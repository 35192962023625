import { StyleSheet } from "aphrodite";
import React from "react";
import { mainGrey, turquoise } from "shared/styles/colors";
import Text, { Font } from "shared/components/Text";
import Button from "shared/components/Button";
import ModalBase from "shared/components/ModalBase";
import UnlockedIcon from "static/svgs/UnlockedIcon";
import { Identifiers, Page } from "services/Translation/enums";
import { TranslationService } from "services/Translation/Translation.Service";
import { LanguagePreference } from "shared/types";
import styled from "@emotion/styled";

interface Props {
  modalIsOpen: boolean;
  setModalIsOpen: (modalIsOpen: boolean) => void;
  userLanguage: LanguagePreference;
  page: Page;
  topics: string[];
}

const UnlockedContentModal = ({
  modalIsOpen,
  setModalIsOpen,
  userLanguage,
  page,
  topics,
}: Props) => {
  return (
    <ModalBase
      modalIsOpen={modalIsOpen}
      closeModal={() => setModalIsOpen(false)}
      setModalIsOpen={() => setModalIsOpen(true)}
      contentLabel="Unlocked Topic"
    >
      <Container>
        <UnlockedIcon />
        <Text style={styles.title} font={Font.ProximaNovaBold}>
          {TranslationService.getStaticText({
            page,
            textIdentifier: Identifiers.UnlockedTitle,
            userLanguage,
          })}
        </Text>
        <Text style={styles.body} font={Font.ProximaNovaRegular}>
          {TranslationService.getStaticText({
            page,
            textIdentifier:
              topics.length > 1
                ? Identifiers.UnlockedTopicsBody
                : Identifiers.UnlockedTopicBody,
            userLanguage,
          })}
        </Text>
        <Text
          style={[styles.body, styles.topicBody]}
          font={Font.ProximaNovaSemibold}
        >
          {topics.map((topicName, index) => {
            const isLastTopic = topics.length - 1 === index;

            return (
              <TopicContainer key={`topic-${topicName}`}>
                {topicName}
                {!isLastTopic && (
                  <Text
                    style={[styles.ampersand]}
                    font={Font.ProximaNovaRegular}
                  >
                    {" "}
                    &{" "}
                  </Text>
                )}
              </TopicContainer>
            );
          })}
        </Text>
        <Button
          buttonStyle={styles.button}
          onClick={() => {
            setModalIsOpen(false);
            document.body.classList.remove("hidden-body");
          }}
          ariaLabel={TranslationService.getStaticText({
            page,
            textIdentifier: Identifiers.ContinueButton,
            userLanguage,
          })}
          text={TranslationService.getStaticText({
            page,
            textIdentifier: Identifiers.ContinueButton,
            userLanguage,
          })}
        />
      </Container>
    </ModalBase>
  );
};

export default UnlockedContentModal;

const Container = styled.div({
  padding: "85px 45px 78px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  minWidth: 325,
  color: mainGrey,
  "@media (min-width: 630px)": {
    minWidth: 450,
  },
});

const TopicContainer = styled.div({
  display: "inline-block",
  whiteSpace: "break-spaces",
});

const styles = StyleSheet.create({
  title: {
    fontSize: 36,
    lineHeight: 1.2,
    color: turquoise,
    margin: "28px 0 10px",
    textTransform: "capitalize",
    textAlign: "center",
  },
  ampersand: {
    fontSize: 20,
    textAlign: "center",
    lineHeight: 1.2,
    display: "inline-block",
  },
  body: {
    maxWidth: 200,
    lineHeight: 1.2,
    textAlign: "center",
    fontSize: 20,
    margin: "0 auto 14px",
    "@media (min-width: 630px)": {
      maxWidth: 300,
    },
  },
  topicBody: {
    margin: "0 auto",
  },
  button: {
    minHeight: 60,
    marginTop: 30,
  },
});
