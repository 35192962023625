import { UserTypeOption } from "./constants";
import { LanguagePreference } from "shared/types";

export const getUserTypeTranslations = ({
  userTypes,
  userLanguage,
}: {
  userTypes: UserTypeOption[];
  userLanguage: LanguagePreference;
}) => {
  const newArray = userTypes.map((userType) => {
    const { translations } = userType;
    const translation = translations[userLanguage];
    return {
      text: translation,
      value: userType.value,
      key: userType.key,
    };
  });
  return newArray;
};
