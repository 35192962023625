import { css, StyleSheet } from "aphrodite";
import React, { useRef, useState } from "react";
import Text from "shared/components/Text";
import { Style } from "shared/types";
import {
  white,
  buttonBlue,
  transparent,
  hoveredButtonBlue,
  greyFour,
} from "shared/styles/colors";
import Spinner from "react-spinkit";
import { useButton } from "@react-aria/button";
import styled from "@emotion/styled";
import { useFocusRing } from "@react-aria/focus";

export enum ButtonType {
  Primary = "Primary",
  Secondary = "Secondary",
  Tertiary = "Tertiary",
  Quaternary = "Quaternary",
}

interface Props {
  text: string;
  disabled: boolean;
  onClick: () => void;
  buttonType: ButtonType;
  buttonStyle?: Style | Style[];
  textStyle?: Style | Style[];
  loading: boolean;
  type: "button" | "submit" | "reset" | undefined;
  ariaLabel: string;
}

const InvitationButton = ({
  text,
  disabled,
  buttonType,
  onClick: passedOnClick,
  buttonStyle,
  textStyle,
  loading,
  type,
  ariaLabel,
}: Props) => {
  const [isHovered, setIsHovered] = useState(false);

  const { isFocusVisible, focusProps } = useFocusRing();
  const ref = useRef(null);
  const { buttonProps } = useButton({}, ref);
  const isSecondaryButton = buttonType === ButtonType.Secondary;
  const loadingColor = isSecondaryButton ? buttonBlue : white;
  const showRightArrowButton = [ButtonType.Tertiary].includes(buttonType);
  const showLeftArrowButton = [ButtonType.Secondary].includes(buttonType);
  const showLeftArrowNoBorderButton = [ButtonType.Quaternary].includes(
    buttonType
  );

  const onClick = () => {
    if (!disabled) {
      passedOnClick();
    }
  };

  const handleHover = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <EmotionButton
      aria-label={ariaLabel}
      {...buttonProps}
      {...focusProps}
      className={css(
        disabled && !loading && styles.disabled,
        styles[buttonType],
        buttonStyle
      )}
      type={type}
      disabled={disabled}
      onClick={onClick}
      isFocusVisible={isFocusVisible}
    >
      {loading && (
        <Spinner name="line-scale" color={loadingColor} fadeIn="none" />
      )}
      {!loading && (
        <Text style={[styles.text, styles[`${buttonType}_text`], textStyle]}>
          {text}
          {showLeftArrowButton && (
            <div
              className={css(
                styles.leftArrowWrapper,
                disabled && styles.leftArrowWrapperDisabled
              )}
              onMouseEnter={handleHover}
              onMouseLeave={handleMouseLeave}
            >
              <svg
                width="28"
                height="23"
                viewBox="0 0 28 23"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={css(
                  styles.leftArrow,
                  isHovered && styles.leftArrowHovered,
                  disabled && styles.leftArrowDisabled
                )}
              >
                <path d="M27.367 12.752a1.5 1.5 0 0 0 0-2.121l-9.546-9.546a1.5 1.5 0 1 0-2.12 2.121l8.484 8.485-8.485 8.486a1.5 1.5 0 1 0 2.121 2.121l9.546-9.546zm-27.156.44h26.096v-3H.21v3z" />
              </svg>
            </div>
          )}
          {showLeftArrowNoBorderButton && (
            <div
              className={css(styles.leftArrowNoBorderWrapper)}
              onMouseEnter={handleHover}
              onMouseLeave={handleMouseLeave}
            >
              <svg
                width="28"
                height="23"
                viewBox="0 0 28 23"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={css(
                  styles.leftArrow,
                  isHovered && styles.leftArrowHovered
                )}
              >
                <path d="M27.367 12.752a1.5 1.5 0 0 0 0-2.121l-9.546-9.546a1.5 1.5 0 1 0-2.12 2.121l8.484 8.485-8.485 8.486a1.5 1.5 0 1 0 2.121 2.121l9.546-9.546zm-27.156.44h26.096v-3H.21v3z" />
              </svg>
            </div>
          )}
          {showRightArrowButton && (
            <div className={css(styles.rightArrowWrapper)}>
              <svg
                width="28"
                height="23"
                viewBox="0 0 28 23"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M27.367 12.752a1.5 1.5 0 0 0 0-2.121l-9.546-9.546a1.5 1.5 0 1 0-2.12 2.121l8.484 8.485-8.485 8.486a1.5 1.5 0 1 0 2.121 2.121l9.546-9.546zm-27.156.44h26.096v-3H.21v3z"
                  fill="#fff"
                />
              </svg>
            </div>
          )}
        </Text>
      )}
    </EmotionButton>
  );
};

export default InvitationButton;

InvitationButton.defaultProps = {
  text: "",
  buttonType: ButtonType.Primary,
  disabled: false,
  onClick: () => {}, // eslint-disable-line
  loading: false,
  type: "submit",
  ariaLabel: "submit",
} as Partial<Props>;

interface FocusProps {
  isFocusVisible: boolean;
}
const EmotionButton = styled.button(({ isFocusVisible }: FocusProps) => ({
  height: 60,
  width: "100%",
  maxWidth: 355,
  borderRadius: 46,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  outline: isFocusVisible ? "2px solid dodgerblue" : "none",
  outlineOffset: 2,
  lineHeight: 1.2,
  transition: "300ms",
  "@media (max-width: 360px)": {
    maxWidth: 300,
  },
}));

const styles = StyleSheet.create({
  [ButtonType.Primary]: {
    backgroundColor: buttonBlue,
    color: white,
    border: `1px solid ${buttonBlue}`,
    ":hover": {
      backgroundColor: hoveredButtonBlue,
    },
  },
  [ButtonType.Secondary]: {
    backgroundColor: transparent,
    color: buttonBlue,
    ":hover": {
      textDecoration: "underline",
    },
  },
  [ButtonType.Tertiary]: {
    border: `1px solid ${buttonBlue}`,
    backgroundColor: buttonBlue,
    color: white,
    ":hover": {
      backgroundColor: hoveredButtonBlue,
    },
  },
  disabled: {
    borderColor: greyFour,
  },
  // text
  text: {
    fontSize: 18,
    lineHeight: 1.2,
    display: "flex",
    alignItems: "center",
    "@media (max-width: 360px)": {
      fontSize: 15,
    },
  },
  rightArrowWrapper: {
    display: "flex",
    alignItems: "center",
  },
  leftArrowWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 59,
    height: 59,
    transform: "scaleX(-1)",
    border: 2,
    borderRadius: "50%",
    borderColor: buttonBlue,
    borderStyle: "solid",
    ":hover": {
      borderColor: hoveredButtonBlue,
    },
  },
  leftArrowNoBorderWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 59,
    height: 59,
    transform: "scaleX(-1)",
    backgroundColor: white,
  },
  leftArrowWrapperDisabled: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 59,
    height: 59,
    transform: "scaleX(-1)",
    border: 2,
    borderRadius: "50%",
    borderColor: greyFour,
    borderStyle: "solid",
    cursor: "default",
    ":hover": {
      borderColor: greyFour,
    },
  },
  leftArrow: {
    fill: buttonBlue,
  },
  leftArrowHovered: {
    fill: hoveredButtonBlue,
  },
  leftArrowDisabled: {
    fill: greyFour,
  },
});
