import styled from "@emotion/styled";
import { StyleSheet } from "aphrodite";
import React from "react";
import { Link } from "react-router-dom";
import { transparent, white } from "shared/styles/colors";
import { LanguagePreference } from "shared/types";
import { logout } from "shared/utils";
import Text, { Font } from "../Text";

interface Props {
  route?: string;
  icon: any;
  text: string;
  isLogoutButton?: boolean;
  languagePreference?: LanguagePreference;
  history?: any;
}

const LinkItem = ({
  route = "/",
  icon,
  text,
  isLogoutButton = false,
  languagePreference = LanguagePreference.English,
  history,
}: Props) => {
  let content;
  if (isLogoutButton && history) {
    content = (
      <LogoutButton
        onClick={() => logout(history, languagePreference)}
        tabIndex={0}
      >
        <IconContainer>{icon}</IconContainer>
        <Text font={Font.ProximaNovaBold} color={white} style={styles.text}>
          {text}
        </Text>
      </LogoutButton>
    );
  } else {
    content = (
      <Link to={route}>
        <LinkItemContainer>
          <IconContainer>{icon}</IconContainer>
          <Text font={Font.ProximaNovaBold} color={white} style={styles.text}>
            {text}
          </Text>
        </LinkItemContainer>
      </Link>
    );
  }
  return content;
};

export default LinkItem;

const styles = StyleSheet.create({
  text: {
    fontSize: 20,
    textTransform: "capitalize",
  },
});

const LinkItemContainer = styled.div({
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
});

const LogoutButton = styled.button({
  display: "flex",
  alignItems: "center",
  textAlign: "left",
  background: transparent,
  ":hover": {
    cursor: "pointer",
  },
  padding: 0,
});

const IconContainer = styled.div({
  width: 36,
});
