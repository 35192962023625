import {
  transparent,
  inactiveBackground,
  greenBlue,
  darkGreenBlue,
  settingsFormRed,
  mainGrey,
  black,
} from "../../../shared/styles/colors";
import styled from "@emotion/styled";
import { StyleSheet } from "aphrodite";

export const Input = styled.input(
  ({
    isActive = false,
    isError = false,
    isNewUser = false,
  }: {
    isActive?: boolean;
    isError?: boolean;
    isNewUser?: boolean;
  }) => ({
    backgroundColor: isActive ? transparent : inactiveBackground,
    border: `1px solid ${greenBlue}`,
    borderColor: isError ? settingsFormRed : greenBlue,
    borderRadius: 8,
    height: 42,
    padding: "19px 12px 19px 10px",
    marginBottom: isError ? 8 : 21,
    width: "100%",
    color: isActive ? darkGreenBlue : "rgba(0, 0, 0, .4)",
    "::placeholder": {
      color: isActive && !isNewUser ? darkGreenBlue : "rgba(0, 0, 0, .4)",
    },
  })
);

export const Form = styled.form({
  display: "flex",
  flexDirection: "column",
});

export const sharedStyles = StyleSheet.create({
  inputLabel: {
    fontSize: 12,
    marginBottom: 7,
    textTransform: "uppercase",
  },
  buttonStyle: {
    height: 45,
    marginBottom: 21,
    alignSelf: "center",
  },
  changeText: {
    textDecoration: "underline",
    cursor: "pointer",
  },
  settingsError: {
    color: settingsFormRed,
    fontSize: 14,
    marginBottom: 21,
  },
  explicitError: {
    fontSize: 14,
    textAlign: "center",
    marginBottom: 21,
    color: mainGrey,
    maxWidth: 280,
    alignSelf: "center",
  },
});

export const InputLabel = styled.label({
  fontFamily: "ProximaNova",
  fontWeight: 600,
  textAlign: "left",
  alignSelf: "flex-start",
  marginBottom: 8,
  color: black,
});

export const AuthInputContainer = styled.div({
  width: 335,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
});
