import { css, StyleSheet } from "aphrodite";
import React, { useRef } from "react";
import Text from "./Text";
import { Style } from "shared/types";
import {
  white,
  buttonBlue,
  transparent,
  hoveredButtonBlue,
} from "shared/styles/colors";
import Spinner from "react-spinkit";
import { useButton } from "@react-aria/button";
import styled from "@emotion/styled";
import { useFocusRing } from "@react-aria/focus";

export enum ButtonType {
  Primary = "Primary",
  Secondary = "Secondary",
  Tertiary = "Tertiary",
}

interface Props {
  text: string;
  disabled: boolean;
  onClick: () => void;
  buttonType: ButtonType;
  buttonStyle?: Style | Style[];
  textStyle?: Style | Style[];
  loading: boolean;
  type: "button" | "submit" | "reset" | undefined;
  ariaLabel: string;
}

const Button = ({
  text,
  disabled,
  buttonType,
  onClick: passedOnClick,
  buttonStyle,
  textStyle,
  loading,
  type,
  ariaLabel,
}: Props) => {
  const { isFocusVisible, focusProps } = useFocusRing();
  const ref = useRef(null);
  const { buttonProps } = useButton({}, ref);
  const isSecondaryButton = buttonType === ButtonType.Secondary;
  const loadingColor = isSecondaryButton ? buttonBlue : white;
  const showRightArrowButton = [
    ButtonType.Secondary,
    ButtonType.Tertiary,
  ].includes(buttonType);

  const onClick = () => {
    if (!disabled) {
      passedOnClick();
    }
  };

  return (
    <EmotionButton
      aria-label={ariaLabel}
      {...buttonProps}
      {...focusProps}
      className={css(
        disabled && !loading && styles.disabled,
        styles[buttonType],
        buttonStyle
      )}
      type={type}
      disabled={disabled}
      onClick={onClick}
      isFocusVisible={isFocusVisible}
    >
      {loading && (
        <Spinner name="line-scale" color={loadingColor} fadeIn="none" />
      )}
      {!loading && (
        <Text style={[styles.text, styles[`${buttonType}_text`], textStyle]}>
          {text}
          {showRightArrowButton && (
            <div className={css(styles.rightArrowWrapper)}>
              <svg width="17" height="14" fill="none" viewBox="0 0 17 14">
                <path
                  fill={isSecondaryButton ? buttonBlue : white}
                  d="M0 7.014c0-.285.113-.557.314-.758.201-.201.474-.314.758-.314h11.753L8.47 1.88c-.109-.094-.197-.21-.26-.339-.063-.13-.1-.27-.107-.414-.007-.144.014-.288.064-.423.049-.135.125-.259.223-.364.099-.105.217-.19.349-.248.132-.058.274-.09.418-.092.144-.002.286.025.42.079.133.054.254.135.356.237l6.347 5.913c.107.1.193.221.252.356.058.135.089.28.089.427 0 .147-.03.293-.09.428-.058.134-.144.256-.251.356l-6.347 5.916c-.208.194-.484.297-.768.287-.285-.01-.553-.132-.747-.34-.194-.207-.298-.484-.288-.768.01-.284.132-.553.34-.747l4.357-4.059H1.072c-.284 0-.557-.113-.758-.314C.113 7.571 0 7.298 0 7.014z"
                />
              </svg>
            </div>
          )}
        </Text>
      )}
    </EmotionButton>
  );
};

Button.defaultProps = {
  text: "",
  buttonType: ButtonType.Primary,
  disabled: false,
  onClick: () => {}, // eslint-disable-line
  loading: false,
  type: "submit",
  ariaLabel: "submit",
} as Partial<Props>;

interface FocusProps {
  isFocusVisible: boolean;
}
const EmotionButton = styled.button(({ isFocusVisible }: FocusProps) => ({
  height: 60,
  width: "100%",
  maxWidth: 355,
  borderRadius: 46,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  outline: isFocusVisible ? "2px solid dodgerblue" : "none",
  outlineOffset: 2,
  lineHeight: 1.2,
  transition: "300ms",
  "@media (max-width: 360px)": {
    maxWidth: 300,
  },
}));

const styles = StyleSheet.create({
  [ButtonType.Primary]: {
    backgroundColor: buttonBlue,
    color: white,
    border: `1px solid ${buttonBlue}`,
    ":hover": {
      backgroundColor: hoveredButtonBlue,
    },
  },
  [ButtonType.Secondary]: {
    backgroundColor: transparent,
    color: buttonBlue,
    ":hover": {
      textDecoration: "underline",
    },
  },
  [ButtonType.Tertiary]: {
    border: `1px solid ${buttonBlue}`,
    backgroundColor: buttonBlue,
    color: white,
    ":hover": {
      backgroundColor: hoveredButtonBlue,
    },
  },
  disabled: {
    opacity: 0.4,
  },
  // text
  text: {
    fontSize: 20,
    lineHeight: 1.2,
    display: "flex",
    alignItems: "center",
    "@media (max-width: 360px)": {
      fontSize: 15,
    },
  },
  rightArrowWrapper: {
    marginLeft: 10,
    display: "flex",
    alignItems: "center",
  },
});
export default Button;
