import { css, StyleSheet } from "aphrodite";
import Text, { Font } from "shared/components/Text";
import React, { useRef, useState } from "react";
import Button from "shared/components/Button";
import { gql } from "apollo-boost";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { mainGrey, turquoise, greyThree } from "shared/styles/colors";
import { useToasts } from "react-toast-notifications";
import { Formik, Form, Field } from "formik";
import { Label } from "semantic-ui-react";
import "shared/styles/Library.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styled from "@emotion/styled";
import Loader from "shared/components/Loader";

const GET_UPLOAD_DATA = gql`
  query GetData {
    organizations {
      id
      name
    }
    productionReadyCurricula {
      id
      name
    }
  }
`;

const CREATE_USERS = gql`
  mutation CreateUsers(
    $csvFile: Upload!
    $curriculumId: String!
    $cohortStart: DateTime!
    $cohortEnd: DateTime!
    $numberOfReflections: Int!
    $organizationId: String!
    $cohortName: String!
  ) {
    createUsers(
      csvFile: $csvFile
      curriculumId: $curriculumId
      cohortStart: $cohortStart
      cohortEnd: $cohortEnd
      numberOfReflections: $numberOfReflections
      organizationId: $organizationId
      cohortName: $cohortName
    ) {
      success
    }
  }
`;

const UploadUsers = () => {
  const { addToast } = useToasts();
  const { loading } = useQuery(GET_UPLOAD_DATA, {
    onCompleted: (data) => {
      setOrgnaizations(data.organizations);
      setCurricula(data.productionReadyCurricula);
    },
  });
  const [cohortStartDate, setCohortStartDate] = useState<Date | null>(
    new Date()
  );
  const [cohortEndDate, setCohortEndDate] = useState<Date | null>(new Date());
  const [organizations, setOrgnaizations] = useState<[any] | []>([]); // TODO fix types
  const [curricula, setCurricula] = useState<[any] | []>([]); // TODO fix types
  const [manualCurriculumIdInput, setManualCurriculumIdInput] = useState<
    boolean
  >(false);

  const [createUsers, { loading: createUsersLoading }] = useMutation(
    CREATE_USERS,
    {
      onCompleted: () => {
        addToast("Success!", {
          appearance: "success",
          autoDismiss: false,
        });
      },
      onError: (error) => {
        if (
          error.message ===
          "GraphQL error: Cohort name already exists for this organization"
        ) {
          addToast("Cohort name already exists for this organization", {
            appearance: "error",
            autoDismiss: false,
          });
        } else {
          addToast(error.message, {
            appearance: "error",
            autoDismiss: false,
          });
        }
      },
    }
  );

  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleSubmit = (
    curriculumId: string,
    cohortStart: Date,
    cohortEnd: Date,
    numberOfReflections: number | undefined,
    organizationId: string,
    cohortName: string
  ) => {
    if (inputRef?.current?.files) {
      createUsers({
        variables: {
          csvFile: inputRef.current.files[0],
          curriculumId,
          cohortStart,
          cohortEnd,
          numberOfReflections,
          organizationId,
          cohortName,
        },
      });
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <OuterContainer>
      <Text font={Font.ProximaNovaBold} style={styles.title}>
        Upload Users
      </Text>
      <Button
        text={
          manualCurriculumIdInput
            ? "Select Curriculum From Dropdown"
            : "Manually enter Curriculum ID"
        }
        onClick={() => setManualCurriculumIdInput(!manualCurriculumIdInput)}
        buttonStyle={styles.button}
      />
      <Formik
        initialValues={{
          curriculumId: "",
          numberOfReflections: 4,
          organizationId: "",
          cohortName: "",
        }}
        onSubmit={(values) => {
          if (
            values.curriculumId &&
            values.numberOfReflections &&
            values.organizationId &&
            values.cohortName &&
            cohortStartDate &&
            cohortEndDate
          ) {
            handleSubmit(
              values.curriculumId,
              cohortStartDate,
              cohortEndDate,
              values.numberOfReflections,
              values.organizationId,
              values.cohortName
            );
          } else {
            addToast("Make sure every field is filled out", {
              appearance: "error",
              autoDismiss: true,
            });
          }
        }}
      >
        {({ values, handleChange }) => (
          <Form className={css(styles.form)}>
            <RowItem>
              <Column>
                <Label>Organization</Label>
                <Field
                  as="select"
                  name="organizationId"
                  placeholder="Select Organization"
                  value={values.organizationId}
                  className={css(styles.input)}
                  onChange={handleChange}
                >
                  <option value="">Select Organization...</option>
                  {organizations &&
                    organizations.map((organization) => (
                      <option value={organization.id} key={organization.id}>
                        {organization.name}
                      </option>
                    ))}
                </Field>
              </Column>
              <Column>
                <Label>Curriculum {manualCurriculumIdInput && "ID"}</Label>
                {!manualCurriculumIdInput && (
                  <Field
                    as="select"
                    name="curriculumId"
                    placeholder="Select Curriculum..."
                    value={values.curriculumId}
                    className={css(styles.input)}
                  >
                    <option value="">Select Curriculum...</option>
                    {curricula &&
                      curricula.map((curriculum) => (
                        <option value={curriculum.id} key={curriculum.id}>
                          {curriculum.name}
                        </option>
                      ))}
                  </Field>
                )}
                {manualCurriculumIdInput && (
                  <Field
                    name="curriculumId"
                    placeholder="Curriculum ID"
                    value={values.curriculumId}
                    className={css(styles.input)}
                  />
                )}
              </Column>
              <Column>
                <Label>Cohort Name</Label>
                <Field
                  name="cohortName"
                  placeholder="Cohort Name"
                  value={values.cohortName}
                  className={css(styles.input)}
                />
              </Column>
            </RowItem>

            <RowItem>
              <Column>
                <Label>Cohort Start Date</Label>
                <InputContainer>
                  <CustomDatePicker
                    selected={cohortStartDate}
                    onChange={(date: Date) => setCohortStartDate(date)}
                  />
                </InputContainer>
              </Column>
              <Column>
                <Label>Cohort End Date</Label>
                <InputContainer>
                  <CustomDatePicker
                    selected={cohortEndDate}
                    onChange={(date: Date) => setCohortEndDate(date)}
                  />
                </InputContainer>
              </Column>
              <Column>
                <Label>Number of Reflections</Label>
                <Field
                  name="numberOfReflections"
                  placeholder="Number of Reflections"
                  value={values.numberOfReflections}
                  type="number"
                  className={css(styles.input)}
                />
              </Column>
            </RowItem>

            <Text font={Font.ProximaNovaRegular} style={styles.text}>
              To add new users, please upload a csv of the users
            </Text>
            <FileInput type="file" ref={inputRef} />
            <Button
              text="Submit Users"
              type="submit"
              loading={createUsersLoading}
              disabled={createUsersLoading}
              ariaLabel="Submit"
            />
          </Form>
        )}
      </Formik>
    </OuterContainer>
  );
};

const OuterContainer = styled.div({
  paddingTop: 10,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  textAlign: "center",
});

const RowItem = styled.div({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  textAlign: "center",
});

const Column = styled.div({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  textAlign: "center",
});

const CustomDatePicker = styled(DatePicker)({
  marginRight: "10px !important", // using important to override library higher level specificity
  marginLeft: 10,
});

const FileInput = styled.input({
  fontSize: 18,
  marginBottom: 50,
  textAlign: "center",
});

const InputContainer = styled.div({
  marginBottom: 20,
});

const styles = StyleSheet.create({
  title: {
    fontSize: 30,
    marginBottom: 25,
    marginTop: 20,
    color: turquoise,
  },
  form: {
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  input: {
    height: 60,
    width: 260,
    borderRadius: 8,
    border: `1px solid ${greyThree}`,
    marginBottom: 20,
    marginRight: 10,
    marginLeft: 10,
    paddingLeft: 20,
    paddingRight: 20,
  },
  text: {
    fontSize: 16,
    color: mainGrey,
    width: 200,
    marginBottom: 30,
  },
  button: {
    height: 34,
    marginBottom: 10,
  },
});

export default UploadUsers;
