import { css, StyleSheet } from "aphrodite";
import React, { useEffect, useState } from "react";
import Text, { Font } from "shared/components/Text";
import Button from "shared/components/Button";
import {
  mainBlueBlack,
  mainGrey,
  redOrange,
  turquoise,
  greyThree,
  redAlpha,
} from "shared/styles/colors";
import { Formik, Form, Field } from "formik";
import { gql } from "apollo-boost";
import { useMutation, useQuery } from "@apollo/react-hooks";
import passwordValidator from "password-validator";
import { useToasts } from "react-toast-notifications";
import ErrorMessage from "./ErrorMessage";
import CookieService, { Identifiers } from "services/Cookie/CookieService";
import { useHistory, useParams } from "react-router";
import { ROUTES } from "../../../constants";
import {
  Page,
  Identifiers as TextIdentifiers,
} from "services/Translation/enums";
import { TranslationService } from "services/Translation/Translation.Service";
import { ReactComponent as ReflectableLogo } from "static/images/ReflectableLogo.svg";
import {
  AuthInputContainer,
  InputLabel,
} from "screens/settings/shared/styledComponents";
import styled from "@emotion/styled";
import Div100vh from "react-div-100vh";
import { useDocumentTitle } from "shared/hooks";

const CREATE_PASSWORD = gql`
  mutation CreatePassword($inviteId: String!, $password: String!) {
    createPassword(inviteId: $inviteId, password: $password) {
      token
      isAdmin
    }
  }
`;

const USER_DATA = gql`
  query UserData($inviteId: String) {
    userLanguage(inviteId: $inviteId)
    signUpPromptTranslation(inviteId: $inviteId)
    hasUserGoneThroughFirstTimeFlow(inviteId: $inviteId)
    userHasPassword(inviteId: $inviteId)
  }
`;

const CreatePassword = () => {
  const [digitsError, setDigitsError] = useState("");
  const [lengthError, setLengthError] = useState("");
  const [spacesError, setSpacesError] = useState("");
  const [lettersError, setLettersError] = useState("");
  const [matchingError, setMatchingError] = useState("");
  const { addToast } = useToasts();
  const history = useHistory();
  const params: any = useParams();
  const inviteId = params?.inviteId;
  const isCreatePasswordScreen = history.location.pathname.startsWith(
    "/create-password"
  );
  useDocumentTitle(
    isCreatePasswordScreen
      ? "Create Password | Reflectable"
      : "Reset Password | Reflectable"
  );

  const [
    createPassword,
    { data: passwordCreateData, error: createPasswordError },
  ] = useMutation(CREATE_PASSWORD, {
    onError: () => {
      //
    },
  });
  const { data } = useQuery(USER_DATA, {
    variables: {
      inviteId,
    },
  });
  const signUpPromptTranslation =
    data?.signUpPromptTranslation ?? "[SIGN UP PROMPT MISSING]";

  const isFirstTime = data ? !data.hasUserGoneThroughFirstTimeFlow : true;

  useEffect(() => {
    if (data && isCreatePasswordScreen && data.userHasPassword) {
      history.push(`${ROUTES.LOGIN}/?language=${userLanguage ?? "ENGLISH"}`);
    }
  }, [data]);

  useEffect(() => {
    if (createPasswordError) {
      addToast(createPasswordError.message, {
        appearance: "error",
        autoDismiss: true,
      });
    }
    if (passwordCreateData?.createPassword?.token) {
      CookieService.setCookie(
        Identifiers.AccessToken,
        passwordCreateData.createPassword.token
      );
      if (passwordCreateData?.createPassword?.isAdmin) {
        history.push(ROUTES.ADMIN);
      } else if (isFirstTime) {
        history.push(ROUTES.INTRO_ONE, { isFirstTime: true });
      } else {
        history.push(ROUTES.DASHBOARD);
      }
    }
  }, [createPasswordError, passwordCreateData]);

  /* eslint-disable */
  const fullPasswordValidator = new passwordValidator();
  const digitsValidator = new passwordValidator();
  const spacesValidator = new passwordValidator();
  const lettersValidator = new passwordValidator();
  /* eslint-enable */
  fullPasswordValidator.has().letters().has().digits().has().not().spaces();
  digitsValidator.has().digits();
  spacesValidator.has().spaces();
  lettersValidator.has().letters();

  const formErrors =
    lengthError || digitsError || spacesError || lettersError || matchingError;

  const userLanguage = data?.userLanguage ?? "ENGLISH";

  return (
    <OuterContainer>
      <Formik
        initialValues={{ password: "", confirmPassword: "" }}
        onSubmit={(values, { setSubmitting }) => {
          if (
            values.password === values.confirmPassword &&
            values.password.length >= 8 &&
            fullPasswordValidator.validate(values.password)
          ) {
            createPassword({
              // inviteId will be replaced by the slug in the routing
              // For now, replace the empty string with the inviteId from the admin
              variables: {
                inviteId,
                password: values.password,
              },
            });
          }
        }}
      >
        {({ isSubmitting, values, handleChange }) => (
          <Form className={css(styles.form)}>
            <div className={css(styles.desktopContainer)}>
              <div className={css(styles.desktopBar)} />
              <ReflectableLogo className={css(styles.logo)} />
              <Text font={Font.ProximaNovaBold} style={styles.title}>
                {TranslationService.getStaticText({
                  page: Page.CreatePassword,
                  textIdentifier: TextIdentifiers.Title,
                  userLanguage,
                })}
              </Text>
              <Text font={Font.ProximaNovaRegular} style={styles.textBlurb}>
                {TranslationService.getStaticText({
                  page: Page.CreatePassword,
                  textIdentifier: TextIdentifiers.SubTitle,
                  userLanguage,
                })}{" "}
                {signUpPromptTranslation}
              </Text>
              <Text font={Font.ProximaNovaBold} style={styles.createText}>
                {TranslationService.getStaticText({
                  page: Page.CreatePassword,
                  textIdentifier: TextIdentifiers.CreatePassword,
                  userLanguage,
                })}
              </Text>
              <AuthInputContainer>
                <InputLabel htmlFor="password-input" id="password-input-label">
                  {TranslationService.getStaticText({
                    userLanguage,
                    page: Page.CreatePassword,
                    textIdentifier: TextIdentifiers.Password,
                  })}
                </InputLabel>
                <Field
                  id="password-input"
                  aria-labelledby="password-input-label"
                  className={
                    lengthError || digitsError || spacesError || lettersError
                      ? css(styles.errorInput)
                      : css(styles.input)
                  }
                  type="password"
                  name="password"
                  placeholder={TranslationService.getStaticText({
                    userLanguage,
                    page: Page.CreatePassword,
                    textIdentifier: TextIdentifiers.Password,
                  })}
                  value={values.password}
                  onChange={handleChange}
                  onBlur={() => {
                    if (values.password.length < 8) {
                      setLengthError("Has at least 8 characters");
                    } else {
                      setLengthError("");
                    }
                    if (!digitsValidator.validate(values.password)) {
                      setDigitsError("Contains numbers");
                    } else {
                      setDigitsError("");
                    }
                    if (spacesValidator.validate(values.password)) {
                      setSpacesError("Contains no spaces");
                    } else {
                      setSpacesError("");
                    }
                    if (!lettersValidator.validate(values.password)) {
                      setLettersError("Contains no letters");
                    } else {
                      setLettersError("");
                    }
                  }}
                />
                {formErrors && (
                  <div className={css(styles.errorContainer)}>
                    {lengthError && <ErrorMessage error={lengthError} />}
                    {digitsError && <ErrorMessage error={digitsError} />}
                    {spacesError && <ErrorMessage error={spacesError} />}
                    {lettersError && <ErrorMessage error={lettersError} />}
                    {matchingError && <ErrorMessage error={matchingError} />}
                  </div>
                )}
                <InputLabel
                  htmlFor="confirm-password-input"
                  id="confirm-password-input-label"
                >
                  {TranslationService.getStaticText({
                    userLanguage,
                    page: Page.CreatePassword,
                    textIdentifier: TextIdentifiers.ConfirmPassword,
                  })}
                </InputLabel>
                <Field
                  id="confirm-password-input"
                  aria-labelledby="confirm-password-input-label"
                  className={
                    matchingError ? css(styles.errorInput) : css(styles.input)
                  }
                  type="password"
                  name="confirmPassword"
                  placeholder={TranslationService.getStaticText({
                    userLanguage,
                    page: Page.CreatePassword,
                    textIdentifier: TextIdentifiers.ConfirmPassword,
                  })}
                  value={values.confirmPassword}
                  onChange={handleChange}
                  onBlur={() => {
                    if (values.password !== values.confirmPassword) {
                      setMatchingError("Passwords must match");
                    } else {
                      setMatchingError("");
                    }
                  }}
                />
              </AuthInputContainer>
            </div>
            <Button
              ariaLabel={TranslationService.getStaticText({
                page: Page.Shared,
                userLanguage,
                textIdentifier: TextIdentifiers.ContinueButton,
              })}
              type="submit"
              text={TranslationService.getStaticText({
                page: Page.Shared,
                userLanguage,
                textIdentifier: TextIdentifiers.ContinueButton,
              })}
            />
          </Form>
        )}
      </Formik>
    </OuterContainer>
  );
};

const OuterContainer = styled(Div100vh)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
});

const styles = StyleSheet.create({
  form: {
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  logo: {
    width: "100%",
  },
  subTitle: {
    fontSize: 16,
    color: mainBlueBlack,
    lineHeight: 1.2,
    marginBottom: 30,
  },
  title: {
    width: 335,
    fontSize: 36,
    lineHeight: 1.2,
    color: turquoise,
    marginBottom: 10,
    "@media (min-width: 630px)": {
      width: 414,
    },
  },
  textBlurb: {
    width: 319,
    fontSize: 16,
    color: mainGrey,
    marginBottom: 108,
    "@media (max-height: 700px)": {
      marginBottom: 40,
    },
    "@media (min-width: 630px)": {
      width: 394,
    },
  },
  createText: {
    letterSpacing: 2,
    lineHeight: 1.2,
    fontSize: 12,
    textTransform: "uppercase",
    marginBottom: 10,
  },
  input: {
    width: 335,
    height: 60,
    marginBottom: 10,
    borderRadius: 8,
    border: `1px solid ${greyThree}`,
    padding: 20,
    fontSize: 16,
    lineHeight: 1.2,
  },

  desktopContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: 50,
    "@media (min-width: 630px)": {
      boxShadow: "0 2px 2px 1px rgba(0, 0, 0, .1)",
      width: 630,
      height: 660,
      borderRadius: 10,
      marginBottom: 60,
    },
  },
  desktopBar: {
    display: "none",
    "@media (min-width: 630px)": {
      width: 630,
      height: 10,
      background: "#0ea6b4",
      display: "flex",
      borderRadius: "10px 10px 0 0",
      marginBottom: 20,
    },
  },
  errorInput: {
    width: 335,
    height: 60,
    marginBottom: 10,
    borderRadius: 8,
    border: `1px solid ${redOrange}`,
    padding: 20,
    fontSize: 16,
    lineHeight: 1.2,
  },
  errorContainer: {
    background: redAlpha,
    height: 78,
    width: 335,
    marginBottom: 10,
    borderRadius: 8,
    padding: "10px 20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  errorMessageContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: 5,
  },
  bulletPoint: {
    height: 5,
    width: 5,
    background: redOrange,
    borderRadius: "50%",
    marginRight: 5,
  },
  errorMessage: {
    fontSize: 12,
    lineHeight: 1.21,
    color: mainGrey,
  },
});

export default CreatePassword;
