import { useQuery } from "@apollo/react-hooks";
import { css, StyleSheet } from "aphrodite";
import gql from "graphql-tag";
import React from "react";
import { useHistory, useParams } from "react-router";
import BackButton from "shared/components/Navigation/BackButton";
import Text, { Font } from "shared/components/Text";
import {
  greyAccent,
  mainBlueBlack,
  mainGrey,
  turquoise,
} from "shared/styles/colors";
import { format, parseISO } from "date-fns";
import Card from "shared/components/Card";
import StepCard from "./StepCard";
import GraphqlErrorScreen from "shared/components/ErrorBoundary/GraphqlErrorScreen";
import ResponsiveCard from "shared/components/ResposniveCard";
import { TranslationService } from "services/Translation/Translation.Service";
import { Identifiers, Page } from "services/Translation/enums";
import HamburgerMenu from "shared/components/HamburgerMenu/HamburgerMenu";
import { useDocumentTitle } from "shared/hooks";

const REFLECTION_DETAILS_QUERY = gql`
  query ReflectionSummary($reflectionId: String!) {
    reflectionSummary(reflectionId: $reflectionId) {
      id
      completionDate
      order
      incomplete
      steps {
        id
        order
        responses {
          id
          answerText
          answerInteger
          choices {
            id
            translationData {
              text
            }
          }
          question {
            id
            order
            type
            translationData {
              text
            }
          }
        }
      }
    }
    userLanguage
  }
`;

const ReflectionDetails = () => {
  useDocumentTitle("Reflection Details | Reflectable");
  const params: any = useParams();
  const history = useHistory();
  const reflectionId = params?.reflectionId;
  const { data, loading, error } = useQuery(REFLECTION_DETAILS_QUERY, {
    variables: { reflectionId },
    fetchPolicy: "no-cache",
  });

  if (loading) {
    return null;
  }
  if (error) {
    return <GraphqlErrorScreen error={error} />;
  }
  if (data) {
    const {
      reflectionSummary: { incomplete, order, completionDate, steps },
    } = data;
    const userLanguage = data?.userLanguage;
    const stepsWithQuestions = steps.filter(
      (step) => step.order !== 1 && step.responses.length
    );
    const page = Page.ReflectionsSummary;
    return (
      <>
        <HamburgerMenu />
        <section className={css(styles.ReflectionDetails)}>
          <div className={css(styles.topContainer)}>
            <BackButton onButtonClick={() => history.goBack()} />
            <Text font={Font.ProximaNovaSemibold} style={styles.summaryText}>
              {TranslationService.getStaticText({
                page,
                userLanguage,
                textIdentifier: Identifiers.ReflectionsSummary,
              })}
            </Text>
            <Text font={Font.ProximaNovaSemibold} style={styles.dateText}>
              {TranslationService.getStaticText({
                page: Page.Shared,
                userLanguage,
                textIdentifier: Identifiers.Week,
              })}{" "}
              {order} &#40;{format(parseISO(completionDate), "M/d")}&#41;
            </Text>
          </div>
          <ResponsiveCard contentStyle={styles.cardsContainer}>
            {incomplete && (
              <Card
                cardStyle={styles.incompleteCardStyle}
                contentStyle={styles.incompleteCardContainerStyle}
                accentColor={greyAccent}
              >
                <Text
                  font={Font.ProximaNovaBold}
                  style={styles.incompleteTitle}
                >
                  {TranslationService.getStaticText({
                    page,
                    userLanguage,
                    textIdentifier: Identifiers.ReflectionIncomplete,
                  })}
                </Text>
                <Text
                  font={Font.ProximaNovaRegular}
                  style={styles.incompleteSubTitle}
                >
                  {TranslationService.getStaticText({
                    page,
                    userLanguage,
                    textIdentifier: Identifiers.ReflectionIncompleteText,
                  })}
                </Text>
              </Card>
            )}
            {stepsWithQuestions?.map((step) => (
              <StepCard
                userLanguage={userLanguage}
                stepOrder={step.order}
                responses={step.responses}
                key={step.id}
              />
            ))}
          </ResponsiveCard>
        </section>
      </>
    );
  }
  return null;
};

export default ReflectionDetails;

const styles = StyleSheet.create({
  ReflectionDetails: {
    paddingBottom: 60,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    "@media (min-width: 630px)": {
      alignItems: "center",
    },
  },
  topContainer: {
    paddingTop: 30,
    paddingLeft: 30,
    marginBottom: 20,
    "@media (min-width: 630px)": {
      width: 630,
    },
  },
  summaryText: {
    color: turquoise,
    textTransform: "uppercase",
    letterSpacing: 2,
    lineHeight: "15px",
    fontSize: 12,
    marginBottom: 5,
  },
  dateText: {
    fontSize: 24,
    color: mainBlueBlack,
    marginBottom: 10,
  },
  cardsContainer: {
    paddingTop: 30,
    paddingRight: 10,
    paddingLeft: 10,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  incompleteCardStyle: {
    width: "100%",
    marginBottom: 40,
    height: 110,
    "@media (min-width: 630px)": {
      width: "75%",
    },
  },
  incompleteCardContainerStyle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: 23,
  },
  incompleteTitle: {
    textTransform: "uppercase",
    fontSize: 12,
    color: mainGrey,
    marginBottom: 12,
    letterSpacing: 2,
  },
  incompleteSubTitle: {
    fontSize: 16,
    color: mainGrey,
    marginBottom: 32,
    textTransform: "capitalize",
  },
});
