import React, { useEffect } from "react";
import { ROUTES } from "../../constants";
import gql from "graphql-tag";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { useToasts } from "react-toast-notifications";
import GraphqlErrorScreen from "shared/components/ErrorBoundary/GraphqlErrorScreen";
import { TranslationService } from "services/Translation/Translation.Service";
import { Page, Identifiers } from "services/Translation/enums";
import { useHistory } from "react-router";
import { CREATE_EXTRA_REFLECTION } from "shared/gql/mutations";
import { useDocumentTitle } from "shared/hooks";
import { getTopicProgressFlower } from "shared/components/TopicProgress/utils";
import Div100vh from "react-div-100vh";
import styled from "@emotion/styled";
import Button, { ButtonType } from "shared/components/Button";
import ResponsiveCard from "shared/components/ResposniveCard";
import { StyleSheet } from "aphrodite";
import Spacer from "shared/components/Spacer";
import Text, { Font } from "shared/components/Text";
import { buttonBlue, mainGrey } from "shared/styles/colors";

const REFLECTION_COMPLETE_QUERY = gql`
  query ReflectionCompleteQuery {
    currentUser {
      id
      languagePreference
    }
    isLastReflection
    userLanguage
    activeReflection {
      hasActiveReflection
      reflectionHasResponses
    }
    currentUserFocus {
      translationData {
        name
      }
      topic {
        numberOfTimesPracticed
        order
        translationData {
          name
        }
      }
    }
  }
`;

const SUBMIT_REFFLECTION = gql`
  mutation SubmitReflection($isLastReflection: Boolean!) {
    submitReflection(isLastReflection: $isLastReflection) {
      success
    }
  }
`;

const ReflectionComplete = () => {
  useDocumentTitle("Reflection Complete | Reflectable");
  const { data, loading, error } = useQuery(REFLECTION_COMPLETE_QUERY, {
    fetchPolicy: "no-cache",
  });

  const [createExtraReflection] = useMutation(CREATE_EXTRA_REFLECTION, {
    onCompleted: () => {
      history.push(ROUTES.TOPIC);
    },
    onError: () => {
      addToast("Error creating new reflection", {
        autoDismiss: true,
        appearance: "error",
      });
    },
  });

  const onContinueButtonClick = async () => {
    if (isLastReflection) {
      await createExtraReflection();
    } else {
      history.push(ROUTES.TOPIC);
    }
  };

  const languagePreference = data?.currentUser?.languagePreference;
  const isLastReflection = data?.isLastReflection;
  const activeReflection = data?.activeReflection?.hasActiveReflection;
  const topicOrder: number = data?.currentUserFocus?.topic?.order;
  const timesCompleted: number =
    data?.currentUserFocus?.topic?.numberOfTimesPracticed;

  const topicProgressData = getTopicProgressFlower({
    topicOrder,
    timesCompleted: timesCompleted + 1,
    userLanguage: languagePreference,
  });

  const [
    submitReflection,
    { data: submitData, loading: submitLoading, error: submitError },
  ] = useMutation(SUBMIT_REFFLECTION);
  const history = useHistory();

  const { addToast } = useToasts();

  useEffect(() => {
    if (data) {
      if (data?.activeReflection?.reflectionHasResponses) {
        submitReflection({
          variables: {
            isLastReflection,
          },
        });
      } else {
        history.push(ROUTES.DASHBOARD);
      }
    }
  }, [data, history, isLastReflection, submitReflection]);

  useEffect(() => {
    if (submitError) {
      addToast(
        `There was an error submitting your reflection, redirecting to topic select screen: ${submitError.message}`,
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
    if (submitError && !activeReflection) {
      if (isLastReflection) {
        history.push(ROUTES.DASHBOARD);
      } else {
        history.push(ROUTES.TOPIC);
      }
    }
  }, [submitError, activeReflection, addToast, history, isLastReflection]);

  if (loading) {
    return null;
  }

  if (error) {
    return <GraphqlErrorScreen error={error} />;
  }

  const page = Page.ReflectionComplete;
  const userLanguage = data?.userLanguage ?? "ENGLISH";

  return (
    <OuterContainer>
      <Spacer />
      <ResponsiveCard
        contentStyle={styles.cardContentStyle}
        cardStyle={styles.cardStyle}
      >
        <Spacer />
        <Image src={topicProgressData.imgSrc} alt="Reflection Complete" />
        <Text style={styles.title} font={Font.ProximaNovaBold}>
          {TranslationService.getStaticText({
            page,
            userLanguage,
            textIdentifier: Identifiers.Title,
          })}
        </Text>
        <Text font={Font.ProximaNovaRegular} style={styles.bodyText}>
          {topicProgressData.copy}
        </Text>
        <Spacer />
      </ResponsiveCard>
      <Spacer />
      <Button
        text={TranslationService.getStaticText({
          page: Page.Shared,
          userLanguage,
          textIdentifier: Identifiers.ContinueButton,
        })}
        onClick={() => onContinueButtonClick()}
        loading={submitLoading}
        disabled={!submitData}
      />
      {isLastReflection && submitData && (
        <Button
          text={TranslationService.getStaticText({
            page,
            userLanguage,
            textIdentifier: Identifiers.SkipButton,
          })}
          onClick={() => history.push(ROUTES.DASHBOARD)}
          loading={submitLoading}
          buttonType={ButtonType.Secondary}
        />
      )}
      <Spacer height={46} flexGrow={0} />
    </OuterContainer>
  );
};

export default ReflectionComplete;

const OuterContainer = styled(Div100vh)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
});

const Image = styled.img({
  borderRadius: "50%",
  height: 197,
  width: 195,
  marginBottom: 31,
});

const styles = StyleSheet.create({
  cardStyle: {
    height: "100%",
    maxHeight: 660,
  },
  cardContentStyle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    height: "100%",
    padding: "0px 40px 58px",
  },
  title: {
    marginBottom: 15,
    color: buttonBlue,
    fontSize: 30,
    lineHeight: "36px",
  },
  bodyText: {
    textAlign: "center",
    fontSize: 16,
    color: mainGrey,
    marginBottom: 44,
    lineHeight: "19px",
  },
  levelText: {
    textAlign: "center",
    fontSize: 20,
    color: mainGrey,
    marginBottom: 24,
    lineHeight: "24px",
  },
});
