/* eslint-disable no-plusplus */
import { useQuery } from "@apollo/react-hooks";
import { css, StyleSheet } from "aphrodite";
import gql from "graphql-tag";
import React, { useEffect, useRef, useState } from "react";
import { white, adminOrange, disabledGrey } from "shared/styles/colors";
import Text from "shared/components/Text";
import GraphSpinner from "./GraphSpinner";
import ChartistGraph from "react-chartist";
import Chartist from "chartist";
import ChartistTooltip from "chartist-plugin-tooltips-updated";
import "chartist-plugin-tooltips";
import "chartist-plugin-legend";
import "./lineGraph.css";
import {
  graphListener,
  sliceGraphData,
  createFullTicksArray,
  updateDataView,
  generateEffectivenessValues,
  checkForDisabled,
} from "./utils";
import { Direction, GraphProps } from "shared/types";
import { TranslationService } from "services/Translation/Translation.Service";
import {
  Page,
  Identifiers as TextIdentifiers,
} from "services/Translation/enums";
import styled from "@emotion/styled";
import { GraphButton } from "./styledComponents";
import BackCarrat from "static/svgs/BackCarrat";

const LINE_GRAPH_QUERY = gql`
  query LineGraphQuestionResponsesByUser(
    $questionId: String!
    $topicId: String!
  ) {
    graphQuestionResponsesByUser(questionId: $questionId, topicId: $topicId) {
      id
      answerInteger
      createdAt
      choices {
        id
        order
        translationData {
          text
        }
      }
      reflection {
        focus {
          topic {
            id
            translationData {
              name
            }
          }
        }
      }
    }
    userLanguage
  }
`;

const LineGraph = ({
  questionId,
  textColor,
  questionTitle,
  topicId,
  setInsufficientData,
  userLanguage,
}: GraphProps) => {
  const [startIndex, setStartIndex] = useState<number>(0);

  const { data, loading } = useQuery(LINE_GRAPH_QUERY, {
    variables: { questionId, topicId },
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (
      data?.graphQuestionResponsesByUser &&
      data.graphQuestionResponsesByUser.length > 5
    ) {
      setStartIndex(data.graphQuestionResponsesByUser.length - 5);
    }
  }, [data]);

  const page = Page.Graphs;
  const chartRef = useRef<ChartistGraph>(null);
  const fullQuestionsArray = data?.graphQuestionResponsesByUser;
  const fullTicksArray = createFullTicksArray(fullQuestionsArray);
  const slicedQuestionResponses = sliceGraphData(
    fullQuestionsArray,
    startIndex
  );
  const slicedTicks = sliceGraphData(fullTicksArray, startIndex);
  const graphData = generateEffectivenessValues(slicedQuestionResponses);

  const handleButtonClick = (direction: Direction) => {
    const newStartIndex = updateDataView(
      direction,
      startIndex,
      fullQuestionsArray
    );
    setStartIndex(newStartIndex);
  };

  const disabledCheck = checkForDisabled({
    startIndex,
    fullArray: fullQuestionsArray,
    maxArrayLength: fullQuestionsArray?.length,
  });

  useEffect(() => {
    if (data) {
      const hasInsufficientData =
        data.graphQuestionResponsesByUser.length < 2 ?? true;
      setInsufficientData(hasInsufficientData);
    }
  }, [data, setInsufficientData]);

  const selectOptions = () => {
    return {
      lineSmooth: true,
      axisX: {
        type: Chartist.StepAxis,
        ticks: slicedTicks,
        low: slicedTicks[0],
        high: slicedTicks[slicedTicks.length - 1],
      },
      low: 0,
      high: 5,
      height: "200px",
      width: "320px",
      plugins: [
        ChartistTooltip({
          anchorToPoint: true,
          appendToBody: true,
          transformTooltipTextFnc: () => "",
          metaIsHTML: true,
          class: "lg-tooltip",
          tooltipOffset: {
            x: 0,
            y: 20,
          },
        }),
        Chartist.plugins.legend({
          legendNames: [
            TranslationService.getStaticText({
              page,
              textIdentifier: TextIdentifiers.MyEffectiveness,
              userLanguage,
            }),
          ],
          clickable: false,
        }),
      ],
    };
  };

  const options = selectOptions();

  if (loading) {
    return <GraphSpinner />;
  }

  return (
    <div className={`line-graph ${css(styles.LineGraph)}`}>
      <Text style={styles.dynamicText} color={adminOrange}>
        {questionTitle}
      </Text>
      <ChartistGraph
        ref={chartRef}
        data={graphData}
        options={options}
        type="Line"
        listener={graphListener}
        className="chartistGraph"
      />
      <TextAndButtonContainer>
        <Text style={styles.xAxisText} color={textColor}>
          {TranslationService.getStaticText({
            page,
            textIdentifier: TextIdentifiers.WeeksPracticed,
            userLanguage,
          })}
        </Text>
        <ButtonContainer>
          <GraphButton
            disabled={startIndex === 0 ?? true}
            onClick={() => handleButtonClick(Direction.Prev)}
            isLeftButton
            isDisabled={startIndex === 0}
          >
            <BackCarrat color={startIndex === 0 ? disabledGrey : white} />
          </GraphButton>
          <GraphButton
            disabled={disabledCheck}
            onClick={() => handleButtonClick(Direction.Next)}
            isDisabled={disabledCheck}
          >
            <BackCarrat color={disabledCheck ? disabledGrey : white} />
          </GraphButton>
        </ButtonContainer>
      </TextAndButtonContainer>
    </div>
  );
};

export default LineGraph;

const ButtonContainer = styled.div({
  display: "flex",
});

const TextAndButtonContainer = styled.div({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "80%",
  marginLeft: 35,
});

const styles = StyleSheet.create({
  LineGraph: {
    background: white,
    marginBottom: 20,
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  dynamicText: {
    textTransform: "uppercase",
    fontSize: 12,
    letterSpacing: 2,
    marginBottom: 15,
    width: 315,
  },
  xAxisText: {
    fontSize: 10,
  },
});
