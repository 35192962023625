import { Identifiers } from "../enums";
import { LanguagePreference } from "../../../shared/types";
import { Translation } from "../Translation.Service";

const translations: Translation = {
  [Identifiers.Title]: {
    [LanguagePreference.English]: "Welcome to Reflectable",
    [LanguagePreference.Spanish]: "¡Bienvenido a Reflectable!",
    [LanguagePreference.Vietnamese]: "Chào mừng đến với Reflectable",
  },
  [Identifiers.SubTitle]: {
    [LanguagePreference.English]:
      "An online reflective practice tool that helps you celebrate your successes and identify what you would like to work on.",
    [LanguagePreference.Spanish]:
      "Una herramienta de práctica reflexiva en línea que le ayuda a celebrar sus éxitos e identificar en qué le gustaría trabajar.",
    [LanguagePreference.Vietnamese]:
      "Một công cụ thực hành phản ánh trực tuyến giúp bạn tôn vinh những thành công của mình và xác định những gì bạn muốn làm.",
  },
  [Identifiers.Email]: {
    [LanguagePreference.English]: "Email or Phone Number",
    [LanguagePreference.Spanish]: "Correo electrónico o Número de Teléfono",
    [LanguagePreference.Vietnamese]: "Địa chỉ email hoặc số điện thoại",
  },
  [Identifiers.Password]: {
    [LanguagePreference.English]: "Password",
    [LanguagePreference.Spanish]: "Contraseña",
    [LanguagePreference.Vietnamese]: "Mật mã",
  },
  [Identifiers.ForgotPassword]: {
    [LanguagePreference.English]: "Forgot Password?",
    [LanguagePreference.Spanish]: "¿Se te olvidó tu contraseña?",
    [LanguagePreference.Vietnamese]: "Quên mật khẩu?",
  },
  [Identifiers.Login]: {
    [LanguagePreference.English]: "Log In",
    [LanguagePreference.Spanish]: "Iniciar sesión",
    [LanguagePreference.Vietnamese]: "Đăng nhập",
  },
};

export default translations;
