import { UserType } from "shared/types";

export interface UserTypeOption {
  key: number;
  translations: { SPANISH: string; ENGLISH: string; VIETNAMESE: string };
  value: UserType;
}

export const userTypeOptions: UserTypeOption[] = [
  {
    key: 0,
    translations: {
      ENGLISH: "Assistant Teacher",
      VIETNAMESE: "Giáo viên phụ",
      SPANISH: "Asistente de Profesor",
    },
    value: UserType.ASSISTANT_TEACHER,
  },
  {
    key: 1,
    translations: {
      ENGLISH: "Family Child Care Provider",
      VIETNAMESE: "Nhà cung cấp dịch vụ chăm sóc trẻ tại gia đình",
      SPANISH: "Proveedor de Cuidado Infantil Familiar",
    },
    value: UserType.FAMILY_CHILD_CARE_PROVIDER,
  },
  {
    key: 2,
    translations: {
      ENGLISH: "Family Engagement worker",
      VIETNAMESE: "Nhân viên Tham gia Gia đình",
      SPANISH: "Trabajador de Compromiso Familiar",
    },
    value: UserType.FAMILY_ENGAGEMENT_WORKER,
  },
  {
    key: 3,
    translations: {
      ENGLISH: "Executive Leadership",
      VIETNAMESE: "Lãnh đạo Cấp cao",
      SPANISH: "Liderazgo Ejecutivo",
    },
    value: UserType.EXECUTIVE_LEADERSHIP,
  },
  {
    key: 4,
    translations: {
      ENGLISH: "School or Site Director/Supervisor",
      VIETNAMESE: "Giám đốc hoặc Người giám sát Trường học/Nơi làm việc",
      SPANISH: "Director o Supervisor de Escuela/Sitio",
    },
    value: UserType.SCHOOL_OR_SITE_DIRECTOR_SUPERVISOR,
  },
  {
    key: 5,
    translations: {
      ENGLISH: "Home Visitor",
      VIETNAMESE: "",
      SPANISH: "",
    },
    value: UserType.HOME_VISITOR,
  },
  {
    key: 6,
    translations: {
      ENGLISH: "Paraprofessional",
      VIETNAMESE: "Người thăm nhà",
      SPANISH: "Visitante del Hogar",
    },
    value: UserType.PARAPROFESSIONAL,
  },
  {
    key: 7,
    translations: {
      ENGLISH: "Parent",
      VIETNAMESE: "Cha mẹ",
      SPANISH: "Padre",
    },
    value: UserType.PARENT,
  },
  {
    key: 8,
    translations: {
      ENGLISH: "Technical Assistance Provider",
      VIETNAMESE: "Nhà cung cấp Hỗ trợ Kỹ thuật",
      SPANISH: "Proveedor de Asistencia Técnica",
    },
    value: UserType.TECHNICAL_ASSISTANCE_PROVIDER,
  },
  {
    key: 9,
    translations: {
      ENGLISH: "Teacher",
      VIETNAMESE: "Giáo viên",
      SPANISH: "Profesor",
    },
    value: UserType.TEACHER,
  },
];

export const languageOptions = [
  { key: 0, text: "English", value: "ENGLISH" },
  {
    key: 1,
    text: "Spanish",
    value: "SPANISH",
  },
  {
    key: 2,
    text: "Vietnamese",
    value: "VIETNAMESE",
  },
];
