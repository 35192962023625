import { css, StyleSheet } from "aphrodite";
import { ROUTES } from "../constants";
import React from "react";
import { useHistory } from "react-router";
import Button, { ButtonType } from "shared/components/Button";
import Text, { Font } from "shared/components/Text";
import Spacer from "shared/components/Spacer";
import { backgroundWhite } from "shared/styles/colors";
import horizontalLogo from "static/images/horizontal-logo.png";
import { useDocumentTitle } from "shared/hooks";

const NotFoundPage = () => {
  useDocumentTitle("404 | Reflectable");
  const history = useHistory();

  return (
    <section className={css(styles.NotFoundPage)}>
      <img
        alt="logo"
        title="logo"
        src={horizontalLogo}
        className={css(styles.horizontalLogo)}
      />
      <Spacer />
      <Text style={styles.header} font={Font.ProximaNovaBold}>
        404
      </Text>
      <Text style={styles.subText} font={Font.ProximaNovaRegular}>
        Oops! We can&apos;t seem to find what you&apos;re looking for
      </Text>
      <Spacer />
      <Button
        ariaLabel="Dashboard"
        buttonType={ButtonType.Tertiary}
        onClick={() => history.push(ROUTES.DASHBOARD)}
        text="Go to Dashboard"
      />
    </section>
  );
};

export default NotFoundPage;

const styles = StyleSheet.create({
  NotFoundPage: {
    width: "100vw",
    height: "100vh",
    padding: 42,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: backgroundWhite,
    "@media (min-width: 700px)": {
      paddingBottom: "10%",
    },
  },
  //
  header: {
    fontSize: 80,
    color: "#2c2c2c",
    marginBottom: 32,
    "@media (min-width: 700px)": {
      fontSize: 124,
    },
  },
  subText: {
    fontSize: 24,
    lineHeight: 1.3,
    color: "#2c2c2c",
    width: 297,
    textAlign: "center",
    "@media (min-width: 700px)": {
      fontSize: 30,
      width: 397,
    },
  },
  notFoundImage: {
    width: 164,
    height: "auto",
    "@media (min-width: 700px)": {
      width: 231,
    },
  },
  horizontalLogo: {
    position: "absolute",
    top: 24,
    left: 24,
    width: 164,
    height: "auto",
    "@media (min-width: 700px)": {
      top: 48,
      left: 42,
      width: 231,
    },
  },
});
