import React, { useEffect, useState } from "react";
import SignUpText from "../InvitationText";
import { StyleSheet, css } from "aphrodite";
import {
  Input,
  InputLabel,
  Form,
} from "../../../screens/settings/shared/styledComponents";
import Text from "shared/components/Text";
import styled from "@emotion/styled";
import { TranslationService } from "services/Translation/Translation.Service";
import {
  Page,
  Identifiers as TextIdentifiers,
} from "services/Translation/enums";
import { LanguagePreference, UserDataKeys } from "shared/types";
import ErrorMessage from "screens/auth/CreatePassword/ErrorMessage";
import { redOrange, redAlpha } from "shared/styles/colors";
import { phoneNumberValidator } from "../../../shared/utils";

interface Props {
  onUserInfoChange: ({
    fullName,
    nickname,
    phoneNumber,
  }: {
    fullName?: string;
    nickname?: string;
    phoneNumber?: string;
  }) => void;
  userLanguage: LanguagePreference;
  setIsNextDisabled: (isNextDisabled) => void;
  fullName: string;
  nickname: string;
  phoneNumber: string;
}

const WelcomeScreen = ({
  onUserInfoChange,
  userLanguage,
  setIsNextDisabled,
  fullName,
  nickname,
  phoneNumber,
}: Props) => {
  const [fullNameLengthError, setFullNameLengthError] = useState<string>("");
  const [nicknameLengthError, setNicknameLengthError] = useState<string>("");
  const [phoneValidationError, setPhoneValidationError] = useState<string>("");

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    key: UserDataKeys
  ) => {
    onUserInfoChange({ [key]: event.target.value });
  };

  const titleTextFontSize = 36;
  const translationOptions = {
    page: Page.UserInvited,
    userLanguage,
  };

  const titleText = TranslationService.getStaticText({
    textIdentifier: TextIdentifiers.IntroTitle,
    ...translationOptions,
  });
  const paragraphText = TranslationService.getStaticText({
    textIdentifier: TextIdentifiers.WelcomeSubtitle,
    ...translationOptions,
  });
  const fullNameLabel = TranslationService.getStaticText({
    textIdentifier: TextIdentifiers.FullName,
    ...translationOptions,
  });
  const required = TranslationService.getStaticText({
    textIdentifier: TextIdentifiers.Required,
    ...translationOptions,
  });
  const nicknameRequired = TranslationService.getStaticText({
    textIdentifier: TextIdentifiers.Nickname,
    ...translationOptions,
  });
  const phoneNumberRequired = TranslationService.getStaticText({
    textIdentifier: TextIdentifiers.PhoneNumber,
    ...translationOptions,
  });
  const fullNameError = TranslationService.getStaticText({
    textIdentifier: TextIdentifiers.FullNameError,
    ...translationOptions,
  });
  const nicknameError = TranslationService.getStaticText({
    textIdentifier: TextIdentifiers.NicknameError,
    ...translationOptions,
  });
  const phoneError = TranslationService.getStaticText({
    textIdentifier: TextIdentifiers.PhoneError,
    ...translationOptions,
  });
  const fullNamePlaceholder = TranslationService.getStaticText({
    textIdentifier: TextIdentifiers.FullNameDefault,
    ...translationOptions,
  });
  const nicknamePlaceholder = TranslationService.getStaticText({
    textIdentifier: TextIdentifiers.NicknameDefault,
    ...translationOptions,
  });
  const phonePlaceholder = TranslationService.getStaticText({
    textIdentifier: TextIdentifiers.PhoneNumberDefault,
    ...translationOptions,
  });

  useEffect(() => {
    setIsNextDisabled(true);
    if (
      fullName.length > 0 &&
      nickname.length > 0 &&
      phoneNumberValidator(phoneNumber)
    ) {
      setIsNextDisabled(false);
    }
  }, [setIsNextDisabled, fullName, nickname, phoneNumber]);

  return (
    <WelcomeScreenWrapper>
      <SignUpText
        titleText={titleText}
        titleTextFontSize={titleTextFontSize}
        paragraphText={paragraphText}
      />
      <Form className={css(styles.formContainer)}>
        <InputLabel htmlFor="nickname-input" id="nickname-input-label">
          {nicknameRequired}
        </InputLabel>
        <Input
          value={nickname}
          placeholder={nicknamePlaceholder}
          onChange={(event) => handleInputChange(event, "nickname")}
          className={
            nicknameLengthError ? css(styles.errorInput) : css(styles.input)
          }
          isActive
          isNewUser
          onBlur={() => {
            if (nickname.length < 1) {
              setNicknameLengthError(nicknameError);
            } else {
              setNicknameLengthError("");
            }
          }}
        />
        {nicknameLengthError && (
          <div className={css(styles.errorContainer)}>
            <ErrorMessage error={nicknameLengthError} />
          </div>
        )}
        <InputRequiredContainer>
          <InputLabel htmlFor="name-input" id="name-input-label">
            {fullNameLabel}
          </InputLabel>
          <Text style={styles.inputeRequired}>{required}</Text>
        </InputRequiredContainer>
        <Input
          value={fullName}
          placeholder={fullNamePlaceholder}
          onChange={(event) => handleInputChange(event, "fullName")}
          className={
            fullNameLengthError ? css(styles.errorInput) : css(styles.input)
          }
          isActive
          isNewUser
          onBlur={() => {
            if (fullName.length < 1) {
              setFullNameLengthError(fullNameError);
            } else {
              setFullNameLengthError("");
            }
          }}
        />
        {fullNameLengthError && (
          <div className={css(styles.errorContainer)}>
            <ErrorMessage error={fullNameLengthError} />
          </div>
        )}
        <InputLabel htmlFor="phone-number-input" id="phone-number-input-label">
          {phoneNumberRequired}
        </InputLabel>
        <Input
          value={phoneNumber}
          placeholder={phonePlaceholder}
          onChange={(event) => handleInputChange(event, "phoneNumber")}
          className={css(styles.input)}
          isActive
          isNewUser
          onBlur={() => {
            if (phoneNumberValidator(phoneNumber)) {
              setPhoneValidationError("");
            } else {
              setPhoneValidationError(phoneError);
            }
          }}
        />
        {phoneValidationError && (
          <div className={css(styles.errorContainer)}>
            <ErrorMessage error={phoneValidationError} />
          </div>
        )}
      </Form>
    </WelcomeScreenWrapper>
  );
};

export default WelcomeScreen;

const WelcomeScreenWrapper = styled.div({
  marginTop: 19,
  marginBottom: 28,
});

const InputRequiredContainer = styled.div({
  display: "flex",
  justifyContent: "space-between",
  width: 335,
});

const styles = StyleSheet.create({
  welcomeScreen: {
    marginTop: 19,
    marginBottom: 28,
  },
  formContainer: {
    marginTop: 20,
    marginLeft: 29,
    marginRight: 26,
  },
  input: {
    height: 60,
  },
  errorInput: {
    border: `1px solid ${redOrange}`,
    height: 60,
  },
  inputRequiredContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  inputeRequired: {
    fontSize: 12,
  },
  errorContainer: {
    background: redAlpha,
    height: "100%",
    width: 335,
    marginBottom: 10,
    borderRadius: 8,
    padding: "10px 20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
});
