import React from "react";
import styled from "@emotion/styled";
import { darkGreenBlue, greenBlue, white } from "shared/styles/colors";

interface Props {
  isChecked: boolean;
  onChange: () => void;
}

const Checkbox = ({ isChecked, onChange }: Props) => {
  return (
    <CustomCheckbox isChecked={isChecked} onClick={onChange}>
      {isChecked && (
        <svg
          width="14"
          height="10"
          viewBox="0 0 14 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M1 4.18182L4.87175 8L13 1" stroke="white" strokeWidth="2" />
        </svg>
      )}
    </CustomCheckbox>
  );
};

export default Checkbox;

const CustomCheckbox = styled.div<{ isChecked: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  width: 25px;
  border: 1px solid ${(props) => (props.isChecked ? darkGreenBlue : greenBlue)};
  border-radius: 5px;
  background-color: ${(props) => (props.isChecked ? darkGreenBlue : white)};
  margin-right: 12px;
  cursor: pointer;
`;
