import React from "react";
import { teal, white } from "shared/styles/colors";

const UnlockedIcon = () => {
  return (
    <svg
      width="86"
      height="86"
      viewBox="0 0 86 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="43" cy="43" r="43" fill={teal} />
      <path
        d="M51.563 34.1v-1.85c0-5.1-4.066-9.25-9.063-9.25-4.997 0-9.063 4.15-9.063 9.25v5.55h-1.812c-2 0-3.625 1.66-3.625 3.7v14.8c0 2.04 1.626 3.7 3.625 3.7h21.75c2 0 3.625-1.66 3.625-3.7V41.5c0-2.04-1.626-3.7-3.625-3.7H37.062v-5.55c0-3.06 2.44-5.55 5.438-5.55 2.998 0 5.438 2.49 5.438 5.55v1.85h3.624z"
        fill={white}
      />
    </svg>
  );
};

export default UnlockedIcon;
